import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment/moment'

import ApplicationsMenu from 'components/common/applications-menu'
import ResizableTable from 'components/common/resizable-table'

import { isLongRunningCluster } from 'utils/isLongRunningCluster'

const getColumns = () => {
  return [
    {
      accessor: 'name',
      Header: 'Name',
    },
    {
      accessor: 'group',
      Header: 'Group',
    },

    {
      accessor: 'applications',
      Header: 'Applications',
    },
    {
      accessor: 'uptime',
      Header: 'Uptime',
    },
  ]
}

const getTableData = (props) => {
  const { airflowClusters, toggleSshDialog } = props

  return airflowClusters
    ? airflowClusters.map((airflowCluster) => ({
        name: airflowCluster.cluster_name,
        group: airflowCluster.owned_by,
        applications: (
          <ApplicationsMenu
            activeCluster={airflowCluster}
            toggleSshDialog={toggleSshDialog}
            activeTab='airflow'
          />
        ),
        uptime: (
          <span
            style={{
              color: isLongRunningCluster(moment(airflowCluster.created_at).fromNow())
                ? '#fe0000'
                : '#000000',
            }}
          >
            {moment().diff(airflowCluster.created_at, 'days') > 0
              ? `${moment().diff(airflowCluster.created_at, 'days')} days`
              : moment(airflowCluster.created_at).fromNow(true)}
          </span>
        ),
      }))
    : []
}

export default function AirflowClustersTable(props) {
  const { isLoading } = props

  const tableData = getTableData(props)
  const columns = getColumns()

  return (
    <ResizableTable
      data={tableData}
      columns={columns}
      isLoading={isLoading}
      noRowsMessage='No clusters found.'
    />
  )
}

AirflowClustersTable.propTypes = {
  searchValue: PropTypes.string.isRequired,
  airflowClusters: PropTypes.array.isRequired,
  isFetchingAirflowClusters: PropTypes.bool,
}

AirflowClustersTable.defaultProps = {
  searchValue: '',
  isFetchingAirflowClusters: false,
}
