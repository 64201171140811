import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rebass'
import { faRetweet } from '@fortawesome/fontawesome-free-solid'
import IconButton from '@nike/nr-vamp-xl-icon-button'

import ResizableTable from 'components/common/resizable-table'

const getColumns = () => {
  return [
    {
      accessor: 'startTime',
      Header: 'Start time',
    },
    {
      accessor: 'endTime',
      Header: 'End time',
    },
    {
      accessor: 'scheduledStatus',
      Header: 'Status',
    },
    {
      accessor: 'diagnostics',
      Header: 'Diagnostics',
    },
    {
      accessor: 'errorMessage',
      Header: 'Error message',
    },
    {
      accessor: 'rerun',
      Header: 'Rerun',
    },
  ]
}

const getTableData = ({ scheduledJobs, handleRerun }) => {
  return scheduledJobs
    ? scheduledJobs
        .sort((current, nextItem) =>
          new Date(current.start_time) < new Date(nextItem.start_time) ? -1 : 1
        )
        .map((scheduledJob) => ({
          startTime: scheduledJob.start_time,
          endTime: scheduledJob.end_time,
          scheduledStatus: scheduledJob.scheduled_status,
          diagnostics: <span style={{ whiteSpace: 'normal' }}>{scheduledJob.diagnostics}</span>,
          errorMessage: <span style={{ whiteSpace: 'normal' }}>{scheduledJob.error_message}</span>,
          rerun: (
            <Box margin='0 auto' width='35px'>
              {scheduledJob.scheduled_status !== 'SCHEDULE_PENDING' && (
                <IconButton
                  icon={faRetweet}
                  onCheck={() => handleRerun(scheduledJob.schedule_job_id)}
                  styleExtension={{
                    container: { backgroundColor: 'transparent' },
                    iconStyle: { '&:hover': { color: '#3498DB' } },
                  }}
                />
              )}
            </Box>
          ),
        }))
    : []
}

export default function ScheduledJobsTable(props) {
  const { isRerunJob, isFetchingScheduledJobs } = props

  const data = getTableData(props)
  const columns = useMemo(() => getColumns(props), [props])

  return (
    <Box mt='30px' style={{ maxHeight: '500px', overflow: 'scroll' }}>
      <ResizableTable
        columns={columns}
        data={data}
        noRowsMessage='No jobs found.'
        isLoading={isRerunJob || isFetchingScheduledJobs}
      />
    </Box>
  )
}

ScheduledJobsTable.propTypes = {
  isRerunJob: PropTypes.bool.isRequired,
  isFetchingScheduledJobs: PropTypes.bool.isRequired,
  scheduledJobs: PropTypes.array.isRequired,
}
