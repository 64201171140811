import { createSelector } from 'reselect'

import { selectRuntimes } from './selectRuntimes'

const selectedRuntime = (state) => state.get('selectedRuntime')

export const selectSelectedRuntime = createSelector(
  [selectedRuntime, selectRuntimes],
  (selectedRuntime, runtimes) => {
    if (!selectedRuntime) {
      return localStorage.getItem('selectedRuntime') || runtimes.get(0)
    }

    return runtimes.contains(selectedRuntime) ? selectedRuntime : runtimes.get(0)
  }
)
