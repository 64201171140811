import React, { useState } from 'react'

import Iframe from 'components/common/iframe'
import { Button, TextInput, Modal, Select, Spinner } from '@nike/epic-react-ui-old'

const IFRAME_STYLE = {
  display: 'block',
  border: 'inset',
  width: '100%',
  height: '500px',
  overflow: 'auto',
  padding: '0',
  marginTop: '8px',
}

export default function GenieLog({ isOpen, toggleDialog, activeCluster, endpoint }) {
  const [error, setError] = useState()
  const [pending, setPending] = useState(false)
  const [logFiles, setLogFiles] = useState()
  const [logFile, setLogFile] = useState()

  async function fetchLog() {
    setLogFile(null)
    const genie_id = document.getElementById('genie_id').value
    if (!/^[a-z0-9-]+$/.test(genie_id)) {
      setError('must only contain letters, digits and dashes')
      return
    }
    setError(null)
    setPending(true)
    const url = endpoint + '?prefix=' + genie_id + '/'
    const genie_res = await fetch(url)
    if (genie_res.status !== 200) setError(await genie_res.text())
    else {
      const res = await genie_res.json()
      if (Array.isArray(res))
        setLogFiles(
          res.reduce((options, item) => {
            options.push({ label: item, value: url + item })
            return options
          }, [])
        )
      else setLogFile(res)
    }
    setPending(false)
  }

  async function getLogFile(url) {
    setPending(true)
    const res = await fetch(url)
    setLogFile(await res.json())
  }

  return (
    <Modal
      modalSize='md'
      title={activeCluster.cluster_name + ' Genie Logs'}
      show={isOpen}
      onClose={() => {
        setError(null)
        setLogFile(null)
        setLogFiles(null)
        setPending(false)
        toggleDialog()
      }}
    >
      <div style={{ backgroundColor: '#eee', padding: '4px' }}>
        <TextInput
          id='genie_id'
          label='Genie Job ID'
          hasErrors={!!error}
          errorMessage={error}
          required={true}
        />
        {!logFiles && (
          <Button onClick={fetchLog} data-external-id='show-logs'>
            Show Logs
          </Button>
        )}
      </div>
      {logFiles && (
        <Select
          label='Select a log file'
          onChange={(selected) => getLogFile(selected.value)}
          options={logFiles}
          autoFocus={true}
          className='ml-2'
        ></Select>
      )}
      {pending && <Spinner />}
      {logFile && <Iframe src={logFile} style={IFRAME_STYLE} onLoad={() => setPending(false)} />}
    </Modal>
  )
}
