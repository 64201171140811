export const getAlertConfig = (sendAlert, onSuccess, onFailure, email) => {
  const alertConfig = {
    alert_configured: false,
  }

  if (sendAlert) {
    alertConfig.alert_configured = true

    if (onSuccess && onFailure) {
      alertConfig.alert_condition = 'on_success_failure'
    } else if (onSuccess) {
      alertConfig.alert_condition = 'on_success_only'
    } else if (onFailure) {
      alertConfig.alert_condition = 'on_failure_only'
    }

    alertConfig.alert_email = email
  }

  return alertConfig
}
