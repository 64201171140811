import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { Modal, Button } from '@nike/epic-react-ui-old'
import { Flex } from 'rebass'
import styled from '@emotion/styled'
import ReactGA from 'react-ga'

const H2 = styled.h2`
  margin: 10px 0;
  font-size: ${(props) => props.fontSize}px !important;
  text-align: ${(props) => props.textAlign || 'center'} !important;
  color: #000000 !important;
`
const H3 = styled.h3`
  color: #ff0000 !important;
`
const A = styled.a`
  color: #007bff;
`

export default function DeleteJobDialog(props) {
  const { deleteJob, toggleDialog, isOpen } = props

  const jobToDelete = props.jobToDelete ? props.jobToDelete : { job_id: '', job_name: '' }

  function handleDeleteJob() {
    console.log('  handleDeleteJob:', jobToDelete.job_id)
    deleteJob(jobToDelete.job_id).then(() => {
      ReactGA.event({ category: 'JOBS', action: 'Delete Job' })
    })

    toggleDialog()
  }

  console.log('DeleteJobDialog')
  console.log('  jobToDelete', jobToDelete)

  return (
    <Modal
      title='Stop this job?'
      show={isOpen}
      onClose={toggleDialog}
      modalSize='sm'
      css={css`
        z-index: 500;
      `}
    >
      <H2 fontSize={18}>Job Name: {jobToDelete.job_name}</H2>
      <H2 fontSize={18}>Job ID: {jobToDelete.job_id}</H2>

      <H3>
        Some jobs cannot be stopped using this method. Contact the platform team at{' '}
        <A
          href='https://app.slack.com/client/T0G3T5X2B/C0N14CG87'
          target='_blank'
          rel='noopener noreferrer'
        >
          #ep-ae-ngap-collaboration
        </A>{' '}
        if you need assistance.
      </H3>

      <Flex justifyContent='center' mt='30px'>
        <Button
          data-e2e='cancel-delete-job-button'
          data-external-id='cancel-delete-job'
          id='cancel-delete-job-button'
          onClick={toggleDialog}
          inverse
          style={{ marginRight: '5px' }}
        >
          Cancel
        </Button>

        <Button
          data-e2e='confirm-delete-job-button'
          data-external-id='confirm-delete-job'
          id='confirm-delete-job-button'
          onClick={handleDeleteJob}
          style={{ marginLeft: '5px' }}
        >
          Stop
        </Button>
      </Flex>
    </Modal>
  )
}

DeleteJobDialog.propTypes = {
  toggleDialog: PropTypes.func.isRequired,
  jobToDelete: PropTypes.object,
  deleteJob: PropTypes.func,
}
