import React from 'react'

import { toast } from 'react-toastify'

import AuthTokenToast from 'components/common/auth-token-toast'

export const showAuthTokenToast = (authToken) => {
  toast(<AuthTokenToast authToken={authToken} />, {
    autoClose: false,
    closeOnClick: false,
    position: 'top-left',
  })
}
