import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import IntegratedPlatform from '@nike/console-integrated-platform'
import { environment } from 'utils/constant'
// Advanced compute uses prod tokens
const envMapping = {
  staging: 'prod',
  develop: 'prod',
  production: 'prod',
}

function IntegratedPlatformWrapper({ navbarTabs, children, accessToken, location }) {
  // Don't show Metadata Explorer and Databricks links on PC navbar
  const filteredNavbarTabs = navbarTabs.filter(
    (x) => x.path !== '/metadataexplorer' && x.path !== '/databricks'
  )

  // Todo: This makes sure there's only one set of nav links for screenreaders.
  // Remove conditional when NGAP navbar is removed.
  const navBarConfig =
    location.pathname === '/'
      ? {
          name: 'Advanced Compute',
          rootPath: '/',
          routes: filteredNavbarTabs,
          RouterLink: ({ href, ...props }) => <Link to={href} {...props} />,
        }
      : {}

  const platformConsoleConfig = {
    /** Application Name */
    name: 'Advanced Compute',
    /** Slack URL, linked on Error screen if this component's error boundary is reached */
    slackURL: 'https://app.slack.com/client/T0G3T5X2B/C0N14CG87',
    /** Boolean of whether or not user is logged in */
    isLoggedIn: true,
    /** Boolean of whether or not auth check has finished */
    hasAuthCheckFinished: true,
    /** Source and Show Console Navigation whether on console domain or not, defaults to false */
    showConsoleNav: true,
    /** Specify whether you want to let Platform console render "logged out" state (inludes login button). Defaults to true */
    useConsoleLoginScreen: false,
    /** Platform Console NavBar configuration */
    navBarConfig,
    /** Tech Solution ID from Platform Console */
    techSolutionId: 'adf81adb-3786-4f68-8c75-496d1bba8afc',
    /** Okta Access token; NOT client_id */
    accessToken,
    env: envMapping[environment] ?? '',
  }

  return <IntegratedPlatform {...platformConsoleConfig}>{children}</IntegratedPlatform>
}

IntegratedPlatformWrapper.propTypes = {
  location: PropTypes.object.isRequired,
  accessToken: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  navbarTabs: PropTypes.array.isRequired,
}

export default IntegratedPlatformWrapper
