import React, { useEffect, useState, useCallback } from 'react'
import { Button, Spinner } from '@nike/epic-react-ui-old'
import { Box, Flex } from 'rebass'
import { Form } from 'formik'
import axios from 'axios'

import BasicDetailsFields from 'components/self-service/basic-details-fields'
import DefineTableFields from 'components/self-service/define-table-fields'
import ReviewFields from 'components/self-service/review-fields'

import { getCookie } from 'utils/cookie'
import { SELF_SERVICE_ENDPOINT } from 'utils/constant'
import { handleSetFormValues } from 'utils/auto-table-form-helpers'

function getStepContent({
  autoTableActiveStep,
  formikProps,
  autoTableFormProps,
  inferredColumns,
  setSavedJobId,
  savedJobId,
  sourceLocationWasEdited,
  setSourceLocationWasEdited,
  isFetchingInferredColumns,
  setIsFetchingInferredColumns,
  curatedColumns,
}) {
  switch (autoTableActiveStep) {
    case 0:
      return (
        <BasicDetailsFields
          {...formikProps}
          username={autoTableFormProps.username}
          selectedGroup={autoTableFormProps.selectedGroup}
          setSourceLocationWasEdited={setSourceLocationWasEdited}
          setSavedJobId={setSavedJobId}
          hasResetAutoTable={autoTableFormProps.hasResetAutoTable}
          selfServiceIsSecure={autoTableFormProps.selfServiceIsSecure}
        />
      )

    case 1:
      return (
        <DefineTableFields
          {...formikProps}
          inferredColumns={inferredColumns}
          curatedColumns={curatedColumns}
          setSavedJobId={setSavedJobId}
          savedJobId={savedJobId}
          selectedGroup={autoTableFormProps.selectedGroup}
          sourceLocationWasEdited={sourceLocationWasEdited}
          setSourceLocationWasEdited={setSourceLocationWasEdited}
          isFetchingInferredColumns={isFetchingInferredColumns}
          setIsFetchingInferredColumns={setIsFetchingInferredColumns}
        />
      )
    case 2:
      return (
        <ReviewFields
          {...formikProps}
          selectedGroup={autoTableFormProps.selectedGroup}
          selfServiceIsSecure={autoTableFormProps.selfServiceIsSecure}
          setAutoTableActiveStep={autoTableFormProps.setAutoTableActiveStep}
        />
      )
    default:
      return ''
  }
}

function FormButtons(props) {
  const {
    setAutoTableActiveStep,
    autoTableActiveStep,
    formikProps,
    isFetchingInferredColumns,
    steps,
  } = props

  function handleAutoTableNext() {
    setAutoTableActiveStep(autoTableActiveStep + 1)
  }

  function handleAutoTableBack() {
    setAutoTableActiveStep(autoTableActiveStep - 1)
  }

  function isPrimaryButtonDisabled() {
    const { isValid, values } = formikProps

    return (
      !isValid ||
      (autoTableActiveStep === 1 // Define Table step
        ? isFetchingInferredColumns ||
          (values.inferredColumns.length <= 0 && values.inferredPartitionColumns.length <= 0)
        : false)
    )
  }

  return (
    <Flex width='620px' m='15px auto 50px' justifyContent='space-between'>
      <Button
        disabled={autoTableActiveStep === 0}
        onClick={handleAutoTableBack}
        inverse
        large
        data-external-id='auto-table-form-back'
      >
        Back
      </Button>

      <Button
        onClick={(event) => {
          event.preventDefault()
          if (autoTableActiveStep === steps.length - 1) {
            formikProps.handleSubmit()
          } else {
            handleAutoTableNext()
          }
        }}
        disabled={isPrimaryButtonDisabled()}
        large
        data-external-id='auto-table-form-next'
      >
        {autoTableActiveStep === steps.length - 1 ? 'Create Table' : 'Next'}
      </Button>

      <Button
        onClick={formikProps.handleReset}
        inverse
        large
        data-external-id='auto-table-form-reset'
      >
        Reset
      </Button>
    </Flex>
  )
}

export function AutoTableForm(props) {
  const {
    formikProps,
    steps,
    hasResetAutoTable,
    setHasResetAutoTable,
    autoTableActiveStep,
    setAutoTableActiveStep,
    savedJobId,
    setSavedJobId,
  } = props

  const [sourceLocationWasEdited, setSourceLocationWasEdited] = useState(false)

  const [isFetchingInferredColumns, setIsFetchingInferredColumns] = useState(false)

  const [isFetchingTableJobValues, setIsFetchingTableJobValues] = useState(false)

  const jobIdFromCookie = getCookie('tableJobId')
  const cloneJobIdFromCookie = getCookie('cloneJobId')

  const resetFormValues = useCallback(
    function (tableJobId) {
      if (!hasResetAutoTable && !isFetchingTableJobValues) {
        setIsFetchingTableJobValues(true)

        axios
          .get(`${SELF_SERVICE_ENDPOINT}/jobs/v1/${tableJobId}`)
          .then((response) =>
            handleSetFormValues(response, formikProps, setHasResetAutoTable, setSavedJobId)
          )
          .finally(() => setIsFetchingTableJobValues(false))
      }
    },
    [
      hasResetAutoTable,
      isFetchingTableJobValues,
      setIsFetchingTableJobValues,
      formikProps,
      setHasResetAutoTable,
      setSavedJobId,
    ]
  )

  useEffect(() => {
    if (cloneJobIdFromCookie) {
      resetFormValues(cloneJobIdFromCookie)
    }

    if (jobIdFromCookie) {
      resetFormValues(jobIdFromCookie)
    }
  }, [
    jobIdFromCookie,
    cloneJobIdFromCookie,
    hasResetAutoTable,
    isFetchingTableJobValues,
    resetFormValues,
  ])

  if (isFetchingTableJobValues) {
    // TODO: is this needed?
    return (
      <Box mt='100px' m='100px auto' width='100px'>
        <Spinner medium />
      </Box>
    )
  }

  return (
    <Box style={{ width: '70%', margin: '0 auto' }}>
      <Form>
        {getStepContent({
          autoTableActiveStep,
          formikProps,
          autoTableFormProps: props,
          savedJobId,
          setSavedJobId,
          sourceLocationWasEdited,
          setSourceLocationWasEdited,
          isFetchingInferredColumns,
          setIsFetchingInferredColumns,
        })}

        <FormButtons
          setAutoTableActiveStep={setAutoTableActiveStep}
          autoTableActiveStep={autoTableActiveStep}
          formikProps={formikProps}
          isFetchingInferredColumns={isFetchingInferredColumns}
          steps={steps}
        />
      </Form>
    </Box>
  )
}

export default AutoTableForm
