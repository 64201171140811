import { createSelector } from 'reselect'
import { List } from 'immutable'

import { selectGroupConfig } from './selectGroupConfig'
import { selectSelectedAccount } from './selectSelectedAccount'

export const selectRegions = createSelector(
  [selectGroupConfig, selectSelectedAccount],
  (groupConfig, selectedAccount) => {
    const groupServices = groupConfig.getIn(['group_services', selectedAccount])

    return groupServices ? List(groupServices.keySeq()) : new List()
  }
)
