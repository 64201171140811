import { createSelector } from 'reselect'
import { List } from 'immutable'

import { selectAuthToken } from './selectAuthToken'

import { getGroupsFromToken } from 'utils/getGroupsFromToken'

export const selectGroups = createSelector([selectAuthToken], (authToken) => {
  if (authToken) {
    return getGroupsFromToken(authToken)
  } else {
    return new List()
  }
})
