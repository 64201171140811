import { List, Map } from 'immutable'

import {
  LIST_JOBS_REQUEST,
  LIST_JOBS_SUCCESS,
  LIST_JOBS_FAILURE,
  CREATE_JOB_SUCCESS,
  CREATE_JOB_FAILURE,
  DESCRIBE_JOB_REQUEST,
  DESCRIBE_JOB_SUCCESS,
  DESCRIBE_JOB_FAILURE,
  DELETE_JOB_REQUEST,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_FAILURE,
  GENERATE_AIRFLOW_OPERATOR_REQUEST,
  GENERATE_AIRFLOW_OPERATOR_SUCCESS,
  GENERATE_AIRFLOW_OPERATOR_FAILURE,
} from 'utils/constant'

const initialState = new Map({
  isListing: false,
  data: new List(),
  selectedJob: {},
  airflowOperator: new List(),
})

export default function jobs(state = initialState, action = {}) {
  switch (action.type) {
    case LIST_JOBS_REQUEST:
    case DESCRIBE_JOB_REQUEST:
    case DELETE_JOB_REQUEST:
    case GENERATE_AIRFLOW_OPERATOR_REQUEST:
      return state.set('isListing', true)

    case LIST_JOBS_SUCCESS:
      return state.set('data', action.jobs).set('isListing', false)

    case DESCRIBE_JOB_SUCCESS:
      return state.set('selectedJob', action.selectedJob).set('isListing', false)

    case DELETE_JOB_SUCCESS:
      const jobsList = state.get('data')
      const index = jobsList.findIndex((job) => job.get('job_id') === action.jobId)
      return state.set('data', jobsList.delete(index)).set('isListing', false)

    case GENERATE_AIRFLOW_OPERATOR_SUCCESS:
      return state.set('airflowOperator', action.airflowOperator).set('isListing', false)

    case LIST_JOBS_FAILURE:
    case CREATE_JOB_SUCCESS:
    case CREATE_JOB_FAILURE:
    case DESCRIBE_JOB_FAILURE:
    case DELETE_JOB_FAILURE:
    case GENERATE_AIRFLOW_OPERATOR_FAILURE:
      return state.set('isListing', false)

    default:
      return state
  }
}
