/* All costs in dollars per hour */

const getMostExpensiveInstanceType = (instanceTypeConfigs, instanceTypeCostRanking) => {
  let mostExpensiveInstanceType = instanceTypeConfigs[0].instanceType

  instanceTypeConfigs.forEach((instanceTypeConfig) => {
    let instanceType = instanceTypeConfig['instanceType']

    if (
      instanceTypeCostRanking.findIndex((item) => item === instanceType) >
      instanceTypeCostRanking.findIndex((item) => item === mostExpensiveInstanceType)
    ) {
      mostExpensiveInstanceType = instanceType
    }
  })

  return mostExpensiveInstanceType
}

// Formulae to calculate Cluster costs based on form states and selected region
export const calculateCustomRegularClusterCost = (values, emrRegularInstanceTypeCosts) => {
  const numTaskNodes = Number(values.numTaskNodes)
  const numCoreNodes = Number(values.numCoreNodes)
  const taskInstType = values.taskInstType.value
  const coreInstType = values.coreInstType.value
  const masterInstType = values.masterInstType.value

  return (
    numTaskNodes * Number(emrRegularInstanceTypeCosts[taskInstType]) +
    numCoreNodes * Number(emrRegularInstanceTypeCosts[coreInstType]) +
    Number(emrRegularInstanceTypeCosts[masterInstType])
  ).toFixed(2)
}

export const calculateCustomInstanceFleetClusterCost = (
  instanceFleets,
  emrRegularInstanceTypeCosts,
  emrInstanceFleetInstanceTypeCosts,
  instanceTypeCostRanking
) => {
  const mostExpensiveMasterInstanceType = getMostExpensiveInstanceType(
    instanceFleets[0].instanceTypeConfigs,
    instanceTypeCostRanking
  )
  const mostExpensiveCoreInstanceType = getMostExpensiveInstanceType(
    instanceFleets[1].instanceTypeConfigs,
    instanceTypeCostRanking
  )
  const mostExpensiveTaskInstanceType = instanceFleets[2]
    ? getMostExpensiveInstanceType(instanceFleets[2].instanceTypeConfigs, instanceTypeCostRanking)
    : null

  const masterNodeCost = Number(emrRegularInstanceTypeCosts[mostExpensiveMasterInstanceType])

  const coreNodeCost =
    (Number(emrInstanceFleetInstanceTypeCosts[mostExpensiveCoreInstanceType]) +
      Number(emrRegularInstanceTypeCosts[mostExpensiveCoreInstanceType])) *
    (instanceFleets[1].targetOnDemandCapacity + instanceFleets[1].targetSpotCapacity)

  const taskNodeCost = instanceFleets[2]
    ? (Number(emrInstanceFleetInstanceTypeCosts[mostExpensiveTaskInstanceType]) +
        Number(emrRegularInstanceTypeCosts[mostExpensiveTaskInstanceType])) *
      (instanceFleets[2].targetOnDemandCapacity + instanceFleets[2].targetSpotCapacity)
    : 0

  return (masterNodeCost + coreNodeCost + taskNodeCost).toFixed(2)
}

export const calculateEmrClusterCost = (
  values,
  instanceFleets,
  emrRegularInstanceTypeCosts,
  emrInstanceFleetInstanceTypeCosts,
  emrRegularClusterCostsBySize,
  emrInstanceClusterCostsBySize,
  instanceTypeCostRanking
) => {
  const clusterSize = values.clusterSize ? values.clusterSize.value : null

  if (values.useInstanceFleets) {
    if (clusterSize === 'custom' && emrRegularInstanceTypeCosts) {
      return calculateCustomInstanceFleetClusterCost(
        instanceFleets,
        emrRegularInstanceTypeCosts,
        emrInstanceFleetInstanceTypeCosts,
        instanceTypeCostRanking
      )
    } else if (emrInstanceClusterCostsBySize && clusterSize) {
      return emrInstanceClusterCostsBySize[clusterSize]
    }
  } else {
    if (clusterSize === 'custom' && emrRegularInstanceTypeCosts) {
      return calculateCustomRegularClusterCost(values, emrRegularInstanceTypeCosts)
    } else if (emrRegularClusterCostsBySize && clusterSize) {
      return emrRegularClusterCostsBySize[clusterSize]
    }
  }
}
