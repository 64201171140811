import { createSelector } from 'reselect'
import { Map } from 'immutable'

import { selectJobsConfig } from 'selectors/selectJobsConfig'
import { subDomain } from 'utils/constant'

export const selectJobsEndpoint = createSelector([selectJobsConfig], (jobsConfig) => {
  let endpointEnv
  if (subDomain === 'one') {
    endpointEnv = 'prod'
  } else {
    endpointEnv = subDomain !== 'localhost' ? subDomain.split('-')[1] : 'dev'
  }
  return (jobsConfig.get('endpoints') || new Map()).get(endpointEnv)
})
