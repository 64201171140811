import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { post } from 'axios'
import { toast } from 'react-toastify'
import { Box } from 'rebass'
import { selectText } from 'utils/selectText'
import styled from '@emotion/styled'
import { Spinner } from '@nike/epic-react-ui-old'
import ToJS from 'components/common/to-js'
import { Modal } from '@nike/epic-react-ui-old'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { selectAuthToken } from 'selectors/selectAuthToken'
import { selectUsername } from 'selectors/selectUsername'
import { selectSelectedRegion } from 'selectors/selectSelectedRegion'

const CodeStyles = styled.div`
  max-width: 100%;
  min-width: min-content;
  font-family: monospace;
  font-weight: 500;
  margin: 1rem;
  padding: 2rem;
  background-color: rgba(17, 17, 17, 0.85);
  border-radius: 0.5rem;
  code {
    color: #ffffff;
    word-break: break-all;
    &::before {
      content: '$ ';
      margin-right: 0.5rem;
      margin-left: 0;
      padding-left: 0;
      color: #ceff00;
    }
  }
  p {
    opacity: ${({ clicked }) => (clicked ? 1 : 0)};
    font-family: monospace;
    text-align: center;
    width: 100%;
    color: #ceff00;
    font-size: 0.8rem;
    line-height: 1rem;
    transition: 0.2s opacity cubic-bezier(0.215, 0.61, 0.355, 1);
  }
`

const getDomain = (activeCluster) => {
  // TODO: Add map for 'stage' / 'prod' environments & per account AND per region
  // only CNNW1 prod endpoint is working:
  // CN: emr.ure.cnnw1.prod.dynamicanalytics.platforms.nike.com
  // CN Stage: emr.ure.cnnw1.stage.dynamicanalytics.platforms.nike.com
  const {
    applications: { MASTER },
  } = activeCluster
  const masterUrl = new URL(MASTER)
  // slice the 'ip-10-100-123-1' part
  return masterUrl.hostname.split('.').slice(1).join('.')
}

const getEndpoint = (activeCluster, selectedRegion, domain, activeTab = 'emr') => {
  if (!activeCluster) return null
  const { master_ip } = activeCluster
  // NOTE: Sometimes the global store doesn't align with activeCluster
  // e.g. changing from NGAP STREAMS-TEST to WI CN EP-AE-TEST
  if (selectedRegion.startsWith('cn-')) {
    return `https://ssh-ip-${master_ip.replace(/[.-]/g, '-')}.${domain}/user_add`
  }
  return activeTab === 'emr'
    ? `https://ip-${master_ip.replace(/[.-]/g, '-')}.${domain}:8086/user_add`
    : `https://${activeCluster.cluster_name}.${domain}:8086/user_add`
}

export const buildCommand = (activeTab, activeCluster, domain, masterIp, selectedRegion) => {
  let sshDomain = domain
  const { owned_by, master_ip } = activeCluster
  if (selectedRegion.startsWith('cn-')) {
    sshDomain = domain.replace('emr.', '')
  }

  const linuxMacosCommand =
    activeTab === 'emr'
      ? `ssh jumpbox.${owned_by.toLowerCase()}.${sshDomain} -t 'export iip=${
          master_ip || masterIp
        }; bash'`
      : `ssh jumpbox.${owned_by.toLowerCase()}.${sshDomain} -t 'export iip=${masterIp}; bash'`

  const windowsCommand =
    activeTab === 'emr'
      ? `ssh jumpbox.${owned_by.toLowerCase()}.${sshDomain} -m hmac-sha2-256 -t "export iip=${
          master_ip || masterIp
        }; bash"`
      : `ssh jumpbox.${owned_by.toLowerCase()}.${sshDomain} -m hmac-sha2-256 -t "export iip=${masterIp}; bash"`

  return { linuxMacosCommand, windowsCommand }
}

export function SshDialog(props) {
  const {
    activeCluster,
    toggleDialog,
    isOpen,
    activeTab,
    initSshCommand = '', // *NOTE used for testing
    initSshWindowsCommand = '', // *NOTE used for testing
    authToken,
    username,
    selectedRegion,
  } = props

  const [isCallingJumpBox, setIsCallingJumpBox] = useState(false)
  const [sshCommand, setSshCommand] = useState(initSshCommand)
  const [sshWindowsCommand, setSshWindowsCommand] = useState(initSshWindowsCommand)
  const [clicked, setClicked] = useState(false)

  const callJumpBox = useCallback(() => {
    if (activeCluster.cluster_name?.includes('-dstools')) {
      setSshCommand(`ssh ${username.toLowerCase()}@${activeCluster.master_ip}`)
      setSshWindowsCommand(`ssh ${username.toLowerCase()}@${activeCluster.master_ip}`)
    } else {
      setIsCallingJumpBox(true)

      const domain = getDomain(activeCluster)
      const endpoint = getEndpoint(activeCluster, selectedRegion, domain, activeTab)

      return post(endpoint, `user_id=${username.toLowerCase()}&jwt_token="${authToken}"`, {
        transformRequest: [
          (data, headers) => {
            delete headers.common.Authorization
            return data
          },
        ],
      })
        .then((response) => {
          // NOTE: this doesn't seem to ever return an ip address
          const masterIp = response.data

          const { linuxMacosCommand, windowsCommand } = buildCommand(
            activeTab,
            activeCluster,
            domain,
            masterIp,
            selectedRegion
          )

          setSshCommand(linuxMacosCommand)
          setSshWindowsCommand(windowsCommand)
        })
        .catch((error) => {
          toggleDialog()
          console.error(error)
          toast.error('An error occured. Please contact platform team.', {
            autoClose: false,
            position: 'top-left',
          })
        })
        .finally(() => setIsCallingJumpBox(false))
    }
  }, [activeCluster, activeTab, authToken, selectedRegion, toggleDialog, username])

  useEffect(() => {
    if (isOpen) {
      callJumpBox()
    }
  }, [isOpen, activeCluster, callJumpBox, username])

  useEffect(() => {
    if (clicked) {
      const copyTimeout = setTimeout(() => setClicked(false), 3000)
      return () => {
        clearTimeout(copyTimeout)
      }
    }
  }, [clicked, setClicked])

  const handleCodeClick = () => {
    // HACK: got rid of unnecessary tooltip and CopyToClipboard which didn't actually copy to clipboard.
    const code = document.getElementById('ssh-command')
    selectText({ target: code }, true)
    setClicked(true)
  }

  const handleWindowsCodeClick = () => {
    // HACK: got rid of unnecessary tooltip and CopyToClipboard which didn't actually copy to clipboard.
    const code = document.getElementById('ssh-windows-command')
    selectText({ target: code }, true)
    setClicked(true)
  }

  return (
    <Modal title='SSH command' show={isOpen} onClose={toggleDialog}>
      <Box>
        {isCallingJumpBox ? (
          <Spinner />
        ) : (
          <>
            <h3>For Linux/Mac</h3>
            <CodeStyles onClick={handleCodeClick} clicked={clicked}>
              <code id='ssh-command'>{sshCommand}</code>
              <p>Copied to clipboard!</p>
            </CodeStyles>

            <h3>For Windows</h3>
            <CodeStyles onClick={handleWindowsCodeClick} clicked={clicked}>
              <code id='ssh-windows-command'>{sshWindowsCommand}</code>
              <p>Copied to clipboard!</p>
            </CodeStyles>
          </>
        )}
      </Box>
    </Modal>
  )
}

SshDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  activeCluster: PropTypes.object.isRequired,
  authToken: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  initSshCommand: PropTypes.string,
  initSshWindowsCommand: PropTypes.string,
}

export const mapStateToProps = (state) => {
  return {
    authToken: selectAuthToken(state),
    username: selectUsername(state),
    selectedRegion: selectSelectedRegion(state),
  }
}

const container = compose(ToJS, connect(mapStateToProps))(SshDialog)

export default container
