import { toast } from 'react-toastify'
import { create } from 'axios'

import {
  TERMINATE_EMR_CLUSTER_REQUEST,
  TERMINATE_EMR_CLUSTER_SUCCESS,
  TERMINATE_EMR_CLUSTER_FAILURE,
} from 'utils/constant'

import { listEmrClusters } from 'actions/listEmrClusters'

import { selectEmrEndpoint } from 'selectors/selectEmrEndpoint'

function terminateEmrClusterRequest() {
  return {
    type: TERMINATE_EMR_CLUSTER_REQUEST,
  }
}

function terminateEmrClusterSuccess(clusterId) {
  return {
    type: TERMINATE_EMR_CLUSTER_SUCCESS,
    clusterId,
  }
}

function terminateEmrClusterFailure() {
  return {
    type: TERMINATE_EMR_CLUSTER_FAILURE,
  }
}

export async function terminateEmrCluster(emrClusterName, emrClusterId) {
  return function (dispatch, getState) {
    const endpoint = selectEmrEndpoint(getState())

    dispatch(terminateEmrClusterRequest())

    return create()
      .delete(`${endpoint}/${emrClusterName}`)
      .then(() => {
        dispatch(terminateEmrClusterSuccess(emrClusterId))

        toast.success(`${emrClusterName} terminated successfully`, {
          autoClose: false,
          position: 'top-left',
        })
      })
      .then(() => dispatch(listEmrClusters()))
      .catch((error) => {
        dispatch(terminateEmrClusterFailure())

        let errorMessage = 'Unable to terminate cluster'

        if (error.response) {
          errorMessage = errorMessage + ': ' + error.response.data.errorMessage

          toast.error(errorMessage, { autoClose: false, position: 'top-left' })
        }
      })
  }
}
