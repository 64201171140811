import React, { useEffect } from 'react'
import { Select } from '@nike/epic-react-ui-old'
import { Box, Flex } from 'rebass'
import { create } from 'axios'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
import { compose } from 'redux'

import { selectGroups } from 'selectors/selectGroups'
import { selectDefaultAccount } from 'selectors/selectDefaultAccount'
import { selectFilteredGroups } from 'selectors/selectFilteredGroups'
import { selectAccountOptions } from 'selectors/selectAccountOptions'
import { selectSelectedAccount } from 'selectors/selectSelectedAccount'
import { selectSelectedRuntime } from 'selectors/selectSelectedRuntime'
import { selectRuntimeOptions } from 'selectors/selectRuntimeOptions'
import { selectSetCookieEndpoint } from 'selectors/selectSetCookieEndpoint'
import { selectSelectedRegion } from 'selectors/selectSelectedRegion'
import { selectRegions } from 'selectors/selectRegions'
import { selectRuntimes } from 'selectors/selectRuntimes'
import { selectSelectedGroup } from 'selectors/selectSelectedGroup'
import { selectEmrEndpoint } from 'selectors/selectEmrEndpoint'

import AppBarMenu from 'components/global/app-bar-menu'
import ToJS from 'components/common/to-js'

import { HUE_ROLE_CHANGE_ENDPOINT, domain, subDomain } from 'utils/constant'

import { updateSelectedAccount } from 'actions/updateSelectedAccount'
import { updateSelectedRuntime } from 'actions/updateSelectedRuntime'
import { updateSelectedGroup } from 'actions/updateSelectedGroup'
import { updateSelectedRegion } from 'actions/updateSelectedRegion'
import { listEmrClusters } from 'actions/listEmrClusters'

import { deleteCookie } from 'utils/cookie'

import { usePrevious } from 'hooks/usePrevious'
import { useInterval } from 'hooks/useInterval'

const LOGOUT_ENDPOINT = `https://${subDomain}${domain}/logout`

export function NavbarAside(props) {
  const {
    groups,
    filteredGroups,
    defaultAccount,
    regions,
    runtimes,
    selectedGroup,
    selectedRegion,
    authToken,
    setActiveCluster,
    updateSelectedRegion,
    updateSelectedGroup,
    fetchGroupConfig,
    selectedAccount,
    selectedRuntime,
    accountOptions,
    updateSelectedAccount,
    updateSelectedRuntime,
    runtimeOptions,
    setCookieEndpoint,
    listEmrClusters,
    emrEndpoint,
  } = props

  const previousGroup = usePrevious(selectedGroup)
  const previousAccount = usePrevious(selectedAccount)
  const previousRegion = usePrevious(selectedRegion)
  const previousEmrEndpoint = usePrevious(emrEndpoint)

  function clearALBCookie() {
    if (subDomain !== 'localhost') {
      create().get(LOGOUT_ENDPOINT).catch()
    }
  }

  useEffect(() => {
    const oktaTokenTimeout = setTimeout(() => {
      deleteCookie('okta-token', 10000)
    })
    return () => {
      clearTimeout(oktaTokenTimeout)
    }
  }, [])

  // ALB session cookies are set automatically. Need to clear them repeatedly to prevent the Okta access token from expiring
  useInterval(clearALBCookie, 60000)

  const selectedAccountSaved = localStorage.getItem('selectedAccount')
  const selectedRegionSaved = localStorage.getItem('selectedRegion')
  const selectedRuntimeSaved = localStorage.getItem('selectedRuntime')

  /* If previous values aren't defined, we know this is first time rendering after page reload, and populate the values from local storage */

  useEffect(() => {
    if (previousGroup !== selectedGroup) {
      if (!previousGroup) {
        updateSelectedAccount(selectedAccountSaved)
      } else {
        updateSelectedAccount(defaultAccount)
      }
    }
  }, [previousGroup, selectedGroup, selectedAccountSaved, updateSelectedAccount, defaultAccount])

  useEffect(() => {
    if (previousAccount !== selectedAccount) {
      if (!previousAccount) {
        updateSelectedRegion(selectedRegionSaved)
      } else {
        updateSelectedRegion(regions[0])
      }
    }
  }, [previousAccount, selectedAccount, selectedRegionSaved, updateSelectedRegion, regions])

  useEffect(() => {
    if (previousRegion !== selectedRegion) {
      if (!previousRegion) {
        updateSelectedRuntime(selectedRuntimeSaved)
      } else {
        updateSelectedRuntime(runtimes[0])
      }
    }
  }, [previousRegion, selectedRegion, selectedRuntimeSaved, updateSelectedRuntime, runtimes])

  // if the emr endpoint changes, refresh clusters
  useEffect(() => {
    if (previousEmrEndpoint && previousEmrEndpoint !== emrEndpoint) {
      listEmrClusters()
    }
  }, [previousEmrEndpoint, emrEndpoint, listEmrClusters])

  const getGroupOptions = () => filteredGroups.map((group) => ({ label: group, value: group }))

  const getRegionOptions = () => regions.map((region) => ({ label: region, value: region }))

  function handleSelectedGroupChange(option) {
    ReactGA.set({ group: option.value })

    const selectedGroup = option.value

    updateSelectedGroup(selectedGroup)

    fetchGroupConfig()

    // NOTE: this endpoint is wrong for CN
    create()
      .post(HUE_ROLE_CHANGE_ENDPOINT, { ngap_role: selectedGroup })
      .catch((error) => console.log({ error }))

    localStorage.setItem('selectedGroup', selectedGroup)

    ReactGA.event({
      category: 'Global',
      action: 'Change group',
      label: option.value,
    })
  }

  function handleSelectedRegionChange(option) {
    localStorage.setItem('selectedRegion', option.value)

    updateSelectedRegion(option.value)

    ReactGA.event({
      category: 'Global',
      action: 'Change region',
      label: option.value,
    })
  }

  function handleAccountChange(option) {
    localStorage.setItem('selectedAccount', option.value)

    updateSelectedAccount(option.value)

    ReactGA.event({
      category: 'Global',
      action: 'Change account',
      label: option.value,
    })
  }

  function handleRuntimeChange(option) {
    localStorage.setItem('selectedRuntime', option.value)

    updateSelectedRuntime(option.value)

    ReactGA.event({
      category: 'Global',
      action: 'Change runtime',
      label: option.value,
    })
  }

  const regionOptions = getRegionOptions()
  const groupOptions = getGroupOptions()

  const numDropdowns = 2 + (accountOptions.length > 1 ? 1 : 0) + (runtimeOptions.length > 1 ? 1 : 0)

  return (
    <Flex justifyContent='space-evenly' alignItems='center'>
      <img src={setCookieEndpoint} style={{ display: 'none' }} alt='setCookie' />

      <Flex mr='10px' mt='5px' minWidth='470px'>
        <Box mr='5px' width={`${100 / numDropdowns}%`} id='group' data-e2e='group-name'>
          <Select
            onChange={handleSelectedGroupChange}
            options={groupOptions}
            value={groupOptions.find((groupOption) => groupOption.value === selectedGroup)}
          />
        </Box>

        {accountOptions.length > 1 && (
          <Box ml='5px' width={`${100 / numDropdowns}%`}>
            <Select
              onChange={handleAccountChange}
              options={accountOptions}
              value={accountOptions.find(
                (accountOption) => selectedAccount === accountOption.value
              )}
            />
          </Box>
        )}

        <Box ml='5px' width={`${100 / numDropdowns}%`} data-e2e='region-name'>
          <Select
            onChange={handleSelectedRegionChange}
            options={regionOptions}
            value={regionOptions.find((regionOption) => regionOption.value === selectedRegion)}
          />
        </Box>

        {runtimeOptions.length > 1 && (
          <Box ml='5px' width={`${100 / numDropdowns}%`}>
            <Select
              onChange={handleRuntimeChange}
              options={runtimeOptions}
              value={runtimeOptions.find(
                (runtimeOption) => selectedRuntime === runtimeOption.value
              )}
            />
          </Box>
        )}
      </Flex>

      <AppBarMenu
        authToken={authToken}
        showEnterARButton={groups.includes('FireFighters') || groups.includes('PlatformDev')}
        showExitARButton={!!JSON.parse(atob(authToken.split('.')[1])).ar_mode}
        setActiveCluster={setActiveCluster}
      />
    </Flex>
  )
}

NavbarAside.defaultProps = {
  groups: [],
  filteredGroups: [],
  regions: [],
}

const mapStateToProps = (state) => {
  return {
    groups: selectGroups(state),
    filteredGroups: selectFilteredGroups(state),
    accountOptions: selectAccountOptions(state),
    selectedRegion: selectSelectedRegion(state),
    selectedGroup: selectSelectedGroup(state),
    selectedAccount: selectSelectedAccount(state),
    selectedRuntime: selectSelectedRuntime(state),
    runtimeOptions: selectRuntimeOptions(state),
    setCookieEndpoint: selectSetCookieEndpoint(state),
    regions: selectRegions(state),
    runtimes: selectRuntimes(state),
    defaultAccount: selectDefaultAccount(state),
    emrEndpoint: selectEmrEndpoint(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedGroup: (selectedGroup) => dispatch(updateSelectedGroup(selectedGroup)),
    updateSelectedAccount: (selectedAccount) => dispatch(updateSelectedAccount(selectedAccount)),
    updateSelectedRegion: (selectedRegion) => dispatch(updateSelectedRegion(selectedRegion)),
    updateSelectedRuntime: (selectedRuntime) => dispatch(updateSelectedRuntime(selectedRuntime)),
    listEmrClusters: () => dispatch(listEmrClusters()),
  }
}

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), ToJS)(NavbarAside)

export default enhanced
