/** @jsx jsx */
import PropTypes from 'prop-types'
import CopyToClipboard from '@nike/nr-vamp-xl-copy-to-clipboard'
import { Box } from 'rebass'
import { css, jsx } from '@emotion/core'

function AuthTokenToast({ authToken }) {
  return (
    <div
      css={css`
        word-break: break-all;
        color: #000000;
        text-align: center;
        overflow: visible;
      `}
    >
      Auth token: <br />
      <CopyToClipboard
        successText=''
        data-e2e='authToken'
        label=''
        styleExtension={{
          button: { backgroundColor: 'transparent', color: '#000000' },
          icon: { color: '#000000' },
        }}
      >
        <Box>{authToken}</Box>
      </CopyToClipboard>
    </div>
  )
}

AuthTokenToast.propTypes = {
  authToken: PropTypes.string.isRequired,
}

export default AuthTokenToast
