import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga'

import { Button, Modal, Select, Spinner } from '@nike/epic-react-ui-old'

const IFRAME_STYLE = {
  display: 'block',
  border: 'inset',
  width: '100%',
  height: '500px',
  overflow: 'auto',
  padding: '0',
  marginTop: '8px',
}

const SPARKLENS_JSON = '.sparklens.json'

export default function EmrLog({ isOpen, toggleDialog, activeCluster, authToken, endpoint }) {
  const [prefix, setPrefix] = useState([])
  const [path, setPath] = useState()
  const [loading, setLoading] = useState(false)
  const [logFile, setLogFile] = useState()
  const [error, setError] = useState()
  const headers = { headers: { Authorization: 'Bearer ' + authToken } }

  useEffect(() => {
    const cluster_id = activeCluster.cluster_id
    if (cluster_id) setPrefix([cluster_id])
  }, [activeCluster])

  useEffect(() => {
    if (!isOpen || !endpoint || !prefix.length) return
    setPath(null)
    setError(null)
    async function fetchPath() {
      const url = endpoint + '?prefix=' + prefix.join('/') + '/'
      const path_res = await fetch(url)
      const path_array = await path_res.json()
      if (Array.isArray(path_array))
        setPath(
          path_array.reduce((options, item) => {
            if (item) options.push({ label: item, value: item })
            return options
          }, [])
        )
      else {
        setError(path_array)
        setPath([])
      }
    }
    fetchPath()
  }, [isOpen, endpoint, prefix])

  async function fetchLog(option) {
    const path = option.value
    const l = path.length
    if (path[l - 1] === '/') {
      setPrefix([...prefix, path.substr(0, l - 1)])
      return
    }
    setLoading(true)
    prefix.push(path)
    const api_url = endpoint + '?prefix=' + prefix.join('/')
    const api_res = await fetch(api_url, headers)
    if (api_res.status !== 200) {
      setError('API returned an error: ' + (await api_res.text()))
      setLoading(false)
      return
    }
    const file = await api_res.json()
    if (file.includes(SPARKLENS_JSON)) {
      download(file, prefix[prefix.length - 1])
      ReactGA.event({
        category: 'EMR applications',
        action: 'Logs - Sparklens',
      })
      setLoading(false)
    }
    setLogFile(file)
  }

  function changePrefix(i) {
    setPrefix(prefix.slice(0, i + 1))
    setLogFile(null)
  }

  function download(href, download) {
    fetch(href)
      .then((res) => res.blob())
      .then((blob) => {
        var a = document.createElement('a')
        a.href = window.URL.createObjectURL(blob)
        a.download = download
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
  }

  return (
    <Modal
      modalSize='lg'
      title={activeCluster.cluster_name + ' EMR Log'}
      show={isOpen}
      onClose={() => {
        setPrefix([])
        setPath(null)
        setLoading(false)
        setLogFile(null)
        toggleDialog()
      }}
    >
      <p>Select the path to the log file(s):</p>
      <p>
        path ={' '}
        {prefix.map((path, i) => (
          <span key={i}>
            {i > 0 && ' / '}
            {i < prefix.length - 1 ? (
              <Button small inverse onClick={() => changePrefix(i)} data-external-id='path-to-logs'>
                {path}
              </Button>
            ) : (
              <span>{path}</span>
            )}
          </span>
        ))}
      </p>
      {path && !logFile && (
        <Select
          onChange={fetchLog}
          options={path}
          autoFocus={true}
          errorMessage={error}
          hasErrors={error}
          className='ml-2'
        ></Select>
      )}
      <br />
      {!path && !logFile && <Spinner />}
      {loading && <Spinner />}
      {logFile && !logFile.includes(SPARKLENS_JSON) && (
        <iframe
          title={prefix[prefix.length - 1]}
          src={logFile}
          style={IFRAME_STYLE}
          onLoad={() => setLoading(false)}
        />
      )}
    </Modal>
  )
}
