import React, { useState } from 'react'
import { Box } from 'rebass'

import Iframe from 'components/common/iframe'
import { PageSpinner } from '@nike/epic-react-ui-old'

export default function DatalakeExplorer() {
  const [isIframeLoading, setIsIframeLoading] = useState(true)

  function handleIframeOnLoad() {
    setIsIframeLoading(false)
  }

  return (
    <Box>
      {isIframeLoading && <PageSpinner show overlay={false} />}

      <Iframe
        title='Datalake Explorer'
        src='https://selfservice-hue-prod2.e1.ngap2.nike.com/hue/filebrowser/view=s3a://'
        onLoad={handleIframeOnLoad}
      />
    </Box>
  )
}
