/** @jsx jsx */
import PropTypes from 'prop-types'
import { Select, TextInput, Checkbox, FormSection } from '@nike/epic-react-ui-old'
import { css, jsx } from '@emotion/core'
import { Form } from 'formik'
import { Box, Flex } from 'rebass'
import Tooltip from '@nike/nr-vamp-xl-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/fontawesome-free-solid'
import TextField from '@material-ui/core-new/TextField'
import { formatDate } from 'utils/formatDate'

const scheduleFrequencyOptions = [
  {
    label: 'Daily',
    value: 'daily',
  },
  {
    label: 'Weekly',
    value: 'weekly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
]

const infoCircleStyle = {
  marginTop: '15px',
  color: '#3498DB',
}

export default function GraduateForm(props) {
  const { selfServiceSchemaListOptions, formikProps, frequencyType } = props

  const { values, handleBlur, handleChange, setFieldValue, errors, touched } = formikProps

  return (
    <Form>
      <FormSection>
        <Select
          name='dbName'
          label='Database name'
          options={selfServiceSchemaListOptions}
          value={values.dbName}
          onChange={(option) => setFieldValue('dbName', option)}
          onBlur={handleBlur}
          required
        />

        <TextInput
          name='tableName'
          label='Table name'
          placeholder='Table name'
          value={values.tableName}
          onChange={handleChange}
          onBlur={handleBlur}
          hasErrors={errors.tableName && touched.tableName}
          errorMessage={errors.tableName && touched.tableName ? errors.tableName : ''}
          required
          css={css`
            margin-top: 10px;
          `}
        />

        <TextInput
          name='targetS3Location'
          label='Target S3 location'
          placeholder='S3 path'
          value={values.targetS3Location}
          onChange={handleChange}
          onBlur={handleBlur}
          hasErrors={errors.targetS3Location && touched.targetS3Location}
          errorMessage={
            errors.targetS3Location && touched.targetS3Location ? errors.targetS3Location : ''
          }
          required
        />

        <Flex>
          <TextInput
            name='mapClusterName'
            label='MAP cluster name'
            placeholder='MAP cluster name'
            value={values.mapClusterName}
            onChange={handleChange}
            onBlur={handleBlur}
            hasErrors={errors.mapClusterName && touched.mapClusterName}
            errorMessage={
              errors.mapClusterName && touched.mapClusterName ? errors.mapClusterName : ''
            }
            required
          />

          <Tooltip placement='right' title='Only one cluster name should be given' type='action'>
            <div>
              <FontAwesomeIcon icon={faInfoCircle} style={infoCircleStyle} />
            </div>
          </Tooltip>
        </Flex>

        <TextInput
          name='dagName'
          label='Dag name'
          placeholder='Dag name'
          value={values.dagName}
          onChange={handleChange}
          onBlur={handleBlur}
          hasErrors={errors.dagName && touched.dagName}
          errorMessage={errors.dagName && touched.dagName ? errors.dagName : ''}
          required
        />

        {frequencyType === 'one-time' && (
          <Box ml='10px' mt='10px' mb='10px'>
            <TextField
              label='Start date'
              type='datetime-local'
              value={values.startDate}
              onChange={(e) => {
                setFieldValue('startDate', e.target.value)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: formatDate(new Date(Date.now())) },
              }}
              css={css`
                width: 100%;
              `}
            />
          </Box>
        )}

        {frequencyType === 'one-time' && (
          <Box mt='10px'>
            <Select
              name='scheduleFrequency'
              options={scheduleFrequencyOptions}
              label='Schedule frequency'
              value={values.scheduleFrequency}
              onChange={(option) => setFieldValue('scheduleFrequency', option)}
              onBlur={handleBlur}
              required
            />
          </Box>
        )}

        <Checkbox
          role='checkbox'
          name='archiveNeeded'
          label='Archive needed'
          checked={values.archiveNeeded}
          onChange={handleChange}
          onBlur={handleBlur}
          css={css`
            margin: 20px 5px;
          `}
        />

        {values.archiveNeeded && (
          <Flex>
            <TextInput
              name='archiveLocation'
              label='Archive location'
              placeholder='Archive location'
              value={values.archiveLocation}
              onChange={handleChange}
              onBlur={handleBlur}
              hasErrors={errors.archiveLocation && touched.archiveLocation}
              errorMessage={
                errors.archiveLocation && touched.archiveLocation ? errors.archiveLocation : ''
              }
              required
            />

            <Tooltip
              placement='right'
              title='We will move the source location data at the time of graduate and daily run to archive location'
              type='action'
            >
              <div>
                <FontAwesomeIcon icon={faInfoCircle} style={infoCircleStyle} />
              </div>
            </Tooltip>
          </Flex>
        )}
      </FormSection>
    </Form>
  )
}

GraduateForm.propTypes = {
  selfServiceSchemaListOptions: PropTypes.array,
  formikProps: PropTypes.object.isRequired,
}

GraduateForm.defaultProps = {
  selfServiceSchemaListOptions: [],
}
