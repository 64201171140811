function getNavbarTabs(globalTabs) {
  let tabs = []

  if (globalTabs.includes('EMR')) {
    tabs.push({
      path: '/emr',
      text: 'EMR',
    })
  }
  /*
  if (globalTabs.includes('AF')) {
    tabs.push({
      path: '/airflow',
      text: 'Airflow',
    });
  }

  if (globalTabs.includes('AF1')) {
    tabs.push({
      path: '/af1',
      text: 'AirflowOne',
    });
  }
*/
  if (globalTabs.includes('METADATA-EXPLORER')) {
    tabs.push({
      path: '/metadataexplorer',
      text: 'Metadata Explorer',
    })
  }

  if (globalTabs.includes('SELFSERVICE')) {
    tabs.push({
      path: '/selfservice',
      text: 'Self-Service',
    })
  }

  if (globalTabs.includes('DATABRICKS')) {
    tabs.push({
      path: '/databricks',
      text: 'Databricks',
    })
  }

  if (globalTabs.includes('JOBS')) {
    tabs.push({
      path: '/jobs',
      text: 'Jobs',
    })
  }

  tabs.push({
    path: '/observability',
    text: 'Observability',
  })

  return tabs
}

export default getNavbarTabs
