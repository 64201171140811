import { toast } from 'react-toastify'
import { create } from 'axios'

import { listTableJobs } from 'actions/listTableJobs'

import {
  SELF_SERVICE_ENDPOINT,
  CREATE_TABLE_JOB_REQUEST,
  CREATE_TABLE_JOB_SUCCESS,
  CREATE_TABLE_JOB_FAILURE,
} from 'utils/constant'

function createTableJobRequest() {
  return {
    type: CREATE_TABLE_JOB_REQUEST,
  }
}

function createTableJobSuccess() {
  return {
    type: CREATE_TABLE_JOB_SUCCESS,
  }
}

function createTableJobFailure() {
  return {
    type: CREATE_TABLE_JOB_FAILURE,
  }
}

export async function createTableJob(payload) {
  return function (dispatch) {
    dispatch(createTableJobRequest())

    return create()
      .post(`${SELF_SERVICE_ENDPOINT}/orchestrate_job/v2`, payload)
      .then(() => {
        dispatch(createTableJobSuccess())

        dispatch(listTableJobs())

        toast.success(`${payload.job_name} created successfully`, {
          autoClose: false,
          position: 'top-left',
        })
      })
      .catch((error) => {
        dispatch(createTableJobFailure())

        const defaultMessage = 'Error. Please contact platform team.'

        const errorMessage =
          error.response.status !== 500
            ? (error.response && error.response.data && error.response.data.message) ||
              defaultMessage
            : defaultMessage

        toast.error(errorMessage, { autoClose: false, closeOnClick: false })
      })
  }
}
