/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box } from 'rebass'
import { Spinner } from '@nike/epic-react-ui-old'
import { jsx } from '@emotion/core'

import DetailsGroup from 'components/common/details-group'
import DescribeInstanceFleetFields from 'components/emr/describe-instance-fleet-fields'

export default function EmrClusterDetails(props) {
  const {
    activeCluster,
    isInstanceFleet,
    isFetchingInstanceFleetDescription,
    instanceFleetDescription,
  } = props

  const generalFields = [{ label: 'Cluster Id', value: activeCluster.cluster_id }]
  if (activeCluster.ranger === true) {
    generalFields.push({ label: 'Hive Environment', value: 'Prod' })
    generalFields.push({
      label: 'AE Compute Environment',
      value: activeCluster.environment,
    })
  } else {
    generalFields.push({
      label: 'Hive Environment',
      value: activeCluster.environment,
    })
  }

  const hardwareFields = [
    {
      label: 'Master instance type',
      value: activeCluster.master_inst_type,
    },
    {
      label: 'Core instance type',
      value: activeCluster.core_inst_type,
    },
    {
      label: 'Task instance type',
      value: activeCluster.task_inst_type,
    },
    {
      label: 'Number of core nodes',
      value: String(activeCluster.num_core_nodes),
    },
    {
      label: 'Number of task nodes',
      value: String(activeCluster.num_task_nodes),
    },
    {
      label: 'Master IP',
      value: activeCluster.master_ip ? String(activeCluster.master_ip) : '',
    },
  ]

  const activityFields = [
    { label: 'Resized at', value: activeCluster.resize_at || '' },
    { label: 'Resized by', value: activeCluster.resize_by || '' },
  ]

  if (activeCluster.cluster_status === 'FAILED') {
    generalFields.push({
      label: 'Error',
      value: activeCluster.error || '',
      error: true,
    })
  }

  return (
    <Box style={{ height: '75vh', overflow: 'scroll' }}>
      <DetailsGroup title='General' fields={generalFields} />

      <DetailsGroup title='Hardware' fields={hardwareFields} marginTop={15}>
        {isFetchingInstanceFleetDescription ? (
          <Box mt={30}>
            <Spinner />
          </Box>
        ) : (
          isInstanceFleet && (
            <DescribeInstanceFleetFields
              instanceFleetDescription={instanceFleetDescription}
              marginLeft={30}
              masterIp={String(activeCluster.master_ip)}
            />
          )
        )}
      </DetailsGroup>

      <DetailsGroup title='Activity' fields={activityFields} marginTop={15} />
    </Box>
  )
}

EmrClusterDetails.propTypes = {
  activeCluster: PropTypes.object.isRequired,
  isInstanceFleet: PropTypes.bool.isRequired,
  isFetchingInstanceFleetDescription: PropTypes.bool.isRequired,
  instanceFleetDescription: PropTypes.object,
}
