import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rebass'
import { Route, Switch, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import axios from 'axios'
import ReactGA from 'react-ga'
import moment from 'moment'
import { PageSpinner, NavBar } from '@nike/epic-react-ui-old'
import { useOktaAuth } from '@okta/okta-react'

import ngap2Logo from 'images/ngap_logo_minimal.png'

import EmrPage from 'components/emr/emr-page'
import AirflowPage from 'components/airflow/airflow-page'
import MetadataExplorerPage from 'components/metadata-explorer/metadata-explorer-page'
import SelfServicePage from 'components/self-service/self-service-page'
import AirflowOnePage from 'components/airflow-one/airflow-one-page'
import DatabricksPage from 'components/databricks/databricks-page'
import JobsDisplayPage from 'components/jobs-display/jobs-display-page'
import PlatformObservabilityPage from 'components/platform-observability/platform-observability-page'
import ToJS from 'components/common/to-js'
import NavbarAside from 'components/global/navbar-aside'
import HomePage from 'components/global/home-page'
import IntegratedPlatformWrapper from 'components/global/integrated-platform-wrapper/integrated-platform-wrapper'

import { fetchGroupConfig } from 'actions/fetchGroupConfig'
import { updateSelectedGroup } from 'actions/updateSelectedGroup'

import { selectAuthToken } from 'selectors/selectAuthToken'
import { selectTokenExpirationTime } from 'selectors/selectTokenEpirationTime'
import { selectSelectedGroup } from 'selectors/selectSelectedGroup'
import { selectSelectedRegion } from 'selectors/selectSelectedRegion'
import { selectGlobalTabs } from 'selectors/selectGlobalTabs'
import { selectFilteredGroups } from 'selectors/selectFilteredGroups'

import { useInterval } from 'hooks/useInterval'

import { googleAnalyticsTrackingId } from 'utils/constant'
import getNavbarTabs from 'utils/getNavbarTabs'

function debounce(func, timeout = 6000) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

function getRoutes(dependencies) {
  const {
    selectedGroup,
    selectedRegion,
    globalTabs,
    searchValue,
    activeCluster,
    setActiveCluster,
    accessToken,
  } = dependencies

  return [
    {
      path: '/emr',
      render: () => (
        <EmrPage
          activeCluster={activeCluster}
          searchValue={searchValue}
          selectedGroup={selectedGroup}
          setActiveCluster={setActiveCluster}
        />
      ),
      condition: globalTabs.includes('EMR'),
    },
    {
      path: '/airflow',
      render: () => (
        <AirflowPage
          activeCluster={activeCluster}
          searchValue={searchValue}
          selectedGroup={selectedGroup}
          selectedRegion={selectedRegion}
          setActiveCluster={setActiveCluster}
        />
      ),
      condition: globalTabs.includes('AF'),
    },
    {
      path: '/af1',
      render: () => (
        <AirflowOnePage selectedGroup={selectedGroup} selectedRegion={selectedRegion} />
      ),
      condition: globalTabs.includes('AF1'),
    },
    {
      path: '/metadataexplorer',
      title: 'Metadata Explorer',
      render: () => (
        <MetadataExplorerPage selectedGroup={selectedGroup} selectedRegion={selectedRegion} />
      ),
      condition: globalTabs.includes('METADATA-EXPLORER'),
    },
    {
      path: '/selfservice',
      render: () => (
        <SelfServicePage
          selectedGroup={selectedGroup}
          selectedRegion={selectedRegion}
          searchValue={searchValue}
        />
      ),
      condition: globalTabs.includes('SELFSERVICE'),
    },
    {
      path: '/databricks',
      render: () => <DatabricksPage />,
      condition: globalTabs.includes('DATABRICKS'),
    },
    {
      path: '/jobs',
      render: () => (
        <JobsDisplayPage
          selectedGroup={selectedGroup}
          selectedRegion={selectedRegion}
          searchValue={searchValue}
        />
      ),
      condition: globalTabs.includes('JOBS'),
    },
    {
      path: '/observability',
      render: () => <PlatformObservabilityPage />,
      condition: true,
    },
    {
      path: '/',
      render: () => <HomePage accessToken={accessToken} />,
      condition: true,
    },
  ]
}

const PrivateRoute = ({ render, condition, ...rest }) => (
  <Route
    {...rest}
    render={() => {
      return condition && render()
    }}
  />
)

const slackUrls = [
  {
    text: 'Contact Support (having an issue)',
    path: 'https://app.slack.com/client/T0G3T5X2B/C3G4JFT1D/thread/CKWQES3EF-1589332189.054000',
  },
  {
    text: 'Ask a Community Question',
    path: 'https://nikedigital.slack.com/messages/C0N14CG87/',
  },
]

export function NgapUi(props) {
  const {
    tokenExpirationTime,
    fetchGroupConfig,
    authToken,
    location,
    globalTabs,
    selectedGroup,
    selectedRegion,
    updateSelectedGroup,
    filteredGroups,
  } = props

  const { authService } = useOktaAuth()

  const [searchValue, updateSearchValue] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const [activeCluster, setActiveCluster] = useState({})
  const [isPageReady, setIsPageReady] = useState(false)
  const [shouldCheckTokenExpiration, setShouldCheckTokenExpiration] = useState(true)

  const selectedGroupSaved = localStorage.getItem('selectedGroup') || 'Demo'

  function checkTokenExpiration() {
    if (window.Notification && Notification.permission !== 'denied') {
      Notification.requestPermission(() => {
        // show notification one hour before token expiration
        if (moment().isSameOrAfter(moment.unix(tokenExpirationTime - 3600))) {
          new Notification('Advanced Compute token will expire in 1 hour', {
            //body: 'Click here to log in again',
            requireInteraction: true,
          })

          setShouldCheckTokenExpiration(false)
        }
      })
    }
  }

  useEffect(() => {
    updateSelectedGroup(selectedGroupSaved || filteredGroups[0] || '')
  }, [updateSelectedGroup, selectedGroupSaved, filteredGroups])

  useEffect(() => {
    axios.defaults.headers.common['Authorization'] = authToken
  }, [authToken])

  useEffect(() => {
    ReactGA.initialize(googleAnalyticsTrackingId)
  }, [])

  useEffect(() => {
    async function setToken() {
      const oktaAccessToken = await authService.getAccessToken()
      setAccessToken(oktaAccessToken)
    }
    setToken()
  }, [authService])

  useEffect(() => {
    fetchGroupConfig().then(() => {
      setIsPageReady(true)
    })
  }, [fetchGroupConfig])

  useInterval(
    checkTokenExpiration,
    shouldCheckTokenExpiration ? 300000 : null // check every 5 minutes if token is expiring in the next hour
  )

  if (!isPageReady) {
    return <PageSpinner show overlay={false} />
  }

  const routeDependencies = {
    selectedGroup,
    selectedRegion,
    globalTabs,
    searchValue,
    activeCluster,
    setActiveCluster,
    accessToken,
  }

  const routes = getRoutes(routeDependencies)

  const navbarTabs = getNavbarTabs(globalTabs)

  const NgapNavBar = () => (
    <div className={'ngap-navbar'}>
      <NavBar
        name='Advanced Compute'
        routes={navbarTabs}
        rootPath='/'
        slack={{ urls: slackUrls }}
        logo={ngap2Logo}
        RouterLink={({ href, ...props }) => <Link to={href} {...props} />}
        onChange={debounce((value) => updateSearchValue(value))}
        aside={
          <NavbarAside
            fetchGroupConfig={fetchGroupConfig}
            authToken={authToken}
            setActiveCluster={setActiveCluster}
          />
        }
      />
    </div>
  )

  const NavSpacer = () => <div className={'nav-spacer'} />

  return (
    accessToken && (
      <>
        {/* Show PC navbar only on homepage */}
        <Switch>
          <Route exact path='/' component={NavSpacer} />
          <Route />
        </Switch>
        <IntegratedPlatformWrapper
          accessToken={accessToken}
          navbarTabs={navbarTabs}
          location={location}
        >
          <Box style={{ width: '100%' }}>
            <NgapNavBar />
            <Switch>
              {routes.map((route, index) => (
                <PrivateRoute
                  key={index}
                  path={route.path}
                  condition={route.condition}
                  render={route.render}
                />
              ))}
            </Switch>
          </Box>
        </IntegratedPlatformWrapper>
      </>
    )
  )
}

NgapUi.propTypes = {
  location: PropTypes.object.isRequired,
  authToken: PropTypes.string.isRequired,
  tokenExpirationTime: PropTypes.number.isRequired,
  selectedRegion: PropTypes.string,
  selectedGroup: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  return {
    authToken: selectAuthToken(state),
    selectedGroup: selectSelectedGroup(state),
    selectedRegion: selectSelectedRegion(state),
    tokenExpirationTime: selectTokenExpirationTime(state),
    globalTabs: selectGlobalTabs(state),
    filteredGroups: selectFilteredGroups(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGroupConfig: () => dispatch(fetchGroupConfig()),
    updateSelectedGroup: (selectedGroup) => dispatch(updateSelectedGroup(selectedGroup)),
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps), ToJS)(NgapUi)
