import { fromJS } from 'immutable'
import { create } from 'axios'

import {
  SELF_SERVICE_ENDPOINT,
  LIST_TABLE_JOBS_REQUEST,
  LIST_TABLE_JOBS_SUCCESS,
  LIST_TABLE_JOBS_FAILURE,
} from 'utils/constant'

function listTableJobsRequest() {
  return {
    type: LIST_TABLE_JOBS_REQUEST,
  }
}

function listTableJobsSuccess(tableJobs) {
  return {
    type: LIST_TABLE_JOBS_SUCCESS,
    tableJobs,
  }
}

function listTableJobsFailure() {
  return {
    type: LIST_TABLE_JOBS_FAILURE,
  }
}

export async function listTableJobs() {
  return function (dispatch, getState) {
    dispatch(listTableJobsRequest())

    const selectedGroup = getState().get('selectedGroup')

    return create()
      .get(`${SELF_SERVICE_ENDPOINT}/jobs/v1/?ngap-group=${selectedGroup}`)
      .then((response) => dispatch(listTableJobsSuccess(fromJS(response.data))))
      .catch((error) => {
        dispatch(listTableJobsFailure(error))
      })
  }
}
