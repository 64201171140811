import React from 'react'
import { toast } from 'react-toastify'
import { fromJS } from 'immutable'
import { create } from 'axios'

import { selectEmrEndpoint } from 'selectors/selectEmrEndpoint'

import { listEmrClusters } from 'actions/listEmrClusters'

import {
  CREATE_EMR_CLUSTER_REQUEST,
  CREATE_EMR_CLUSTER_SUCCESS,
  CREATE_EMR_CLUSTER_FAILURE,
} from 'utils/constant'

function createEmrClusterRequest() {
  return {
    type: CREATE_EMR_CLUSTER_REQUEST,
  }
}

function createEmrClusterSuccess(emrCluster, metadata) {
  return {
    type: CREATE_EMR_CLUSTER_SUCCESS,
    emrCluster,
    metadata,
  }
}

function createEmrClusterFailure() {
  return {
    type: CREATE_EMR_CLUSTER_FAILURE,
  }
}

// This error occurs if the user attempts to use a template that was created before the form was converted to Epic
const TEMPLATE_ERROR = 'server error - TypeError: app.toLowerCase is not a function'
const TEMPLATE_MESSAGE =
  'Template is not compatible with this version of OneUI. Please delete and re-create the template'

export async function createEmrCluster(payload) {
  return function (dispatch, getState) {
    const endpoint = selectEmrEndpoint(getState())

    dispatch(createEmrClusterRequest())

    return create()
      .post(endpoint, payload)
      .then((response) => {
        dispatch(
          createEmrClusterSuccess(fromJS(response.data), {
            isInstanceFleet: payload.isInstanceFleet,
            isDSTools: payload.cluster_name.includes('dstools'),
            clusterSize: payload.clusterSize,
          })
        )

        toast.success(`${payload.cluster_name} is starting`, {
          autoClose: false,
          position: 'top-left',
        })
      })
      .then(() => dispatch(listEmrClusters()))
      .catch((error) => {
        dispatch(createEmrClusterFailure())

        let errorMessage = 'Unable to create cluster: '

        if (error.response && error.response.data && error.response.data.errorMessage) {
          if (error.response.data.errorMessage === TEMPLATE_ERROR) errorMessage += TEMPLATE_MESSAGE
          else errorMessage += error.response.data.errorMessage

          toast.error(<div style={{ wordBreak: 'break-word' }}>{errorMessage}</div>, {
            autoClose: false,
            position: 'top-left',
          })
        }
      })
  }
}
