import { fromJS } from 'immutable'
import { create } from 'axios'
import { toast } from 'react-toastify'

import { selectSelectedGroup } from 'selectors/selectSelectedGroup'
import { selectSelectedRegion } from 'selectors/selectSelectedRegion'
import { selectSelectedAccount } from 'selectors/selectSelectedAccount'
import { selectSelectedRuntime } from 'selectors/selectSelectedRuntime'

import { setEmrVersion } from 'actions/setEmrVersion'

import {
  META_ENDPOINT,
  FETCH_EMR_CONFIG_REQUEST,
  FETCH_EMR_CONFIG_SUCCESS,
  FETCH_EMR_CONFIG_FAILURE,
} from 'utils/constant'

function fetchEmrConfigRequest() {
  return {
    type: FETCH_EMR_CONFIG_REQUEST,
  }
}

function fetchEmrConfigSuccess(emrConfig) {
  return {
    type: FETCH_EMR_CONFIG_SUCCESS,
    emrConfig,
  }
}

export function fetchEmrConfigFailure() {
  return {
    type: FETCH_EMR_CONFIG_FAILURE,
  }
}

export async function fetchEmrConfig() {
  return function (dispatch, getState) {
    dispatch(fetchEmrConfigRequest())

    const selectedGroup = selectSelectedGroup(getState())
    const selectedRegion = selectSelectedRegion(getState())
    const selectedAccount = selectSelectedAccount(getState())
    const selectedRuntime = selectSelectedRuntime(getState())

    return create()
      .get(
        `${META_ENDPOINT}/emr/v2?ngap_group=${selectedGroup}&ngap_region=${selectedRegion}&account=${selectedAccount}&runtime=${selectedRuntime}`,
        {
          transformRequest: [
            (data, headers) => {
              delete headers.common.Authorization
              return data
            },
          ],
        }
      )
      .then((response) => {
        dispatch(fetchEmrConfigSuccess(fromJS(response.data)))

        dispatch(setEmrVersion(response.data.emr_version[0]))
      })
      .catch((error) => {
        dispatch(fetchEmrConfigFailure(error))

        if (error.response) {
          toast.error(`EMR configuration not found in ${selectedRegion.toUpperCase()}`, {
            autoClose: false,
            position: 'top-left',
          })
        }
      })
  }
}
