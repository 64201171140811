import { Map } from 'immutable'
import {
  FETCH_TOKEN_REQUEST,
  FETCH_TOKEN_SUCCESS,
  FETCH_TOKEN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
} from 'utils/constant'

const initialState = Map({ token: '', isFetching: false })

export default function authToken(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN_REQUEST:
    case FETCH_TOKEN_REQUEST:
      return state.set('isFetching', true)

    case LOGIN_SUCCESS:
      return state.set('token', action.token).set('isFetching', false)

    case LOGIN_FAILURE:
    case FETCH_TOKEN_FAILURE:
    case FETCH_TOKEN_SUCCESS:
      return state.set('isFetching', false)

    default:
      return state
  }
}
