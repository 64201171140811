import { array, boolean, object, string } from 'yup'
import ReactGA from 'react-ga'

import { formatDate } from 'utils/formatDate'
import { getAlertConfig } from 'utils/getAlertConfig'
import { getCookie, deleteCookie } from 'utils/cookie'
import { addDays } from 'utils/addDate'

export const genAutoTableSchema = (acl) => {
  const emailListRegex = /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?)+$/
  const noSpaceRegex = /^\S*$/
  let sl_regex = new RegExp(acl.rw.concat(acl.ro).join('|').replace(/,/g, ''))
  let tdl_regex = new RegExp(acl.rw.join('|').replace(/,/g, ''))

  return object().shape({
    sourceLocation: string()
      .required('Input data location is required')
      .matches(sl_regex, 'You do not appear to have access to this path.'),
    tableName: string().required('Table name is required'),
    dbName: string().required(),
    tableDataLocation: string()
      .required()
      .matches(tdl_regex, 'You do not appear to have access to this path.'),
    scheduleType: object().required(),
    cadence: object().required(),
    scheduleStart: string(),
    scheduleEnd: string(),
    schemaEvolution: object().required(),
    sendAlert: boolean(),
    onSuccess: boolean(),
    onFailure: boolean(),
    email: string()
      .matches(noSpaceRegex, 'Should not contain spaces')
      .matches(emailListRegex, 'Emails should be separated by comma')
      .when('sendAlert', {
        is: true,
        then: string().required('Email is required'),
      }),
    jobName: string().required(),
    userDefinedColumns: string(),
    userDefinedPartitionColumns: string(),
    inferredColumns: array(),
    inferredPartitionColumns: array(),
    inferredFileStructure: string(),
    curatedColumns: array(),
  })
}

export const getInitialValues = ({ username }) => {
  const now = new Date(Date.now())

  return {
    sourceLocation: '',
    tableName: '',
    dbName: username,
    tableDataLocation: '',
    scheduleType: { label: 'One-time', value: 'one-time' },
    cadence: { label: 'Daily', value: 'daily' },
    scheduleStart: formatDate(now),
    scheduleEnd: formatDate(addDays(now, 90)),
    schemaEvolution: { label: 'Suspend Data Refresh', value: 'no_update' },
    sendAlert: false,
    onSuccess: false,
    onFailure: false,
    email: '',
    jobName: '',
    inferredColumns: [],
    inferredPartitionColumns: [],
    inferredFileStructure: '',
    curatedColumns: [],
  }
}

export const onSubmit = ({
  values,
  bag,
  savedJobId,
  setSavedJobId,
  createTableJob,
  selectedGroup,
}) => {
  const {
    sourceLocation,
    tableName,
    dbName,
    tableDataLocation,
    scheduleType,
    cadence,
    scheduleStart,
    scheduleEnd,
    schemaEvolution,
    sendAlert,
    onSuccess,
    onFailure,
    email,
    inferredColumns,
    inferredPartitionColumns,
    inferredFileStructure,
    jobName,
    curatedColumns,
  } = values

  const frequencyConfig = {
    frequency_type: scheduleType.value,
  }

  if (scheduleType.value === 'scheduled') {
    frequencyConfig.schedule = cadence.value

    frequencyConfig.start_time = scheduleStart

    frequencyConfig.end_time = scheduleEnd
  }

  const payload = {
    job_name: jobName,
    source_location: sourceLocation,
    table_data_location: tableDataLocation,
    table_name: tableName,
    db_name: dbName,
    group: selectedGroup,
    frequency: frequencyConfig,
    send_alert: getAlertConfig(sendAlert, onSuccess, onFailure, email),
    schema_fetch_type: 'schema_inference',
    submitted_columns: inferredColumns,
    schema_evolution_option: schemaEvolution.value,
  }

  if (curatedColumns) {
    payload.submitted_columns = payload.submitted_columns.concat(curatedColumns)
  }

  payload.submit_job = 'True'

  payload.inferred_columns = inferredColumns

  payload.job_id = savedJobId

  payload.submitted_partition_columns = inferredPartitionColumns

  payload.file_format = inferredFileStructure

  if (payload.submitted_partition_columns) {
    // eslint-disable-next-line
    for (let col in payload.submitted_partition_columns) {
      if (payload.submitted_partition_columns[col].filter_value) {
        payload.submitted_partition_columns[col].filter_partition = true
        payload.submitted_partition_columns[col].filter_value = payload.submitted_partition_columns[
          col
        ].filter_value.replace(/"/g, '')
      }
    }
  }

  if (payload.job_id === '') {
    delete payload.job_id
  }

  if (!payload.job_id) {
    payload.job_id = getCookie('tableJobId')
  }

  createTableJob(payload).then(() => {
    bag.resetForm()

    setSavedJobId('')

    deleteCookie('tableJobId')
    deleteCookie('cloneJobId')

    ReactGA.event({ category: 'SELF-SERVICE', action: 'Create table' })
  })
}

export const handleSetFormValues = (response, formikProps, setHasResetAutoTable, setSavedJobId) => {
  const {
    job_id,
    file_format,
    frequency,
    group,
    inferred_columns,
    inferred_partition_columns,
    job_name,
    schema_evolution_option,
    send_alert,
    source_location,
    table_data_location,
    table_name,
    curated_columns,
  } = response.data

  const db_name = formikProps.initialValues.dbName

  const now = new Date()

  const newValues = {
    sourceLocation: source_location,
    tableName: table_name,
    dbName: db_name,
    tableDataLocation: table_data_location,
    group,
    scheduleStart: frequency.start_time || formatDate(now),
    scheduleEnd: frequency.end_time || formatDate(addDays(now, 90)),
    sendAlert: Boolean(send_alert.alert_configured),
    onSuccess: !!(
      send_alert['alert_condition'] === 'on_success' ||
      send_alert['alert_condition'] === 'on_success_failure'
    ),
    onFailure: !!(
      send_alert['alert_condition'] === 'on_failure' ||
      send_alert['alert_condition'] === 'on_success_failure'
    ),
    email: send_alert.alert_email || '',
    jobName: job_name || '',
    inferredColumns: inferred_columns === 'None' || !inferred_columns ? [] : inferred_columns,
    inferredPartitionColumns:
      inferred_partition_columns === 'None' || !inferred_partition_columns
        ? []
        : inferred_partition_columns,
    inferredFileStructure: file_format || '',
    curatedColumns: curated_columns === 'None' || !curated_columns ? [] : curated_columns,
  }

  if (frequency.frequency_type === 'one-time') {
    newValues.scheduleType = {
      label: 'One-time',
      value: 'one-time',
    }
  } else if (frequency.frequency_type === 'scheduled') {
    newValues.scheduleType = {
      label: 'Recurring',
      value: 'scheduled',
    }
  } else {
    newValues.scheduleType = {
      label: 'One-time',
      value: 'one-time',
    }
  }

  if (frequency.schedule === 'daily') {
    newValues.cadence = {
      label: 'Daily',
      value: 'daily',
    }
  } else if (frequency.schedule === 'weekly') {
    newValues.cadence = {
      label: 'Weekly',
      value: 'weekly',
    }
  } else if (frequency.schedule === 'monthly') {
    newValues.cadence = {
      label: 'Monthly',
      value: 'monthly',
    }
  } else {
    newValues.cadence = {
      label: 'Daily',
      value: 'daily',
    }
  }

  if (schema_evolution_option === 'add_update') {
    newValues.schemaEvolution = {
      label: 'Allow Additives Changes',
      value: 'add_update',
    }
  } else if (schema_evolution_option === 'no_update') {
    newValues.schemaEvolution = {
      label: 'Suspend Data Refresh',
      value: 'no_update',
    }
  } else {
    newValues.schemaEvolution = {
      label: 'Suspend Data Refresh',
      value: 'no_update',
    }
  }

  formikProps.setValues(newValues)

  setHasResetAutoTable(true)

  setSavedJobId(job_id)
}
