/** @jsx jsx */
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rebass'
import {
  IconButton,
  Select,
  FormSection,
  TextInput,
  Checkbox,
  TextArea,
} from '@nike/epic-react-ui-old'
import { css, jsx } from '@emotion/core'
import { Form, FieldArray } from 'formik'
import Divider from '@material-ui/core-new/Divider'

import InstanceFleetsGrid from 'components/emr/instance-fleets-grid'

import useForceUpdate from 'hooks/useForceUpdate'

import { calculateEmrClusterCost } from 'utils/emrCosts'
import { getEmrClusterTemplates } from 'utils/getEmrClusterTemplates'

import {
  classificationOptions,
  hiveEnvironmentOptions,
  aeComputeEnvironmentOptions,
  getTemplateNameOptions,
} from 'utils/create-emr-cluster-form-helpers'

export default function CreateEmrClusterForm(props) {
  const {
    setEmrVersion,
    supportedEmrVersionOptions,
    formikProps,
    supportedInstanceTypeOptions,
    supportedTaskInstanceTypeOptions,
    clusterSizeOptions,
    defaultInstances,
    emrRegularInstanceTypeCosts,
    emrInstanceFleetInstanceTypeCosts,
    emrRegularClusterCostsBySize,
    emrInstanceClusterCostsBySize,
    instanceTypeCostRanking,
    emrApplications,
    isRangerAllowed,
    isBootstrapAllowed,
    isManagedScalingAllowed,
  } = props

  const [isCreateNewTemplate, setIsCreateNewTemplate] = useState(false)

  const forceUpdate = useForceUpdate()

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleReset,
    setFieldValue,
    setValues,
  } = formikProps

  const templateNameOptions = getTemplateNameOptions()

  const handleApplicationChange = (appName) => (event) => {
    if (event.target.checked) {
      setFieldValue('applications', [...values.applications, appName])
    } else {
      setFieldValue(
        'applications',
        values.applications.filter((app) => app !== appName)
      )
    }
  }

  const handleOkeraOrRanger = (e) => {
    if (e.target.name === 'enableOkera' && e.target.value && values.enableRanger) {
      setFieldValue('enableRanger', false)
    } else if (e.target.name === 'enableRanger' && e.target.value && values.enableOkera) {
      setFieldValue('enableOkera', false)
    }
  }

  const handleResetDefaultEnvironment = () => {
    setFieldValue('environment', {
      label: values.enableRanger ? 'Non-Prod' : 'Dev',
      value: 'dev',
    })
  }

  const handleCustomInstanceFleets = (index, val, correspondingField) => {
    const defaultVal = Number(val) + values.instanceFleets[index][correspondingField]
    if (index === 1) {
      setFieldValue('numCoreNodes', defaultVal)
      setFieldValue('coreMax', defaultVal)
    } else if (index === 2) {
      setFieldValue('numTaskNodes', defaultVal)
      setFieldValue('taskMax', defaultVal)
    }
  }

  const setDefaultCustomInstanceFleets = () => {
    const coreOnDemand = values.instanceFleets[1]['targetOnDemandCapacity']
    const taskOnDemand = values.instanceFleets[2]['targetOnDemandCapacity']
    handleCustomInstanceFleets(1, coreOnDemand, 'targetSpotCapacity')
    handleCustomInstanceFleets(2, taskOnDemand, 'targetSpotCapacity')
  }

  const emrCost = calculateEmrClusterCost(
    values,
    values.instanceFleets,
    emrRegularInstanceTypeCosts,
    emrInstanceFleetInstanceTypeCosts,
    emrRegularClusterCostsBySize,
    emrInstanceClusterCostsBySize,
    instanceTypeCostRanking
  )

  return (
    <Form>
      <Box>
        <FormSection title='Template (optional)'>
          <Box style={{ position: 'relative' }}>
            <Select
              id='templateName'
              name='templateName'
              label='Templates'
              options={templateNameOptions}
              placeholder='Select'
              value={values.templateName}
              onChange={(option) => {
                if (option.value === 'none') {
                  handleReset()
                }

                const selectedTemplate = getEmrClusterTemplates().find(
                  (template) => template.newTemplateName === option.value
                )

                if (selectedTemplate) {
                  setValues(selectedTemplate)
                  forceUpdate()
                }

                setFieldValue('templateName', option)
              }}
              onBlur={handleBlur}
            />

            {values.templateName && values.templateName.value !== 'none' && (
              <div
                css={css`
                  position: absolute;
                  left: 95%;
                  top: 30%;
                `}
              >
                <IconButton
                  data-e2e='template-reset-button'
                  type='delete'
                  iconFontSize='24px'
                  bare
                  onClick={() => {
                    const updated = getEmrClusterTemplates().filter(
                      (template) => template.newTemplateName !== values.templateName.value
                    )

                    localStorage.setItem('emrClusterTemplates', JSON.stringify(updated))

                    handleReset()
                  }}
                />
              </div>
            )}
          </Box>

          <Box mt='20px' ml='10px'>
            <Checkbox
              data-e2e='should-create-template-checkbox'
              label='Create new template'
              checked={isCreateNewTemplate}
              onChange={(event) => {
                setIsCreateNewTemplate(event.target.checked)
              }}
              onBlur={handleBlur}
            />
          </Box>

          {isCreateNewTemplate && (
            <TextInput
              data-e2e='new-template-name-textinput'
              name='newTemplateName'
              label='New template name'
              placeholder='New template name'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.newTemplateName}
              css={css`
                margin-top: 20px;
              `}
            />
          )}
        </FormSection>
      </Box>

      <FormSection title='General Configuration'>
        <TextInput
          data-e2e='cluster-name-textinput'
          required
          name='clusterName'
          label='Cluster name'
          placeholder='Cluster name'
          value={values.clusterName}
          onChange={handleChange}
          onBlur={handleBlur}
          hasErrors={errors.clusterName && touched.clusterName}
          errorMessage={errors.clusterName && touched.clusterName && errors.clusterName}
          css={css`
            margin-bottom: 10px;
          `}
        />

        <Box mt='10px' mb='20px' ml='10px'>
          <Checkbox
            data-e2e='enable-ds-tools-checkbox'
            id='enableDsTools'
            label='Enable Data Science Tools (group access required)'
            name='enableDsTools'
            checked={values.enableDsTools}
            onChange={(event) => {
              const clusterNameUpdated = !values.enableDsTools
                ? `${values.clusterName}-dstools`
                : `${values.clusterName.replace('-dstools', '')}`

              setFieldValue('clusterName', clusterNameUpdated)

              setFieldValue('enableDsTools', event.target.checked)
            }}
            onBlur={handleBlur}
          />

          <Box mt='10px'>
            <Checkbox
              data-e2e='enable-custom-dns-checkbox'
              id='enableCustomDNS'
              label='Enable Custom DNS (adds 3-5 mins to spin up)'
              name='enableCustomDNS'
              checked={values.enableCustomDNS}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>
        </Box>

        <Select
          id='classification-select'
          name='classification'
          label='Classification'
          options={classificationOptions}
          placeholder='Classification'
          value={values.classification}
          onChange={(option) => setFieldValue('classification', option)}
          onBlur={handleBlur}
        />
      </FormSection>

      <FormSection title='Software Configuration'>
        <Select
          id='emr-version-select'
          name='emrVersion'
          label='EMR version'
          options={supportedEmrVersionOptions}
          placeholder='EMR version'
          value={values.emrVersion}
          onChange={(option) => {
            setEmrVersion(option.value)
            setFieldValue('emrVersion', option)
          }}
          onBlur={handleBlur}
        />

        <Box mt='10px' mb='20px' ml='10px'>
          <div className='epic-font-base-md'>Data Access Control</div>
          <Box mt='5px'>
            <Checkbox
              data-e2e='enable-ranger-checkbox'
              id='enableRanger'
              name='enableRanger'
              label='Enable Ranger'
              checked={values.enableRanger}
              disabled={!isRangerAllowed}
              onChange={(e) => {
                handleChange(e)
                handleOkeraOrRanger(e)
                handleResetDefaultEnvironment()
              }}
              onBlur={handleBlur}
            />
          </Box>
        </Box>

        {emrApplications && (
          <Box mt='20px' ml='10px'>
            <div className='epic-font-base-md'>Applications</div>
            {emrApplications.map((app, i) => (
              <Checkbox
                key={i}
                data-e2e={`${app}-checkbox`}
                label={`${app}`}
                checked={values.applications.includes(app.toLowerCase())}
                onChange={handleApplicationChange(app.toLowerCase())}
              />
            ))}

            <p style={{ color: '#FE0000', marginTop: '15px' }}>{errors.applications}</p>
          </Box>
        )}
      </FormSection>

      <FormSection
        title='Hardware Configuration'
        css={
          values.useInstanceFleets && values.clusterSize && values.clusterSize.value === 'custom'
            ? css`
                padding: 30px 10%;
                min-width: 1020px;
              `
            : ''
        }
      >
        <Box ml='15px'>
          <Checkbox
            data-e2e='use-instance-fleets-checkbox'
            name='useInstanceFleets'
            label='Use Instance Fleets'
            checked={values.useInstanceFleets}
            onChange={(e) => {
              handleChange(e)
              if (values.clusterSize.value === 'custom') {
                if (e.target.checked) {
                  setDefaultCustomInstanceFleets()
                } else {
                  setFieldValue('coreMax', values.numCoreNodes)
                  setFieldValue('taskMax', values.numTaskNodes)
                }
              }
            }}
            onBlur={handleBlur}
          />
        </Box>

        <Box mt='20px'>
          <Select
            id='cluster-size-select'
            name='clusterSize'
            label='Cluster size'
            options={clusterSizeOptions}
            placeholder='Cluster size'
            value={values.clusterSize}
            onChange={(option) => {
              setFieldValue('clusterSize', option)
              if (option.value !== 'custom') {
                setFieldValue('coreMax', defaultInstances[option.value].numCoreNodes)
                setFieldValue('taskMax', defaultInstances[option.value].numTaskNodes)
              } else {
                setDefaultCustomInstanceFleets()
              }
            }}
            onBlur={handleBlur}
          />
        </Box>

        {values.useInstanceFleets &&
          values.clusterSize &&
          values.clusterSize.value === 'custom' && (
            <InstanceFleetsGrid
              instanceFleets={values.instanceFleets}
              supportedCoreInstanceTypeOptions={supportedInstanceTypeOptions}
              supportedTaskInstanceTypeOptions={supportedTaskInstanceTypeOptions}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              handleCustomInstanceFleets={handleCustomInstanceFleets}
            />
          )}

        {!values.useInstanceFleets && values.clusterSize && values.clusterSize.value === 'custom' && (
          <Box>
            <Select
              id='master-inst-type-select'
              label='Master instance type'
              options={supportedInstanceTypeOptions}
              name='masterInstType'
              value={values.masterInstType}
              onChange={(option) => setFieldValue('masterInstType', option)}
              onBlur={handleBlur}
            />

            <Select
              id='core-inst-type-select'
              label='Core instance type'
              options={supportedInstanceTypeOptions}
              name='coreInstType'
              value={values.coreInstType}
              onChange={(option) => setFieldValue('coreInstType', option)}
              onBlur={handleBlur}
            />

            <Select
              id='task-inst-type-select'
              label='Task instance type'
              options={supportedTaskInstanceTypeOptions}
              name='taskInstType'
              value={values.taskInstType}
              onChange={(option) => setFieldValue('taskInstType', option)}
              onBlur={handleBlur}
            />

            <TextInput
              data-e2e='num-core-nodes-textinput'
              name='numCoreNodes'
              label='Number of core nodes'
              type='number'
              value={values.numCoreNodes}
              onChange={(event) => {
                setFieldValue('numCoreNodes', event.target.value)
                setFieldValue('coreMax', event.target.value)
              }}
              onBlur={handleBlur}
            />

            <TextInput
              data-e2e='num-task-nodes-textinput'
              name='numTaskNodes'
              label='Number of task nodes'
              type='number'
              value={values.numTaskNodes}
              onChange={(event) => {
                setFieldValue('numTaskNodes', event.target.value)
                setFieldValue('taskMax', event.target.value)
              }}
              onBlur={handleBlur}
            />
          </Box>
        )}

        {isManagedScalingAllowed && (
          <Box mt='30px' ml='15px'>
            <Checkbox
              data-e2e='is-managedscaling-checkbox'
              id='isManagedScaling'
              name='isManagedScaling'
              label='Managed Scaling'
              checked={values.isManagedScaling}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>
        )}

        {isManagedScalingAllowed && values.isManagedScaling && (
          <Box mt='20px'>
            <TextInput
              data-e2e='core-min-textinput'
              type='number'
              name='coreMin'
              label='Core min'
              value={values.coreMin}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <TextInput
              data-e2e='core-max-textinput'
              type='number'
              name='coreMax'
              label='Core max'
              value={values.coreMax}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <TextInput
              data-e2e='task-min-textinput'
              type='number'
              name='taskMin'
              label='Task min'
              value={values.taskMin}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <TextInput
              data-e2e='task-max-textinput'
              type='number'
              name='taskMax'
              label='Task max'
              value={values.taskMax}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>
        )}

        <Box
          mt={20}
          ml={30}
          css={css`
            text-align: right;
            margin-right: 10px;
            color: red;
          `}
        >
          {emrCost && !isNaN(emrCost) && `Approximate cost: $${emrCost} per hour`}
        </Box>
      </FormSection>

      {isBootstrapAllowed && (
        <FormSection title='Bootstrap Actions (optional)'>
          <FieldArray
            name='bootstrapActions'
            render={(arrayHelpers) => (
              <Box>
                {values.bootstrapActions.map((bootstrapAction, index) => (
                  <Box
                    key={index}
                    css={css`
                      position: relative;
                      margin-top: ${index === 0 ? '0' : '30px'};
                    `}
                  >
                    <div
                      css={css`
                        position: absolute;
                        left: 95%;
                      `}
                    >
                      <IconButton
                        data-e2e={`delete-bootstrap-action-button-${index}`}
                        type='delete'
                        iconFontSize='24px'
                        bare
                        onClick={() => {
                          arrayHelpers.remove(index)
                        }}
                      />
                    </div>

                    <TextInput
                      data-e2e={`bootstrap-action-name-${index}`}
                      name={`bootstrapActions.${index}.Name`}
                      label='Name'
                      placeholder='Name'
                      value={bootstrapAction.Name}
                      onChange={handleChange}
                    />

                    <TextInput
                      data-e2e={`bootstrap-action-path-${index}`}
                      name={`bootstrapActions.${index}.ScriptBootstrapAction.Path`}
                      label='Path'
                      placeholder='Location in S3'
                      value={bootstrapAction.ScriptBootstrapAction.Path}
                      onChange={handleChange}
                    />

                    <TextInput
                      data-e2e={`bootstrap-action-args-${index}`}
                      name={`bootstrapActions.${index}.ScriptBootstrapAction.Args`}
                      label='Args (separate with commas)'
                      placeholder='Args'
                      value={bootstrapAction.ScriptBootstrapAction.Args}
                      onChange={handleChange}
                    />

                    <Divider />
                  </Box>
                ))}

                <IconButton
                  data-e2e='add-bootstrap-action-button'
                  type='add'
                  iconFontSize='24px'
                  bare
                  onClick={() =>
                    arrayHelpers.push({
                      Name: '',
                      ScriptBootstrapAction: {
                        Path: '',
                        Args: '',
                      },
                    })
                  }
                >
                  Add
                </IconButton>
              </Box>
            )}
          />
        </FormSection>
      )}

      <FormSection title='Additional EMR Configuration (OPTIONAL)'>
        <Box
          css={css`
            position: relative;
          `}
        >
          {values.isEmrConf && (
            <div
              css={css`
                position: absolute;
                left: 95%;
              `}
            >
              <IconButton
                data-e2e='delete-emr-conf-button'
                type='delete'
                iconFontSize='24px'
                bare
                onClick={() => setFieldValue('isEmrConf', false)}
              />
            </div>
          )}

          {values.isEmrConf && (
            <TextArea
              data-e2e='emr-conf-text-area'
              name='emrConfigParams'
              value={values.emrConfigParams}
              label='JSON configuration (double quotes only)'
              onChange={handleChange}
              hasErrors={values.emrConfigParams && errors.emrConfigParams}
              errorMessage={
                errors.emrConfigParams && values.emrConfigParams && errors.emrConfigParams
              }
            />
          )}

          {!values.isEmrConf && (
            <IconButton
              data-e2e='add-emr-conf-button'
              type='add'
              iconFontSize='24px'
              bare
              onClick={() => setFieldValue('isEmrConf', !values.isEmrConf)}
            >
              Add
            </IconButton>
          )}
        </Box>
      </FormSection>

      <FormSection title='Tags (optional)'>
        <FieldArray
          name='tags'
          render={(arrayHelpers) => (
            <Box>
              {values.tags.map((tag, index) => (
                <Box
                  key={index}
                  css={css`
                    position: relative;
                    margin-top: ${index === 0 ? '0' : '15px'};
                  `}
                >
                  <div
                    css={css`
                      position: absolute;
                      left: 95%;
                      top: 0;
                    `}
                  >
                    <IconButton
                      data-e2e={`delete-tag-button-${index}`}
                      type='delete'
                      iconFontSize='24px'
                      bare
                      onClick={() => arrayHelpers.remove(index)}
                    />
                  </div>

                  <TextInput
                    data-e2e={`tag-key-textinput-${index}`}
                    name={`tags.${index}.Key`}
                    label='Key'
                    placeholder='Key'
                    value={tag.Key}
                    onChange={handleChange}
                  />

                  <TextInput
                    data-e2e={`tag-value-textinput-${index}`}
                    name={`tags.${index}.Value`}
                    label='Value'
                    placeholder='Value'
                    value={tag.Value}
                    onChange={handleChange}
                  />

                  <Divider />
                </Box>
              ))}

              <div>
                <IconButton
                  data-e2e='add-tag-button'
                  type='add'
                  iconFontSize='24px'
                  bare
                  onClick={() => arrayHelpers.push({ Key: '', Value: '' })}
                >
                  Add
                </IconButton>
              </div>
            </Box>
          )}
        />
      </FormSection>

      <FormSection title='Hive Environment'>
        {!values.enableRanger ? (
          <Select
            name='hiveEnvironment'
            label=''
            options={hiveEnvironmentOptions}
            value={values.environment}
            onChange={(option) => setFieldValue('environment', option)}
          />
        ) : (
          <div>
            <div className='epic-font-base-md'>Prod</div>
            <Box
              css={css`
                text-align: right;
                margin-top: 16px;
                color: red;
              `}
            >
              Only Production Hive Metastore is available because you selected Ranger enabled
              cluster
            </Box>
          </div>
        )}
      </FormSection>

      {values.enableRanger && (
        <FormSection title='AE Compute Environment'>
          <Select
            name='aeComputeEnvironment'
            label=''
            options={aeComputeEnvironmentOptions}
            value={values.environment}
            onChange={(option) => setFieldValue('environment', option)}
          />
        </FormSection>
      )}
    </Form>
  )
}

CreateEmrClusterForm.propTypes = {
  setEmrVersion: PropTypes.func.isRequired,
  supportedEmrVersionOptions: PropTypes.arrayOf(PropTypes.object),
  formikProps: PropTypes.object.isRequired,
  supportedInstanceTypeOptions: PropTypes.arrayOf(PropTypes.object),
  supportedTaskInstanceTypeOptions: PropTypes.arrayOf(PropTypes.object),
  isRangerAllowed: PropTypes.bool,
  isBootstrapAllowed: PropTypes.bool,
}
