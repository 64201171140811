import { fromJS } from 'immutable'
import { create } from 'axios'

import {
  GENERATE_AIRFLOW_OPERATOR_REQUEST,
  GENERATE_AIRFLOW_OPERATOR_SUCCESS,
  GENERATE_AIRFLOW_OPERATOR_FAILURE,
} from 'utils/constant'

import { selectJobsEndpoint } from 'selectors/selectJobsEndpoint'

function generateAirflowOperatorRequest() {
  return {
    type: GENERATE_AIRFLOW_OPERATOR_REQUEST,
  }
}

function generateAirflowOperatorSuccess(airflowOperator) {
  return {
    type: GENERATE_AIRFLOW_OPERATOR_SUCCESS,
    airflowOperator,
  }
}

function generateAirflowOperatorFailure() {
  return {
    type: GENERATE_AIRFLOW_OPERATOR_FAILURE,
  }
}

export async function generateAirflowOperator(jobId) {
  return function (dispatch, getState) {
    dispatch(generateAirflowOperatorRequest())

    const endpoint = selectJobsEndpoint(getState())

    return create()
      .get(`${endpoint}/airflow/operator/v1/${jobId}`)
      .then((response) => dispatch(generateAirflowOperatorSuccess(fromJS(response.data))))
      .catch((error) => {
        dispatch(generateAirflowOperatorFailure(error))
      })
  }
}
