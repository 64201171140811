/** @jsx jsx */
import PropTypes from 'prop-types'
import { Modal, Button, TextArea } from '@nike/epic-react-ui-old'
import { css, jsx } from '@emotion/core'
import { Flex, Box } from 'rebass'

export default function JobAirflowOperatorDialog(props) {
  const { isOpen, toggleJobAirflowOperatorDialog, airflowOperator, jobForAirflowOperator } = props

  const jobName = jobForAirflowOperator ? jobForAirflowOperator.job_name : ''
  const airflowOperatorRendered = airflowOperator ? airflowOperator[0] : ''

  return (
    <Modal
      title={'Airflow Operator for ' + jobName}
      show={isOpen}
      onClose={toggleJobAirflowOperatorDialog}
      modalSize='md'
      swoosh
      css={css`
        z-index: 500;
        height: 75vh;
        overflow: scroll;
      `}
    >
      <Flex flexDirection='column'>
        <div
          style={{
            backgroundColor: '#f7f7f7',
            borderRadius: '1em',
            padding: '30px',
            margin: '20px',
          }}
        >
          <Box style={{ height: '51vh', overflow: 'scroll' }}>
            <TextArea style={{ whiteSpace: 'pre-wrap' }} value={airflowOperatorRendered} readOnly />
          </Box>
        </div>

        <Flex mt='15px' justifyContent='center'>
          <Button
            data-e2e='job-airflow-operator-dialog-close-button'
            data-external-id='close-job-airflow-operator-dialog'
            id='job-airflow-operator-dialog-close-button'
            onClick={toggleJobAirflowOperatorDialog}
            large
            inverse
          >
            Close
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

JobAirflowOperatorDialog.propTypes = {
  isOpen: PropTypes.bool,
  toggleJobAirflowOperatorDialog: PropTypes.func.isRequired,
  airflowOperator: PropTypes.array,
  jobForAirflowOperator: PropTypes.object,
}
