import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@nike/epic-react-ui-old'
import { Box } from 'rebass'

import TableJobInfo from 'components/self-service/table-job-info'

export function JobDetailsDialog(props) {
  const { activeJob, isJobDetailsDialogOpen, toggleDialog } = props

  function closeDialog() {
    toggleDialog('JobDetailsDialog')
  }

  return (
    <Modal title='Job Details' show={isJobDetailsDialogOpen} modalSize='md' onClose={closeDialog}>
      <Box style={{ height: '51vh', overflow: 'scroll' }}>
        <TableJobInfo tableJob={activeJob} />
      </Box>
    </Modal>
  )
}

JobDetailsDialog.propTypes = {
  activeJob: PropTypes.object.isRequired,
}

export default JobDetailsDialog
