/** @jsx jsx */
import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { css, jsx } from '@emotion/core'
import { Box } from 'rebass'
import Tooltip from '@nike/nr-vamp-xl-tooltip'
import { TextInput, Select } from '@nike/epic-react-ui-old'
import { Map } from 'immutable'
import ReactGA from 'react-ga'

import JobsTableActionsMenu from 'components/self-service/jobs-table-actions-menu'
import ResizableTable from 'components/common/resizable-table'

const tooltipMap = {
  STARTING: 'Starting to build a table using source data.',
  RUNNING: 'Submitted/Scheduled Job is running ',
  SUCCEEDED: 'Job has completed successfully and Table is ready to query',
  FAILED: 'Job has failed, please check for the details in Job information page.',
  FLATTENING: 'Expanding/Exploding the source data.',
  SCHEDULE_PENDING: "Scheduled Job is waiting for it's schedule time to run ",
  PROFILE_PENDING: 'Identified data profile and is about to kick off data processing.',
  CREATING_TABLE: 'Creating the table.',
  CREATED_TABLE: 'Table is created and will be ready to query soon.',
  UPDATING_TABLE_METADATA: 'Adding partitioned data to tables.',
  SCHEMA_INFERRING: 'Identifying the schema in the source location.',
  SCHEMA_INFERRED: 'Identified the schema in the source location.',
  SPARK_INVOKED: 'processing the source data to build the table.',
  TRIGGERED_AIRFLOW: 'Backend Job has been kicked off to process the data.',
  null: 'The thing',
  GRADUATING: 'In the process of moving tables to managed space',
  GRADUATED: 'Completed moving tables to managed space',
}

const statusOptions = Object.keys(tooltipMap).map((key) => ({
  label: key.toLowerCase(),
  value: key.toLowerCase(),
}))

const scheduleOptions = [
  {
    label: 'one-time',
    value: 'one-time',
  },
  {
    label: 'scheduled',
    value: 'scheduled',
  },
]

const makeTooltipStyle = (status) => {
  let style = {
    tooltip: {},
  }
  switch (status.toLowerCase()) {
    case 'succeeded':
      style.tooltip.color = 'chartreuse'
      break
    case 'failed':
      style.tooltip.color = 'tomato'
      break
    default:
      style.tooltip.color = 'cyan'
  }

  return style
}

const getDataKeys = () => {
  return [
    {
      accessor: 'jobName',
      Header: 'Job name',
    },

    {
      accessor: 'group',
      Header: 'Group',
    },
    {
      accessor: 'tableName',
      Header: 'Table Name',
    },
    {
      accessor: 'dbName',
      Header: 'DB Name',
    },
    {
      accessor: 'frequencyType',
      Header: 'Schedule',
    },
    {
      accessor: 'status',
      Header: 'Status',
    },
    {
      accessor: 'actions',
      Header: 'actions',
    },
  ]
}

const getTableData = (props, filterValues, handleFilterChange, searchValue) => {
  const { toggleDialog, tableJobs, navigateToAutoTable } = props

  const searchValueLc = searchValue.toLowerCase()

  let tableData = tableJobs
    ? tableJobs
        .sort((current, nextItem) =>
          new Date(current.updated_time) > new Date(nextItem.updated_time) ? -1 : 1
        )
        .filter(
          (tableJob) =>
            (tableJob.job_name.toLowerCase().includes(filterValues.get('jobName')) ||
              !filterValues.get('jobName')) &&
            (tableJob.group.toLowerCase().includes(filterValues.get('group')) ||
              !filterValues.get('group')) &&
            (tableJob.table_name.toLowerCase().includes(filterValues.get('tableName')) ||
              !filterValues.get('tableName')) &&
            (tableJob.db_name.toLowerCase().includes(filterValues.get('dbName')) ||
              !filterValues.get('dbName')) &&
            (tableJob.frequency.frequency_type
              .toLowerCase()
              .includes(filterValues.get('frequencyType')) ||
              !filterValues.get('frequencyType')) &&
            (tableJob.status.toLowerCase().includes(filterValues.get('status')) ||
              !filterValues.get('status'))
        )
        .filter((tableJob) => {
          if (!searchValueLc) {
            return true
          }

          return (
            (tableJob.job_name && tableJob.job_name.toLowerCase().includes(searchValueLc)) ||
            (tableJob.group && tableJob.group.toLowerCase().includes(searchValueLc)) ||
            (tableJob.table_name && tableJob.table_name.toLowerCase().includes(searchValueLc)) ||
            (tableJob.db_name && tableJob.db_name.toLowerCase().includes(searchValueLc)) ||
            (tableJob.frequency &&
              tableJob.frequency.frequencyType &&
              tableJob.frequency.frequency_type.toLowerCase().includes(searchValueLc)) ||
            (tableJob.status && tableJob.status.toLowerCase().includes(searchValueLc))
          )
        })
    : []

  tableData.unshift({})

  return tableData.map((tableJob, index) => ({
    jobName:
      index === 0 ? (
        <TextInput
          placeholder='Filter'
          value={filterValues.get('jobName')}
          onChange={(event) => handleFilterChange('jobName', event.target.value)}
        />
      ) : (
        <span
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            backgroundColor: 'transparent',
          }}
          onClick={() => {
            toggleDialog('JobDetailsDialog', tableJob)
            ReactGA.event({
              category: 'SELF-SERVICE',
              action: 'View table job details',
            })
          }}
        >
          {tableJob.job_name}
        </span>
      ),
    group:
      index === 0 ? (
        <TextInput
          placeholder='Filter'
          value={filterValues.get('group')}
          onChange={(event) => handleFilterChange('group', event.target.value)}
        />
      ) : (
        tableJob.group
      ),
    tableName:
      index === 0 ? (
        <TextInput
          placeholder='Filter'
          value={filterValues.get('tableName')}
          onChange={(event) => handleFilterChange('tableName', event.target.value)}
        />
      ) : (
        tableJob.table_name
      ),
    dbName:
      index === 0 ? (
        <TextInput
          placeholder='Filter'
          value={filterValues.get('dbName')}
          onChange={(event) => handleFilterChange('dbName', event.target.value)}
        />
      ) : (
        tableJob.db_name
      ),
    frequencyType:
      index === 0 ? (
        <Select
          placeholder='Filter'
          onChange={(option) => handleFilterChange('frequencyType', option ? option.value : '')}
          options={scheduleOptions}
          isClearable={!!filterValues.get('frequencyType')}
        />
      ) : tableJob.frequency.frequency_type === 'scheduled' ? (
        <button
          style={{
            textDecoration: 'underline',
            backgroundColor: 'transparent',
            padding: '0px',
          }}
          onClick={(event) => {
            event.preventDefault()
            toggleDialog('ScheduledJobDialog', tableJob)
          }}
          data-external-id='open-scheduled-job-dialog'
        >
          {tableJob && tableJob.frequency ? tableJob.frequency.frequency_type : ''}
        </button>
      ) : tableJob && tableJob.frequency ? (
        tableJob.frequency.frequency_type
      ) : (
        ''
      ),
    status:
      index === 0 ? (
        <Select
          placeholder='Filter'
          onChange={(option) => handleFilterChange('status', option ? option.value : '')}
          options={statusOptions}
          isClearable={!!filterValues.get('status')}
        />
      ) : (
        <Tooltip
          title={tooltipMap[tableJob.status] || ''}
          enterDelay={1}
          placement='top'
          styleExtension={makeTooltipStyle(tableJob.status || '')}
        >
          <span
            css={css`
              color: ${['created_table', 'succeeded'].includes(
                tableJob.status ? tableJob.status.toLowerCase() : ''
              )
                ? '#48bd1f'
                : tableJob.status === 'FAILED'
                ? '#fe0000'
                : '000000'};
              min-width: 225px;
              max-width: 225px;
              padding: 8px;
            `}
          >
            {tableJob.status ? tableJob.status.toLowerCase() : 'NULL'}
          </span>
        </Tooltip>
      ),
    actions: index !== 0 && (
      <Box width='20%' margin='0 auto'>
        <JobsTableActionsMenu
          tableJob={tableJob}
          navigateToAutoTable={navigateToAutoTable}
          setHasResetAutoTable={props.setHasResetAutoTable}
          toggleDialog={props.toggleDialog}
        />
      </Box>
    ),
  }))
}

export default function JobsTable(props) {
  const { isLoading, searchValue } = props

  const [filterValues, setFilterValues] = useState(new Map())

  function handleFilterChange(columnAccessor, value) {
    setFilterValues((prevState) => prevState.set(columnAccessor, value))
  }

  const data = getTableData(props, filterValues, handleFilterChange, searchValue)
  const columns = useMemo(() => getDataKeys(), [])

  return (
    <ResizableTable
      columns={columns}
      data={data}
      isLoading={isLoading}
      noRowsMessage='No jobs found.'
      shouldFirstRowOverflow
    />
  )
}

JobsTable.propTypes = {
  tableJobs: PropTypes.array,
  isListingTableJobs: PropTypes.bool,
  isLoading: PropTypes.bool,
  searchValue: PropTypes.string,
  toggleDialog: PropTypes.func,
  navigateToAutoTable: PropTypes.func,
}
