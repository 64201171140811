import { List, Map } from 'immutable'

import {
  LIST_EMR_CLUSTERS_REQUEST,
  LIST_EMR_CLUSTERS_SUCCESS,
  LIST_EMR_CLUSTERS_FAILURE,
  CREATE_EMR_CLUSTER_REQUEST,
  CREATE_EMR_CLUSTER_FAILURE,
  RESIZE_EMR_CLUSTER_REQUEST,
  RESIZE_EMR_CLUSTER_FAILURE,
  TERMINATE_EMR_CLUSTER_REQUEST,
  TERMINATE_EMR_CLUSTER_SUCCESS,
  TERMINATE_EMR_CLUSTER_FAILURE,
  FETCH_EMR_CONFIG_FAILURE,
} from 'utils/constant'

const initialState = new Map({
  isFetching: false,
  data: new List(),
})

export default function emrClusters(state = initialState, action = {}) {
  switch (action.type) {
    case LIST_EMR_CLUSTERS_REQUEST:
    case CREATE_EMR_CLUSTER_REQUEST:
    case RESIZE_EMR_CLUSTER_REQUEST:
    case TERMINATE_EMR_CLUSTER_REQUEST:
      return state.set('isFetching', true)

    case LIST_EMR_CLUSTERS_SUCCESS:
      return state.set('data', action.emrClusters).set('isFetching', false)

    case LIST_EMR_CLUSTERS_FAILURE:
    case CREATE_EMR_CLUSTER_FAILURE:
    case RESIZE_EMR_CLUSTER_FAILURE:
    case TERMINATE_EMR_CLUSTER_FAILURE:
      return state.set('isFetching', false)

    case TERMINATE_EMR_CLUSTER_SUCCESS:
      const emrClusters = state.get('data')
      const index = emrClusters.findIndex(
        (emrCluster) => emrCluster.get('cluster_id') === action.clusterId
      )
      return state.set('data', emrClusters.delete(index)).set('isFetching', false)

    case FETCH_EMR_CONFIG_FAILURE:
      return state.set('data', new List())

    default:
      return state
  }
}
