import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Flex } from 'rebass'
import { Button } from '@nike/epic-react-ui-old'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
import { compose } from 'redux'

import GraduateDialog from 'components/self-service/graduate-dialog'
import DeleteJobDialog from 'components/self-service/delete-job-dialog'
import JobDetailsDialog from 'components/self-service/job-details-dialog'
import ScheduledJobDialog from 'components/self-service/scheduled-job-dialog'
import JobsTable from 'components/self-service/jobs-table'
import ToJS from 'components/common/to-js'

import { listTableJobs } from 'actions/listTableJobs'

import { selectTableJobs } from 'selectors/selectTableJobs'
import { selectIsListingTableJobs } from 'selectors/selectIsListingTableJobs'

export function JobsPage(props) {
  const {
    isListingTableJobs,
    tableJobs,
    listTableJobs,
    navigateToAutoTable,
    setHasResetAutoTable,
    searchValue,
  } = props

  const [isDeleteJobDialogOpen, setIsDeleteJobDialogOpen] = useState(false)

  const [isJobDetailsDialogOpen, setIsJobDetailsDialogOpen] = useState(false)

  const [isScheduledJobDialogOpen, setIsScheduledJobDialogOpen] = useState(false)

  const [isGraduateDialogOpen, setGraduateDialogOpen] = useState(false)

  const [activeJob, setActiveJob] = useState({})

  useEffect(() => {
    listTableJobs()
  }, [listTableJobs])

  function handleRefreshTableJobs() {
    listTableJobs()

    ReactGA.event({ category: 'SELF-SERVICE', action: 'Refresh jobs' })
  }

  function toggleDialog(dialogName, activeJob = {}) {
    setActiveJob(activeJob)

    switch (dialogName) {
      case 'DeleteJobDialog':
        setIsDeleteJobDialogOpen((isDeleteJobDialogOpen) => !isDeleteJobDialogOpen)
        break
      case 'JobDetailsDialog':
        setIsJobDetailsDialogOpen((isJobDetailsDialogOpen) => !isJobDetailsDialogOpen)
        break
      case 'ScheduledJobDialog':
        setIsScheduledJobDialogOpen((isScheduledJobDialogOpen) => !isScheduledJobDialogOpen)
        break
      case 'Graduate':
        setGraduateDialogOpen((isGraduateDialogOpen) => !isGraduateDialogOpen)
        break
      default:
    }
  }

  return (
    <Box>
      <Flex justifyContent='flex-end' mb='10px'>
        <Button
          inverse
          onClick={handleRefreshTableJobs}
          style={{ marginRight: '10px' }}
          data-external-id='jobs-page-refresh'
        >
          Refresh
        </Button>
      </Flex>

      <JobsTable
        isLoading={isListingTableJobs}
        tableJobs={tableJobs}
        toggleDialog={toggleDialog}
        navigateToAutoTable={navigateToAutoTable}
        setHasResetAutoTable={setHasResetAutoTable}
        searchValue={searchValue}
      />

      <JobDetailsDialog
        toggleDialog={toggleDialog}
        isJobDetailsDialogOpen={isJobDetailsDialogOpen}
        activeJob={activeJob}
      />

      <ScheduledJobDialog
        toggleDialog={toggleDialog}
        isScheduledJobDialogOpen={isScheduledJobDialogOpen}
        activeJob={activeJob}
      />

      <GraduateDialog
        isGraduateDialogOpen={isGraduateDialogOpen}
        toggleDialog={toggleDialog}
        activeJob={activeJob}
      />

      <DeleteJobDialog
        toggleDialog={toggleDialog}
        isDeleteJobDialogOpen={isDeleteJobDialogOpen}
        activeJob={activeJob}
      />
    </Box>
  )
}

JobsPage.propTypes = {
  tableJobs: PropTypes.array,
  isListingTableJobs: PropTypes.bool,
  listTableJobs: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    tableJobs: selectTableJobs(state),
    isListingTableJobs: selectIsListingTableJobs(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listTableJobs: () => dispatch(listTableJobs()),
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), ToJS)(JobsPage)
