import React from 'react'
import { Box, Flex } from 'rebass'
import Divider from '@material-ui/core-new/Divider'
import IconButton from '@nike/nr-vamp-xl-icon-button'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FieldArray } from 'formik'
import { Select, TextInput } from '@nike/epic-react-ui-old'

import { InstanceTypeConfig } from 'utils/create-emr-cluster-form-helpers'

const targetCapacityOptions = [
  {
    label: 'On-demand',
    value: 'on-demand',
  },
  {
    label: 'Spot',
    value: 'spot',
  },
]

const definedSpotDurationOptions = [
  { label: 'none', value: 'none' },
  { label: '60', value: '60' },
  { label: '120', value: '120' },
  { label: '180', value: '180' },
  { label: '240', value: '240' },
  { label: '300', value: '300' },
  { label: '360', value: '360' },
]

const timeoutActionOptions = [
  { label: 'TERMINATE_CLUSTER', value: 'TERMINATE_CLUSTER' },
  { label: 'SWITCH_TO_ON_DEMAND', value: 'SWITCH_TO_ON_DEMAND' },
]

export default function InstanceFleetsGrid(props) {
  const {
    instanceFleets,
    supportedCoreInstanceTypeOptions,
    supportedTaskInstanceTypeOptions,
    handleChange,
    setFieldValue,
    handleCustomInstanceFleets,
  } = props

  function getSupportedInstance(instanceFleetType) {
    if (instanceFleetType === 'TASK') {
      return supportedTaskInstanceTypeOptions
    } else {
      return supportedCoreInstanceTypeOptions
    }
  }

  return (
    <FieldArray
      name='instanceFleets'
      render={(arrayHelpers) => (
        <Box width='100%' mt={20}>
          <Flex>
            <Box width='12%'>Node type</Box>

            <Box width='30%'>Fleet instance types</Box>

            <Box width='29%'>Target capacity</Box>

            <Box width='29%'>Spot options</Box>
          </Flex>

          <Divider />

          {instanceFleets.map((instanceFleet, instanceFleetIndex) => (
            <Flex
              mt={instanceFleetIndex > 0 ? 20 : 10}
              key={`instanceFleetIndex-${instanceFleetIndex}`}
            >
              <Box width='10%' mr='10px'>
                <Flex>
                  <div>{instanceFleet.instanceFleetType}</div>

                  {instanceFleet.instanceFleetType === 'TASK' && (
                    <IconButton
                      data-e2e='delete-task-instance-button'
                      icon={faTimes}
                      onCheck={(event) => {
                        event.preventDefault()
                        arrayHelpers.remove(2)
                      }}
                      styleExtension={{
                        container: {
                          backgroundColor: 'transparent',
                          cursor: 'pointer',
                        },
                      }}
                    />
                  )}
                </Flex>
              </Box>

              <Box width='30%' pr={10}>
                {instanceFleet.instanceTypeConfigs.map(
                  (instanceTypeConfig, instanceTypeConfigIndex) => (
                    <Box key={`instanceTypeConfigIndex-${instanceTypeConfigIndex}`}>
                      {instanceTypeConfigIndex > 0 && <Divider light />}

                      <Box mt={15} style={{ position: 'relative', cursor: 'pointer' }}>
                        {instanceTypeConfigIndex > 0 && (
                          <IconButton
                            data-e2e='delete-instance-button'
                            icon={faTimes}
                            onCheck={(event) => {
                              event.preventDefault()

                              const updated = instanceFleet.instanceTypeConfigs.filter(
                                (instanceTypeConfig, i) => i !== instanceTypeConfigIndex
                              )

                              setFieldValue(
                                `instanceFleets.${instanceFleetIndex}.instanceTypeConfigs`,
                                updated
                              )
                            }}
                            styleExtension={{
                              container: {
                                backgroundColor: 'transparent',
                                position: 'absolute',
                                left: '90%',
                                bottom: '-15px',
                              },
                            }}
                          />
                        )}
                      </Box>

                      <Box mb='10px'>
                        <Select
                          id='instance-type-select'
                          label='Instance type'
                          value={{
                            label: instanceTypeConfig.instanceType,
                            value: instanceTypeConfig.instanceType,
                          }}
                          onChange={(option) => {
                            setFieldValue(
                              `instanceFleets.${instanceFleetIndex}.instanceTypeConfigs.${instanceTypeConfigIndex}.instanceType`,
                              option.value
                            )
                          }}
                          options={getSupportedInstance(String(instanceFleet.instanceFleetType))}
                        />
                      </Box>

                      <TextInput
                        data-e2e='max-bid-price-textinput'
                        name={`instanceFleets.${instanceFleetIndex}.instanceTypeConfigs.${instanceTypeConfigIndex}.bidPriceAsPercentageOfOnDemandPrice`}
                        label='Max bid price (% on-demand)'
                        value={String(instanceTypeConfig.bidPriceAsPercentageOfOnDemandPrice)}
                        onChange={handleChange}
                        type='number'
                      />

                      <TextInput
                        data-e2e='weighted-capacity-textinput'
                        name={`instanceFleets.${instanceFleetIndex}.instanceTypeConfigs.${instanceTypeConfigIndex}.weightedCapacity`}
                        label='Weighted capacity'
                        value={String(instanceTypeConfig.weightedCapacity)}
                        onChange={handleChange}
                        type='number'
                      />
                    </Box>
                  )
                )}

                {instanceFleet.instanceTypeConfigs.length < 5 && (
                  <IconButton
                    data-e2e='add-instance-type-button'
                    label='Add instance type to fleet'
                    icon={faPlus}
                    onCheck={(event) => {
                      event.preventDefault()
                      // immutable push https://vincent.billey.me/pure-javascript-immutable-array/
                      const updated = [].concat(
                        instanceFleets[instanceFleetIndex].instanceTypeConfigs,
                        new InstanceTypeConfig({})
                      )

                      setFieldValue(
                        `instanceFleets.${instanceFleetIndex}.instanceTypeConfigs`,
                        updated
                      )
                    }}
                    styleExtension={{
                      container: {
                        backgroundColor: 'transparent',
                        cursor: 'pointer',
                        marginTop: '15px',
                        paddingLeft: '0',
                      },
                    }}
                  />
                )}
              </Box>

              <Box width='30%' pr={10}>
                {instanceFleet.instanceFleetType === 'MASTER' ? (
                  <Box mt='15px'>
                    <Select
                      id='defined-spot-duration-select'
                      label='Defined spot duration (minutes)'
                      value={{
                        label: instanceFleet.isOnDemand ? 'On-demand' : 'Spot',
                        value: instanceFleet.isOnDemand ? 'on-demand' : 'spot',
                      }}
                      onChange={(option) => {
                        setFieldValue(
                          `instanceFleets.${instanceFleetIndex}.isOnDemand`,
                          option.value === 'on-demand'
                        )
                      }}
                      options={targetCapacityOptions}
                    />
                  </Box>
                ) : (
                  <Box mt='15px'>
                    <TextInput
                      data-e2e='on-demand-units-textinput'
                      label='On demand units'
                      value={String(instanceFleet.targetOnDemandCapacity)}
                      onChange={(event) => {
                        setFieldValue(
                          `instanceFleets.${instanceFleetIndex}.targetOnDemandCapacity`,
                          Number(event.target.value)
                        )
                        handleCustomInstanceFleets(
                          instanceFleetIndex,
                          event.target.value,
                          'targetSpotCapacity'
                        )
                      }}
                      type='number'
                    />

                    <Box mt='10px'>
                      <TextInput
                        data-e2e='spot-units-textinput'
                        label='Spot units'
                        value={String(instanceFleet.targetSpotCapacity)}
                        onChange={(event) => {
                          setFieldValue(
                            `instanceFleets.${instanceFleetIndex}.targetSpotCapacity`,
                            Number(event.target.value)
                          )
                          handleCustomInstanceFleets(
                            instanceFleetIndex,
                            event.target.value,
                            'targetOnDemandCapacity'
                          )
                        }}
                        type='number'
                      />
                    </Box>
                  </Box>
                )}
              </Box>

              <Box width='30%' pr={10}>
                {instanceFleet.instanceFleetType === 'MASTER' && instanceFleet.isOnDemand ? null : (
                  <Box mt='15px'>
                    <Box mb='10px'>
                      <Select
                        id='defined-spot-duration-select'
                        label='Defined spot duration (minutes)'
                        value={{
                          label:
                            instanceFleet.launchSpecifications.spotSpecification
                              .blockDurationMinutes,
                          value:
                            instanceFleet.launchSpecifications.spotSpecification
                              .blockDurationMinutes,
                        }}
                        onChange={(option) => {
                          setFieldValue(
                            `instanceFleets.${instanceFleetIndex}.launchSpecifications.spotSpecification.blockDurationMinutes`,
                            option.value
                          )
                        }}
                        options={definedSpotDurationOptions}
                      />
                    </Box>

                    <TextInput
                      data-e2e='timeout-duration-textinput'
                      label='Timeout duration minutes'
                      value={String(
                        instanceFleet.launchSpecifications.spotSpecification.timeoutDurationMinutes
                      )}
                      onChange={(event) => {
                        setFieldValue(
                          `instanceFleets.${instanceFleetIndex}.launchSpecifications.spotSpecification.timeoutDurationMinutes`,
                          Number(event.target.value)
                        )
                      }}
                      type='number'
                    />

                    <Select
                      id='timeout-action-select'
                      label='Timeout action'
                      value={{
                        label: instanceFleet.launchSpecifications.spotSpecification.timeoutAction,
                        value: instanceFleet.launchSpecifications.spotSpecification.timeoutAction,
                      }}
                      onChange={(option) => {
                        setFieldValue(
                          `instanceFleets.${instanceFleetIndex}.launchSpecifications.spotSpecification.timeoutAction`,
                          option.value
                        )
                      }}
                      options={timeoutActionOptions}
                    />
                  </Box>
                )}
              </Box>
            </Flex>
          ))}

          <Box mt={20}>
            {!instanceFleets[2] && (
              <IconButton
                data-e2e='add-task-instance-button'
                label='Add task instance to fleet'
                icon={faPlus}
                onCheck={(event) => {
                  event.preventDefault()

                  arrayHelpers.push({
                    instanceFleetType: 'TASK',
                    targetOnDemandCapacity: 0,
                    targetSpotCapacity: 5,
                    instanceTypeConfigs: [
                      new InstanceTypeConfig({
                        instanceType: 'r4.2xlarge',
                      }),
                      new InstanceTypeConfig({
                        instanceType: 'r4.4xlarge',
                        weightedCapacity: 2,
                      }),
                      new InstanceTypeConfig({
                        instanceType: 'r4.8xlarge',
                        weightedCapacity: 4,
                      }),
                      new InstanceTypeConfig({
                        instanceType: 'm4.4xlarge',
                      }),
                    ],
                    launchSpecifications: {
                      spotSpecification: {
                        blockDurationMinutes: '60',
                        timeoutAction: 'SWITCH_TO_ON_DEMAND',
                        timeoutDurationMinutes: 15,
                      },
                    },
                  })
                }}
                styleExtension={{
                  container: {
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                  },
                }}
              />
            )}
          </Box>

          <Divider />
        </Box>
      )}
    />
  )
}
