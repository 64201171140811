import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { Modal, Button } from '@nike/epic-react-ui-old'
import { Flex } from 'rebass'
import styled from '@emotion/styled'
import ReactGA from 'react-ga'

const H2 = styled.h2`
  margin: 10px 0;
  font-size: ${(props) => props.fontSize}px !important;
  text-align: ${(props) => props.textAlign || 'center'} !important;
  color: #000000 !important;
`

export default function TerminateEmrClusterDialog(props) {
  const { activeCluster, terminateEmrCluster, toggleDialog, isOpen } = props

  function handleTerminateCluster() {
    terminateEmrCluster(activeCluster.cluster_name, activeCluster.cluster_id).then(() => {
      ReactGA.event({ category: 'EMR', action: 'Terminate EMR' })
    })

    toggleDialog()
  }

  return (
    <Modal
      title='Terminate this cluster?'
      show={isOpen}
      onClose={toggleDialog}
      modalSize='sm'
      css={css`
        z-index: 500;
      `}
    >
      <H2 fontSize={18}>{activeCluster.cluster_name}</H2>

      <Flex justifyContent='center' mt='30px'>
        <Button
          data-e2e='cancel-emr-terminate-button'
          data-external-id='cancel-emr-terminate'
          onClick={toggleDialog}
          inverse
          style={{ marginRight: '5px' }}
        >
          Cancel
        </Button>

        <Button
          data-e2e='confirm-emr-terminate-button'
          data-external-id='terminate-emr-cluster'
          onClick={handleTerminateCluster}
          style={{ marginLeft: '5px' }}
        >
          Terminate
        </Button>
      </Flex>
    </Modal>
  )
}

TerminateEmrClusterDialog.propTypes = {
  toggleDialog: PropTypes.func.isRequired,
  activeCluster: PropTypes.object.isRequired,
}
