import React, { useState, useEffect } from 'react'
import { Box } from 'rebass'
import ReactGA from 'react-ga'
import { Select } from '@nike/epic-react-ui-old'

import Splash from 'components/global/splash'
import { connect } from 'react-redux'
import ToJS from 'components/common/to-js'
import Iframe from 'components/common/iframe'

import { selectDatabricksConfig } from 'selectors/selectDatabricksConfig'
import PageTitle from '../../common/page-title'

const getWorkspacesOptions = (databricksConfig) => {
  return Object.keys(databricksConfig).map((workspace) => ({
    label: workspace,
    value: workspace,
  }))
}

export function DatabricksPage(props) {
  const { databricksConfig } = props

  const [isIframeLoading, setIsIFrameLoading] = useState(true)

  const workspaceOptions = getWorkspacesOptions(databricksConfig)

  const [selectedWorkspace, setSelectedWorkspace] = useState(workspaceOptions[0])

  useEffect(() => {
    ReactGA.pageview('/databricks')
  }, [])

  function updateDatabricksWorkspace(value) {
    setSelectedWorkspace(value)

    ReactGA.event({
      category: 'Databricks',
      action: 'Change workspace',
      label: value.label,
    })
  }

  const endpoint = databricksConfig[selectedWorkspace.value] || ''

  const pageTitleConfig = { title: 'Databricks' }

  return (
    <Box style={{ position: 'relative' }}>
      <PageTitle {...pageTitleConfig} />
      <Box width='300px' style={{ position: 'absolute', top: '10px', right: '42%' }}>
        <Select
          options={workspaceOptions}
          onChange={updateDatabricksWorkspace}
          value={selectedWorkspace}
          placeholder='Workspaces'
        />
      </Box>

      {isIframeLoading && <Splash />}
      <Iframe title='Databricks' src={endpoint} onLoad={() => setIsIFrameLoading(false)} />
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    databricksConfig: selectDatabricksConfig(state),
  }
}

export default connect(mapStateToProps)(ToJS(DatabricksPage))
