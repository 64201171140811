export const setCookie = (cname, cvalue, exdays, domain, secure) => {
  // https://www.w3schools.com/js/js_cookies.asp

  const d = new Date()

  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)

  const expires = d.toUTCString()

  // let cookie = `${cname}=${cvalue};expires=${expires};path=/;${
  //   process.env.NODE_ENV !== 'development' ? 'secure;' : ''
  // }`;

  let cookie = `${cname}=${cvalue};expires=${expires};path=/;`

  if (domain) {
    cookie += `domain=${domain};`
  }

  // if (secure) {
  //   cookie += `secure;`;
  // }

  document.cookie = cookie
}

export const getCookie = (cname) => {
  const name = `${cname}=`

  const ca = document.cookie.split(';')

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]

    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }

  return ''
}

export const deleteCookie = (cname, domain) => {
  let cookie = `${cname}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;`

  if (domain) {
    cookie += `domain=${domain};`
  }

  document.cookie = cookie
}
