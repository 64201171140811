import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Flex } from 'rebass'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import isEmpty from 'lodash.isempty'
import axios from 'axios'
import ReactGA from 'react-ga'
import useMetaConfig from 'hooks/useMetaConfig'
import { Button, Tabs } from '@nike/epic-react-ui-old'
import { withRouter } from 'react-router-dom'

import DatalakeExplorer from 'components/self-service/datalake-explorer'
import QueryEditor from 'components/self-service/query-editor'
import AutoTablePage from 'components/self-service/auto-table-page'
import JobsPage from 'components/self-service/jobs-page'
import ToJS from 'components/common/to-js'
import TabContainer from 'components/common/tab-container'
import Splash from 'components/global/splash'
import WalledGardenWarningDialog from 'components/self-service/walled-garden-warning-dialog'

import { selectGroups } from 'selectors/selectGroups'
import { selectSelfServiceTabs } from 'selectors/selectSelfServiceTabs'
import { selectSelfServiceIsSecure } from 'selectors/selectSelfServiceIsSecure'
import { selectGlobalTabs } from 'selectors/selectGlobalTabs'
import { selectSelfServiceConfig } from 'selectors/selectSelfServiceConfig'

import { fetchSelfServiceConfig } from 'actions/fetchSelfServiceConfig'

import { HUE_ROLE_CHANGE_ENDPOINT } from 'utils/constant'
import { getCookie, setCookie } from 'utils/cookie'
import PageTitle from '../../common/page-title'

const Message = styled.h1`
  font-size: 30px;
  font-weight: bold;
  margin: 30px;
`

function getTabs(selfServiceTabs) {
  // reorder selfServiceTabs
  const tabs = []

  if (selfServiceTabs.includes('AUTO_TABLE')) {
    tabs.push('AUTO_TABLE')
  }

  if (selfServiceTabs.includes('JOBS')) {
    tabs.push('JOBS')
  }

  if (selfServiceTabs.includes('QUERY_EDITOR')) {
    tabs.push('QUERY_EDITOR')
  }

  if (selfServiceTabs.includes('DATALAKE_EXPLORER')) {
    tabs.push('DATALAKE_EXPLORER')
  }

  return tabs
}

function SupportLink() {
  return (
    <Flex justifyContent='flex-end' mr='15px' mt='15px'>
      <a
        href='https://app.slack.com/client/T0G3T5X2B/CKWQES3EF'
        target='_blank'
        rel='noopener noreferrer'
        onClick={() => ReactGA.event({ category: 'SELF-SERVICE', action: 'Support link' })}
      >
        <Button data-external-id='i-need-help'>I need help! (#ae-self-service)</Button>
      </a>
    </Flex>
  )
}

export function SelfServicePage(props) {
  const {
    globalTabs,
    selfServiceConfig,
    selectedGroup,
    selectedRegion,
    fetchSelfServiceConfig,
    selfServiceTabs,
    selfServiceIsSecure,
    searchValue,
    history,
  } = props

  useEffect(() => {
    ReactGA.pageview('/selfservice')

    axios.post(HUE_ROLE_CHANGE_ENDPOINT, { ngap_role: selectedGroup })
  }, [selectedGroup])

  const isLoadingSelfServicePage = useMetaConfig({
    configFetcher: fetchSelfServiceConfig,
    callback: null,
    selectedGroup,
    selectedRegion,
  })

  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const [showWGWarningDialog, setShowWGWarningDialog] = useState(
    selfServiceIsSecure && !getCookie('sswg_accept')
  )

  const [hasResetAutoTable, setHasResetAutoTable] = useState(false)

  const [autoTableActiveStep, setAutoTableActiveStep] = useState(0)

  const tabs = getTabs(selfServiceTabs)

  function getTabIndex(page) {
    return tabs.findIndex((tab) => tab === page)
  }

  function getPageByTabIndex(tabIndex) {
    return tabs[tabIndex]
  }

  function handleTabChange(tabIndex) {
    setActiveTabIndex(tabIndex)

    ReactGA.event({
      category: 'SELF-SERVICE',
      action: 'Tab click',
      label: getPageByTabIndex(tabIndex),
    })
  }

  function navigateToAutoTable(activeStep = 0) {
    const autoTableTabIndex = getTabIndex('AUTO_TABLE')

    handleTabChange(autoTableTabIndex)

    setAutoTableActiveStep(activeStep)
  }

  function navigateToPage(page) {
    const tabIndex = getTabIndex(page)

    handleTabChange(tabIndex)
  }

  function onWGTermsAccept() {
    setCookie('sswg_accept', true)

    setShowWGWarningDialog(false)

    ReactGA.event({ category: 'SELF-SERVICE', action: 'Accept WG terms' })
  }

  function onWGTermsDecline() {
    history.push(`/${globalTabs[0].toLowerCase()}`)

    ReactGA.event({ category: 'SELF-SERVICE', action: 'Decline WG terms' })
  }

  if (isLoadingSelfServicePage) {
    return <Splash />
  }

  if (!globalTabs.includes('SELFSERVICE') || isEmpty(selfServiceConfig)) {
    return (
      <Message>Self-Service Analytics is not available for the selected group or region.</Message>
    )
  }

  const componentMap = {
    AUTO_TABLE: (
      <AutoTablePage
        selfServiceIsSecure={selfServiceIsSecure}
        setHasResetAutoTable={setHasResetAutoTable}
        hasResetAutoTable={hasResetAutoTable}
        autoTableActiveStep={autoTableActiveStep}
        setAutoTableActiveStep={setAutoTableActiveStep}
        navigateToPage={navigateToPage}
      />
    ),
    JOBS: (
      <JobsPage
        navigateToAutoTable={navigateToAutoTable}
        setHasResetAutoTable={setHasResetAutoTable}
        setAutoTableActiveStep={setAutoTableActiveStep}
        searchValue={searchValue}
      />
    ),
    QUERY_EDITOR: <QueryEditor isSecure={selfServiceIsSecure} />,
    DATALAKE_EXPLORER: <DatalakeExplorer />,
  }

  function toTitleCase(str) {
    return str
      .toLowerCase()
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const pageTitleConfig = { title: `Self Service: ${toTitleCase(tabs[activeTabIndex])}` }

  return (
    <Box>
      <SupportLink />

      <Tabs
        tabs={tabs.map((t) => t.replace(/_/g, ' '))}
        defaultIndex={activeTabIndex}
        onSelect={handleTabChange}
        selectedIndex={activeTabIndex}
      >
        {tabs.map((tab, index) => (
          <TabContainer key={index} hidden={activeTabIndex !== index}>
            <PageTitle {...pageTitleConfig} />
            {componentMap[tab]}
          </TabContainer>
        ))}
      </Tabs>

      <WalledGardenWarningDialog
        show={showWGWarningDialog}
        onAccept={onWGTermsAccept}
        onDecline={onWGTermsDecline}
      />
    </Box>
  )
}

SelfServicePage.propTypes = {
  selectedGroup: PropTypes.string.isRequired,
  selectedRegion: PropTypes.string.isRequired,
  fetchSelfServiceConfig: PropTypes.func.isRequired,
  selfServiceTabs: PropTypes.array.isRequired,
  globalTabs: PropTypes.array.isRequired,
  selfServiceConfig: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  return {
    groups: selectGroups(state),
    selfServiceTabs: selectSelfServiceTabs(state),
    selfServiceIsSecure: selectSelfServiceIsSecure(state),
    globalTabs: selectGlobalTabs(state),
    selfServiceConfig: selectSelfServiceConfig(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSelfServiceConfig: () => dispatch(fetchSelfServiceConfig()),
  }
}

const container = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  ToJS
)(SelfServicePage)

export default container
