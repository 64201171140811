export const getS3AclMap = (selfServiceS3RoAcls, selfServiceS3RwAcls) => ({
  ro: selfServiceS3RoAcls
    .replace(/arn:aws:s3:::/g, 's3://')
    .replace(/\*/g, '.*')
    .replace(/"/g, '')
    .replace('[', '')
    .replace(']', '')
    .replace(/\s/g, '')
    .split(','),
  rw: selfServiceS3RwAcls
    .replace(/arn:aws:s3:::/g, 's3://')
    .replace(/\*/g, '.*')
    .replace(/"/g, '')
    .replace('[', '')
    .replace(']', '')
    .replace(/\s/g, '')
    .split(','),
})
