import { fromJS } from 'immutable'
import { create } from 'axios'

import {
  META_ENDPOINT,
  FETCH_SELF_SERVICE_CONFIG_REQUEST,
  FETCH_SELF_SERVICE_CONFIG_SUCCESS,
  FETCH_SELF_SERVICE_CONFIG_FAILURE,
} from 'utils/constant'

import { selectSelectedGroup } from 'selectors/selectSelectedGroup'
import { selectSelectedRegion } from 'selectors/selectSelectedRegion'
import { selectSelectedAccount } from 'selectors/selectSelectedAccount'
import { selectSelectedRuntime } from 'selectors/selectSelectedRuntime'

function fetchSelfServiceConfigRequest() {
  return {
    type: FETCH_SELF_SERVICE_CONFIG_REQUEST,
  }
}

function fetchSelfServiceConfigSuccess(selfServiceConfig) {
  return {
    type: FETCH_SELF_SERVICE_CONFIG_SUCCESS,
    selfServiceConfig,
  }
}

function fetchSelfServiceConfigFailure() {
  return {
    type: FETCH_SELF_SERVICE_CONFIG_FAILURE,
  }
}

export async function fetchSelfServiceConfig() {
  return function (dispatch, getState) {
    dispatch(fetchSelfServiceConfigRequest())

    const selectedGroup = selectSelectedGroup(getState())
    const selectedRegion = selectSelectedRegion(getState())
    const selectedAccount = selectSelectedAccount(getState())
    const selectedRuntime = selectSelectedRuntime(getState())

    return create()
      .get(
        `${META_ENDPOINT}/ss/v1?ngap_group=${selectedGroup}&ngap_region=${selectedRegion}&account=${selectedAccount}&runtime=${selectedRuntime}`,
        {
          transformRequest: [
            (data, headers) => {
              delete headers.common.Authorization
              return data
            },
          ],
        }
      )
      .then((response) => {
        dispatch(fetchSelfServiceConfigSuccess(fromJS(response.data)))
      })
      .catch((error) => {
        dispatch(fetchSelfServiceConfigFailure(error))
      })
  }
}
