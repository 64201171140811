/** @jsx jsx */
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useTable, useBlockLayout, useResizeColumns, useSortBy, usePagination } from 'react-table'
import { Table, Thead, Tr, Th, Td, Tbody, Spinner, Icon } from '@nike/epic-react-ui-old'
import { css, jsx } from '@emotion/core'
import sort_icon from 'images/sort_icon.png'
import rewind_icon from 'images/rewind_icon.png'
import backward_icon from 'images/backward_icon.png'
import forward_icon from 'images/forward_icon.png'
import fast_forward_icon from 'images/fast_forward_icon.png'

const Styles = styled.div`
  .resizer {
    display: inline-block;
    width: 10px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    ${'' /* prevents from scrolling while dragging on touch devices */}
    touch-action:none;
  }

  .pagination {
    padding: 0.5rem;
    margin: 10px 0;

    .left-block {
      float: left;
    }

    .right-block {
      float: right;
    }

    .page-input {
      width: 35px;
      border-width: 2px;
      border-style: inset;
      border-color: rgb(133, 133, 133);
    }

    .page-btns {
      padding-left: 30px;
      vertical-align: middle;
    }

    .page-button {
      color: #5197d6;
      background-color: white;
      border: 1px solid #c7c7c7;
      font-weight: bolder;
    }

    .disabled,
    [disabled] {
      color: #a0a0a0;
      background-color: #f0f0f0;
    }
  }
`

function ResizableTableEnhanced(props) {
  const {
    columns,
    data,
    isLoading,
    shouldFirstRowOverflow,
    shouldOverflow,
    overflowColumns,
    customWidth,
    noRowsMessage,
    sortTypes,
  } = props

  const defaultColumn = useMemo(
    () => ({
      width: customWidth || window.innerWidth / columns.length,
    }),
    [columns.length, customWidth]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      sortTypes: sortTypes ? sortTypes : {},
      initialState: { pageSize: 20 },
    },
    useBlockLayout,
    useResizeColumns,
    useSortBy,
    usePagination
  )

  return (
    <Styles>
      <div>
        {isLoading ? (
          <Spinner
            medium
            css={css`
              margin: 30px auto;
            `}
          />
        ) : (
          <div>
            <Table {...getTableProps()}>
              <Thead>
                {headerGroups.map((headerGroup) => (
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header')}
                        {/* Use column.getResizerProps to hook up the events correctly */}
                        <div {...column.getResizerProps()} className='resizer' />
                        <span style={{ float: 'right' }}>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon type='chevron-down' fontSize='18px' />
                            ) : (
                              <Icon type='chevron-up' fontSize='18px' />
                            )
                          ) : column.canSort ? (
                            <img
                              src={sort_icon}
                              alt='Sort'
                              style={{
                                height: '18px',
                                width: '18px',
                                filter: 'invert(100%)',
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </span>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>

              <Tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <Tr {...row.getRowProps()}>
                      {row.cells.map((cell, j) => {
                        return (
                          <Td {...cell.getCellProps()}>
                            <div
                              style={{
                                overflow:
                                  (shouldFirstRowOverflow && i === 0) ||
                                  (shouldOverflow && overflowColumns.includes(j))
                                    ? 'visible'
                                    : 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {cell.render('Cell')}
                            </div>
                          </Td>
                        )
                      })}
                    </Tr>
                  )
                })}

                {page.length <= 0 && (
                  <Tr>
                    <Td style={{ border: 'none', fontSize: '20px' }}>{noRowsMessage}</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
            <div className='pagination'>
              <div className='left-block'>
                <select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value))
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
              <div className='right-block'>
                <span>
                  Page{' '}
                  <strong>
                    <input
                      type='number'
                      className='page-input'
                      value={pageIndex + 1}
                      onChange={(e) => {
                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                        gotoPage(page)
                      }}
                    />{' '}
                    of {pageOptions.length}
                  </strong>
                </span>
                <span className='page-btns'>
                  <button
                    data-external-id='table-control-rewind'
                    className='page-button'
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    <img
                      src={rewind_icon}
                      alt='Rewind'
                      style={{
                        height: '18px',
                        width: '18px',
                      }}
                    />
                  </button>
                  <button
                    className='page-button'
                    data-external-id='table-control-previous'
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    <img
                      src={backward_icon}
                      alt='Backward'
                      style={{
                        height: '18px',
                        width: '18px',
                      }}
                    />
                  </button>
                  <button
                    className='page-button'
                    data-external-id='table-control-next'
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    <img
                      src={forward_icon}
                      alt='Forward'
                      style={{
                        height: '18px',
                        width: '18px',
                      }}
                    />
                  </button>
                  <button
                    className='page-button'
                    data-external-id='table-control-fast-forward'
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    <img
                      src={fast_forward_icon}
                      alt='Fast Forward'
                      style={{
                        height: '18px',
                        width: '18px',
                      }}
                    />
                  </button>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </Styles>
  )
}

ResizableTableEnhanced.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  shouldFirstRowOverflow: PropTypes.bool, // useful for column filters
  shouldOverflow: PropTypes.bool,
  overflowColumns: PropTypes.arrayOf(PropTypes.number),
  customWidth: PropTypes.number,
  noRowsMessage: PropTypes.string,
}

ResizableTableEnhanced.defaultProps = {
  isLoading: false,
  shouldFirstRowOverflow: false,
  shouldOverflow: false,
}

export default ResizableTableEnhanced
