import { toast } from 'react-toastify'
// import { domain } from 'utils/constant';
import { create } from 'axios'

import { PAAS_TOKEN_ENDPOINT, LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE } from 'utils/constant'
import { setCookie } from 'utils/cookie'
import { getGroupsFromToken } from 'utils/getGroupsFromToken'

export function loginRequest() {
  return {
    type: LOGIN_REQUEST,
  }
}

export function loginSuccess(ngap2Token) {
  return {
    type: LOGIN_SUCCESS,
    token: ngap2Token,
  }
}

function loginFailure(error) {
  return {
    type: LOGIN_FAILURE,
    error,
  }
}

export async function login(oktaAccessToken) {
  return function (dispatch) {
    if (!oktaAccessToken) {
      dispatch(loginFailure())

      return false
    }

    const oktaPreferredUsername = JSON.parse(atob(oktaAccessToken.split('.')[1])).sub

    dispatch(loginRequest())

    return create()
      .post(PAAS_TOKEN_ENDPOINT, {
        credentials: oktaAccessToken,
        audience: 'ngap',
      })
      .then((response) => {
        const token = response.data.user_token

        const groups = getGroupsFromToken(token)

        if (groups.size <= 0 && !oktaPreferredUsername.toLowerCase().startsWith('p.')) {
          dispatch(loginFailure())

          return false // return whether user has access
        } else {
          setCookie('OKERA_TOKEN', token, 1, '.nike.com', true)

          setCookie('cdt-platform-auth', token, 1, '.nike.com', true)

          setCookie('paasui-auth', token, 1)

          dispatch(loginSuccess(token))

          return true
        }
      })
      .catch((error) => {
        dispatch(loginFailure())

        let errorMessage = 'Unable to login'

        if (error.response) {
          errorMessage = errorMessage + ': ' + error.response.data

          toast.error(errorMessage, { autoClose: false, position: 'top-left' })
        }

        return false
      })
  }
}
