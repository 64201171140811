import { UPDATE_SELECTED_REGION } from 'utils/constant'

const initialState = ''

export default function selectedRegion(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_SELECTED_REGION:
      return action.selectedRegion || ''

    default:
      return state
  }
}
