import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { css } from '@emotion/core'
import { Modal, Button, TextInput } from '@nike/epic-react-ui-old'
import { Box, Flex } from 'rebass'
import styled from '@emotion/styled'

import {
  getInitialFieldValues,
  getResubmitNoChangePayload,
  validateResubmitNoChange,
  resubmitNoChange,
} from 'utils/create-job-form-helpers'

const H2 = styled.h2`
  margin: 10px 0;
  font-size: ${(props) => props.fontSize}px !important;
  text-align: ${(props) => props.textAlign || 'center'} !important;
  color: #000000 !important;
`

const getJobFieldValues = (props) => {
  const { selectedJob } = props

  return selectedJob.hasOwnProperty('payload')
    ? getResubmitNoChangePayload(JSON.parse(selectedJob.payload))
    : getInitialFieldValues(props)
}

export default function ResubmitJobDialog(props) {
  const { toggleResubmitJobDialog, isOpen } = props

  const jobToResubmit = getJobFieldValues(props)

  return (
    <Formik
      initialValues={jobToResubmit}
      validate={validateResubmitNoChange}
      onSubmit={(values, bag) => resubmitNoChange(values, bag, props)}
      enableReinitialize
    >
      {(formikProps) => {
        const { handleSubmit, values, touched, errors, handleChange } = formikProps

        return (
          <Modal
            title='Resubmit this job?'
            show={isOpen}
            onClose={toggleResubmitJobDialog}
            modalSize='sm'
            css={css`
              z-index: 500;
            `}
          >
            <H2 fontSize={18}>{jobToResubmit.jobName}</H2>
            <Form>
              <Box>
                <Box style={{ position: 'relative' }}>
                  <TextInput
                    data-e2e='databricks-token-resubmit-textinput'
                    name='databricksToken'
                    label='Databricks Token'
                    value={values.databricksToken}
                    onChange={handleChange}
                    hasErrors={errors.databricksToken && touched.databricksToken}
                    errorMessage={errors.databricksToken}
                    required
                  />
                </Box>
              </Box>
            </Form>
            <Flex justifyContent='center' mt='30px'>
              <Button
                data-e2e='cancel-resubmit-job-button'
                data-external-id='cancel-resubmit-job'
                id='cancel-resubmit-job-button'
                onClick={toggleResubmitJobDialog}
                inverse
                style={{ marginRight: '5px' }}
              >
                Cancel
              </Button>

              <Button
                data-e2e='confirm-resubmit-job-button'
                data-external-id='confirm-resubmit-job'
                id='confirm-resubmit-job-button'
                onClick={handleSubmit}
                style={{ marginLeft: '5px' }}
              >
                Resubmit
              </Button>
            </Flex>
          </Modal>
        )
      }}
    </Formik>
  )
}

ResubmitJobDialog.propTypes = {
  toggleResubmitJobDialog: PropTypes.func.isRequired,
  selectedJob: PropTypes.object,
}
