import { toast } from 'react-toastify'
import { fromJS } from 'immutable'
import { create } from 'axios'

import { listEmrClusters } from 'actions/listEmrClusters'

import {
  RESIZE_EMR_CLUSTER_REQUEST,
  RESIZE_EMR_CLUSTER_SUCCESS,
  RESIZE_EMR_CLUSTER_FAILURE,
} from 'utils/constant'

import { selectEmrEndpoint } from 'selectors/selectEmrEndpoint'

function resizeEmrClusterRequest() {
  return {
    type: RESIZE_EMR_CLUSTER_REQUEST,
  }
}

function resizeEmrClusterSuccess(updatedCluster) {
  return {
    type: RESIZE_EMR_CLUSTER_SUCCESS,
    updatedCluster,
  }
}

function resizeEmrClusterFailure() {
  return {
    type: RESIZE_EMR_CLUSTER_FAILURE,
  }
}

export async function resizeEmrCluster(emrClusterName, emrClusterId, payload) {
  return function (dispatch, getState) {
    const endpoint = selectEmrEndpoint(getState())

    dispatch(resizeEmrClusterRequest())

    return create()
      .put(`${endpoint}/${emrClusterName}`, payload)
      .then((response) => {
        dispatch(resizeEmrClusterSuccess(fromJS(response.data)))

        toast.success(`${emrClusterName} resized successfully`, {
          autoClose: false,
          position: 'top-left',
        })
      })
      .then(() => dispatch(listEmrClusters()))
      .catch((error) => {
        dispatch(resizeEmrClusterFailure())

        let errorMessage = 'Unable to resize cluster'

        if (error.response) {
          errorMessage = errorMessage + ': ' + error.response.data.errorMessage

          toast.error(errorMessage, { autoClose: false, position: 'top-left' })
        }
      })
  }
}
