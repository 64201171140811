import React, { useState, useEffect } from 'react'
import { Box, Flex } from 'rebass'
import { create } from 'axios'
import { deleteCookie, setCookie } from 'utils/cookie'
import { domain } from 'utils/constant'
import { Modal, Button, TextArea, Checkbox, FormSection } from '@nike/epic-react-ui-old'
import { toast } from 'react-toastify'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'

import { FF_AUTH_ENDPOINT, COMMON_ENDPOINT } from 'utils/constant'

function validate(values) {
  const errors = {}

  if (!values.justification) {
    errors.justification = 'Please enter justification'
  }

  return errors
}

export function AssumeRoleForm(props) {
  const { isFullList, toggleDialog, dialogOpen } = props

  const [disableChecks, setDisableChecks] = useState(false)
  const [availableRoles, setAvailableRoles] = useState([])
  const [roleFilter, setRoleFilter] = useState([])

  function getAssumeRoleList() {
    create()
      .get(`${FF_AUTH_ENDPOINT}/assume-role-mappings/v1`)
      .then((resp) => setAvailableRoles(resp.data.groups))
      .catch((err) =>
        toast.error(err && err.response && err.response.data, {
          autoClose: false,
          position: 'top-left',
        })
      )
  }

  function getLimitedAssumeRoleList() {
    create()
      .get(`${COMMON_ENDPOINT}/assume-role/v1?AssumeRole=PlatformDev`)
      .then((resp) => setRoleFilter(resp.data.RolesAccessTo))
      .catch((err) =>
        toast.error(err && err.response && err.response.data, {
          autoClose: false,
          position: 'top-left',
        })
      )
  }

  useEffect(() => {
    getAssumeRoleList()

    getLimitedAssumeRoleList()
  }, [])

  function changeToken(token) {
    toast.success('Entering Assume Role Mode', { autoClose: false, position: 'top-left' })

    deleteCookie('paasui-auth')

    deleteCookie('cdt-platform-auth')

    setCookie('cdt-platform-auth', token, 1, domain)

    setCookie('paasui-auth', token, 1)

    window.location.replace('/')
  }

  function rotateAssumeRoleToken(payload) {
    create()
      .post(`${FF_AUTH_ENDPOINT}/assume-role-enter/v1`, payload)
      .then((resp) => {
        if (resp.data.user_assume_role_token === 'No assume role found') {
          toast.error('No assume role found', { autoClose: false, position: 'top-left' })
        } else {
          changeToken(resp.data.user_assume_role_token)
        }
      })
  }

  function onSubmit(values) {
    const payload = {
      groups: values.selectedRoles,
      justification: values.justification,
    }

    rotateAssumeRoleToken(payload)

    toggleDialog()
  }

  return (
    <Modal show={dialogOpen} modalSize='lg' onClose={toggleDialog}>
      <Formik
        initialValues={{ selectedRoles: [], justification: '' }}
        onSubmit={onSubmit}
        validate={validate}
        initialErrors={{ justification: 'Please enter justification' }}
      >
        {(formikProps) => {
          const { values, handleChange, setFieldValue, handleSubmit, handleBlur, errors } =
            formikProps

          function onRoleCheck(event) {
            if (event.target.checked) {
              if (values.selectedRoles.length > 1) {
                setDisableChecks(true)
              }

              setFieldValue('selectedRoles', [...values.selectedRoles, event.target.name])
            } else {
              setFieldValue(
                'selectedRoles',
                values.selectedRoles.filter((item) => item !== event.target.name)
              )

              if (values.selectedRoles.length < 4) {
                setDisableChecks(false)
              }
            }
          }

          return (
            <FormSection title='Select Roles (max 3)'>
              <Flex maxHeight={350} overflow='scroll' flexDirection='column' flexWrap='wrap'>
                {isFullList
                  ? availableRoles
                      .sort()
                      .map((item) => (
                        <Checkbox
                          key={item}
                          name={item}
                          label={item}
                          disabled={disableChecks && !values.selectedRoles.includes(item)}
                          onChange={onRoleCheck}
                        />
                      ))
                  : roleFilter
                      .filter((item) => item !== 'PlatformDev' && item !== 'FireFighters')
                      .sort()
                      .map((item) => (
                        <Checkbox
                          key={item}
                          name={item}
                          label={item}
                          disabled={disableChecks && !values.selectedRoles.includes(item)}
                          onChange={onRoleCheck}
                        />
                      ))}
              </Flex>

              <Box mt='30px'>
                <TextArea
                  name='justification'
                  label='Business justification'
                  onChange={handleChange}
                  value={values.justification}
                  onBlur={handleBlur}
                />
              </Box>

              <Flex justifyContent='center' mt='30px'>
                <Button
                  onClick={toggleDialog}
                  inverse
                  style={{ marginRight: '15px' }}
                  data-external-id='modal-assume-role-cancel'
                >
                  Cancel
                </Button>

                <Button
                  onClick={handleSubmit}
                  disabled={!isEmpty(errors)}
                  style={{ marginLeft: '15px' }}
                  data-external-id='modal-assume-role-submit'
                >
                  Submit
                </Button>
              </Flex>
            </FormSection>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default AssumeRoleForm
