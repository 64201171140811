import { SET_EMR_CLUSTER_STATUS } from 'utils/constant'
import { LOGIN_SUCCESS } from 'utils/constant'

export default function selectedEmrClusterStatus(state = 'ACTIVE', action = {}) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return localStorage.getItem('selectedEmrClusterStatus') || 'ACTIVE'

    case SET_EMR_CLUSTER_STATUS:
      return action.emrClusterStatus

    default:
      return state
  }
}
