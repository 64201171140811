import React, { useState, useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { Spinner } from '@nike/epic-react-ui'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { getCookie } from 'utils/cookie'

import NgapUi from 'components/global/ngap-ui'

import { login, loginSuccess } from 'actions/login'

// Logs user in automatically
const LoginPage = () => {
  const { authService } = useOktaAuth()

  useEffect(() => {
    async function oktaLogin() {
      authService.login(window.location.host.includes('.platforms.nike.com') ? '/ngap' : '/')
    }

    const oktaCookie = getCookie('okta-token')

    if (!oktaCookie) {
      oktaLogin()
    }
  }, [authService])

  return null
}

const AuthWrapper = (props) => {
  const { login, loginSuccess } = props

  const { authState, authService } = useOktaAuth()

  const [hasAccess, setHasAccess] = useState(null)
  const [isFetchingNgap2Token, setIsFetchingNgap2Token] = useState(null)

  const oktaCookie = getCookie('okta-token')

  useEffect(() => {
    async function ngap2Login(oktaCookie) {
      const oktaAccessToken = oktaCookie || (await authService.getAccessToken())

      setIsFetchingNgap2Token(true)

      login(oktaAccessToken)
        .then((hasAccess) => setHasAccess(hasAccess))
        .finally(() => setIsFetchingNgap2Token(false))
    }

    const ngap2Token = getCookie('paasui-auth')

    if (ngap2Token) {
      // case 1, user has ngap2 token
      setHasAccess(true)

      loginSuccess(ngap2Token)
    } else {
      if (oktaCookie && hasAccess === null && !isFetchingNgap2Token) {
        // case 2, user has okta token and needs ngap2 token
        ngap2Login(oktaCookie)
      } else {
        // case 2, does not have okta token or ngap2 token. login with okta sdk
        if (authState.isAuthenticated && hasAccess === null) {
          ngap2Login()
        }
      }
    }
  }, [authState, authService, login, hasAccess, oktaCookie, loginSuccess, isFetchingNgap2Token])

  if (authState.isPending || isFetchingNgap2Token) {
    return <Spinner show overlay={false} />
  }

  if (hasAccess === false) {
    return <Redirect to='request-access' />
  }

  return (
    <div>
      {(authState.isAuthenticated || oktaCookie) && hasAccess && <NgapUi authState={authState} />}

      {!authState.isAuthenticated && <LoginPage />}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (oktaAccessToken) => dispatch(login(oktaAccessToken)),
    loginSuccess: (ngap2Token) => dispatch(loginSuccess(ngap2Token)),
  }
}

export default connect(null, mapDispatchToProps)(AuthWrapper)
