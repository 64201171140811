import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Formik } from 'formik'
import { Box } from 'rebass'
import Stepper from '@material-ui/core-new/Stepper'
import Step from '@material-ui/core-new/Step'
import StepLabel from '@material-ui/core-new/StepLabel'

import ToJS from 'components/common/to-js'
import AutoTableForm from 'components/self-service/auto-table-form'

import { selectS3AclMap } from 'selectors/selectS3AclMap'
import { selectSelfServiceSchemaList } from 'selectors/selectSelfServiceSchemaList'
import { selectSelectedGroup } from 'selectors/selectSelectedGroup'
import { selectUsername } from 'selectors/selectUsername'
import { selectSelfServiceIsSecure } from 'selectors/selectSelfServiceIsSecure'

import { createTableJob } from 'actions/createTableJob'

import { genAutoTableSchema, getInitialValues, onSubmit } from 'utils/auto-table-form-helpers'
import { deleteCookie } from 'utils/cookie'

const steps = ['Basic Details', 'Define Table', 'Review and Submit']

export function AutoTablePage(props) {
  const {
    selfServiceSchemaList,
    s3AclMap,
    selfServiceIsSecure,
    selectedGroup,
    hasResetAutoTable,
    setHasResetAutoTable,
    autoTableActiveStep,
    setAutoTableActiveStep,
    username,
    navigateToPage,
    createTableJob,
  } = props

  const [savedJobId, setSavedJobId] = useState(null)

  // Wait for props to be available before setting formik initialValues
  if (!selectedGroup) {
    return null
  }

  function handleReset(values, { setValues, setErrors }) {
    deleteCookie('tableJobId')
    deleteCookie('cloneJobId')

    setValues(getInitialValues({ username }))
    setErrors({})
    setHasResetAutoTable(false)

    setAutoTableActiveStep(0)
  }

  function handleSubmit(values, bag) {
    onSubmit({
      values,
      bag,
      savedJobId,
      setSavedJobId,
      createTableJob,
      selectedGroup,
      setAutoTableActiveStep,
      navigateToPage,
    })

    setHasResetAutoTable(false)

    setAutoTableActiveStep(0)

    navigateToPage('JOBS')
  }

  return (
    <Box>
      <Box width='75%' margin='0 auto'>
        <Stepper activeStep={autoTableActiveStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Formik
        initialValues={getInitialValues({ username })}
        onSubmit={handleSubmit}
        onReset={handleReset}
        validationSchema={genAutoTableSchema(s3AclMap)}
        validateOnMount={false}
      >
        {(formikProps) => {
          return (
            <AutoTableForm
              formikProps={formikProps}
              username={username}
              selectedGroup={selectedGroup}
              s3AclMap={s3AclMap}
              selfServiceSchemaList={selfServiceSchemaList}
              selfServiceIsSecure={selfServiceIsSecure}
              steps={steps}
              hasResetAutoTable={hasResetAutoTable}
              setHasResetAutoTable={setHasResetAutoTable}
              autoTableActiveStep={autoTableActiveStep}
              setAutoTableActiveStep={setAutoTableActiveStep}
              savedJobId={savedJobId}
              setSavedJobId={setSavedJobId}
            />
          )
        }}
      </Formik>
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    s3AclMap: selectS3AclMap(state),
    selfServiceSchemaList: selectSelfServiceSchemaList(state),
    selectedGroup: selectSelectedGroup(state),
    username: selectUsername(state),
    selfServiceIsSecure: selectSelfServiceIsSecure(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createTableJob: (payload) => dispatch(createTableJob(payload)),
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), ToJS)(AutoTablePage)
