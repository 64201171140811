import { fromJS } from 'immutable'
import { create } from 'axios'

import { DESCRIBE_JOB_REQUEST, DESCRIBE_JOB_SUCCESS, DESCRIBE_JOB_FAILURE } from 'utils/constant'

import { selectJobsEndpoint } from 'selectors/selectJobsEndpoint'

function describeJobRequest() {
  return {
    type: DESCRIBE_JOB_REQUEST,
  }
}

function describeJobSuccess(selectedJob) {
  return {
    type: DESCRIBE_JOB_SUCCESS,
    selectedJob,
  }
}

function describeJobFailure() {
  return {
    type: DESCRIBE_JOB_FAILURE,
  }
}

export async function describeJob(jobId) {
  return function (dispatch, getState) {
    dispatch(describeJobRequest())

    const endpoint = selectJobsEndpoint(getState())

    return create()
      .get(`${endpoint}/v2/${jobId}`)
      .then((response) => dispatch(describeJobSuccess(fromJS(response.data))))
      .catch((error) => {
        dispatch(describeJobFailure(error))
      })
  }
}
