import { useState } from 'react'

// https://stackoverflow.com/questions/46240647/react-how-can-i-force-render-a-function-component
function useForceUpdate() {
  // eslint-disable-next-line
  const [value, setValue] = useState(0) // integer state
  return () => setValue((value) => ++value) // update the state to force render
}

export default useForceUpdate
