import { fromJS, Map } from 'immutable'
import { create } from 'axios'

import {
  LIST_AIRFLOW_CLUSTERS_REQUEST,
  LIST_AIRFLOW_CLUSTERS_SUCCESS,
  LIST_AIRFLOW_CLUSTERS_FAILURE,
  subDomain,
} from 'utils/constant'

/* List Airflow Clusters */

function listAirflowClustersRequest() {
  return {
    type: LIST_AIRFLOW_CLUSTERS_REQUEST,
  }
}

function listAirflowClustersSuccess(airflowClusters) {
  return {
    type: LIST_AIRFLOW_CLUSTERS_SUCCESS,
    airflowClusters,
  }
}

function listAirflowClustersFailure(error) {
  return {
    type: LIST_AIRFLOW_CLUSTERS_FAILURE,
    error,
  }
}

export async function listAirflowClusters() {
  return function (dispatch, getState) {
    const airflowConfig = getState().getIn(['airflowConfig', 'data']) || new Map()

    const endpoint = (airflowConfig.get('endpoints') || new Map()).get(subDomain)

    dispatch(listAirflowClustersRequest())

    return create()
      .get(endpoint)
      .then((response) => dispatch(listAirflowClustersSuccess(fromJS(response.data))))
      .catch((error) => {
        dispatch(listAirflowClustersFailure(error))
      })
  }
}
