import { Map } from 'immutable'

import {
  FETCH_GROUP_CONFIG_REQUEST,
  FETCH_GROUP_CONFIG_SUCCESS,
  FETCH_GROUP_CONFIG_FAILURE,
} from 'utils/constant'

const initialState = new Map({
  isFetching: false,
  data: new Map(),
})

export default function groupConfig(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_GROUP_CONFIG_REQUEST:
      return state.set('isFetching', true)

    case FETCH_GROUP_CONFIG_SUCCESS:
      return state.set('data', action.groupConfig).set('isFetching', false)

    case FETCH_GROUP_CONFIG_FAILURE:
      return state.set('isFetching', false).set('data', new Map())

    default:
      return state
  }
}
