import { List, Map } from 'immutable'
import { createSelector } from 'reselect'

import { selectEmrVersionConfig } from 'selectors/selectEmrVersionConfig'

export const selectSupportedTaskInstanceTypeOptions = createSelector(
  [selectEmrVersionConfig],
  (emrVersionConfig) => {
    return emrVersionConfig.get('ALLOWED_TASK_INSTANCE_TYPES')
      ? emrVersionConfig.get('ALLOWED_TASK_INSTANCE_TYPES').map((item) =>
          Map({
            label: item.get('instance_type'),
            value: item.get('instance_type'),
          })
        )
      : List([Map({ label: '', value: '' })])
  }
)
