import React from 'react'
import moment from 'moment'
import ReactGA from 'react-ga'
import { Box } from 'rebass'
import { Icon, Tooltip } from '@nike/epic-react-ui-old'

import ResizableTableEnhanced from 'components/common/resizable-table-enhanced'
import airflow_logo from 'images/airflow_logo.png'
import stop_icon from 'images/stop_icon.png'
import play_icon from 'images/play_icon.png'
import refresh_icon from 'images/refresh_icon.png'

const statusToShowTooltip = ['SUBMITTED', 'QUEUED', 'RUNNING', 'PENDING']

const getColumns = () => {
  const columns = [
    {
      accessor: 'name',
      Header: 'Name',
      sortType: 'nameSort',
    },
    {
      accessor: 'jobId',
      Header: 'Job ID',
      minWidth: 325,
      sortType: 'basic',
    },
    {
      accessor: 'creator',
      Header: 'Creator',
      maxWidth: 200,
    },
    {
      accessor: 'runtime',
      Header: 'Runtime',
      maxWidth: 200,
    },
    {
      accessor: 'createdAt',
      Header: 'Created At',
      sortType: 'createdAtSort',
    },
    {
      accessor: 'status',
      Header: 'Status',
      maxWidth: 200,
      sortType: 'statusSort',
    },
    {
      accessor: 'actions',
      Header: 'Actions',
      minWidth: 325,
      disableSortBy: true,
    },
  ]

  return columns
}

const getData = (props) => {
  const {
    jobs,
    toggleJobDetailsDialog,
    toggleJobLogsDialog,
    toggleDeleteJobDialog,
    toggleResubmitJobDialog,
    toggleJobSHSDialog,
    handleSHS,
    toggleJobAirflowOperatorDialog,
    handleRefreshJob,
  } = props

  let sortedJobs = jobs
    ? jobs.sort((current, nextItem) =>
        new Date(current.submitted_at) > new Date(nextItem.submitted_at) ? -1 : 1
      )
    : []

  const handleDetailsClick = (job) => () => {
    toggleJobDetailsDialog(job)
    ReactGA.modalview('/jobs/details')
  }

  const handleJobLogsClick = (job) => () => {
    toggleJobLogsDialog(job)
    ReactGA.modalview('/jobs/logs')
  }

  const handleSHSClick = (job) => () => {
    toggleJobSHSDialog()
    ReactGA.modalview('/jobs/shs')
    handleSHS(job.job_id)
  }

  const handleStopJobClick = (job) => () => {
    toggleDeleteJobDialog(job)
    ReactGA.modalview('/jobs/delete')
  }

  const handleResubmitJobClick = (job) => () => {
    toggleResubmitJobDialog(job)
    ReactGA.modalview('/jobs/resubmit')
  }

  const handleJobAirflowOperatorClick = (job) => () => {
    toggleJobAirflowOperatorDialog(job)
    ReactGA.modalview('/jobs/airflow-operator')
  }

  const handleRefreshJobClick = (job) => () => {
    handleRefreshJob(job.job_id)
  }

  const currentTime = moment(new Date(new Date().toUTCString().substr(0, 25)).toISOString())

  const getTooltipStatus = (job, currentTime, statusToShow) =>
    statusToShow.includes(job.job_status) &&
    currentTime.diff(moment(job.last_updated_at), 'seconds') > 120

  return sortedJobs
    ? sortedJobs.map((job, i) => ({
        name: (
          <button
            data-e2e='job-name-button'
            data-external-id='job-name'
            onClick={handleDetailsClick(job)}
            style={{
              textDecoration: 'underline',
              color: '#2482d4',
              backgroundColor: 'transparent',
            }}
          >
            {job.job_name}
          </button>
        ),
        jobId: job.job_id,
        creator: job.submitted_by,
        runtime: job.runtime,
        createdAt: moment(job.submitted_at).format('MMM D hh:mm:ss a'),
        status: (
          <Box>
            <span
              style={{
                color:
                  job.job_status === 'SUBMITTED'
                    ? 'rgb(72, 189, 31)'
                    : job.job_status === 'PENDING'
                    ? '#949494'
                    : job.job_status === 'RUNNING'
                    ? '#5197d6'
                    : job.job_status === 'SUCCEEDED'
                    ? '#32a64f'
                    : job.job_status === 'FAILED'
                    ? '#fe0000'
                    : '#000000',
              }}
            >
              {job.job_status}
            </span>
            <span style={{ float: 'right', color: '#2482D4' }}>
              {getTooltipStatus(job, currentTime, statusToShowTooltip) && (
                <Tooltip message='Please refresh job.' position='top'>
                  <div>
                    <Icon type='walkthrough' fontSize='18px' />
                  </div>
                </Tooltip>
              )}
            </span>
          </Box>
        ),
        actions: (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              data-e2e='job-logs-button'
              data-external-id='job-logs'
              style={{
                textDecoration: 'underline',
                color: '#2482d4',
                backgroundColor: 'transparent',
              }}
              onClick={handleJobLogsClick(job)}
            >
              Logs
            </button>
            <button
              data-e2e='job-shs-button'
              data-external-id='job-shs'
              style={{
                textDecoration: 'underline',
                color: '#2482d4',
                backgroundColor: 'transparent',
              }}
              onClick={handleSHSClick(job)}
            >
              SHS
            </button>
            <button
              data-e2e='stop-button'
              data-external-id='stop-job'
              style={{ backgroundColor: 'transparent' }}
              onClick={handleStopJobClick(job)}
            >
              <img src={stop_icon} alt='Stop' style={{ height: '25px', width: '25px' }} />
            </button>
            <button
              data-e2e='play-button'
              data-external-id='play-job'
              style={{ backgroundColor: 'transparent' }}
              onClick={handleResubmitJobClick(job)}
            >
              <img src={play_icon} alt='Play' style={{ height: '25px', width: '25px' }} />
            </button>
            <button
              data-e2e='airflow-button'
              data-external-id='job-airflow-operator'
              style={{ backgroundColor: 'transparent', padding: '0 6px' }}
              onClick={handleJobAirflowOperatorClick(job)}
            >
              <img src={airflow_logo} alt='Airflow' style={{ height: '20px', width: '20px' }} />
            </button>
            <button
              data-e2e='refresh-button'
              data-external-id='job-refresh'
              style={{ backgroundColor: 'transparent', padding: '0 0 0 10px' }}
              onClick={handleRefreshJobClick(job)}
            >
              <img src={refresh_icon} alt='Refresh' style={{ height: '20px', width: '20px' }} />
            </button>
          </Box>
        ),
      }))
    : []
}

export default function JobsDisplayTable(props) {
  const { isLoadingJobsDisplayPage } = props

  const data = getData(props)
  const columns = getColumns()
  const customWidth = (window.innerWidth - 600) / (columns.length - 3)
  const overflowColumns = [5]

  const sortTypes = {
    nameSort: (row1, row2, columnName) => {
      if (
        row1.values[columnName]['props']['children'] > row2.values[columnName]['props']['children']
      )
        return 1
      return -1
    },
    createdAtSort: (row1, row2, columnName) => {
      if (
        moment(row1.values[columnName], 'MMM D hh:mm:ss a').isAfter(
          moment(row2.values[columnName], 'MMM D hh:mm:ss a')
        )
      )
        return 1
      return -1
    },
    statusSort: (row1, row2, columnName) => {
      if (
        row1.values[columnName]['props']['children'][0]['props']['children'] >
        row2.values[columnName]['props']['children'][0]['props']['children']
      )
        return 1
      return -1
    },
  }

  return (
    <ResizableTableEnhanced
      columns={columns}
      data={data}
      isLoading={isLoadingJobsDisplayPage}
      shouldOverflow={true}
      overflowColumns={overflowColumns}
      customWidth={customWidth}
      noRowsMessage='No jobs found.'
      sortTypes={sortTypes}
    />
  )
}
