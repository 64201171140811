import React, { useState } from 'react'
import { useStateIfMounted } from 'use-state-if-mounted'
import IconButton from '@nike/nr-vamp-xl-icon-button'
import { Box } from 'rebass'
import axios from 'axios'
import { arExitLogger } from 'utils/constant'
import { toast } from 'react-toastify'
import { faUserSecret, faPersonBooth, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { faBars, faClipboard, faRedoAlt, faInfoCircle } from '@fortawesome/fontawesome-free-solid'
import { faAws } from '@fortawesome/free-brands-svg-icons'
import Menu from '@material-ui/core-new/Menu'
import MenuItem from '@material-ui/core-new/MenuItem'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { useOktaAuth } from '@okta/okta-react'
import ReactGA from 'react-ga'
import ToJS from 'components/common/to-js'

import TeamInformation from 'components/global/team-information'
import AssumeRoleForm from 'components/global/assumerole-form'

import { showAuthTokenToast } from 'utils/showAuthTokenToast'
import { getCookie, deleteCookie } from 'utils/cookie'
import { subDomain } from 'utils/constant'

import { selectIsUserLead } from 'selectors/selectIsUserLead'
import { selectIsUserVendor } from 'selectors/selectIsUserVendor'
import { selectSelectedGroup } from 'selectors/selectSelectedGroup'
import { selectGroups } from 'selectors/selectGroups'
import { selectAppBarLinks } from 'selectors/selectAppBarLinks'

export function AppBarMenu(props) {
  const {
    isUserLead,
    isUserVendor,
    selectedGroup,
    showEnterARButton,
    showExitARButton,
    authToken,
    setActiveCluster,
    groups,
    links,
  } = props

  const { authService } = useOktaAuth()
  const [oktaToken, setOktaToken] = useStateIfMounted(getCookie('okta-token'))
  if (!oktaToken && authService.getAccessToken)
    // on localhost servers but not in Jest
    authService.getAccessToken().then(setOktaToken)

  const [anchorEl, setAnchorEl] = useState(null)
  const [showTeamInfo, setShowTeamInfo] = useState(false)
  const [showAssumeRoleDialog, setShowAssumeRoleDialog] = useState(null)

  function handleClose() {
    setAnchorEl(null)
  }

  function handleViewToken() {
    showAuthTokenToast(authToken)

    ReactGA.event({ category: 'Global menu', action: 'View token' })
  }

  function handleGenerate() {
    ReactGA.event({ category: 'Global menu', action: 'Generate new token' })

    deleteCookie('paasui-auth')

    window.location.reload()
  }

  function handleExitAssumeRoleMode() {
    toast.info('Leaving Assume Role Mode')

    axios
      .get(arExitLogger)
      .then(() => handleGenerate() && window.location.reload())
      .catch(() =>
        toast.error('error posting to assumerole exit', {
          autoClose: false,
          position: 'top-left',
        })
      )
  }

  function handleTeamInfo() {
    handleClose()
    ReactGA.event({ category: 'Global menu', action: 'Team Information' })
    setShowTeamInfo(true)
  }

  function logout() {
    deleteCookie('paasui-auth')
    deleteCookie('cdt-platform-auth')

    authService.clearAuthState()

    window.location.replace('https://nike.okta.com')
  }

  function iconButton(id, icon) {
    const styleExtension = { container: { backgroundColor: 'transparent' } }
    if (icon === faBars) styleExtension.iconStyle = { color: '#000000' }

    return (
      <IconButton
        id={id}
        icon={icon}
        onCheck={(event) => setAnchorEl(event.currentTarget)}
        styleExtension={styleExtension}
      />
    )
  }

  function toggleAssumeRoleDialog(activeCluster) {
    setShowAssumeRoleDialog((isOpen) => !isOpen)

    setActiveCluster(activeCluster && !showAssumeRoleDialog ? activeCluster : {})
  }

  function menuItemClick(label, key, icon, onClick) {
    return (
      <MenuItem key={key} onClick={onClick}>
        {iconButton(key, icon)}
        {label}
      </MenuItem>
    )
  }

  function menuItemLink(label, key, icon, gaAction, url) {
    return (
      <MenuItem
        key={key}
        component='a'
        onClick={() => {
          handleClose()
          ReactGA.event({ category: 'Global menu', action: gaAction })
        }}
        href={url}
        target='_blank'
      >
        {iconButton(key, icon)}
        {label}
      </MenuItem>
    )
  }

  function getMenuItemLinks() {
    const appMenuLinks = []
    if (links.length > 0) {
      const env = subDomain === 'localhost' ? 'one' : subDomain
      links.forEach((app) => {
        const key = app.name.toLowerCase().replaceAll(' ', '-')
        const url = app.url[env].replace('<GROUP_LOWER>', selectedGroup.toLowerCase())
        if (app.icon === 'aws') {
          appMenuLinks.push(menuItemLink(app.name, key, faAws, app['google-analytics'].action, url))
        } else {
          appMenuLinks.push(
            menuItemLink(app.name, key, app.icon, app['google-analytics'].action, url)
          )
        }
      })
    }
    return appMenuLinks
  }

  return (
    <Box>
      {iconButton('menu-button', faBars)}

      <Menu id='menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {!isUserVendor && [
          menuItemClick('View Token', 'token', faClipboard, handleViewToken),
          getMenuItemLinks(),
        ]}

        {!showExitARButton &&
          menuItemClick('Generate New Token', 'Generate New Token', faRedoAlt, handleGenerate)}

        {isUserLead &&
          menuItemClick('Team Information', 'team-information', faInfoCircle, handleTeamInfo)}

        {showEnterARButton &&
          menuItemClick(
            'Assume Roles',
            'Enter AssumeRole Mode',
            faUserSecret,
            toggleAssumeRoleDialog
          )}

        {showExitARButton &&
          menuItemClick(
            'Leave AssumeRole Mode',
            'Exit AssumeRole Mode',
            faPersonBooth,
            handleExitAssumeRoleMode
          )}

        {menuItemClick('Logout', 'Sign Out', faSignOutAlt, logout)}
      </Menu>

      {showTeamInfo && (
        <TeamInformation
          key={selectedGroup}
          oktaToken={oktaToken}
          showTeamInfo={showTeamInfo}
          selectedGroup={selectedGroup}
          closeTeamInfo={() => setShowTeamInfo(false)}
        />
      )}

      {showEnterARButton && (
        <AssumeRoleForm
          toggleDialog={toggleAssumeRoleDialog}
          dialogOpen={showAssumeRoleDialog}
          isFullList={groups.includes('FireFighters')}
        />
      )}
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    isUserLead: selectIsUserLead(state),
    isUserVendor: selectIsUserVendor(state),
    selectedGroup: selectSelectedGroup(state),
    groups: selectGroups(state),
    links: selectAppBarLinks(state),
  }
}

const enhanced = compose(connect(mapStateToProps), ToJS)(AppBarMenu)

export default enhanced
