import { List, Map } from 'immutable'

import {
  LIST_TABLE_JOBS_REQUEST,
  LIST_TABLE_JOBS_SUCCESS,
  LIST_TABLE_JOBS_FAILURE,
  CREATE_TABLE_JOB_REQUEST,
  CREATE_TABLE_JOB_SUCCESS,
  CREATE_TABLE_JOB_FAILURE,
  DELETE_TABLE_JOB_REQUEST,
  DELETE_TABLE_JOB_SUCCESS,
  DELETE_TABLE_JOB_FAILURE,
} from 'utils/constant'

const initialState = new Map({
  isListing: false,
  data: new List(),
})

export default function tableJobs(state = initialState, action = {}) {
  switch (action.type) {
    case LIST_TABLE_JOBS_REQUEST:
    case CREATE_TABLE_JOB_REQUEST:
    case DELETE_TABLE_JOB_REQUEST:
      return state.set('isListing', true)

    case LIST_TABLE_JOBS_SUCCESS:
      return state.set('data', action.tableJobs).set('isListing', false)

    case LIST_TABLE_JOBS_FAILURE:
    case CREATE_TABLE_JOB_SUCCESS:
    case CREATE_TABLE_JOB_FAILURE:
    case DELETE_TABLE_JOB_FAILURE:
      return state.set('isListing', false)

    case DELETE_TABLE_JOB_SUCCESS:
      const tableJobs = state.get('data')
      const index = tableJobs.findIndex((tableJob) => tableJob.get('job_id') === action.jobId)

      return state.set('data', tableJobs.delete(index)).set('isListing', false)

    default:
      return state
  }
}
