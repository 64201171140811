import { useEffect, useState } from 'react'

function useMetaConfig({ configFetcher, callback, selectedRegion, selectedGroup, selectedFilter }) {
  const [isFetching, setIsFetching] = useState(false)

  function callbackDefault() {} // prevents infinite loop

  useEffect(() => {
    setIsFetching(true)

    if (selectedRegion) {
      if (selectedGroup && selectedFilter !== undefined) {
        configFetcher()
          .then(callbackDefault)
          .finally(() => {
            callback(selectedGroup, selectedFilter, false)
            setIsFetching(false)
          })
      } else {
        configFetcher()
          .then(callback || callbackDefault)
          .finally(() => setIsFetching(false))
      }
    } else {
      setIsFetching(false)
    }
  }, [selectedRegion, configFetcher, callback, selectedGroup, selectedFilter])

  return isFetching
}

export default useMetaConfig
