import React, { useState, useEffect } from 'react'
import { Box } from 'rebass'
import ReactGA from 'react-ga'
import { Select } from '@nike/epic-react-ui-old'

import Splash from 'components/global/splash'
import { connect } from 'react-redux'
import ToJS from 'components/common/to-js'
import Iframe from 'components/common/iframe'

import { fetchMetadataExplorerConfig } from 'actions/fetchMetadataExplorerConfig'

import { selectMetadataExplorerConfig } from 'selectors/selectMetadataExplorerConfig'

import useMetaConfig from 'hooks/useMetaConfig'
import PageTitle from '../../common/page-title'

export function MetadataExplorerPage(props) {
  const { metadataExplorerConfig, fetchMetadataExplorerConfig, selectedGroup, selectedRegion } =
    props

  const [isIframeLoading, setIsIFrameLoading] = useState(true)

  const [metadataExplorerEnvironment, setMetadataExplorerEnvironment] = useState({
    label: 'Prod',
    value: 'prod',
  })

  useEffect(() => {
    ReactGA.pageview('/metadataexplorer')
  }, [])

  useMetaConfig({
    configFetcher: fetchMetadataExplorerConfig,
    callback: null,
    selectedGroup,
    selectedRegion,
  })

  let endpoint = ''

  if (metadataExplorerConfig.endpoints) {
    endpoint = metadataExplorerConfig.endpoints[metadataExplorerEnvironment.value]
  }

  function updateMetadataExplorerEnvironment(value) {
    setMetadataExplorerEnvironment(value)

    ReactGA.event({
      category: 'Metadata Explorer',
      action: 'Change environment',
      label: value.label,
    })
  }
  const pageTitleConfig = { title: 'Metadata Explorer' }

  return (
    <Box style={{ position: 'relative' }}>
      <PageTitle {...pageTitleConfig} />
      <Box width='150px' style={{ position: 'absolute', top: '5px', right: '10px' }}>
        <Select
          options={[
            { label: 'Prod', value: 'prod' },
            { label: 'Non-prod', value: 'non-prod' },
          ]}
          onChange={updateMetadataExplorerEnvironment}
          value={metadataExplorerEnvironment}
          placeholder='Environment'
        />
      </Box>

      {isIframeLoading && <Splash />}
      <Iframe title='Metadata Explorer' src={endpoint} onLoad={() => setIsIFrameLoading(false)} />
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    metadataExplorerConfig: selectMetadataExplorerConfig(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMetadataExplorerConfig: () => dispatch(fetchMetadataExplorerConfig()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToJS(MetadataExplorerPage))
