import { createSelector } from 'reselect'

import { selectAvailableServices } from './selectAvailableServices'
import { selectSelectedAccount } from './selectSelectedAccount'
import { selectSelectedRegion } from './selectSelectedRegion'
import { selectSelectedRuntime } from './selectSelectedRuntime'

export const selectDatabricksConfig = createSelector(
  [selectAvailableServices, selectSelectedAccount, selectSelectedRegion, selectSelectedRuntime],
  (availableServices, selectedAccount, selectedRegion, selectedRuntime) => {
    return (
      availableServices.getIn([selectedAccount, selectedRegion, selectedRuntime, 'databricks']) ||
      {}
    )
  }
)
