import React from 'react'
import { toast } from 'react-toastify'
import { create } from 'axios'

import { listJobs } from 'actions/listJobs'

import { CREATE_JOB_REQUEST, CREATE_JOB_SUCCESS, CREATE_JOB_FAILURE } from 'utils/constant'

import { selectJobsEndpoint } from 'selectors/selectJobsEndpoint'

function createJobRequest() {
  return {
    type: CREATE_JOB_REQUEST,
  }
}

function createJobSuccess() {
  return {
    type: CREATE_JOB_SUCCESS,
  }
}

function createJobFailure() {
  return {
    type: CREATE_JOB_FAILURE,
  }
}

export async function createJob(payload, groupName, isUserJobs) {
  return function (dispatch, getState) {
    dispatch(createJobRequest())

    const endpoint = selectJobsEndpoint(getState())

    let databricksToken = payload.databricksToken
    let header = {
      headers: {
        'Runtime-Token': 'Bearer ' + databricksToken,
      },
    }
    delete payload['databricksToken']

    return create()
      .post(`${endpoint}/v2`, payload, header)
      .then(() => {
        dispatch(createJobSuccess())

        dispatch(listJobs(groupName, isUserJobs, false))

        toast.success(`${payload.jobName} created successfully`, {
          autoClose: false,
          position: 'top-left',
        })
      })
      .catch((error) => {
        dispatch(createJobFailure())

        const defaultMessage = 'Error. Please contact platform team.'

        const errorResponse =
          error.response &&
          error.response.status !== 500 &&
          error.response.data &&
          error.response.data.detail
            ? error.response.data.detail
            : defaultMessage

        if (errorResponse === defaultMessage) {
          toast.error(errorResponse, { autoClose: false, closeOnClick: false })
          return
        }

        let err
        for (err of errorResponse) {
          const elt = err.loc[1] + ': ' + err.msg
          toast.error(<p>{elt}</p>, { autoClose: false, closeOnClick: false })
        }

        return 'error'
      })
  }
}
