/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Spinner } from '@nike/epic-react-ui-old'

export default function Splash() {
  return (
    <Spinner
      large
      css={css`
        margin: 30px auto;
      `}
    />
  )
}
