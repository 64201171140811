import { SET_EMR_VERSION } from 'utils/constant'

const initialState = ''

export default function emrVersion(state = initialState, action = {}) {
  switch (action.type) {
    case SET_EMR_VERSION:
      return action.emrVersion

    default:
      return state
  }
}
