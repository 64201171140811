/* https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/?utm_source=dev&utm_medium=sc&utm_campaign=rbac_react */

const rules = {
  Alpha: {
    static: ['emr:create', 'emr:resize'],
  },
  Leads: {
    static: ['emr:create', 'emr:resize'],
  },
}

export default rules
