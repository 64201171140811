/**
 * Select text within a node onClick for copying/pasting
 * @param {Event} target The target node of an onclick event (e.g. `event.target`)
 * @param {Boolean} copy Copy the selection to clipboard (default = false)
 */
export const selectText = ({ target }, copy = false) => {
  let range = new Range()
  range.selectNodeContents(target)
  document.getSelection().removeAllRanges() // clear existing selection if any
  document.getSelection().addRange(range)
  copy && navigator.clipboard.writeText(range)
}
