/** @jsx jsx */
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { appFullName } from '../../../utils/constant'

PageTitle.propTypes = { title: PropTypes.string }
PageTitle.defaultProps = { title: null }

export default function PageTitle({ title }) {
  useEffect(() => {
    document.title = title ? `${title} - ${appFullName}` : appFullName
  }, [title])

  return null
}
