import { combineReducers } from 'redux-immutable'

import airflowClusters from './airflowClusters'
import authToken from './authToken'
import emrClusters from './emrClusters'
import tableJobs from './tableJobs'
import groupConfig from './groupConfig'
import selfServiceConfig from './selfServiceConfig'
import emrConfig from './emrConfig'
import airflowConfig from './airflowConfig'
import airflowOneConfig from './airflowOneConfig'
import selectedGroup from './selectedGroup'
import selectedRegion from './selectedRegion'
import selectedAccount from './selectedAccount'
import selectedRuntime from './selectedRuntime'
import metadataExplorerConfig from './metadataExplorerConfig'
import emrVersion from './emrVersion'
import selectedEmrClusterStatus from './selectedEmrClusterStatus'
import jobs from './jobs'

const reducers = combineReducers({
  airflowClusters,
  authToken,
  emrClusters,
  tableJobs,
  groupConfig,
  selfServiceConfig,
  emrConfig,
  airflowConfig,
  airflowOneConfig,
  selectedGroup,
  selectedRegion,
  metadataExplorerConfig,
  emrVersion,
  selectedEmrClusterStatus,
  selectedAccount,
  selectedRuntime,
  jobs,
})

export default reducers
