/** @jsx jsx */
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useTable, useBlockLayout, useResizeColumns } from 'react-table'
import { Table, Thead, Tr, Th, Td, Tbody, Spinner } from '@nike/epic-react-ui-old'
import { css, jsx } from '@emotion/core'

const Styles = styled.div`
      .resizer {
        display: inline-block;
        width: 10px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;
      }
    }
`

function ResizableTable(props) {
  const { columns, data, isLoading, shouldFirstRowOverflow, noRowsMessage } = props

  const defaultColumn = useMemo(
    () => ({
      minWidth: window.innerWidth / columns.length,
    }),
    [columns.length]
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useBlockLayout,
    useResizeColumns
  )

  return (
    <Styles>
      <div data-e2e='spinner'>
        {isLoading ? (
          <Spinner
            medium
            css={css`
              margin: 30px auto;
            `}
          />
        ) : (
          <Table {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th {...column.getHeaderProps()}>
                      {column.render('Header')}
                      {/* Use column.getResizerProps to hook up the events correctly */}
                      <div {...column.getResizerProps()} className='resizer' />
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>

            <Tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row)
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <Td {...cell.getCellProps()}>
                          <div
                            style={{
                              overflow: shouldFirstRowOverflow && i === 0 ? 'visible' : 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {cell.render('Cell')}
                          </div>
                        </Td>
                      )
                    })}
                  </Tr>
                )
              })}

              {rows.length <= 0 && (
                <Tr>
                  <Td style={{ border: 'none', fontSize: '20px' }}>{noRowsMessage}</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        )}
      </div>
    </Styles>
  )
}

ResizableTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  shouldFirstRowOverflow: PropTypes.bool, // useful for column filters
  noRowsMessage: PropTypes.string,
}

ResizableTable.defaultProps = {
  isLoading: false,
  shouldFirstRowOverflow: false,
}

export default ResizableTable
