import { createSelector } from 'reselect'

import { selectRegions } from './selectRegions'

const selectedRegion = (state) => state.get('selectedRegion')

export const selectSelectedRegion = createSelector(
  [selectedRegion, selectRegions],
  (selectedRegion, regions) => {
    if (!selectedRegion) {
      return localStorage.getItem('selectedRegion') || regions.get(0)
    }

    return regions.contains(selectedRegion) ? selectedRegion : regions.get(0)
  }
)
