import React from 'react'
import { toast } from 'react-toastify'
import { create } from 'axios'

import {
  SELF_SERVICE_ENDPOINT,
  DELETE_TABLE_JOB_REQUEST,
  DELETE_TABLE_JOB_SUCCESS,
  DELETE_TABLE_JOB_FAILURE,
} from 'utils/constant'

function deleteTableJobRequest() {
  return {
    type: DELETE_TABLE_JOB_REQUEST,
  }
}

function deleteTableJobSuccess(jobId) {
  return {
    type: DELETE_TABLE_JOB_SUCCESS,
    jobId,
  }
}

function deleteTableJobFailure() {
  return {
    type: DELETE_TABLE_JOB_FAILURE,
  }
}

export async function deleteTableJob(jobId, jobName) {
  return function (dispatch) {
    dispatch(deleteTableJobRequest())

    return create()
      .delete(`${SELF_SERVICE_ENDPOINT}/jobs/v1/${jobId}`)
      .then(() => {
        dispatch(deleteTableJobSuccess(jobId))

        toast.success(`${jobName} deleted successfully`, {
          autoClose: false,
          position: 'top-left',
        })
      })
      .catch((error) => {
        dispatch(deleteTableJobFailure())

        let errorMessage = 'Unable to delete job'

        if (error.response && error.response.data && error.response.data.response) {
          errorMessage = error.response.data.response
        }

        toast.error(<div style={{ wordBreak: 'break-word' }}>{errorMessage}</div>, {
          autoClose: false,
          closeOnClick: false,
          position: 'top-left',
        })
      })
  }
}
