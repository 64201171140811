import React, { useEffect } from 'react'
import { Box } from 'rebass'
import AppBar from '@material-ui/core-new/AppBar'
import Tabs from '@material-ui/core-new/Tabs'
import Tab from '@material-ui/core-new/Tab'
import { connect } from 'react-redux'
import { compose } from 'redux'
import ReactGA from 'react-ga'

import TabContainer from 'components/common/tab-container'
import Iframe from 'components/common/iframe'
import ToJS from 'components/common/to-js'

import { selectPlatformObservabilityLinks } from 'selectors/selectPlatformObservabilityLinks'
import { useNarkOnPageLoad } from 'utils/nark.js'
import PageTitle from '../../common/page-title'

const labels = [
  'AWS Cost Usage',
  'AWS Cost Trends',
  'Instance Modernization',
  'Auto-Terminated EMR',
  'Legacy Airflow Status',
  'Databricks Cost Usage',
]

const pageName = 'platform-observability'

export function PlatformObservabilityPage(props) {
  useNarkOnPageLoad(pageName)
  const { platformObservabilityLinks } = props

  const [activeTabIndex, setActiveTabIndex] = React.useState(0)

  useEffect(() => {
    ReactGA.pageview('/observability')
  }, [])

  function handleTabChange(event, newValue) {
    setActiveTabIndex(newValue)

    ReactGA.event({
      category: 'Platform observability',
      action: 'Tab change',
      label: labels[newValue],
    })
  }

  const pageTitleConfig = { title: `Platform Observability: ${labels[activeTabIndex]}` }

  return (
    <Box>
      <PageTitle {...pageTitleConfig} />
      <AppBar
        position='static'
        style={{
          backgroundColor: '#000000',
          minWidth: `${window.innerWidth}px`,
        }}
      >
        <Tabs value={activeTabIndex} onChange={handleTabChange}>
          <Tab label={labels[0]} />
          <Tab label={labels[1]} />
          <Tab label={labels[2]} />
          <Tab label={labels[3]} />
          <Tab label={labels[4]} />
          <Tab label={labels[5]} />
        </Tabs>
      </AppBar>

      <TabContainer hidden={activeTabIndex !== 0}>
        <Iframe title='AWS Cost Usage' src={platformObservabilityLinks['aws-cost-usage']} />
      </TabContainer>

      <TabContainer hidden={activeTabIndex !== 1}>
        <Iframe title='AWS Cost Trends' src={platformObservabilityLinks['aws-cost-trends']} />
      </TabContainer>

      <TabContainer hidden={activeTabIndex !== 2}>
        <Iframe
          title='Instance Modernization'
          src={platformObservabilityLinks['instance-modernization']}
        />
      </TabContainer>

      <TabContainer hidden={activeTabIndex !== 3}>
        <Iframe title='EMR Termination' src={platformObservabilityLinks['emr-termination']} />
      </TabContainer>

      <TabContainer hidden={activeTabIndex !== 4}>
        <Iframe
          title='Legacy Airflow Status'
          src={platformObservabilityLinks['legacy-airflow-status']}
        />
      </TabContainer>

      <TabContainer hidden={activeTabIndex !== 5}>
        <Iframe
          title='Databricks Cost Usage'
          src={platformObservabilityLinks['databricks-cost-usage']}
        />
      </TabContainer>
    </Box>
  )
}

const mapStateTopProps = (state) => {
  return {
    platformObservabilityLinks: selectPlatformObservabilityLinks(state),
  }
}

export default compose(connect(mapStateTopProps), ToJS)(PlatformObservabilityPage)
