import { fromJS } from 'immutable'
import { create } from 'axios'

import { updateSelectedGroup } from 'actions/updateSelectedGroup'
import { updateSelectedRegion } from 'actions/updateSelectedRegion'

import { LIST_JOBS_REQUEST, LIST_JOBS_SUCCESS, LIST_JOBS_FAILURE } from 'utils/constant'

import { selectJobsEndpoint } from 'selectors/selectJobsEndpoint'

import { fetchEmrConfigFailure } from './fetchEmrConfig'

function listJobsRequest() {
  return {
    type: LIST_JOBS_REQUEST,
  }
}

function listJobsSuccess(jobs) {
  return {
    type: LIST_JOBS_SUCCESS,
    jobs,
  }
}

function listJobsFailure() {
  return {
    type: LIST_JOBS_FAILURE,
  }
}

export async function listJobs(groupName, userJobs, jobId) {
  const queryParams = new URLSearchParams(window?.location?.href?.split('?')[1]) || {}
  // getById: user has added jobId parameter to query string
  const getById = queryParams.get('job_id')
  const apiJobId = getById || jobId
  const apiGroupName = getById ? queryParams.get('group_name') : groupName
  const apiRegion = getById ? queryParams.get('region') : localStorage.getItem('selectedRegion')

  // If the region is not available DON'T dispatch anything
  if (!apiRegion) return

  return function (dispatch, getState) {
    if (getById) {
      if (groupName !== apiGroupName) {
        localStorage.setItem('selectedGroup', apiGroupName)
        dispatch(updateSelectedGroup(apiGroupName))
      }
      if (localStorage.getItem('selectedRegion') !== apiRegion) {
        localStorage.setItem('selectedRegion', apiRegion)
        dispatch(updateSelectedRegion(apiRegion))
      }
    }

    dispatch(listJobsRequest())

    const endpoint = selectJobsEndpoint(getState())

    if (!endpoint) {
      dispatch(fetchEmrConfigFailure())
      return
    }
    let queryString = '?group_name=' + groupName
    if (apiJobId) {
      queryString += '&job_id=' + apiJobId
    } else {
      queryString += userJobs ? '&user_jobs=True' : ''
    }
    queryString += '&region=' + apiRegion

    return create()
      .get(`${endpoint}/v2${queryString}`)
      .then((response) => dispatch(listJobsSuccess(fromJS(response.data))))
      .catch((error) => {
        dispatch(listJobsFailure(error))
      })
  }
}
