import React from 'react'
import { Box, Flex } from 'rebass'
import { Modal, Button } from '@nike/epic-react-ui-old'

import { selfServiceWGPrivacyStatement } from 'utils/constant'

export default function WalledGardenWarningDialog(props) {
  const { show, onAccept, onDecline } = props

  return (
    <Modal show={show} onClose={onDecline}>
      <Box width='550px'>
        <p style={{ whiteSpace: 'pre-wrap', maxHeight: '500px', overflow: 'scroll' }}>
          {selfServiceWGPrivacyStatement}
        </p>

        <Flex justifyContent='center' mt='30px'>
          <Button
            onClick={onDecline}
            inverse
            style={{ marginRight: '10px' }}
            data-external-id='walled-garden-warning-decline'
          >
            Decline
          </Button>

          <Button
            onClick={onAccept}
            style={{ marginLeft: '10px' }}
            data-external-id='walled-garden-warning-accept'
          >
            Accept
          </Button>
        </Flex>
      </Box>
    </Modal>
  )
}
