import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
import { Flex } from 'rebass'
import { Button } from '@nike/epic-react-ui-old'

import AirflowClustersTable from 'components/airflow/airflow-clusters-table'
import ToJS from 'components/common/to-js'
import SshDialog from 'components/common/ssh-dialog'

import { listAirflowClusters } from 'actions/listAirflowClusters'
import { fetchAirflowConfig } from 'actions/fetchAirflowConfig'

import { selectAirflowClusters } from 'selectors/selectAirflowClusters'
import { selectIsFetchingAirflowClusters } from 'selectors/selectIsFetchingAirflowClusters'

import useMetaConfig from 'hooks/useMetaConfig'
import PageTitle from '../../common/page-title'

const filterAirflowClusters = (airflowClusters, searchValue) => {
  const searchValueLc = searchValue.toLowerCase()

  return airflowClusters.filter(
    (airflowCluster) =>
      !searchValue ||
      airflowCluster.cluster_name.toLowerCase().includes(searchValueLc) ||
      airflowCluster.owned_by.toLowerCase().includes(searchValueLc)
  )
}

export function AirflowPage(props) {
  const {
    airflowClusters,
    searchValue,
    fetchAirflowConfig,
    listAirflowClusters,
    selectedGroup,
    selectedRegion,
    isFetchingAirflowClusters,
    setActiveCluster,
    activeCluster,
  } = props

  const [isSshDialogOpen, setIsSshDialogOpen] = useState(false)

  useEffect(() => {
    ReactGA.pageview('/airflow')
  }, [])

  const isLoadingAirflowPage = useMetaConfig({
    configFetcher: fetchAirflowConfig,
    callback: listAirflowClusters,
    selectedGroup,
    selectedRegion,
  })

  const filteredAirflowClusters = filterAirflowClusters(airflowClusters, searchValue)

  function toggleSshDialog(activeCluster) {
    setIsSshDialogOpen(() => !isSshDialogOpen)

    setActiveCluster(activeCluster && !isSshDialogOpen ? activeCluster : {})
  }

  function handleRefreshAirflowClusters() {
    listAirflowClusters()

    ReactGA.event({ category: 'Airflow', action: 'Refresh' })
  }

  const pageTitleConfig = { title: 'Airflow' }

  return (
    <div>
      <PageTitle {...pageTitleConfig} />
      <Flex
        justifyContent='flex-end'
        style={{
          backgroundColor: '#ececec',
          borderTop: '1px solid #ffffff',
        }}
        p='10px 30px'
      >
        <Button
          id='list-airflow-cluster'
          onClick={handleRefreshAirflowClusters}
          inverse
          data-external-id='refresh-cluster-list'
        >
          Refresh
        </Button>
      </Flex>

      <AirflowClustersTable
        isLoading={isLoadingAirflowPage || isFetchingAirflowClusters}
        airflowClusters={filteredAirflowClusters}
        toggleSshDialog={toggleSshDialog}
      />

      <SshDialog
        isOpen={isSshDialogOpen}
        toggleDialog={toggleSshDialog}
        activeCluster={activeCluster}
        activeTab='airflow'
      />
    </div>
  )
}

AirflowPage.propTypes = {
  airflowClusters: PropTypes.array.isRequired,
  isFetchingAirflowClusters: PropTypes.bool.isRequired,
  searchValue: PropTypes.string,
  fetchAirflowConfig: PropTypes.func.isRequired,
  listAirflowClusters: PropTypes.func.isRequired,
  selectedGroup: PropTypes.string.isRequired,
  selectedRegion: PropTypes.string.isRequired,
  setActiveCluster: PropTypes.func.isRequired,
  activeCluster: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  return {
    airflowClusters: selectAirflowClusters(state),
    isFetchingAirflowClusters: selectIsFetchingAirflowClusters(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listAirflowClusters: () => dispatch(listAirflowClusters()),
    fetchAirflowConfig: () => dispatch(fetchAirflowConfig()),
  }
}

const enhanced = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  ToJS
)(AirflowPage)

export default enhanced
