import { createSelector } from 'reselect'

import { selectIsFetchingSelfServiceConfig } from 'selectors/selectIsFetchingSelfServiceConfig'

const isListingTableJobs = (state) => state.getIn(['tableJobs', 'isListing'])

export const selectIsListingTableJobs = createSelector(
  [isListingTableJobs, selectIsFetchingSelfServiceConfig],
  (isListingTableJobs, isFetchingSelfServiceConfig) =>
    isListingTableJobs || isFetchingSelfServiceConfig
)
