/** @jsx jsx */
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Formik } from 'formik'
import { Modal, Button, Spinner } from '@nike/epic-react-ui-old'
import { css, jsx } from '@emotion/core'
import { Flex, Box } from 'rebass'

import CreateJobForm from 'components/jobs-display/create-job-form'

import {
  getInitialFieldValues,
  transformPayloadToFieldValues,
  resubmit,
  validate,
} from 'utils/create-job-form-helpers'

const getJobFields = (props) => {
  const { selectedJob, computeRuntimes } = props

  return selectedJob.hasOwnProperty('payload') && selectedJob.hasOwnProperty('runtime')
    ? transformPayloadToFieldValues(
        JSON.parse(selectedJob.payload),
        selectedJob.runtime,
        computeRuntimes
      )
    : getInitialFieldValues()
}

export function JobDetailsDialog(props) {
  const { isJobDetailsDialogOpen, toggleJobDetailsDialog } = props

  const [isSubmitting, setIsSubmitting] = useState(false)

  const jobDetails = getJobFields(props)

  return (
    <Formik
      initialValues={jobDetails}
      validate={validate}
      onSubmit={(values, bag) => {
        setIsSubmitting(true)
        resubmit(values, bag, props).then(() => setIsSubmitting(false))
      }}
      enableReinitialize
    >
      {(formikProps) => {
        const { handleSubmit, handleReset } = formikProps

        return (
          <Modal
            title={jobDetails.jobName}
            show={isJobDetailsDialogOpen}
            onClose={toggleJobDetailsDialog}
            modalSize='lg'
            swoosh
            css={css`
              z-index: 500;
              height: 75vh;
              overflow: scroll;
            `}
          >
            <Flex flexDirection='column'>
              <Box style={{ height: '51vh', overflow: 'scroll' }}>
                <CreateJobForm {...props} formikProps={formikProps} newJob={false} />
              </Box>

              <Flex mt='15px' justifyContent='center'>
                <Button
                  data-e2e='job-details-cancel-button'
                  data-external-id='job-details-cancel'
                  id='job-details-cancel-button'
                  onClick={() => {
                    toggleJobDetailsDialog()
                    handleReset()
                  }}
                  large
                  inverse
                  css={css`
                    margin-right: 10px;
                  `}
                >
                  Cancel
                </Button>

                <Button
                  data-e2e='job-details-submit-button'
                  data-external-id='job-details-submit'
                  id='job-details-submit-button'
                  onClick={handleSubmit}
                  large
                  css={css`
                    margin-right: 20px;
                  `}
                >
                  Submit Job
                </Button>
              </Flex>
              <Flex mt='30px' justifyContent='center'>
                {isSubmitting && <Spinner />}
              </Flex>
            </Flex>
          </Modal>
        )
      }}
    </Formik>
  )
}

JobDetailsDialog.propTypes = {
  isJobDetailsDialogOpen: PropTypes.bool,
  toggleJobDetailsDialog: PropTypes.func.isRequired,
  createJob: PropTypes.func.isRequired,
  selectedJob: PropTypes.object,
  validate: PropTypes.func,
}

JobDetailsDialog.defaultProps = {
  isJobDetailsDialogOpen: false,
}

export default JobDetailsDialog
