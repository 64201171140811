import { getEmrClusterTemplates } from 'utils/getEmrClusterTemplates'
import cloneDeep from 'lodash.clonedeep'
import isJson from 'utils/isJson'
import ReactGA from 'react-ga'

export class InstanceTypeConfig {
  constructor({
    bidPriceAsPercentageOfOnDemandPrice = 100,
    weightedCapacity = 1,
    instanceType = 'm4.xlarge',
  }) {
    this.bidPriceAsPercentageOfOnDemandPrice = bidPriceAsPercentageOfOnDemandPrice
    this.weightedCapacity = weightedCapacity
    this.instanceType = instanceType
  }
}

export const getInstanceFleets = (fleetConfiguration) => {
  const masterInstanceTypeConfig = []
  const coreInstanceTypeConfig = []
  const taskInstanceTypeConfig = []

  for (let i = 0; i < fleetConfiguration.masterInstType.length; i++) {
    masterInstanceTypeConfig.push(
      new InstanceTypeConfig({
        instanceType: fleetConfiguration.masterInstType[i],
      })
    )
  }
  for (let i = 0; i < fleetConfiguration.coreInstType.length; i++) {
    coreInstanceTypeConfig.push(
      new InstanceTypeConfig({
        instanceType: fleetConfiguration.coreInstType[i].instanceType,
        weightedCapacity: Number(fleetConfiguration.coreInstType[i].weightedCapacity),
      })
    )
  }
  for (let i = 0; i < fleetConfiguration.taskInstType.length; i++) {
    taskInstanceTypeConfig.push(
      new InstanceTypeConfig({
        instanceType: fleetConfiguration.taskInstType[i].instanceType,
        weightedCapacity: Number(fleetConfiguration.taskInstType[i].weightedCapacity),
      })
    )
  }
  return [
    {
      instanceFleetType: 'MASTER',
      isOnDemand: true,
      targetOnDemandCapacity: 1,
      instanceTypeConfigs: masterInstanceTypeConfig,
      launchSpecifications: {
        spotSpecification: {
          blockDurationMinutes: 60,
          timeoutAction: 'SWITCH_TO_ON_DEMAND',
          timeoutDurationMinutes: 15,
        },
      },
    },
    {
      instanceFleetType: 'CORE',
      targetOnDemandCapacity: 0,
      targetSpotCapacity: Number(fleetConfiguration.numCoreNodes),
      instanceTypeConfigs: coreInstanceTypeConfig,
      launchSpecifications: {
        spotSpecification: {
          blockDurationMinutes: 60,
          timeoutAction: 'SWITCH_TO_ON_DEMAND',
          timeoutDurationMinutes: 30,
        },
      },
    },
    {
      instanceFleetType: 'TASK',
      targetOnDemandCapacity: 0,
      targetSpotCapacity: Number(fleetConfiguration.numTaskNodes),
      instanceTypeConfigs: taskInstanceTypeConfig,
      launchSpecifications: {
        spotSpecification: {
          blockDurationMinutes: 60,
          timeoutAction: 'SWITCH_TO_ON_DEMAND',
          timeoutDurationMinutes: 30,
        },
      },
    },
  ]
}

export const classificationOptions = [
  { label: 'Bronze', value: 'bronze' },
  { label: 'Silver', value: 'silver' },
  { label: 'Gold', value: 'gold' },
  { label: 'Platinum', value: 'platinum' },
]

export const hiveEnvironmentOptions = [
  {
    label: 'Prod',
    value: 'prod',
  },
  {
    label: 'Non-Prod',
    value: 'dev',
  },
]

export const aeComputeEnvironmentOptions = [
  {
    label: 'Dev',
    value: 'dev',
  },
  {
    label: 'QA',
    value: 'qa',
  },
  {
    label: 'Prod',
    value: 'prod',
  },
]

export function saveTemplateToLocalStorage(values) {
  const emrClusterTemplatesClone = cloneDeep(getEmrClusterTemplates())

  const template = {
    ...values,
  }

  const duplicateIndex = emrClusterTemplatesClone.findIndex(
    (template) => template.newTemplateName === values.newTemplateName
  )

  if (duplicateIndex < 0) {
    emrClusterTemplatesClone.push(template)
  } else {
    // override template with same name
    emrClusterTemplatesClone[duplicateIndex] = template
  }

  localStorage.setItem('emrClusterTemplates', JSON.stringify(emrClusterTemplatesClone))
}

export const formatInstanceFleets = (instanceFleets) => {
  let instanceFleetsFormatted = cloneDeep(instanceFleets)

  if (instanceFleets[0].isOnDemand) {
    delete instanceFleetsFormatted[0].targetSpotCapacity

    delete instanceFleetsFormatted[0].launchSpecifications
  } else {
    delete instanceFleetsFormatted[0].targetOnDemandCapacity
  }

  for (let i = 0; i < instanceFleets.length; i++) {
    if (i === 0 && instanceFleets[0].isOnDemand) {
      continue
    }

    if (
      instanceFleetsFormatted[i].launchSpecifications.spotSpecification.blockDurationMinutes ===
      'none'
    ) {
      delete instanceFleetsFormatted[i].launchSpecifications.spotSpecification.blockDurationMinutes
    } else {
      const blockDurationMinutes =
        instanceFleetsFormatted[i].launchSpecifications.spotSpecification.blockDurationMinutes

      instanceFleetsFormatted[i].launchSpecifications.spotSpecification.blockDurationMinutes =
        Number(blockDurationMinutes)
    }
  }

  return instanceFleetsFormatted
}

export function submit(values, bag, props) {
  const {
    createEmrCluster,
    toggleCreateEmrClusterDialog,
    selectedGroup,
    defaultInstances,
    defaultInstanceFleets,
    emrApplications,
    isBootstrapAllowed,
    isManagedScalingAllowed,
  } = props

  const { resetForm } = bag

  const payload = {
    cluster_name: values.clusterName,
    cerebro_cdas: values.enableOkera,
    ranger_enabled: values.enableRanger,
    custom_dns_status: values.enableCustomDNS,
    group: selectedGroup,
    classification: values.classification.value,
    emr_version: `emr-${values.emrVersion.value}`,
    applications: [],
    isInstanceFleet: values.useInstanceFleets,
    master_inst_type: values.masterInstType.value,
    core_inst_type: values.coreInstType.value,
    task_inst_type: values.taskInstType.value,
    num_core_nodes: Number(values.numCoreNodes),
    num_task_nodes: Number(values.numTaskNodes),
    managed_auto_scaling: values.isManagedScaling,
  }

  if (emrApplications && values.applications) {
    for (let app of values.applications) {
      let appCapitalized = app.charAt(0).toUpperCase() + app.slice(1)
      if (emrApplications.includes(appCapitalized)) {
        payload.applications.push(app)
      }
    }
  }

  if (values.useInstanceFleets) {
    if (values.clusterSize.value === 'custom') {
      payload.instanceFleets = formatInstanceFleets(values.instanceFleets)
    } else {
      let instanceFleets
      let fleetConfiguration

      switch (values.clusterSize.value) {
        case 'doubleExtraSmall':
          fleetConfiguration = defaultInstanceFleets.doubleExtraSmall
          instanceFleets = getInstanceFleets(fleetConfiguration)
          break
        case 'extraSmall':
          fleetConfiguration = defaultInstanceFleets.extraSmall
          instanceFleets = getInstanceFleets(fleetConfiguration)
          break
        case 'small':
          fleetConfiguration = defaultInstanceFleets.small
          instanceFleets = getInstanceFleets(fleetConfiguration)
          break
        case 'medium':
          fleetConfiguration = defaultInstanceFleets.medium
          instanceFleets = getInstanceFleets(fleetConfiguration)
          break
        case 'large':
          fleetConfiguration = defaultInstanceFleets.large
          instanceFleets = getInstanceFleets(fleetConfiguration)
          break
        case 'extraLarge':
          fleetConfiguration = defaultInstanceFleets.extraLarge
          instanceFleets = getInstanceFleets(fleetConfiguration)
          break

        case 'doubleExtraLarge':
          fleetConfiguration = defaultInstanceFleets.doubleExtraLarge
          instanceFleets = getInstanceFleets(fleetConfiguration)
          break

        default:
      }

      payload.instanceFleets = formatInstanceFleets(instanceFleets)
    }
  }

  let hardwareConfig

  switch (values.clusterSize.value) {
    case 'doubleExtraSmall':
      hardwareConfig = defaultInstances.doubleExtraSmall
      break
    case 'extraSmall':
      hardwareConfig = defaultInstances.extraSmall
      break
    case 'small':
      hardwareConfig = defaultInstances.small
      break
    case 'medium':
      hardwareConfig = defaultInstances.medium
      break
    case 'large':
      hardwareConfig = defaultInstances.large
      break
    case 'extraLarge':
      hardwareConfig = defaultInstances.extraLarge
      break

    case 'doubleExtraLarge':
      hardwareConfig = defaultInstances.doubleExtraLarge
      break

    case 'custom':
      hardwareConfig = {
        masterInstType: values.masterInstType.value,
        coreInstType: values.coreInstType.value,
        taskInstType: values.taskInstType.value,
        numCoreNodes: values.numCoreNodes,
        numTaskNodes: values.numTaskNodes,
      }
      break

    default:
  }

  payload.master_inst_type = hardwareConfig.masterInstType
  payload.core_inst_type = hardwareConfig.coreInstType
  payload.task_inst_type = hardwareConfig.taskInstType
  payload.num_core_nodes = Number(hardwareConfig.numCoreNodes)
  payload.num_task_nodes = Number(hardwareConfig.numTaskNodes)

  if (isManagedScalingAllowed && values.isManagedScaling) {
    payload.core_min = Number(values.coreMin)
    payload.core_max = Number(values.coreMax)
    payload.task_min = Number(values.taskMin)
    payload.task_max = Number(values.taskMax)
  } else {
    payload.managed_auto_scaling = false
  }

  if (isBootstrapAllowed && values.bootstrapActions.length > 0) {
    // remove the spaces from args

    const clone = cloneDeep(values.bootstrapActions)

    payload.bootstrapactions = clone.map((bootstrapAction) => {
      let args = bootstrapAction.ScriptBootstrapAction.Args

      bootstrapAction.ScriptBootstrapAction.Args = args ? args.replace(/\s/g, '').split(',') : []

      return bootstrapAction
    })
  }

  payload.tags = [{ Key: 'environment', Value: values.environment.value }, ...values.tags]

  if (values.emrConfigParams) {
    payload.emr_configurations = JSON.parse(values.emrConfigParams)
  }

  createEmrCluster(payload).then(() => {
    if (values.newTemplateName) {
      saveTemplateToLocalStorage(values)
    }

    ReactGA.event({
      category: 'EMR',
      action: `Create cluster - ${values.clusterSize.value}`,
      label: values.useInstanceFleets ? 'Instance fleets' : 'Uniform instance groups',
    })

    if (values.enableDsTools) {
      ReactGA.event({ category: 'EMR', action: 'Enable dstools' })
    }

    if (values.enableOkera) {
      ReactGA.event({ category: 'EMR', action: 'Enable okera' })
    }

    if (values.enableRanger) {
      ReactGA.event({ category: 'EMR', action: 'Enable ranger' })
    }

    if (values.enableCustomDNS) {
      ReactGA.event({ category: 'EMR', action: 'Enable custom DNS' })
    }
  })

  resetForm()

  toggleCreateEmrClusterDialog()
}

export function getInitialFieldValues({
  supportedInstanceTypeOptions,
  supportedTaskInstanceTypeOptions,
  emrVersion,
  defaultInstanceFleets,
  clusterSizeOptions,
}) {
  return {
    templateName: { label: 'none', value: 'none' },
    newTemplateName: '',
    clusterName: '',
    enableDsTools: false,
    classification: classificationOptions[0],
    applications: [],
    enableOkera: false,
    enableRanger: false,
    enableCustomDNS: false,
    emrVersion: { label: emrVersion, value: emrVersion },
    useInstanceFleets: true,
    clusterSize: clusterSizeOptions[0],
    masterInstType: supportedInstanceTypeOptions[0],
    coreInstType: supportedInstanceTypeOptions[0],
    taskInstType: supportedTaskInstanceTypeOptions[0],
    numCoreNodes: '1',
    numTaskNodes: '1',
    isManagedScaling: true,
    coreMin: '1',
    coreMax: '1',
    taskMin: '1',
    taskMax: '1',
    environment: {
      label: 'Non-Prod',
      value: 'dev',
    },
    bootstrapActions: [],
    tags: [],
    instanceFleets: defaultInstanceFleets && getInstanceFleets(defaultInstanceFleets.small),
    isEmrConf: false,
    emrConfigParams: '',
  }
}

export const validate = (values) => {
  const errors = {}

  const emrVersionNumber = values.emrVersion && Number(values.emrVersion.value.split('.')[1])

  if (emrVersionNumber < 21) {
    const isTensorflowChecked = values.applications.some((app) => app === 'tensorflow')

    if (isTensorflowChecked) {
      errors.applications = 'Tensorflow requires EMR version 5.21 or later'
    }
  }

  if (!values.clusterName) {
    errors.clusterName = 'Please enter a cluster name'
  }

  if (values.clusterName.length >= 230) {
    errors.clusterName = 'Must be less than 230 characters'
  }

  if (values.clusterName.includes('_')) {
    errors.clusterName = 'Should not include an underscore'
  }

  if (values.clusterName.includes(' ')) {
    errors.clusterName = 'Should not include a space'
  }

  if (values.emrConfigParams && !isJson(values.emrConfigParams)) {
    errors.emrConfigParams = 'Not valid JSON'
  }

  return errors
}

export const getTemplateNameOptions = () => {
  return [{ label: 'none', value: 'none' }].concat(
    getEmrClusterTemplates().map((template) => ({
      label: template.newTemplateName,
      value: template.newTemplateName,
    }))
  )
}
