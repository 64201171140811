import cloneDeep from 'lodash.clonedeep'
import ReactGA from 'react-ga'

export const jobTypeOptions = [
  { label: 'spark-scala', value: 'spark-scala' },
  { label: 'spark-python', value: 'spark-python' },
]
export const repoTypeOptions = [
  { label: 'maven', value: 'maven' },
  { label: 'pypi', value: 'pypi' },
]
export const repoNameOptions = [
  { label: 'nike-artifactory', value: 'nike-artifactory' },
  { label: 'maven-central', value: 'maven-central' },
  { label: 'pypi', value: 'pypi' },
]

export function getInitialFieldValues() {
  return {
    jobName: '',
    jobType: jobTypeOptions[0],
    jobArguments: [],
    jobEntryPoint: '',
    className: '',
    jobConf: '',
    libraries: [],
    files: [],
    env: '',
    version: '',
    hints: '',
    runtime: '',
    databricksToken: '',
  }
}

export function createSelectOptionsFromArray(arr) {
  let optionsArr = []
  let item
  for (item of arr) {
    let optionObj = {}
    optionObj['label'] = item
    optionObj['value'] = item.toLowerCase()
    optionsArr.push(optionObj)
  }
  return optionsArr
}

function createObjFromStr(confStr) {
  if (!confStr) return {}

  let keyValues = confStr.split('\n')
  let conf = {}
  var keyVal
  for (keyVal of keyValues) {
    let keyValArr = []
    keyValArr = keyVal.split(' ')
    conf[keyValArr[0]] = keyValArr[1]
  }
  return conf
}

function createStrFromArray(arr) {
  let str = ''
  let arg
  for (arg of arr) {
    str += arg + '\n'
  }
  return str.trim()
}

function createStrFromObj(obj) {
  let str = ''
  for (const [key, value] of Object.entries(obj)) {
    str += key + ' ' + value + '\n'
  }
  return str.trim()
}

export function transformPayloadToFieldValues(payload, runtime, computeRuntimes) {
  let fieldValues = {}

  fieldValues.jobName = payload.jobName ? payload.jobName : ''
  fieldValues.jobType = payload.jobType ? { label: payload.jobType, value: payload.jobType } : ''
  fieldValues.jobArguments = payload.jobArguments ? createStrFromArray(payload.jobArguments) : ''
  fieldValues.jobEntryPoint = payload.jobEntryPoint ? payload.jobEntryPoint : ''
  fieldValues.className = payload.className ? payload.className : ''
  fieldValues.jobConf = payload.jobConf ? createStrFromObj(payload.jobConf) : ''
  fieldValues.files = payload.files ? createStrFromArray(payload.files) : ''
  fieldValues.env = payload.env ? createStrFromObj(payload.env) : ''
  fieldValues.version = payload.version ? { label: payload.version, value: payload.version } : ''
  fieldValues.hints = payload.hints ? createStrFromObj(payload.hints) : ''
  fieldValues.databricksToken = payload.databricksToken ? payload.databricksToken : ''

  fieldValues.libraries = []
  if (payload.libraries) {
    for (let library of payload.libraries) {
      fieldValues.libraries.push({
        repoType: library.repoType ? { label: library.repoType, value: library.repoType } : '',
        repoName: library.repoName ? { label: library.repoName, value: library.repoName } : '',
        package: library.package ? library.package : '',
      })
    }
  }

  const runtimeOptions = createSelectOptionsFromArray(computeRuntimes)
  for (let runtimeOp of runtimeOptions) {
    if (runtime === runtimeOp.value) {
      fieldValues.runtime = runtimeOp
    }
  }

  return fieldValues
}

export function getResubmitNoChangePayload(payload) {
  payload.databricksToken = ''
  return payload
}

export const validateResubmitNoChange = (values) => {
  const errors = {}

  if (!values.databricksToken) {
    errors.databricksToken = 'Please enter your Databricks token'
  }

  return errors
}

export const validate = (values) => {
  const errors = {}

  if (values?.runtime?.value === 'databricks' && !values.databricksToken) {
    errors.databricksToken = 'Please enter your Databricks token'
  }

  return errors
}

export function buildSubmitPayload(values) {
  const payload = {
    jobName: values.jobName,
    jobType: values.jobType.value,
    jobEntryPoint: values.jobEntryPoint,
    className: values.className,
    version: values.version.value,
    databricksToken: values.databricksToken,
  }

  payload.jobArguments = values.jobArguments.length > 0 ? values.jobArguments.split('\n') : []

  if (values.files.length > 0) {
    payload.files = values.files.split('\n')
  }

  if (values.libraries.length > 0) {
    const clone = cloneDeep(values.libraries)

    if (
      !clone[clone.length - 1]['repoType'].value &&
      !clone[clone.length - 1]['repoName'].value &&
      !clone[clone.length - 1]['package']
    ) {
      clone.pop()
    }

    if (clone.length > 0) {
      payload.libraries = clone.map((library) => {
        library['repoType'] = library['repoType'].value
        library['repoName'] = library['repoName'].value

        return library
      })
    }
  }

  payload.jobConf = createObjFromStr(values.jobConf)

  if (values.env) {
    payload.env = createObjFromStr(values.env)
  }

  payload.hints = values.hints ? createObjFromStr(values.hints) : {}

  return payload
}

export function submit(values, bag, props) {
  const { createJob, toggleCreateJobDialog, selectedGroup, selectedRegion, isUserJobs } = props

  const { resetForm } = bag

  const payload = buildSubmitPayload(values)

  payload.hints['eap.compute.runtime.role'] = selectedGroup
  payload.hints['eap.compute.runtime'] = values.runtime.value
  payload.hints['eap.compute.region'] = selectedRegion

  return createJob(payload, selectedGroup, isUserJobs).then((error) => {
    if (!error) {
      ReactGA.event({
        category: 'JOBS',
        action: 'Create job',
      })
      resetForm()
      toggleCreateJobDialog()
    }
  })
}

export function resubmit(values, bag, props) {
  const { createJob, toggleJobDetailsDialog, selectedGroup, isUserJobs } = props

  const { resetForm } = bag

  const payload = buildSubmitPayload(values)

  return createJob(payload, selectedGroup, isUserJobs).then((error) => {
    if (!error) {
      ReactGA.event({
        category: 'JOBS',
        action: 'Resubmit job',
      })
      resetForm()
      toggleJobDetailsDialog()
    }
  })
}

export function resubmitNoChange(values, bag, props) {
  const { createJob, toggleResubmitJobDialog, selectedGroup, isUserJobs } = props

  const { resetForm } = bag

  createJob(values, selectedGroup, isUserJobs).then(() => {
    ReactGA.event({
      category: 'JOBS',
      action: 'Resubmit job',
    })
  })

  resetForm()
  toggleResubmitJobDialog()
}
