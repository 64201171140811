/** @jsx jsx */
import { Modal, Button } from '@nike/epic-react-ui-old'
import { Box, Flex } from 'rebass'
import isEmpty from 'lodash.isempty'
import { jsx, css } from '@emotion/core'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'

import { deleteTableJob } from 'actions/deleteTableJob'

export function DeleteJobDialog(props) {
  const { isDeleteJobDialogOpen, toggleDialog, activeJob, deleteTableJob } = props

  if (isEmpty(activeJob)) {
    return null
  }

  function closeDialog() {
    toggleDialog('DeleteJobDialog')
  }

  return (
    <Modal
      title='Are you sure you want to delete this job?'
      show={isDeleteJobDialogOpen}
      onClose={closeDialog}
    >
      <Box mt='15px'>
        <h1 style={{ textAlign: 'center' }}>{activeJob.job_name}</h1>

        <Flex justifyContent='center' mt='30px'>
          <Button
            id='cancel'
            data-external-id='cancel-delete-job'
            onClick={closeDialog}
            inverse
            css={css`
              margin-right: 10px;
            `}
          >
            Cancel
          </Button>

          <Button
            id='accept'
            data-external-id='confirm-delete-job'
            onClick={() => {
              deleteTableJob(activeJob.job_id, activeJob.job_name)
              closeDialog()
              ReactGA.event({ category: 'SELF-SERVICE', action: 'Delete table job' })
            }}
            css={css`
              margin-left: 10px;
            `}
          >
            Delete
          </Button>
        </Flex>
      </Box>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteTableJob: (jobId, jobName) => dispatch(deleteTableJob(jobId, jobName)),
  }
}

export default connect(null, mapDispatchToProps)(DeleteJobDialog)
