import { NarkPlatform } from '@nike/nark-sdk'
import { useEffect } from 'react'

let nark

export function getOktaToken() {
  try {
    const oktaToken = JSON.parse(window.localStorage.getItem('okta-token-storage'))
    return oktaToken.accessToken.accessToken
  } catch (error) {
    console.error(error)
    return ''
  }
}

function disableNarkOnLocalhost() {
  console.log('[Nark is currently disabled on localhost:3002]')
  return window.location.origin.includes('localhost:3002') || process.env.NODE_ENV === 'test'
}

export function narkConfig() {
  if (
    window.location.origin.includes('one.cx-platform-prod.nikecloud.com') ||
    window.location.origin.includes('https://console.platforms.nike.com/ngap/')
  )
    return {
      env: 'prod',
      url: 'https://api.nark.nikecloud.com/v1',
      platformId: '==fCI7X9bKw32fITUq0zSkW',
      techSolution: 'advanced-compute',
    }
  return {
    env: 'prod', // using prod tokens for nonprod
    url: 'https://api.nark.nikecloud.com/v1',
    platformId: '==kfFDWbeGHyonf473nSFI8',
    techSolution: 'advanced-compute-preprod',
  }
}

/**
 *
 * @param {string} source - Web application should use `ui`
 * @param {string} feature - The what you want to document
 * @param {string} operation - Dot separated operations e.g. page.load
 * @returns void
 */
export function NarkLog({ source = 'ui', feature, operation }) {
  if (disableNarkOnLocalhost()) return // *NOTE: if you want to test Nark in localhost comment this line

  const token = getOktaToken()
  const platform = { ...narkConfig(), token }

  if (!token) return
  if (!nark) {
    nark = new NarkPlatform(platform)
  }

  const payload = {
    operation,
    source,
    feature,
  }
  nark.log(payload)
}

export function useNarkOnPageLoad(pageName) {
  useEffect(() => {
    const payload = {
      source: 'ui',
      feature: `${pageName}-page`,
      operation: 'page.load',
    }
    NarkLog(payload)
  }, [pageName])
}
