import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { css } from '@emotion/core'
import Menu from '@material-ui/core-new/Menu'
import MenuItem from '@material-ui/core-new/MenuItem'
import { Formik, Form } from 'formik'
import isEmpty from 'lodash.isempty'
import { create } from 'axios'
import { toast } from 'react-toastify'
import { fromJS } from 'immutable'
import { Box, Flex } from 'rebass'
import { PropTypes } from 'prop-types'
import ReactGA from 'react-ga'
import { Button, IconButton, Modal, TextInput, Spinner } from '@nike/epic-react-ui-old'

import ToJS from 'components/common/to-js'

import { selectIsSSHDisabled } from 'selectors/selectIsSSHDisabled'

import { account, subDomain } from 'utils/constant'

const getListOfApplications = (applications = [], emrClusterName) => {
  if (isEmpty(applications)) {
    return []
  }

  return Object.keys(applications)
    .map((appName) => appName.toLowerCase())
    .filter(
      (appName) =>
        !(
          appName === 'master' ||
          appName === 'spark_hive_site' ||
          appName.startsWith('shs_') ||
          appName.startsWith('nss') ||
          (appName === 'shell' && emrClusterName.includes('dstools')) ||
          (appName.toLowerCase() === 'hue' && emrClusterName.includes('dstools'))
        )
    )
}

const getDomain = (activeCluster, selectedRegion) => {
  // NOTE: Apparently some are hardcoded, but we should get them from the activeCluster
  if (selectedRegion === 'us-east-1') {
    if (account === 'streams-test') {
      return 'e1.ngap2dev.nike.com'
    } else {
      return 'e1.ngap2.nike.com'
    }
  } else if (selectedRegion === 'us-west-2') {
    if (account === 'streams-test') {
      return 'w2.ngap2dev.nike.com'
    } else {
      return 'w2.ngap2.nike.com'
    }
  } else if (selectedRegion === 'eu-west-1') {
    if (account === 'streams-test') {
      return 'euw1.ngap2dev.nike.com'
    } else {
      return 'euw1.ngap2.nike.com'
    }
  }
  const {
    applications: { MASTER, namenode },
  } = activeCluster

  const masterUrl = new URL(MASTER || namenode)
  return masterUrl.hostname.split('.').slice(1).join('.')
}

function ApplicationsMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [modalSHS, setModalSHS] = useState(false)
  const [urlSHS, setUrlSHS] = useState()
  const [modalApplicationId, setModalApplicationId] = useState(false)
  const [urlWithApplicationId, setUrlWithApplicationId] = useState()
  const [appIdResPending, setAppIdResPending] = useState(false)

  const {
    activeTab,
    activeCluster,
    SSHDisabled,
    selectedRegion,
    applicationLinks,
    applicationLinkGAInfo,
  } = props // activeCluster is passed as regular object.

  function onCheck(event) {
    setAnchorEl(event.currentTarget)
  }

  let domain = getDomain(activeCluster, selectedRegion)

  function handleAppNameClick(event, appName) {
    const { activeCluster, username, authToken } = props

    if (appName in applicationLinkGAInfo) {
      ReactGA.event({
        category: applicationLinkGAInfo[appName].category,
        action: applicationLinkGAInfo[appName].action.replace(
          '<CLUSTER_STATUS>',
          activeCluster.cluster_status
        ),
      })
    }

    if (appName === 'hue' || appName === 'zeppelin') {
      create()
        .post(
          `https://ip-${activeCluster.master_ip.replace(/[.-]/g, '-')}.${domain}:8086/user_add`,
          `user_id=${username.toLowerCase()}&jwt_token="${authToken}"`,
          {
            transformRequest: [
              (data, headers) => {
                delete headers.common.Authorization
                return data
              },
            ],
          }
        )
        .catch(() =>
          toast.error('An error occured. Please contact platform team.', {
            autoClose: false,
            position: 'top-left',
          })
        )
    }

    handleClose()
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const handleSshClick = (activeCluster) => {
    const { toggleSshDialog } = props

    handleClose()

    toggleSshDialog(fromJS(activeCluster))
  }

  async function handleSparkHistoryClick() {
    const { activeCluster, authToken } = props
    if (!activeCluster.applications.shs_api) {
      toast.error('Missing shs_api', {
        autoClose: false,
        position: 'top-left',
      })
      return
    }

    handleClose()
    setUrlSHS(null)
    setModalSHS(true)

    const headers = {
      Authorization: 'Bearer ' + authToken,
      'Content-Type': 'application/json',
    }
    const body = JSON.stringify({
      group: activeCluster.owned_by,
      clusterID: activeCluster.cluster_id,
      bucketPath: activeCluster.applications.shs_s3,
    })

    const api_res = await fetch(activeCluster.applications.shs_api, {
      method: 'POST',
      headers,
      body,
    })

    if (api_res.status !== 200) {
      toast.error('Spark History Server is not available', {
        autoClose: false,
        position: 'top-left',
      })

      setModalSHS(false)

      return
    }
    const spark_history = JSON.parse(await api_res.text())
    setUrlSHS(spark_history.url)

    if ('spark' in applicationLinkGAInfo) {
      ReactGA.event({
        category: applicationLinkGAInfo['spark'].category,
        action: applicationLinkGAInfo['spark'].action.replace(
          '<CLUSTER_STATUS>',
          activeCluster.cluster_status
        ),
      })
    }
  }

  function validateApplicationId(values) {
    const errors = {}

    if (!values.applicationId) {
      errors.applicationId = 'Please enter an Application ID i.e. <application_1611569771952_0001>'
    } else if (!values.applicationId.includes('application_')) {
      errors.applicationId = "Application ID must begin with 'application_'"
    }

    return errors
  }

  async function handleApplicationId(values, bag) {
    const { resetForm } = bag
    setAppIdResPending(true)
    const newUrl = urlWithApplicationId.replace('<APP_ID>', values.applicationId)
    fetch(newUrl, { method: 'POST' })
      .then((response) => response.text())
      .then((res) => {
        if (res.includes('nil')) {
          toast.error('Unable to kill Yarn app. Please verify your Application ID.', {
            autoClose: false,
            position: 'top-left',
          })
          setAppIdResPending(false)
          return
        }
        toast.success(`Kill Yarn App Success: ${res}`, {
          autoClose: false,
          position: 'top-left',
        })
        setModalApplicationId(false)
        setAppIdResPending(false)
        resetForm()
        return
      })
      .catch((error) => {
        toast.error('Unable to kill Yarn app', {
          autoClose: false,
          position: 'top-left',
        })
        setAppIdResPending(false)
        return
      })
  }

  const terminated =
    activeCluster.cluster_status === 'TERMINATED' || activeCluster.cluster_status === 'FAILED'
  const menuItems = getListOfApplications(activeCluster.applications, activeCluster.cluster_name)
    .filter((appName) => !terminated) // || ['spark', 'berloit'].includes(appName))
    .map((appName, index) => (
      <MenuItem
        key={index}
        data-e2e={`${appName}-menuitem`}
        component='a'
        onClick={(event) => handleAppNameClick(event, appName)}
        href={activeCluster.applications[appName]}
        target='_blank'
      >
        {appName.charAt(0).toUpperCase() + appName.slice(1)}
      </MenuItem>
    ))

  const EMR_VERSIONS = ['emr-5.7.0', 'emr-5.9.0', 'emr-5.11.0']
  if (
    activeCluster.cluster_status === 'TERMINATED' &&
    !EMR_VERSIONS.includes(activeCluster.version)
  )
    menuItems.push(
      <MenuItem
        key='spark-history'
        data-e2e='spark-history-menuitem'
        onClick={handleSparkHistoryClick}
      >
        Spark
      </MenuItem>
    )

  if (activeTab === 'emr') {
    if (applicationLinks) {
      const env = subDomain === 'localhost' ? 'one-dev' : subDomain
      applicationLinks.forEach((app) => {
        const clusterStatus =
          activeCluster.cluster_status === 'READY'
            ? 'active'
            : activeCluster.cluster_status.toLowerCase()
        if (app.status.includes(clusterStatus)) {
          const key = app.name.toLowerCase().replaceAll(' ', '-')
          if (!app.url[env].includes('<')) {
            menuItems.push(
              <MenuItem
                key={key}
                data-e2e={`${key}-menuitem`}
                onClick={() => {
                  handleClose()
                  if (key === 'emr-log') {
                    props.toggleEmrLog(activeCluster, app.url[env])
                  } else if (key === 'genie-log') {
                    props.toggleGenieLog(activeCluster, app.url[env])
                  }
                  ReactGA.event({
                    category: app['google-analytics']['category'],
                    action: app['google-analytics']['action'].replace(
                      '<CLUSTER_STATUS>',
                      activeCluster.cluster_status
                    ),
                  })
                }}
              >
                {app.name}
              </MenuItem>
            )
          } else {
            const url = app.url[env]
              .replaceAll('<CLUSTER_ID>', activeCluster.cluster_id)
              .replaceAll('<MASTER_IP>', activeCluster.master_ip)
            if (!url.includes('<APP_ID>')) {
              menuItems.push(
                <MenuItem
                  key={key}
                  data-e2e={`${key}-menuitem`}
                  component='a'
                  href={url}
                  onClick={() => {
                    ReactGA.event({
                      category: app['google-analytics']['category'],
                      action: app['google-analytics']['action'].replace(
                        '<CLUSTER_STATUS>',
                        activeCluster.cluster_status
                      ),
                    })
                  }}
                  target='_blank'
                >
                  {app.name}
                </MenuItem>
              )
            } else {
              menuItems.push(
                <MenuItem
                  key={key}
                  data-e2e={`${key}-menuitem`}
                  component='a'
                  onClick={() => {
                    setModalApplicationId(true)
                    setUrlWithApplicationId(url)
                  }}
                  target='_blank'
                >
                  {app.name}
                </MenuItem>
              )
            }
          }
        }
      })
    }

    // NOTE: removed !selectedRegion.startsWith('cn-) to allow CH
    if (!terminated && !SSHDisabled) {
      menuItems.push(
        <MenuItem
          key='ssh'
          data-e2e='ssh-menuitem'
          onClick={() => {
            handleSshClick(activeCluster)
            if ('ssh-emr' in applicationLinkGAInfo) {
              ReactGA.event({
                category: applicationLinkGAInfo['ssh-emr'].category,
                action: applicationLinkGAInfo['ssh-emr'].action.replace(
                  '<CLUSTER_STATUS>',
                  activeCluster.cluster_status
                ),
              })
            }
          }}
        >
          SSH
        </MenuItem>
      )
    }
  } else {
    // activeTab === 'airflow'
    menuItems.push(
      <MenuItem
        key='airflow'
        data-e2e='airflow-menuitem'
        onClick={() => {
          handleSshClick(activeCluster)
        }}
      >
        SSH
      </MenuItem>
    )
  }

  return (
    <Box>
      <IconButton
        data-e2e='application-button'
        type='chevron-down'
        iconFontSize='16px'
        bare
        onClick={onCheck}
        style={{ width: '100%' }}
      />

      <Menu
        data-e2e='applications-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems}
      </Menu>

      <Modal
        title='Spark History Server'
        show={modalSHS}
        onClose={() => {
          setModalSHS(false)
        }}
      >
        <br />
        {urlSHS ? (
          <a href={urlSHS} target='_blank' rel='noopener noreferrer'>
            <Button data-e2e='shs-open-button' data-external-id='open-in-a-new-tab'>
              Open in a new tab
            </Button>
          </a>
        ) : (
          <div>
            Starting server, please wait
            <br />
            (may take up to one minute)
            <br />
            <Spinner />
          </div>
        )}
      </Modal>

      <Formik
        initialValues={{ applicationId: '' }}
        validate={validateApplicationId}
        onSubmit={(values, bag) => handleApplicationId(values, bag)}
      >
        {(formikProps) => {
          const { handleChange, handleSubmit, values, errors } = formikProps

          return (
            <Modal
              title='Please enter your Application ID'
              show={modalApplicationId}
              onClose={() => setModalApplicationId(false)}
              css={css`
                z-index: 500;
              `}
            >
              <Form>
                <Box>
                  <Box style={{ position: 'relative' }}>
                    <TextInput
                      data-e2e='application-id-textinput'
                      name='applicationId'
                      label='Application ID'
                      onChange={handleChange}
                      value={values.applicationId}
                      required
                      hasErrors={errors.applicationId}
                      errorMessage={errors.applicationId}
                    />
                  </Box>
                </Box>
              </Form>
              <Flex justifyContent='center' mt='30px'>
                {appIdResPending && <Spinner />}
                {!appIdResPending && (
                  <Button
                    type='submit'
                    data-e2e='submit-application-id-button'
                    data-external-id='submit-application'
                    id='submit-application-id-button'
                    onClick={handleSubmit}
                    style={{ marginLeft: '5px' }}
                  >
                    Submit
                  </Button>
                )}
              </Flex>
            </Modal>
          )
        }}
      </Formik>
    </Box>
  )
}

ApplicationsMenu.defaultProps = {
  activeTab: '',
  activeCluster: {},
  selectedRegion: '',
}

ApplicationsMenu.propTypes = {
  activeTab: PropTypes.string,
  activeCluster: PropTypes.object,
  SSHDisabled: PropTypes.bool,
}

const makeMapStateToProps = () => {
  const selectIsSSHDisabledInstance = selectIsSSHDisabled()
  const mapStateToProps = (state, props) => {
    return {
      SSHDisabled: selectIsSSHDisabledInstance(state, props),
    }
  }
  return mapStateToProps
}

const enhanced = compose(connect(makeMapStateToProps), ToJS)(ApplicationsMenu)

export default enhanced
