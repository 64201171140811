import { List } from 'immutable'

import { createSelector } from 'reselect'

import { selectGroupConfig } from 'selectors/selectGroupConfig'
import { selectSelectedAccount } from './selectSelectedAccount'
import { selectSelectedRegion } from './selectSelectedRegion'
import { selectSelectedRuntime } from './selectSelectedRuntime'

export const selectGlobalTabs = createSelector(
  [selectGroupConfig, selectSelectedAccount, selectSelectedRegion, selectSelectedRuntime],
  (groupConfig, selectedAccount, selectedRegion, selectedRuntime) => {
    const globalTabs = groupConfig.getIn([
      'group_services',
      selectedAccount,
      selectedRegion,
      selectedRuntime,
    ])

    return globalTabs || new List()
  }
)
