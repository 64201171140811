import { createSelector } from 'reselect'

import { selectEmrConfig } from 'selectors/selectEmrConfig'

const getActiveCluster = (state, props) => props.activeCluster

export const selectIsSSHDisabled = () => {
  return createSelector([selectEmrConfig, getActiveCluster], (emrConfig, activeCluster) => {
    if (activeCluster && activeCluster.hasOwnProperty('version')) {
      const emrVersionFormatted = activeCluster.version.replace(/\.|-/g, '')
      const disableSSH = (emrConfig.get(emrVersionFormatted) || new Map()).get('disable_ssh')
      return disableSSH === 'yes'
    } else {
      return false
    }
  })
}
