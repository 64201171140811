import { createSelector } from 'reselect'

import { selectDefaultAccount } from './selectDefaultAccount'

const selectedAccount = (state) => state.get('selectedAccount')

export const selectSelectedAccount = createSelector(
  [selectedAccount, selectDefaultAccount],
  (selectedAccount, defaultAccount) => selectedAccount || defaultAccount
)
