import { toast } from 'react-toastify'
import { fromJS } from 'immutable'
import { create } from 'axios'

import { DELETE_JOB_REQUEST, DELETE_JOB_SUCCESS, DELETE_JOB_FAILURE } from 'utils/constant'

import { selectJobsEndpoint } from 'selectors/selectJobsEndpoint'

function deleteJobRequest() {
  return {
    type: DELETE_JOB_REQUEST,
  }
}

function deleteJobSuccess(selectedJob) {
  return {
    type: DELETE_JOB_SUCCESS,
    selectedJob,
  }
}

function deleteJobFailure() {
  return {
    type: DELETE_JOB_FAILURE,
  }
}

export async function deleteJob(jobId) {
  return function (dispatch, getState) {
    dispatch(deleteJobRequest())

    const endpoint = selectJobsEndpoint(getState())

    return create()
      .delete(`${endpoint}/v2/${jobId}`)
      .then((response) => {
        // This API returns status 200 even if the job failed to delete!
        if (typeof response.data === 'string' && response.data.includes('cannot be stopped')) {
          dispatch(deleteJobFailure())
          toast.error(response.data, { autoClose: false, closeOnClick: false })
        } else {
          dispatch(deleteJobSuccess(fromJS(response.data)))
          toast.success(response.data, { autoClose: false, closeOnClick: false })
        }
      })
      .catch((error) => {
        dispatch(deleteJobFailure())

        let errorMessage
        const responseData = error.response?.data
        if (responseData.detail) {
          errorMessage = `An error occurred: ${error.response.status} ${responseData.detail}`
        } else {
          errorMessage = `Please contact platform team. An unknown error occurred: ${
            error.response.status
          } ${JSON.stringify(responseData)}`
        }

        toast.error(errorMessage, { autoClose: false, closeOnClick: false })
      })
  }
}
