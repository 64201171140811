import { environment } from 'utils/constant'

export const getSelfServiceEndpoint = () => {
  // let selfServiceEndpoint =
  //   'https://zdsrw8s25h.execute-api.us-east-1.amazonaws.com/dev/analytics_platform';

  let selfServiceEndpoint =
    'https://nsqwnbt0d1.execute-api.us-east-1.amazonaws.com/stg/analytics_platform'

  if (environment === 'staging') {
    selfServiceEndpoint =
      'https://nsqwnbt0d1.execute-api.us-east-1.amazonaws.com/stg/analytics_platform'
  } else if (environment === 'production') {
    selfServiceEndpoint =
      'https://627k54nhmd.execute-api.us-east-1.amazonaws.com/prod/analytics_platform'
  }

  return selfServiceEndpoint
}
