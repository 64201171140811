export const isLongRunningCluster = (duration) => {
  if (duration.includes('day') || duration.includes('month') || duration.includes('year')) {
    return true
  } else if (duration.includes('hours')) {
    if (parseInt(duration.replace(' hours ago', ''), 10) >= 12) {
      return true
    }
  } else {
    return false
  }
}
