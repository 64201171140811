import { createSelector } from 'reselect'
import { Map } from 'immutable'

import { selectAccounts } from './selectAccounts'
import { selectGroupConfig } from './selectGroupConfig'

export const selectAccountOptions = createSelector(
  [selectAccounts, selectGroupConfig],
  (accounts, groupConfig) =>
    accounts.map(
      (account) =>
        new Map({
          label: groupConfig.getIn(['accounts', account]),
          value: account,
        })
    )
)
