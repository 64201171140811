import React, { useEffect, useState } from 'react'
import { Box } from 'rebass'
import { subDomain } from 'utils/constant'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import ReactGA from 'react-ga'
import { Select } from '@nike/epic-react-ui-old'

import ToJS from 'components/common/to-js'
import Iframe from 'components/common/iframe'

import { fetchAirflowOneConfig } from 'actions/fetchAirflowOneConfig'

import { selectAirflowOneConfig } from 'selectors/selectAirflowOneConfig'

import useMetaConfig from 'hooks/useMetaConfig'
import PageTitle from '../../common/page-title'

export function AirflowOnePage(props) {
  const { fetchAirflowOneConfig, selectedGroup, selectedRegion, airflowOneConfig } = props

  const [airflowOneEnvironment, setAirflowOneEnvironment] = useState({
    label: 'Dev',
    value: 'dev',
  })

  useEffect(() => {
    ReactGA.pageview('/af1')
  }, [])

  useMetaConfig({
    configFetcher: fetchAirflowOneConfig,
    callback: null,
    selectedGroup,
    selectedRegion,
  })

  let endpoint = ''

  if (airflowOneConfig.endpoints && airflowOneConfig.endpoints[subDomain]) {
    endpoint = airflowOneConfig.endpoints[subDomain][airflowOneEnvironment.value]
  } else if (airflowOneConfig.endpoints) {
    /* TODO: refactor duplicate logic in fetchAirflowOneConfig */
    toast.error('Configuration not found for AirflowOne in this region', {
      autoClose: false,
      position: 'top-left',
    })
  }

  function updateAirflowOneEnvironment(value) {
    setAirflowOneEnvironment(value)

    ReactGA.event({
      category: 'AirflowOne',
      action: 'Change environment',
      label: value.label,
    })
  }

  const pageTitleConfig = { title: 'AirflowOne' }

  return endpoint ? (
    <Box style={{ borderTop: '2px solid #ffffff', position: 'relative' }}>
      <PageTitle {...pageTitleConfig} />
      <Box width='150px' style={{ position: 'absolute', top: '5px', right: '10px' }}>
        <Select
          options={[
            { label: 'Dev', value: 'dev' },
            { label: 'QA', value: 'qa' },
            { label: 'Prod', value: 'prod' },
          ]}
          onChange={updateAirflowOneEnvironment}
          value={airflowOneEnvironment}
          placeholder='Environment'
        />
      </Box>
      <Iframe title='AirflowOne' src={endpoint} />
    </Box>
  ) : null
}

const mapStateToProps = (state) => {
  return {
    airflowOneConfig: selectAirflowOneConfig(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAirflowOneConfig: () => dispatch(fetchAirflowOneConfig()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToJS(AirflowOnePage))
