export const getApiBaseUrl = (subDomain, domain) => {
  let postfix = ''

  //   if (subDomain === 'localhost') {
  //     postfix = '-dev'
  //   } else {
  postfix = subDomain.indexOf('-') <= 0 ? '' : `${subDomain.slice(subDomain.indexOf('-'))}`

  //   }

  return `https://platform-internal${postfix}.w2${domain}`
}
