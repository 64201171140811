/** @jsx jsx */
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'
import { css, jsx } from '@emotion/core'
import { Modal, Button, TextInput, Select } from '@nike/epic-react-ui-old'
import { Flex } from 'rebass'

import ToJS from 'components/common/to-js'

import { selectSupportedTaskInstanceTypeOptions } from 'selectors/selectSupportedTaskInstanceTypeOptions'
import { selectSupportedEmrVersionOptions } from 'selectors/selectSupportedEmrVersionOptions'
import { selectEmrVersion } from 'selectors/selectEmrVersion'
import { selectSelectedGroup } from 'selectors/selectSelectedGroup'

import { setEmrVersion } from 'actions/setEmrVersion'

const validate = (values) => {
  const errors = {}

  const numCoreNodes = Number(values.numCoreNodes)

  const numTaskNodes = Number(values.numTaskNodes)

  if (numCoreNodes > 20) {
    errors.numCoreNodes = 'Must be less than 20'
  }

  if (numCoreNodes <= 0) {
    errors.numCoreNodes = 'Must be greater than 0'
  }

  if (numTaskNodes > 20) {
    errors.numTaskNodes = 'Must be less than 20'
  }

  if (numTaskNodes < 0) {
    errors.numTaskNodes = 'Must be greater than or equal to 0'
  }

  if (numCoreNodes + numTaskNodes > 20) {
    errors.totalNodes = 'Must be 20 or fewer nodes in total'
  }

  return errors
}

export function ResizeEmrClusterDialog(props) {
  const {
    isOpen,
    toggleDialog,
    activeCluster,
    supportedTaskInstanceTypeOptions,
    supportedEmrVersionOptions,
    emrVersion,
  } = props

  if (isEmpty(activeCluster)) {
    return null
  }

  function onSubmit(values) {
    const { numCoreNodes, numTaskNodes, taskInstType, emrVersion } = values
    const { resizeEmrCluster, group } = props

    const payload = {
      cluster_name: activeCluster.cluster_name,
      num_core_nodes: Number(numCoreNodes),
      num_task_nodes: Number(numTaskNodes),
      emr_version: `emr-${emrVersion.value}`,
      task_inst_type: taskInstType.value,
      group: group,
    }

    resizeEmrCluster(activeCluster.cluster_name, activeCluster.cluster_id, payload)

    toggleDialog()
  }

  const formInitialValues = {
    numCoreNodes: String(activeCluster.num_core_nodes),
    numTaskNodes: String(activeCluster.num_task_nodes),
    taskInstType: supportedTaskInstanceTypeOptions[0],
    emrVersion: { label: emrVersion, value: emrVersion },
  }

  return (
    <Formik initialValues={formInitialValues} onSubmit={onSubmit} validate={validate}>
      {(formikProps) => {
        const { values, handleChange, setFieldValue, handleBlur, errors, handleSubmit } =
          formikProps

        return (
          <Modal title={activeCluster.cluster_name} onClose={toggleDialog} show={isOpen}>
            <TextInput
              data-e2e='num-core-nodes-textinput'
              name='numCoreNodes'
              value={values.numCoreNodes}
              onChange={handleChange}
              onBlur={handleBlur}
              type='number'
              label='Number of core nodes'
              hasErrors={errors.numCoreNodes}
              errorMessage={errors.numCoreNodes}
            />

            <TextInput
              data-e2e='num-task-nodes-textinput'
              name='numTaskNodes'
              value={values.numTaskNodes}
              onChange={handleChange}
              onBlur={handleBlur}
              type='number'
              label='Number of task nodes'
              hasErrors={errors.numTaskNodes}
              errorMessage={errors.numTaskNodes}
            />

            <Select
              id='task-inst-type-select'
              name='taskInstType'
              label='Task instance type'
              placeholder='Task instance type'
              options={supportedTaskInstanceTypeOptions}
              value={values.taskInstType}
              onChange={(option) => setFieldValue('taskInstType', option)}
              isDisabled={!(activeCluster.task_inst_type === null)}
            />

            <Select
              id='emr-version-select'
              name='emrVersion'
              label='EMR version'
              placeholder='EMR version'
              options={supportedEmrVersionOptions}
              value={values.emrVersion}
              onChange={(option) => {
                setEmrVersion(option.value)
                setFieldValue('emrVersion', option)
              }}
              onBlur={handleBlur}
            />

            {errors.totalNodes && (
              <p
                css={css`
                  color: red;
                  text-align: center;
                  margin-top: 15px;
                `}
              >
                Must be less than 20 nodes in total
              </p>
            )}

            <Flex justifyContent='center' mt='30px'>
              <Button
                data-e2e='resize-button'
                data-external-id='resize-emr-cluster'
                label='Resize'
                onClick={handleSubmit}
                disabled={!isEmpty(errors)}
              >
                Resize
              </Button>
            </Flex>
          </Modal>
        )
      }}
    </Formik>
  )
}

ResizeEmrClusterDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  activeCluster: PropTypes.object,
  supportedTaskInstanceTypeOptions: PropTypes.array,
  supportedEmrVersionOptions: PropTypes.array,
}

const mapStateToProps = (state) => {
  return {
    supportedTaskInstanceTypeOptions: selectSupportedTaskInstanceTypeOptions(state),
    supportedEmrVersionOptions: selectSupportedEmrVersionOptions(state),
    emrVersion: selectEmrVersion(state),
    group: selectSelectedGroup(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setEmrVersion: (emrVersion) => dispatch(setEmrVersion(emrVersion)),
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), ToJS)(ResizeEmrClusterDialog)
