import { fromJS } from 'immutable'
import { create } from 'axios'

import { selectSelectedGroup } from 'selectors/selectSelectedGroup'

import {
  META_ENDPOINT,
  FETCH_GROUP_CONFIG_REQUEST,
  FETCH_GROUP_CONFIG_SUCCESS,
  FETCH_GROUP_CONFIG_FAILURE,
} from 'utils/constant'

function fetchGroupConfigRequest() {
  return {
    type: FETCH_GROUP_CONFIG_REQUEST,
  }
}

function fetchGroupConfigSuccess(groupConfig) {
  return {
    type: FETCH_GROUP_CONFIG_SUCCESS,
    groupConfig,
  }
}

function fetchGroupConfigFailure() {
  return {
    type: FETCH_GROUP_CONFIG_FAILURE,
  }
}

export async function fetchGroupConfig() {
  return function (dispatch, getState) {
    dispatch(fetchGroupConfigRequest())

    const selectedGroup = selectSelectedGroup(getState())

    return create()
      .get(`${META_ENDPOINT}/group/v1?ngap_group=${selectedGroup}`, {
        transformRequest: [
          (data, headers) => {
            delete headers.common.Authorization
            return data
          },
        ],
      })
      .then((response) => {
        dispatch(fetchGroupConfigSuccess(fromJS(response.data)))

        return response.data
      })
      .catch((error) => {
        dispatch(fetchGroupConfigFailure(error))
      })
  }
}
