import React from 'react'
import { Security, LoginCallback } from '@okta/okta-react'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import promise from 'redux-promise'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { NikeThemeProvider } from '@nike/nr-sole-xl-theme-provider'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { composeWithDevTools } from 'redux-devtools-extension'

import '@nike/epic-react-ui/dist/styles.css'
import './App.css'

import AuthWrapper from 'components/global/auth-wrapper/auth-wrapper'
import RequestAccessPage from 'components/global/request-access-page'

import reducers from './reducers'

import { oktaConfig } from 'utils/constant'

const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunkMiddleware, promise)))

/* Save the selected group/account/region/runtime to local storage, and use again when the page refreshes */

store.subscribe(() => {
  const selectedGroup = store.getState().get('selectedGroup')
  const selectedAccount = store.getState().get('selectedAccount')
  const selectedRegion = store.getState().get('selectedRegion')
  const selectedRuntime = store.getState().get('selectedRuntime')

  if (selectedGroup) {
    localStorage.setItem('selectedGroup', selectedGroup)
  }

  if (selectedAccount) {
    localStorage.setItem('selectedAccount', selectedAccount)
  }

  if (selectedRegion) {
    localStorage.setItem('selectedRegion', selectedRegion)
  }

  if (selectedRuntime) {
    localStorage.setItem('selectedRuntime', selectedRuntime)
  }
})

const basename = window.location.href.includes('platforms.nike.com/ngap') ? '/ngap' : '/'

const App = () => (
  <Provider store={store}>
    <NikeThemeProvider>
      <ToastContainer />
      <Router basename={basename}>
        <Security {...oktaConfig}>
          <Switch>
            <Route path='/implicit/callback' component={LoginCallback} />
            <Route path='/request-access' component={RequestAccessPage} />
            <Route path='/' component={AuthWrapper} />
          </Switch>
        </Security>
      </Router>
    </NikeThemeProvider>
  </Provider>
)

export default App
