import React, { useState } from 'react'
import { Box } from 'rebass'

import { PageSpinner } from '@nike/epic-react-ui-old'
import Iframe from 'components/common/iframe'

export default function QueryEditor(props) {
  const { isSecure } = props

  const [isIframeLoading, setIsIframeLoading] = useState(true)

  function handleIframeOnLoad() {
    setIsIframeLoading(false)
  }

  const frm_src = isSecure
    ? 'https://shared-query-engine-e1.ngap2.nike.com/hue/editor/?type=presto'
    : 'https://selfservice-hue-prod2.e1.ngap2.nike.com/hue/editor/?type=athena'

  return (
    <div>
      {isSecure && (
        <h2
          style={{
            background: 'red',
            color: 'white',
            fontSize: 18,
            textAlign: 'center',
          }}
        >
          Please note, you are using an Ad Hoc query engine.
        </h2>
      )}
      <Box>
        {isIframeLoading && <PageSpinner show overlay={false} />}

        <Iframe
          title='Query Access'
          sandbox='allow-forms allow-scripts allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin'
          src={frm_src}
          onLoad={handleIframeOnLoad}
        />
      </Box>
    </div>
  )
}
