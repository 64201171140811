import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { create } from 'axios'
import { toast } from 'react-toastify'

import InferredColumns from 'components/self-service/inferred-columns'
import ToJS from 'components/common/to-js'

import { SELF_SERVICE_ENDPOINT } from 'utils/constant'
import { getAlertConfig } from 'utils/getAlertConfig'
import { setCookie, getCookie, deleteCookie } from 'utils/cookie'

import { useInterval } from 'hooks/useInterval'

const getJobIdPayload = (values, selectedGroup) => {
  const {
    sourceLocation,
    tableName,
    dbName,
    tableDataLocation,
    jobName,
    scheduleType,
    cadence,
    scheduleStart,
    scheduleEnd,
    schemaEvolution,
    sendAlert,
    onSuccess,
    onFailure,
    email,
  } = values

  const frequencyConfig = {
    frequency_type: scheduleType.value,
  }

  if (scheduleType.value === 'scheduled') {
    frequencyConfig.schedule = cadence.value

    frequencyConfig.start_time = scheduleStart

    frequencyConfig.end_time = scheduleEnd
  }

  return {
    job_name: jobName,
    file_format: 'json',
    source_location: sourceLocation,
    table_data_location: tableDataLocation,
    table_name: tableName,
    db_name: dbName,
    group: selectedGroup,
    frequency: frequencyConfig,
    send_alert: getAlertConfig(sendAlert, onSuccess, onFailure, email),
    schema_fetch_type: 'schema_inference',
    schema_evolution_option: schemaEvolution.value,
  }
}

async function handleFetchJobId(args) {
  const { values, setSavedJobId, selectedGroup } = args
  if (getCookie('cloneJobId')) {
    setCookie('cloningWIP', 1)
  }

  return create()
    .post(`${SELF_SERVICE_ENDPOINT}/orchestrate_job/v2`, getJobIdPayload(values, selectedGroup))
    .then((response) => {
      const id = response.data.job_id

      setSavedJobId(id)

      if (getCookie('cloneJobId')) {
        setCookie('tableJobId', id)
        deleteCookie('cloningWIP')
      }

      return id
    })
    .catch((error) => {
      const defaultMessage = 'Error. Please contact platform team.'

      const errorMessage =
        error.response.status !== 500
          ? (error.response && error.response.data && error.response.data.message) || defaultMessage
          : defaultMessage

      toast.error(errorMessage, { autoClose: false, closeOnClick: false, position: 'top-left' })
    })
}

async function handleFetchInferredColumns(args) {
  const { setIsFetchingInferredColumns, setFieldValue, savedJobId, setSourceLocationWasEdited } =
    args

  setIsFetchingInferredColumns(true)

  const jobId =
    getCookie('tableJobId') ||
    getCookie('cloneJobId') ||
    savedJobId ||
    (await handleFetchJobId(args))

  create()
    .get(`${SELF_SERVICE_ENDPOINT}/jobs/v1/${jobId}`)
    .then((response) => {
      const schema = response.data

      const status = schema.status

      if (status === 'FAILED') {
        throw new Error()
      } else if (status === 'SCHEMA_INFERRED') {
        const inferredColumns = schema && schema.inferred_columns ? schema.inferred_columns : []

        const inferredPartitionColumns =
          schema && schema.inferred_partition_columns ? schema.inferred_partition_columns : []
        const inferredFileStructure = schema.file_format

        setFieldValue('inferredColumns', inferredColumns)
        setFieldValue('inferredPartitionColumns', inferredPartitionColumns)
        setFieldValue('inferredFileStructure', inferredFileStructure)

        setIsFetchingInferredColumns(false)
        setSourceLocationWasEdited(false) // reset flag
      }

      return schema
    })
    .catch((error) => {
      toast.error(
        <div style={{ wordBreak: 'break-word', overflow: 'scroll' }}>
          <a
            href='https://app.slack.com/client/T0G3T5X2B/CKWQES3EF'
            target='_blank'
            rel='noopener noreferrer'
            style={{ color: '#ffffff' }}
          >
            {(error && error.response && error.response.data.error_message) ||
              'Unable to infer columns. Please contact Platform Team.'}
          </a>
        </div>,
        {
          autoClose: false,
          closeOnClick: false,
        }
      )

      setFieldValue('inferredColumns', [])
      setFieldValue('inferredPartitionColumns', [])

      setIsFetchingInferredColumns(false)
      setSourceLocationWasEdited(false) // reset flag
    })
}

function DefineTableFields({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  setSavedJobId,
  savedJobId,
  selectedGroup,
  sourceLocationWasEdited,
  setSourceLocationWasEdited,
  isFetchingInferredColumns,
  setIsFetchingInferredColumns,
}) {
  const args = {
    values,
    setFieldValue,
    setIsFetchingInferredColumns,
    setSavedJobId,
    savedJobId,
    setSourceLocationWasEdited,
    selectedGroup,
  }

  const shouldFetchSchemaOnMount =
    (values.inferredColumns.length <= 0 && values.inferredPartitionColumns.length <= 0) ||
    sourceLocationWasEdited

  const shouldPollForSchema = isFetchingInferredColumns || sourceLocationWasEdited

  // fetch schema on mount
  useEffect(() => {
    if (shouldFetchSchemaOnMount) {
      handleFetchInferredColumns(args)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // continuously poll for schema
  useInterval(
    () => {
      handleFetchInferredColumns(args)
    },
    shouldPollForSchema ? 5000 : null
  )

  if (getCookie('cloneJobId')) {
    if (!getCookie('cloningWIP')) {
      handleFetchJobId(args).then(() => {
        deleteCookie('cloneJobId')
      })
    }
  }

  return (
    <InferredColumns
      values={values}
      inferredColumns={values.inferredColumns}
      handleChange={handleChange}
      handleBlur={handleBlur}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      isFetchingInferredColumns={isFetchingInferredColumns}
    />
  )
}

DefineTableFields.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
}

export default ToJS(DefineTableFields)
