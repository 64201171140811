import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button, Spinner } from '@nike/epic-react-ui-old'
import { Flex } from 'rebass'

export default function JobSHSDialog(props) {
  const { urlSHS, isOpen, toggleJobSHSDialog } = props

  return (
    <Modal title='Spark History Server' show={isOpen} onClose={toggleJobSHSDialog}>
      <br />
      <Flex justifyContent='center'>
        {urlSHS ? (
          <a href={urlSHS} target='_blank' rel='noopener noreferrer'>
            <Button data-e2e='shs-open-button' data-external-id='shs-open'>
              Open in a new tab
            </Button>
          </a>
        ) : (
          <div>
            Starting server, please wait
            <br />
            (may take up to one minute)
            <br />
            <Spinner />
          </div>
        )}
      </Flex>
    </Modal>
  )
}

JobSHSDialog.propTypes = {
  toggleJobSHSDialog: PropTypes.func.isRequired,
}
