import { createSelector } from 'reselect'

import { selectSelectedGroup } from './selectSelectedGroup'

const airflowClusters = (state) =>
  state
    .getIn(['airflowClusters', 'data'])
    .sort((airflowCluster1, airflowCluster2) =>
      airflowCluster1.get('cluster_name').localeCompare(airflowCluster2.get('cluster_name'))
    )

export const selectAirflowClusters = createSelector(
  [airflowClusters, selectSelectedGroup],
  (airflowClusters, selectedGroup) =>
    airflowClusters.filter((airflowCluster) => airflowCluster.get('owned_by') === selectedGroup)
)
