import { createSelector } from 'reselect'

import { selectAvailableServices } from './selectAvailableServices'
import { selectSelectedAccount } from './selectSelectedAccount'
import { selectSelectedRegion } from './selectSelectedRegion'
import { selectSelectedRuntime } from './selectSelectedRuntime'
import { CNNW_EMR_ENDPOINT } from 'utils/constant'

export const selectEmrEndpoint = createSelector(
  [selectAvailableServices, selectSelectedAccount, selectSelectedRegion, selectSelectedRuntime],
  (availableServices, selectedAccount, selectedRegion, selectedRuntime) => {
    if (selectedRegion?.startsWith('cn-')) {
      return CNNW_EMR_ENDPOINT
    }
    return (
      availableServices.getIn([
        selectedAccount,
        selectedRegion,
        selectedRuntime,
        'EMR',
        'endpoint',
      ]) || ''
    )
  }
)
