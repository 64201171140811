import { createSelector } from 'reselect'

import { selectIsFetchingEmrConfig } from 'selectors/selectIsFetchingEmrConfig'

const isFetchingEmrClusters = (state) => state.getIn(['emrClusters', 'isFetching'])

export const selectIsFetchingEmrClusters = createSelector(
  [isFetchingEmrClusters, selectIsFetchingEmrConfig],
  (isFetchingEmrClusters, isFetchingEmrConfig) => isFetchingEmrClusters || isFetchingEmrConfig
)
