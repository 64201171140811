import { Map } from 'immutable'
import { createSelector } from 'reselect'

import { selectEmrConfig } from 'selectors/selectEmrConfig'

export const selectSupportedEmrVersionOptions = createSelector([selectEmrConfig], (emrConfig) => {
  return (
    emrConfig.get('emr_version') &&
    emrConfig.get('emr_version').map((emrVersion) => Map({ label: emrVersion, value: emrVersion }))
  )
})
