/** @jsx jsx */
import PropTypes from 'prop-types'
import GraduateForm from 'components/self-service/graduate-form'
import Splash from 'components/global/splash'
import { Formik } from 'formik'
import { boolean, date, object, string } from 'yup'
import { SELF_SERVICE_ENDPOINT } from 'utils/constant'
import { create } from 'axios'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { selectSelectedGroup } from 'selectors/selectSelectedGroup'
import isEmpty from 'lodash.isempty'
import { Modal, Button } from '@nike/epic-react-ui-old'
import { Box, Flex } from 'rebass'
import { jsx, css } from '@emotion/core'
import { compose } from 'redux'
import ReactGA from 'react-ga'

import ToJS from 'components/common/to-js'

import { selectSelfServiceSchemaList } from 'selectors/selectSelfServiceSchemaList'
import { selectS3AclMap } from 'selectors/selectS3AclMap'

import { formatDate } from 'utils/formatDate'

function getValidationSchema(aclMap) {
  const tdlRegex = new RegExp(aclMap.rw.join('|').replace(/,/g, ''))

  return object().shape({
    dbName: object().required('Db name is a required field'),
    tableName: string().required('Table name is a required field'),
    targetS3Location: string()
      .required('Target S3 location is a required field')
      .matches(tdlRegex, 'You do not have access to this path.'),
    mapClusterName: string().required('MAP cluster name is a required field'),
    dagName: string().required('Dag name is a required field'),
    startDate: date().required('Start date is a required field'),
    scheduleFrequency: object().required('Schedule frequency is a required field'),
    archiveNeeded: boolean(),
    archiveLocation: string().when('archiveNeeded', {
      is: true,
      then: string().required('Archive location is a required field'),
    }),
  })
}

export function GraduateDialog(props) {
  const {
    selfServiceSchemaList,
    s3AclMap,
    selectedGroup,
    toggleDialog,
    isGraduateDialogOpen,
    activeJob,
  } = props

  if (isEmpty(activeJob)) {
    return null
  }

  const selfServiceSchemaListOptions = selfServiceSchemaList.map((item) => ({
    label: item,
    value: item,
  }))

  function submit(values /*bag*/) {
    const payload = {
      db_name: values.dbName.value,
      table_name: values.tableName,
      target_location: values.targetS3Location,
      map_cluster_name: values.mapClusterName,
      dag_name: values.dagName,
      start_time: activeJob.frequency.frequencyType === 'one-time' ? null : values.startDate,
      schedule:
        activeJob.frequency.frequencyType === 'one-time' ? null : values.scheduleFrequency.value,
      archive_needed: values.archiveNeeded,
      group: selectedGroup,
      job_id: activeJob.job_id,
    }

    if (values.archiveNeeded) {
      payload.archive_location = values.archiveLocation
    }

    create()
      .post(`${SELF_SERVICE_ENDPOINT}/graduate/v1`, payload)
      .then(() => toast.success('Graduation submitted successfully'))
      .catch((error) => {
        const errorMessage =
          error && error.response && error.response.data && error.response.data.error_message
            ? error.response.data.error_message
            : 'Error. Please contact platform team.'

        toast.error(errorMessage, { autoClose: false, closeOnClick: false })
      })
  }

  function closeDialog() {
    toggleDialog('Graduate')
  }

  return (
    <Formik
      initialValues={{
        dbName: selfServiceSchemaListOptions[0],
        tableName: '',
        targetS3Location: '',
        mapClusterName: '',
        dagName: '',
        startDate: formatDate(new Date()),
        scheduleFrequency: { label: 'Daily', value: 'daily' },
        archiveNeeded: false,
        archiveLocation: '',
      }}
      validationSchema={getValidationSchema(s3AclMap)}
      onSubmit={submit}
    >
      {(formikProps) =>
        formikProps.isSubmitting ? (
          <Splash />
        ) : (
          <Modal
            title='Graduate to Managed Space'
            show={isGraduateDialogOpen}
            onClose={closeDialog}
            modalSize='md'
            css={css`
              z-index: 500;
              height: 80vh;
              overflow: scroll;
            `}
          >
            <Flex flexDirection='column' style={{ minWidth: '600px' }}>
              <Box style={{ height: '51vh', overflow: 'scroll' }}>
                <GraduateForm
                  selfServiceSchemaListOptions={selfServiceSchemaListOptions}
                  formikProps={formikProps}
                  frequencyType={activeJob.frequency.frequency_type}
                />
              </Box>

              <Flex mt='50px' justifyContent='center'>
                <Button
                  id='graduate-cancel'
                  data-external-id='graduate-cancel'
                  onClick={closeDialog}
                  large
                  inverse
                  css={css`
                    margin-right: 20px;
                  `}
                >
                  Cancel
                </Button>

                <Button
                  id='graduate-submit'
                  data-external-id='graduate-submit'
                  onClick={() => {
                    formikProps.handleSubmit()
                    ReactGA.event({
                      category: 'SELF-SERVICE',
                      action: 'Graduate table job',
                    })
                  }}
                  disabled={!isEmpty(formikProps.errors)}
                  large
                  css={css`
                    margin-right: 20px;
                  `}
                >
                  Graduate
                </Button>
              </Flex>
            </Flex>
          </Modal>
        )
      }
    </Formik>
  )
}

GraduateDialog.propTypes = {
  selfServiceSchemaList: PropTypes.array.isRequired,
  s3AclMap: PropTypes.object.isRequired,
  selectedGroup: PropTypes.string.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  isGraduateDialogOpen: PropTypes.bool.isRequired,
  activeJob: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  return {
    selectedGroup: selectSelectedGroup(state),
    s3AclMap: selectS3AclMap(state),
    selfServiceSchemaList: selectSelfServiceSchemaList(state),
  }
}

export default compose(connect(mapStateToProps), ToJS)(GraduateDialog)
