import React, { useState, useEffect } from 'react'
import { Button, Modal, TextInput, Spinner } from '@nike/epic-react-ui-old'
import { toast } from 'react-toastify'
import { account, subDomain, environment, ENV_PRODUCTION, ENV_STAGING } from 'utils/constant'

// Find the meta api for this environment based on the hostname
let metaApi = 'https://platform-internal.w2.ngap2dev.nike.com' // one-dev.ngap2dev.nike.com
if (environment === ENV_PRODUCTION)
  // one.ngap2 or one.ngap2dev
  metaApi =
    account === 'cdt' // ngap2
      ? 'https://platform-internal.e1.ngap2.nike.com' // one.ngap2.nike.com
      : 'https://platform-internal.w2.ngap2dev.nike.com'
// one.ngap2dev.nike.com
else if (environment === ENV_STAGING) metaApi = 'https://platform-internal.e1.ngap2.nike.com' // one-stage.ngap2.nike.com
metaApi += '/meta/group/v1'

const INPUTS = [
  { label: 'Nike Application', tag: 'nike-application' },
  { label: 'Cost Center', tag: 'cost-center' },
  { label: 'Alert Email', tag: 'alert_email' },
  { label: 'Team Slack Channel', tag: 'slack_channel' },
]
const reCostCenter = /^\d{6}$/ // Cost Center must have six digits

export default function TeamInformation({ showTeamInfo, selectedGroup, closeTeamInfo, oktaToken }) {
  const [server, setServer] = useState()
  const [tags, setTags] = useState()
  const [newTags, setNewTags] = useState()

  useEffect(() => {
    const stage = subDomain === 'localhost' ? 'one-dev' : subDomain
    fetch(metaApi + '?ngap_group=' + selectedGroup + '&fields=team_info')
      .then((res) => res.json())
      .then((res) => setServer(res.team_info ? res.team_info[stage] : null))
  }, [selectedGroup])

  function fetchTags() {
    if (!showTeamInfo || !server) return
    fetch(server + 'gettags/v1?group=' + selectedGroup)
      .then((res) => res.json())
      .then((tags) => {
        setTags(tags)
        setNewTags({ ...tags })
      })
  }
  useEffect(fetchTags, [showTeamInfo, selectedGroup, server])

  async function submit() {
    setTags(null) // start the spinner

    const updateUrl = server + 'updatetags/v1'
    const updatedTags = {}
    for (const tag in tags) if (tags[tag] !== newTags[tag]) updatedTags[tag] = newTags[tag]

    const response = await fetch(updateUrl, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Methods': 'PUT,OPTIONS',
        'Access-Control-Allow-Origin': '*',
        Authorization: oktaToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        group: selectedGroup,
        payload: { tags: updatedTags },
      }),
    }).catch((err) => console.error(updateUrl, err))
    if (response.status === 200)
      toast.success('Team Information updated successfully', {
        autoClose: false,
        position: 'top-left',
      })
    else
      toast.error(await response.text(), {
        autoClose: false,
        position: 'top-left',
      })

    fetchTags() // confirm the update by fetching the tags again
  }

  function closeModal() {
    setNewTags(null)
    closeTeamInfo()
  }

  // disable the Submit button until a tag has been changed
  const changed =
    tags && newTags ? Object.keys(tags).some((tag) => tags[tag] !== newTags[tag]) : false
  // identify form errors
  const hasErrors = {},
    errorMessage = {}
  if (newTags)
    for (const tag in newTags) {
      if (newTags[tag] === '') {
        hasErrors[tag] = true
        errorMessage[tag] = 'Required'
      }
      if (tag === 'cost-center' && !reCostCenter.test(newTags[tag])) {
        hasErrors[tag] = true
        errorMessage[tag] = 'must have six digits'
      }
    }

  return (
    <Modal
      modalSize='sm'
      title={'Update Team information for ' + selectedGroup}
      show={showTeamInfo}
      closeButton={false}
    >
      {!newTags && <Spinner />}
      {INPUTS.map(({ tag, label }) => {
        let className = 'new-text-input'
        if (tags && newTags && tags[tag] !== newTags[tag]) className += ' epic-bg-warn' // highlight changed tags
        return (
          <TextInput
            type='text'
            key={tag}
            id={tag}
            hasErrors={hasErrors[tag]}
            errorMessage={errorMessage[tag]}
            className={className}
            label={label}
            value={newTags ? newTags[tag] : ''}
            onChange={(e) => {
              if (!newTags) return
              newTags[tag] = e.target.value
              setNewTags({ ...newTags })
            }}
          />
        )
      })}

      <div>
        <Button
          id='submit'
          data-external-id='modal-tags-submit'
          inverse
          onClick={submit}
          disabled={!changed || Object.keys(hasErrors).length > 0}
        >
          {newTags && !tags ? <Spinner /> : 'Submit'}
        </Button>

        <Button
          id='cancel'
          inverse
          style={{ float: 'right' }}
          onClick={closeModal}
          data-external-id='modal-tags-cancel'
        >
          Cancel
        </Button>
      </div>

      <div style={{ marginTop: '10px', textAlign: 'center' }}>
        <Button
          small
          inverse
          theme={{ primary: '#8b8c8c', secondary: 'white' }}
          data-external-id='cloud-tagging-standard'
        >
          <a
            style={{ color: '#b2b2b4' }}
            target='_blank'
            rel='noopener noreferrer'
            href='https://confluence.nike.com/display/CCOE/Cloud+Tagging+Standard'
          >
            Cloud Tagging Standard
          </a>
        </Button>
      </div>
    </Modal>
  )
}
