import { createSelector } from 'reselect'

import { selectEmrEndpoint } from './selectEmrEndpoint'
import { selectAuthToken } from './selectAuthToken'

export const selectSetCookieEndpoint = createSelector(
  [selectEmrEndpoint, selectAuthToken],
  (emrEndpoint, ngap2Token) => {
    if (!emrEndpoint) {
      return ''
    }

    const url = new URL(emrEndpoint)

    const domains = url.host.split('.')

    domains[0] = 'set'

    url.host = domains.join('.')

    return `${url.origin}/set_cookie?cdt-platform-auth=${ngap2Token}`
  }
)
