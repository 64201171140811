import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Box, Flex } from 'rebass'
import { compose } from 'redux'
import ReactGA from 'react-ga'
import { Button, Select } from '@nike/epic-react-ui-old'

import EmrClustersTable from 'components/emr/emr-clusters-table'
import CreateEmrClusterDialog from 'components/emr/create-emr-cluster-dialog'
import EmrClusterDetailsDialog from 'components/emr/emr-cluster-details-dialog'
import ResizeEmrClusterDialog from 'components/emr/resize-emr-cluster-dialog'
import TerminateEmrClusterDialog from 'components/emr/terminate-emr-cluster-dialog'
import EmrNodeInfo from 'components/emr/emr-node-info'
import EmrLog from 'components/emr/emr-log'
import GenieLog from 'components/emr/genie-log'
import ToJS from 'components/common/to-js'
import SshDialog from 'components/common/ssh-dialog'
import Can from 'components/common/can'

import useMetaConfig from 'hooks/useMetaConfig'

import { resizeEmrCluster } from 'actions/resizeEmrCluster'
import { terminateEmrCluster } from 'actions/terminateEmrCluster'
import { fetchEmrConfig } from 'actions/fetchEmrConfig'
import { setEmrClusterStatus } from 'actions/setEmrClusterStatus'
import { listEmrClusters } from 'actions/listEmrClusters'
import { createEmrCluster } from 'actions/createEmrCluster'

import { selectAuthToken } from 'selectors/selectAuthToken'
import { selectUsername } from 'selectors/selectUsername'
import { selectEmrClusters } from 'selectors/selectEmrClusters'
import { selectSelectedRegion } from 'selectors/selectSelectedRegion'
import { selectSelectedAccount } from 'selectors/selectSelectedAccount'
import { selectSelectedRuntime } from 'selectors/selectSelectedRuntime'
import { selectIsUserLead } from 'selectors/selectIsUserLead'
import { selectIsFetchingEmrClusters } from 'selectors/selectIsFetchingEmrClusters'
import { selectSelectedEmrClusterStatus } from 'selectors/selectSelectedEmrClusterStatus'
import { selectGroups } from 'selectors/selectGroups'
import { selectEmrEndpoint } from 'selectors/selectEmrEndpoint'
import { selectApplicationLinks } from 'selectors/selectApplicationLinks'
import { selectApplicationLinkGAInfo } from 'selectors/selectApplicationLinkGAInfo'
import { useNarkOnPageLoad } from 'utils/nark.js'
import PageTitle from '../../common/page-title'

const filterEmrClusters = (emrClusters, searchValue) => {
  const searchValueLc = searchValue.toLowerCase()

  return emrClusters.filter((emrCluster) => {
    if (!searchValue) return true
    return (
      emrCluster.cluster_name.toLowerCase().includes(searchValueLc) ||
      emrCluster.created_by.toLowerCase().includes(searchValueLc) ||
      emrCluster.owned_by.toLowerCase().includes(searchValueLc) ||
      emrCluster.version.toLowerCase().includes(searchValueLc) ||
      (emrCluster.master_ip || '').toLowerCase().includes(searchValueLc) ||
      emrCluster.cluster_status.toLowerCase().includes(searchValueLc)
    )
  })
}

const clusterStatusOptions = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'FAILED', label: 'Failed' },
  { value: 'TERMINATED', label: 'Terminated' },
]

const pageName = 'emr'
export function EmrPage(props) {
  useNarkOnPageLoad(pageName)

  const {
    fetchEmrConfig,
    listEmrClusters,
    selectedGroup,
    selectedRegion,
    selectedAccount,
    selectedRuntime,
    authToken,
    username,
    emrClusters,
    searchValue,
    activeCluster,
    terminateEmrCluster,
    isUserLead,
    resizeEmrCluster,
    createEmrCluster,
    isFetchingEmrClusters,
    selectedEmrClusterStatus,
    setEmrClusterStatus,
    setActiveCluster,
    groups,
    emrEndpoint,
    applicationLinks,
    applicationLinkGAInfo,
  } = props

  const [isEmrLogOpen, setIsEmrLogOpen] = useState(false)
  const [isGenieLogOpen, setIsGenieLogOpen] = useState(false)
  const [isSshDialogOpen, setIsSshDialogOpen] = useState(false)
  const [isTerminateEmrClusterDialogOpen, setIsTerminateEmrClusterDialogOpen] = useState(false)
  const [isEmrClusterDetailsDialogOpen, setIsEmrClusterDetailsDialogOpen] = useState(false)
  const [isCreateEmrClusterDialogOpen, setIsCreateEmrClusterDialogOpen] = useState(false)
  const [isResizeEmrClusterDialogOpen, setIsResizeEmrClusterDialogOpen] = useState(false)
  const [isEmrNodeInfoDialogOpen, setIsEmrNodeInfoDialogOpen] = useState(false)

  const [emrLogEndpoint, setEmrLogEndpoint] = useState()
  const [genieLogEndpoint, setGenieLogEndpoint] = useState()

  useEffect(() => {
    ReactGA.pageview('/emr')
  }, [])

  const isFetchingEmrConfig = useMetaConfig({
    configFetcher: fetchEmrConfig,
    callback: listEmrClusters,
    selectedRegion,
  })

  const isLoadingEmrPage = isFetchingEmrConfig || isFetchingEmrClusters

  const filteredEmrClusters = filterEmrClusters(emrClusters, searchValue)

  function handleClusterStatusChange(option) {
    localStorage.setItem('selectedEmrClusterStatus', option.value)

    setEmrClusterStatus(option.value)

    listEmrClusters()

    ReactGA.event({
      category: 'EMR',
      action: 'Filter status change',
      label: option.value,
    })
  }

  function toggleEmrLog(activeCluster, endpoint) {
    setEmrLogEndpoint(endpoint)
    setIsEmrLogOpen((isOpen) => !isOpen)

    setActiveCluster(activeCluster && !isEmrLogOpen ? activeCluster : {})
  }

  function toggleGenieLog(activeCluster, endpoint) {
    setGenieLogEndpoint(endpoint)
    setIsGenieLogOpen((isOpen) => !isOpen)

    setActiveCluster(activeCluster && !isGenieLogOpen ? activeCluster : {})
  }

  function toggleSshDialog(activeCluster) {
    setIsSshDialogOpen(() => !isSshDialogOpen)

    setActiveCluster(activeCluster && !isSshDialogOpen ? activeCluster : {})
  }

  function toggleTerminateEmrClusterDialog(activeCluster) {
    setIsTerminateEmrClusterDialogOpen((isOpen) => !isOpen)

    setActiveCluster(activeCluster && !isTerminateEmrClusterDialogOpen ? activeCluster : {})
  }

  function toggleEmrClusterDetailsDialog(activeCluster) {
    setIsEmrClusterDetailsDialogOpen((isOpen) => !isOpen)

    setActiveCluster(!isEmrClusterDetailsDialogOpen ? activeCluster : {})
  }

  function toggleResizeEmrClusterDialog(activeCluster) {
    setIsResizeEmrClusterDialogOpen((isOpen) => !isOpen)

    setActiveCluster(!isResizeEmrClusterDialogOpen ? activeCluster : {})
  }

  function toggleCreateEmrClusterDialog() {
    setIsCreateEmrClusterDialogOpen((isOpen) => !isOpen)

    if (isCreateEmrClusterDialogOpen) {
      ReactGA.modalview('/emr/create-cluster')
    }
  }

  function toggleEmrNodeInfoDialog() {
    setIsEmrNodeInfoDialogOpen((isOpen) => !isOpen)

    if (isEmrNodeInfoDialogOpen) {
      ReactGA.event({ category: 'EMR', action: 'Node info' })
    }
  }

  function handleRefreshEmr(event) {
    event.preventDefault()

    listEmrClusters()

    ReactGA.event({ category: 'EMR', action: 'Refresh' })
  }

  const pageTitleConfig = { title: 'EMR' }

  return (
    <Box>
      <PageTitle {...pageTitleConfig} />
      <form>
        <Flex
          justifyContent='space-between'
          style={{
            fontSize: '16px',
            backgroundColor: '#ececec',
            borderTop: '1px solid #ffffff',
          }}
          p='0 30px'
        >
          <Box p='4px'>
            <label htmlFor='filter-cluster-status-select' style={{ paddingRight: '25px' }}>
              Filter by Cluster Status
            </label>

            <Box width='150px' display='inline-block' style={{ backgroundColor: '#ececec' }}>
              <Select
                id='filter-cluster-status-select'
                isDisabled={!emrEndpoint}
                onChange={handleClusterStatusChange}
                options={clusterStatusOptions}
                value={clusterStatusOptions.find(
                  (status) => selectedEmrClusterStatus === status.value
                )}
              />
            </Box>
          </Box>

          <Flex>
            <Can
              roles={groups}
              perform='emr:create'
              yes={() => (
                <Button
                  data-e2e='create-emr-cluster-button'
                  data-external-id='create-emr-cluster-submit'
                  disabled={!emrEndpoint}
                  onClick={toggleCreateEmrClusterDialog}
                  style={{ marginRight: '8px' }}
                >
                  Create Cluster
                </Button>
              )}
              no={() => (
                <Button
                  data-e2e='emr-node-info-button'
                  data-external-id='emr-node-info'
                  onClick={toggleEmrNodeInfoDialog}
                  style={{ marginLeft: '8px' }}
                >
                  EMR Node Info
                </Button>
              )}
            />

            <Button
              data-e2e='refresh-emr-clusters-button'
              data-external-id='refresh-emr-cluster'
              onClick={handleRefreshEmr}
              disabled={!emrEndpoint}
              inverse
            >
              Refresh
            </Button>
          </Flex>
        </Flex>
      </form>

      <EmrClustersTable
        emrClusters={filteredEmrClusters || emrClusters}
        toggleEmrClusterDetailsDialog={toggleEmrClusterDetailsDialog}
        toggleResizeEmrClusterDialog={toggleResizeEmrClusterDialog}
        toggleTerminateEmrClusterDialog={toggleTerminateEmrClusterDialog}
        toggleSshDialog={toggleSshDialog}
        toggleEmrLog={toggleEmrLog}
        toggleGenieLog={toggleGenieLog}
        isLoadingEmrPage={isLoadingEmrPage}
        authToken={authToken}
        username={username}
        selectedRegion={selectedRegion}
        selectedGroup={selectedGroup}
        isUserLead={isUserLead}
        applicationLinks={applicationLinks}
        applicationLinkGAInfo={applicationLinkGAInfo}
      />

      {!isLoadingEmrPage && ( // don't mount until emr config data is fetched from meta api
        <CreateEmrClusterDialog
          isCreateEmrClusterDialogOpen={isCreateEmrClusterDialogOpen}
          toggleCreateEmrClusterDialog={toggleCreateEmrClusterDialog}
          selectedRegion={selectedRegion}
          createEmrCluster={createEmrCluster}
        />
      )}

      <EmrClusterDetailsDialog
        isOpen={isEmrClusterDetailsDialogOpen}
        toggleDialog={toggleEmrClusterDetailsDialog}
        activeCluster={activeCluster}
      />

      <EmrNodeInfo
        isOpen={isEmrNodeInfoDialogOpen}
        toggleEmrNodeInfoDialog={toggleEmrNodeInfoDialog}
        activeCluster={activeCluster}
      />

      <ResizeEmrClusterDialog
        isOpen={isResizeEmrClusterDialogOpen}
        toggleDialog={toggleResizeEmrClusterDialog}
        activeCluster={activeCluster}
        resizeEmrCluster={resizeEmrCluster}
      />

      <TerminateEmrClusterDialog
        isOpen={isTerminateEmrClusterDialogOpen}
        toggleDialog={toggleTerminateEmrClusterDialog}
        activeCluster={activeCluster}
        terminateEmrCluster={terminateEmrCluster}
      />

      <EmrLog
        isOpen={isEmrLogOpen}
        toggleDialog={toggleEmrLog}
        activeCluster={activeCluster}
        authToken={authToken}
        endpoint={emrLogEndpoint}
        selectedGroup={selectedGroup}
        selectedRegion={selectedRegion}
        selectedAccount={selectedAccount}
        selectedRuntime={selectedRuntime}
      />

      <GenieLog
        isOpen={isGenieLogOpen}
        toggleDialog={toggleGenieLog}
        activeCluster={activeCluster}
        endpoint={genieLogEndpoint}
        selectedGroup={selectedGroup}
        selectedRegion={selectedRegion}
      />

      <SshDialog
        isOpen={isSshDialogOpen}
        toggleDialog={toggleSshDialog}
        activeCluster={activeCluster}
        activeTab='emr'
      />
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    authToken: selectAuthToken(state),
    username: selectUsername(state),
    emrClusters: selectEmrClusters(state),
    selectedRegion: selectSelectedRegion(state),
    selectedAccount: selectSelectedAccount(state),
    selectedRuntime: selectSelectedRuntime(state),
    isFetchingEmrClusters: selectIsFetchingEmrClusters(state),
    isUserLead: selectIsUserLead(state),
    selectedEmrClusterStatus: selectSelectedEmrClusterStatus(state),
    groups: selectGroups(state),
    emrEndpoint: selectEmrEndpoint(state),
    applicationLinks: selectApplicationLinks(state),
    applicationLinkGAInfo: selectApplicationLinkGAInfo(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resizeEmrCluster: (
      emrClusterName,
      emrClusterId,
      payload,
      selectedAccount,
      selectedRegion,
      selectedRuntime
    ) =>
      dispatch(
        resizeEmrCluster(
          emrClusterName,
          emrClusterId,
          payload,
          selectedAccount,
          selectedRegion,
          selectedRuntime
        )
      ),
    terminateEmrCluster: (emrClusterName, emrClusterId) =>
      dispatch(terminateEmrCluster(emrClusterName, emrClusterId)),
    fetchEmrConfig: () => dispatch(fetchEmrConfig()),
    setEmrClusterStatus: (status) => dispatch(setEmrClusterStatus(status)),
    listEmrClusters: () => dispatch(listEmrClusters()),
    createEmrCluster: (payload) => dispatch(createEmrCluster(payload)),
  }
}

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), ToJS)(EmrPage)

export default enhanced
