import { createSelector } from 'reselect'
import { List } from 'immutable'

import { selectSelectedGroup } from './selectSelectedGroup'

const emrClusters = (state) => {
  if (state.getIn(['emrClusters', 'data'])) {
    return state
      .getIn(['emrClusters', 'data'])
      .filter(
        (emrCluster) => emrCluster.get('cluster_name') !== 'prod-omega-audience-persistent-cluster'
      )
  } else {
    return new List()
  }
}

export const selectEmrClusters = createSelector(
  [emrClusters, selectSelectedGroup],
  (emrClusters, selectedGroup) =>
    emrClusters.filter((emrCluster) => emrCluster.get('owned_by') === selectedGroup)
)
