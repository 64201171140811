import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga'
import { connect } from 'react-redux'

import { Button, Modal, Select, Spinner } from '@nike/epic-react-ui-old'

import { Flex } from 'rebass'

import { selectJobsEndpoint } from 'selectors/selectJobsEndpoint'

const IFRAME_STYLE = {
  display: 'block',
  border: 'inset',
  width: '100%',
  height: '500px',
  overflow: 'auto',
  padding: '0',
  marginTop: '8px',
}

function JobLogsDialog({ isOpen, toggleJobLogsDialog, authToken, jobForLog, endpoint }) {
  const job = jobForLog ? jobForLog : { job_name: '', job_id: '' }

  const [path, setPath] = useState([])
  const [pathFinal, setPathFinal] = useState()
  const [loading, setLoading] = useState(false)
  const [logFile, setLogFile] = useState()
  const [error, setError] = useState()
  const headers = { headers: { Authorization: 'Bearer ' + authToken } }

  useEffect(() => {
    const job_id = job.job_id
    if (job_id) setPath([job_id])
  }, [job])

  useEffect(() => {
    if (!isOpen || path.length === 0) return
    setPathFinal(null)
    setError(null)
    async function fetchPath() {
      let urlPath = path[0]
      if (path.length > 1) {
        urlPath += '?path=' + path.slice(1).join('/') + '/'
      }
      const logDirsRes = await fetch(`${endpoint}/logs/v1/${urlPath}`, headers)
      if (logDirsRes.status !== 200) {
        setLoading(false)
        setError('API returned an error: ' + JSON.parse(await logDirsRes.text())['detail'])
        return
      }
      const logDirsArray = await logDirsRes.json()
      if (Array.isArray(logDirsArray))
        setPathFinal(
          logDirsArray.reduce((options, item) => {
            if (item) options.push({ label: item, value: item })
            return options
          }, [])
        )
      else {
        setError(logDirsArray)
        setPathFinal()
      }
    }
    fetchPath()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, path])

  async function fetchLog(option) {
    const logPath = option.value
    if (logPath[logPath.length - 1] === '/') {
      setPath([...path, logPath.substr(0, logPath.length - 1)])
      return
    }
    setLoading(true)
    path.push(logPath)
    let urlPath = path[0] + '?path=' + path.slice(1).join('/')
    const logFileUrlRes = await fetch(`${endpoint}/logs/v1/${urlPath}`, headers)
    if (logFileUrlRes.status !== 200) {
      setError('API returned an error: ' + (await logFileUrlRes.text()))
      setLoading(false)
      return
    }
    const logFileUrl = await logFileUrlRes.json()
    setLogFile(logFileUrl)
    ReactGA.event({
      category: 'JOBS',
      action: 'Logs',
    })
    setLoading(false)
  }

  function changePath(i) {
    setPath(path.slice(0, i + 1))
    setLogFile(null)
  }

  return (
    <Modal
      modalSize='md'
      title={job.job_name + ' Log'}
      show={isOpen}
      onClose={() => {
        setPath([])
        setPathFinal(null)
        setLoading(false)
        setLogFile(null)
        toggleJobLogsDialog()
      }}
    >
      <p>Select the path to the log file(s):</p>
      <p>
        path ={' '}
        {path.map((pathStep, i) => (
          <span key={i}>
            {i > 0 && ' / '}
            {i < path.length - 1 ? (
              <Button small inverse onClick={() => changePath(i)} data-external-id='path-to-logs'>
                {pathStep}
              </Button>
            ) : (
              <span>{pathStep}</span>
            )}
          </span>
        ))}
      </p>
      <span style={{ color: 'red' }}>{error}</span>
      {pathFinal && !logFile && (
        <Select
          onChange={fetchLog}
          options={pathFinal}
          autoFocus={true}
          errorMessage={error}
          hasErrors={error}
          className='ml-2'
        ></Select>
      )}
      <br />
      <Flex flexDirection='column'>
        <Flex mt='15px' justifyContent='center'>
          <div>
            {!pathFinal && !logFile && !error && <Spinner medium={true} />}
            {loading && <Spinner medium={true} />}
          </div>
        </Flex>
      </Flex>
      {logFile && (
        <iframe
          title={path[path.length - 1]}
          src={logFile}
          style={IFRAME_STYLE}
          onLoad={() => setLoading(false)}
        />
      )}
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    endpoint: selectJobsEndpoint(state),
  }
}

export default connect(mapStateToProps)(JobLogsDialog)
