/** @jsx jsx */
import PropTypes from 'prop-types'
import DetailsGroup from 'components/common/details-group'
import { Spinner } from '@nike/epic-react-ui-old'
import { css, jsx } from '@emotion/core'

export default function TableJobInfo({ tableJob = {} }) {
  if (!tableJob.frequency) {
    return (
      <Spinner
        medium
        css={css`
          margin: 30px auto;
        `}
      />
    )
  }

  let generalFields = [
    { label: 'Job name', value: tableJob.job_name },
    { label: 'Job Id', value: tableJob.job_id },
    { label: 'Created by', value: tableJob.user },
    { label: 'Source Location', value: tableJob.source_location },
    { label: 'Table Data Location', value: tableJob.table_data_location },
    {
      label: 'Schedule',
      value: tableJob.frequency.frequency_type,
    },
  ]

  if (tableJob.frequency.frequency_type !== 'one-time') {
    generalFields = generalFields.concat([
      { label: 'Interval', value: tableJob.frequency.schedule },
      {
        label: 'Start time',
        value: tableJob.frequency.start_time,
      },
      { label: 'End time', value: tableJob.frequency.end_time },
    ])
  }

  generalFields.push({
    label: 'Receive alerts',
    value: String(tableJob.send_alert.alert_configured),
  })

  if (tableJob.send_alert.alert_configured) {
    generalFields = generalFields.concat([
      {
        label: 'Alert email',
        value: tableJob.send_alert.alert_email,
      },
      {
        label: 'Alert condition',
        value: tableJob.send_alert.alert_condition,
      },
    ])
  }

  let errorString = tableJob.error_message ? tableJob.error_message : 'No Errors'

  let diagString = tableJob.diagnostics ? tableJob.diagnostics : 'No Diagnostics'

  generalFields = generalFields.concat([
    { label: 'DDL', value: tableJob.ddl },
    { label: 'Error message', value: errorString },
    { label: 'Diagnostics', value: diagString },
  ])

  return <DetailsGroup title='General' fields={generalFields} />
}

TableJobInfo.propTypes = {
  tableJob: PropTypes.object.isRequired,
}
