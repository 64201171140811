/** @jsx jsx */
import { Box, Flex } from 'rebass'
import styled from '@emotion/styled'
import { FieldArray } from 'formik'
import Tooltip from '@nike/nr-vamp-xl-tooltip'
import { css, jsx } from '@emotion/core'
import { Select, TextInput, Checkbox, Spinner, IconButton } from '@nike/epic-react-ui-old'

const columnDataTypeOptions = [
  { label: 'Int', value: 'int' },
  { label: 'String', value: 'string' },
  { label: 'Date', value: 'date' },
  { label: 'Decimal', value: 'decimal' },
]

const functionNameOptions = [
  { label: 'lit', value: 'lit' },
  { label: 'concat', value: 'concat' },
  { label: 'date_format', value: 'date_format' },
  { label: 'current_timestamp', value: 'current_timestamp' },
  { label: 'regexp_replace', value: 'regexp_replace' },
  { label: 'case', value: 'case' },
  { label: 'add', value: 'add' },
  { label: 'sub', value: 'sub' },
  { label: 'mul', value: 'mul' },
  { label: 'div', value: 'div' },
]

const getCuratedColumnDataTypeOptions = (function_name) => {
  switch (function_name) {
    case 'lit':
    case 'concat':
    case 'regexp_replace':
    case 'case':
      return [{ label: 'String', value: 'string' }]

    case 'date_format':
    case 'current_timestamp':
      return [{ label: 'Date', value: 'date' }]

    case 'add':
    case 'sub':
    case 'mul':
    case 'div':
      return [
        { label: 'Int', value: 'int' },
        { label: 'Decimal', value: 'decimal' },
      ]

    default:
      return [{ label: '', value: '' }]
  }
}

const Title = styled.h1`
  font-weight: bold;
`

const Header = styled.h3`
  margin-top: 30px;
  font-size: 20px;
`

const getPlaceholderText = (function_name) => {
  switch (function_name) {
    case 'lit':
      return 'Self Service'
    case 'concat':
      return 'concat(existing_col1,existing_col2)'
    case 'date_format':
      return "date_format(existing_col1, 'yyyy/MM/dd HH:mm:ss')"
    case 'current_timestamp':
      return '%Y-%m-%d %H:%M:%S'
    case 'regexp_replace':
      return "regexp_replace(existing_col1,'pattern','replacement')"
    case 'case':
      return "case when existing_col1 == 'condition1' then value1 when existing_col2 == 'condition2' then value2 else value3 end."
    case 'add':
      return 'existing_col1+existing_col2'
    case 'sub':
      return 'existing_col1-existing_col2'
    case 'mul':
      return 'existing_col1*existing_col2'
    case 'div':
      return 'existing_col1/existing_col2'
    default:
      return ''
  }
}

/* Note: formik does not seem to set array fields as touched via handleBlur. Need to setFieldTouched manually */
export default function InferredColumns({
  values,
  handleChange,
  setFieldValue,
  isFetchingInferredColumns,
}) {
  return (
    <Box
      p='30px'
      css={css`
        height: 100%;
      `}
    >
      {isFetchingInferredColumns ? (
        <Flex
          css={css`
            height: 70%;
          `}
          alignItems='center'
          justifyContent='center'
          marginBottom='100px'
        >
          <Flex flexDirection='column'>
            <Spinner
              medium
              css={css`
                margin: 0 auto;
              `}
            />

            <Header>Detecting schema (may take several minutes)...</Header>
          </Flex>
        </Flex>
      ) : (
        <Box>
          <Box>
            <Title>Columns:</Title>

            <FieldArray
              name='inferredColumns'
              render={(arrayHelpers) => (
                <Box>
                  <Box>
                    {values.inferredColumns.length > 0
                      ? values.inferredColumns.map((column, index) => (
                          <Flex ml='15px' alignItems='center' key={index}>
                            <Box width='30%'>
                              <TextInput
                                name={`inferredColumns.${index}.column_name`}
                                value={column.column_name}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box width='41%' px='10px'>
                              <Select
                                name={`inferredColumns.${index}.datatype`}
                                options={columnDataTypeOptions}
                                placeholder='Data type'
                                value={{
                                  label: `${
                                    column.datatype.charAt(0).toUpperCase() +
                                    column.datatype.slice(1)
                                  }`,
                                  value: column.datatype,
                                }}
                                onChange={(option) => {
                                  setFieldValue(`inferredColumns.${index}.datatype`, option.value)
                                }}
                              />
                            </Box>

                            <Box width='10%'>
                              <Checkbox
                                name={`inferredColumns.${index}.should_expand`}
                                checked={column.should_expand}
                                onChange={handleChange}
                                label='Expand'
                                disabled={!column.datatype.startsWith('array')}
                              />
                            </Box>

                            <Box width='9%'>
                              <Checkbox
                                name={`inferredColumns.${index}.dedup`}
                                checked={column.dedup}
                                onChange={handleChange}
                                label='Dedup'
                              />
                            </Box>

                            <Box width='4%'>
                              <IconButton
                                type='delete'
                                iconFontSize='24px'
                                bare
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            </Box>
                          </Flex>
                        ))
                      : 'None'}
                  </Box>
                </Box>
              )}
            />
          </Box>

          <FieldArray
            name='curatedColumns'
            render={(arrayHelpers) => (
              <Box>
                <Box>
                  {values.curatedColumns.length > 0
                    ? values.curatedColumns.map((curatedColumn, index) => (
                        <Flex ml='15px' alignItems='center' key={index}>
                          <Box width='30%'>
                            <TextInput
                              name={`curatedColumns.${index}.column_name`}
                              placeholder='Column name'
                              value={curatedColumn.column_name}
                              onChange={handleChange}
                            />
                          </Box>

                          <Box width='41%'>
                            <Flex alignItems='center'>
                              <Box width='20%'>
                                <Select
                                  name={`curatedColumns.${index}.datatype`}
                                  options={getCuratedColumnDataTypeOptions(
                                    curatedColumn.function_name
                                  )}
                                  value={{
                                    label: `${
                                      curatedColumn.datatype.charAt(0).toUpperCase() +
                                      curatedColumn.datatype.slice(1)
                                    }`,
                                    value: curatedColumn.datatype,
                                  }}
                                  onChange={(option) => {
                                    setFieldValue(`curatedColumns.${index}.datatype`, option.value)
                                  }}
                                />
                              </Box>

                              <Box width='31%'>
                                <Select
                                  name={`curatedColumns.${index}.function_name`}
                                  options={functionNameOptions}
                                  value={{
                                    label: curatedColumn.function_name,
                                    value: curatedColumn.function_name,
                                  }}
                                  onChange={(option) => {
                                    setFieldValue(
                                      `curatedColumns.${index}.function_name`,
                                      option.value
                                    )

                                    const dataTypeValue = getCuratedColumnDataTypeOptions(
                                      option.value
                                    )[0].value

                                    setFieldValue(`curatedColumns.${index}.datatype`, dataTypeValue)
                                  }}
                                />
                              </Box>

                              <Box width='47%'>
                                <Tooltip
                                  title={getPlaceholderText(curatedColumn.function_name)}
                                  enterDelay={1}
                                >
                                  <TextInput
                                    name={`curatedColumns.${index}.function_input`}
                                    placeholder={getPlaceholderText(curatedColumn.function_name)}
                                    value={curatedColumn.function_input}
                                    onChange={handleChange}
                                  />
                                </Tooltip>
                              </Box>
                            </Flex>
                          </Box>

                          <Box width='10%'>
                            <Checkbox
                              name={`curatedColumns.${index}.should_expand`}
                              checked={curatedColumn.should_expand}
                              onChange={handleChange}
                              label='Expand'
                              disabled={!curatedColumn.datatype.startsWith('array')}
                            />
                          </Box>

                          <Box width='9%'>
                            <Checkbox
                              name={`curatedColumns.${index}.dedup`}
                              checked={curatedColumn.dedup}
                              onChange={handleChange}
                              label='Dedup'
                            />
                          </Box>

                          <Box width='4%'>
                            <IconButton
                              type='delete'
                              iconFontSize='24px'
                              bare
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          </Box>
                        </Flex>
                      ))
                    : ''}
                </Box>

                {values.inferredColumns.length > 0 && (
                  <Box mt='15px'>
                    <IconButton
                      type='add'
                      iconFontSize='24px'
                      bare
                      onClick={() => {
                        arrayHelpers.push({
                          id: values.curatedColumns.length
                            ? values.inferredColumns.length + values.curatedColumns.length + 1
                            : values.inferredColumns.length + 1,
                          column_name: '',
                          datatype: 'int',
                          function_name: 'add',
                          function_input: '',
                          dedup: false,
                          should_expand: false,
                        })
                      }}
                    >
                      Add
                    </IconButton>
                  </Box>
                )}
              </Box>
            )}
          />

          <Box mt={30}>
            <Title>Partition Columns:</Title>

            <FieldArray
              name='inferredPartitionColumns'
              render={() => (
                <Box>
                  {values.inferredPartitionColumns.length > 0
                    ? values.inferredPartitionColumns.map((partitionColumn, index) => (
                        <Flex ml={15} alignItems='center' key={index}>
                          <Box width='30%'>
                            <TextInput
                              name={`inferredPartitionColumns.${index}.column_name`}
                              value={partitionColumn.column_name}
                              onChange={handleChange}
                            />
                          </Box>

                          <Box width='20%' ml='20px'>
                            <TextInput
                              name={`inferredPartitionColumns.${index}.datatype`}
                              value={`${
                                partitionColumn.datatype.charAt(0).toUpperCase() +
                                partitionColumn.datatype.slice(1)
                              }`}
                              readOnly
                            />
                          </Box>

                          <Box width='20%' ml='20px'>
                            <TextInput
                              name={`inferredPartitionColumns.${index}.filter_value`}
                              onChange={handleChange}
                              value={partitionColumn.filter_value || ''}
                              placeholder='Filter'
                            />
                          </Box>
                        </Flex>
                      ))
                    : 'None'}
                </Box>
              )}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}
