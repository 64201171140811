/** @jsx jsx */
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Formik } from 'formik'
import { Modal, Button, Spinner } from '@nike/epic-react-ui-old'
import { css, jsx } from '@emotion/core'
import { Flex, Box } from 'rebass'

import CreateJobForm from 'components/jobs-display/create-job-form'

import { getInitialFieldValues, submit, validate } from 'utils/create-job-form-helpers'

export function CreateJobDialog(props) {
  const { isCreateJobDialogOpen, toggleCreateJobDialog, submit } = props
  const [isSubmitting, setIsSubmitting] = useState(false)

  return (
    <Formik
      initialValues={getInitialFieldValues(props)}
      validate={validate}
      onSubmit={(values, bag) => {
        setIsSubmitting(true)
        submit(values, bag, props).then(() => setIsSubmitting(false))
      }}
    >
      {(formikProps) => {
        const { handleSubmit, handleReset } = formikProps

        return (
          <Modal
            title='New Job'
            show={isCreateJobDialogOpen}
            onClose={toggleCreateJobDialog}
            modalSize='lg'
            swoosh
            css={css`
              z-index: 500;
              height: 75vh;
              overflow: scroll;
            `}
          >
            <Flex flexDirection='column'>
              <Box style={{ height: '51vh', overflow: 'scroll' }}>
                <CreateJobForm {...props} formikProps={formikProps} newJob={true} />
              </Box>

              <Flex mt='15px' justifyContent='center'>
                <Button
                  data-e2e='create-job-cancel-button'
                  data-external-id='create-job-cancel'
                  id='create-job-cancel-button'
                  onClick={() => {
                    toggleCreateJobDialog()
                    handleReset()
                  }}
                  large
                  inverse
                  css={css`
                    margin-right: 10px;
                  `}
                >
                  Cancel
                </Button>

                <Button
                  data-e2e='create-job-submit-button'
                  data-external-id='create-job'
                  id='create-job-submit-button'
                  onClick={handleSubmit}
                  large
                  css={css`
                    margin-right: 20px;
                  `}
                >
                  Create Job
                </Button>
              </Flex>
              <Flex mt='30px' justifyContent='center'>
                {isSubmitting && <Spinner />}
              </Flex>
            </Flex>
          </Modal>
        )
      }}
    </Formik>
  )
}

CreateJobDialog.propTypes = {
  isCreateJobDialogOpen: PropTypes.bool,
  toggleCreateJobDialog: PropTypes.func.isRequired,
  createJob: PropTypes.func.isRequired,
  listJobs: PropTypes.func.isRequired,
  selectedGroup: PropTypes.string.isRequired,
  selectedRegion: PropTypes.string,
  submit: PropTypes.func,
  validate: PropTypes.func,
}

CreateJobDialog.defaultProps = {
  isCreateJobDialogOpen: false,
  submit,
}

export default CreateJobDialog
