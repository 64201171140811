import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { Box, Flex } from 'rebass'
import { compose } from 'redux'
import ReactGA from 'react-ga'
import { Button, ToggleSwitch, ActionInput } from '@nike/epic-react-ui-old'

import JobsDisplayTable from 'components/jobs-display/jobs-display-table'
import CreateJobDialog from 'components/jobs-display/create-job-dialog'
import JobDetailsDialog from 'components/jobs-display/job-details-dialog'
import DeleteJobDialog from 'components/jobs-display/delete-job-dialog'
import ResubmitJobDialog from 'components/jobs-display/resubmit-job-dialog'
import JobLogsDialog from 'components/jobs-display/job-logs-dialog'
import JobSHSDialog from 'components/jobs-display/job-shs-dialog'
import JobAirflowOperatorDialog from 'components/jobs-display/job-airflow-operator-dialog'
import ToJS from 'components/common/to-js'

import useMetaConfig from 'hooks/useMetaConfig'

import { fetchEmrConfig } from 'actions/fetchEmrConfig'
import { listJobs } from 'actions/listJobs'
import { createJob } from 'actions/createJob'
import { describeJob } from 'actions/describeJob'
import { deleteJob } from 'actions/deleteJob'
import { generateAirflowOperator } from 'actions/generateAirflowOperator'

import { selectJobs } from 'selectors/selectJobs'
import { selectIsListingJobs } from 'selectors/selectIsListingJobs'
import { selectSelectedJob } from 'selectors/selectSelectedJob'
import { selectAuthToken } from 'selectors/selectAuthToken'
import { selectJobAirflowOperator } from 'selectors/selectJobAirflowOperator'
import { selectJobsComputeRuntimes } from 'selectors/selectJobsComputeRuntimes'
import { selectJobsSparkVersions } from 'selectors/selectJobsSparkVersions'
import { selectJobsEndpoint } from 'selectors/selectJobsEndpoint'
import { useNarkOnPageLoad } from 'utils/nark.js'
import PageTitle from '../../common/page-title'

const filterJobs = (jobs, searchValue) => {
  const searchValueLc = searchValue.toLowerCase()

  return jobs.filter((job) => {
    if (!searchValue) return true
    return (
      job.job_name.toLowerCase().includes(searchValueLc) ||
      job.job_id.toLowerCase().includes(searchValueLc) ||
      job.submitted_by.toLowerCase().includes(searchValueLc) ||
      job.runtime.toLowerCase().includes(searchValueLc) ||
      job.job_status.toLowerCase().includes(searchValueLc)
    )
  })
}

const pageName = 'jobs-display'

export function JobsDisplayPage(props) {
  useNarkOnPageLoad(pageName)
  const {
    fetchEmrConfig,
    jobs,
    listJobs,
    selectedRegion,
    isListingJobs,
    searchValue,
    createJob,
    selectedJob,
    describeJob,
    deleteJob,
    generateAirflowOperator,
    authToken,
    selectedGroup,
    airflowOperator,
    computeRuntimes,
    sparkVersions,
    jobsEndpoint,
  } = props

  const [isCreateJobDialogOpen, setIsCreateJobDialogOpen] = useState(false)
  const [isJobDetailsDialogOpen, setIsJobDetailsDialogOpen] = useState(false)
  const [isDeleteJobDialogOpen, setIsDeleteJobDialogOpen] = useState(false)
  const [isResubmitJobDialogOpen, setIsResubmitJobDialogOpen] = useState(false)
  const [isJobLogsDialogOpen, setIsJobLogsDialogOpen] = useState(false)
  const [isJobSHSDialogOpen, setIsJobSHSDialogOpen] = useState(false)
  const [isJobAirflowOperatorDialogOpen, setIsJobAirflowOperatorDialogOpen] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState(true)
  const [searchJobId, setSearchJobId] = useState()
  const [jobToDelete, setJobToDelete] = useState()
  const [jobForLog, setJobForLog] = useState()
  const [urlSHS, setUrlSHS] = useState(null)
  const [jobForAirflowOperator, setJobForAirflowOperator] = useState()

  useEffect(() => {
    ReactGA.pageview('/jobs')
  }, [])

  const isFetchingEmrConfig = useMetaConfig({
    configFetcher: fetchEmrConfig,
    callback: listJobs,
    selectedRegion,
    selectedGroup,
    selectedFilter,
  })

  const isLoadingJobsDisplayPage = isFetchingEmrConfig || isListingJobs

  const filteredJobs = filterJobs(jobs, searchValue)

  function toggleCreateJobDialog() {
    setIsCreateJobDialogOpen((isOpen) => !isOpen)

    if (isCreateJobDialogOpen) {
      ReactGA.modalview('/jobs/create-job')
    }
  }

  function toggleJobDetailsDialog(job) {
    setIsJobDetailsDialogOpen(!isJobDetailsDialogOpen)

    if (!isJobDetailsDialogOpen) {
      describeJob(job.job_id)
    }
  }

  function toggleDeleteJobDialog(job) {
    setIsDeleteJobDialogOpen((isOpen) => !isOpen)
    console.log('toggleDeleteJobDialog')
    console.log('  job', job)
    setJobToDelete(job)
  }

  function toggleResubmitJobDialog(job) {
    setIsResubmitJobDialogOpen((isOpen) => !isOpen)

    if (!isResubmitJobDialogOpen) {
      describeJob(job.job_id)
    }
  }

  function toggleJobLogsDialog(job) {
    setIsJobLogsDialogOpen((isOpen) => !isOpen)
    setJobForLog(job)
  }

  function toggleJobSHSDialog() {
    setIsJobSHSDialogOpen((isOpen) => !isOpen)
  }

  async function handleSHS(jobId) {
    setUrlSHS(null)
    const headers = {
      Authorization: 'Bearer ' + authToken,
      'Content-Type': 'application/json',
    }

    const api_res = await fetch(`${jobsEndpoint}/shs/v1/${jobId}`, {
      method: 'POST',
      headers,
    })

    if (api_res.status !== 200) {
      toast.error('Spark History Server is not available', {
        autoClose: false,
        position: 'top-left',
      })
      toggleJobSHSDialog()
      return
    }
    const spark_history = JSON.parse(await api_res.text())
    setUrlSHS(spark_history.url)

    ReactGA.event({ category: 'JOBS', action: 'Spark History Server' })
  }

  function toggleJobAirflowOperatorDialog(job) {
    setIsJobAirflowOperatorDialogOpen((isOpen) => !isOpen)
    setJobForAirflowOperator(job)
    if (!isJobAirflowOperatorDialogOpen) {
      generateAirflowOperator(job.job_id)
    }
  }

  function handleRefreshJobs(event) {
    event.preventDefault()
    listJobs(selectedGroup, selectedFilter, false)
    ReactGA.event({ category: 'JOBS', action: 'Refresh Jobs' })
  }

  function handleRefreshJob(jobId) {
    describeJob(jobId).then(() => listJobs(selectedGroup, selectedFilter, false))
    ReactGA.event({ category: 'JOBS', action: 'Refresh Job' })
  }

  function handleChangeJobsFilter(selected) {
    setSelectedFilter(selected)
    ReactGA.event({
      category: 'JOBS',
      action: 'Filter user jobs',
      label: selected,
    })
  }

  function handleSearchJob(event) {
    event.preventDefault()
    listJobs(selectedGroup, selectedFilter, searchJobId)
  }

  const pageTitleConfig = { title: 'Jobs' }

  return (
    <Box>
      <PageTitle {...pageTitleConfig} />
      <form>
        <Flex
          justifyContent='space-between'
          style={{
            fontSize: '16px',
            backgroundColor: '#ececec',
            borderTop: '1px solid #ffffff',
            padding: '15px',
          }}
        >
          <Flex>
            <Button
              data-e2e='create-job-button'
              data-external-id='create-job'
              onClick={toggleCreateJobDialog}
              style={{ marginLeft: '8px' }}
            >
              Create Job
            </Button>
            <Button
              data-e2e='refresh-jobs-button'
              data-external-id='refresh-jobs'
              onClick={handleRefreshJobs}
              style={{ marginLeft: '8px' }}
              inverse
            >
              Refresh
            </Button>
          </Flex>
          <Flex>
            <ToggleSwitch
              data-e2e='filter-toggle-switch'
              onChange={handleChangeJobsFilter}
              options={[
                {
                  label: 'Created by me',
                  value: true,
                },
                {
                  label: 'Accessible by me',
                  value: false,
                },
              ]}
              value={selectedFilter}
            />
            <div style={{ padding: '6px 0 0 10px' }}>
              <ActionInput
                data-e2e='search-jobs-action-input'
                ariaLabel='search jobs'
                style={{ border: '2px solid #5197d6', fontSize: '14px' }}
                placeholder='Search a job ID'
                onChange={(event) => setSearchJobId(event.target.value)}
                onAction={handleSearchJob}
              />
            </div>
          </Flex>
        </Flex>
      </form>

      <JobsDisplayTable
        isLoadingJobsDisplayPage={isLoadingJobsDisplayPage}
        isListingJobs={isListingJobs}
        jobs={filteredJobs}
        toggleJobDetailsDialog={toggleJobDetailsDialog}
        toggleJobLogsDialog={toggleJobLogsDialog}
        toggleDeleteJobDialog={toggleDeleteJobDialog}
        toggleResubmitJobDialog={toggleResubmitJobDialog}
        toggleJobSHSDialog={toggleJobSHSDialog}
        handleSHS={handleSHS}
        toggleJobAirflowOperatorDialog={toggleJobAirflowOperatorDialog}
        handleRefreshJob={handleRefreshJob}
      />

      {!isListingJobs && (
        <CreateJobDialog
          isCreateJobDialogOpen={isCreateJobDialogOpen}
          toggleCreateJobDialog={toggleCreateJobDialog}
          createJob={createJob}
          listJobs={listJobs}
          isUserJobs={selectedFilter}
          selectedGroup={selectedGroup}
          selectedRegion={selectedRegion}
          computeRuntimes={computeRuntimes}
          sparkVersions={sparkVersions}
        />
      )}

      <JobDetailsDialog
        isJobDetailsDialogOpen={isJobDetailsDialogOpen}
        toggleJobDetailsDialog={toggleJobDetailsDialog}
        createJob={createJob}
        selectedJob={selectedJob}
        selectedGroup={selectedGroup}
        isUserJobs={selectedFilter}
        computeRuntimes={computeRuntimes}
        sparkVersions={sparkVersions}
      />

      <DeleteJobDialog
        isOpen={isDeleteJobDialogOpen}
        toggleDialog={toggleDeleteJobDialog}
        jobToDelete={jobToDelete}
        deleteJob={deleteJob}
      />

      <ResubmitJobDialog
        isOpen={isResubmitJobDialogOpen}
        toggleResubmitJobDialog={toggleResubmitJobDialog}
        createJob={createJob}
        selectedJob={selectedJob}
        selectedGroup={selectedGroup}
        isUserJobs={selectedFilter}
      />

      <JobLogsDialog
        isOpen={isJobLogsDialogOpen}
        toggleJobLogsDialog={toggleJobLogsDialog}
        jobForLog={jobForLog}
        authToken={authToken}
      />

      <JobSHSDialog
        isOpen={isJobSHSDialogOpen}
        toggleJobSHSDialog={toggleJobSHSDialog}
        urlSHS={urlSHS}
      />

      <JobAirflowOperatorDialog
        isOpen={isJobAirflowOperatorDialogOpen}
        toggleJobAirflowOperatorDialog={toggleJobAirflowOperatorDialog}
        jobForAirflowOperator={jobForAirflowOperator}
        airflowOperator={airflowOperator}
      />
    </Box>
  )
}

JobsDisplayPage.propTypes = {
  jobs: PropTypes.array,
  isListingJobs: PropTypes.bool,
  listJobs: PropTypes.func,
  toggleJobDetailsDialog: PropTypes.func,
  selectedJob: PropTypes.object,
  airflowOperator: PropTypes.array,
  selectedGroup: PropTypes.string,
}

const mapStateToProps = (state) => {
  return {
    jobs: selectJobs(state),
    isListingJobs: selectIsListingJobs(state),
    selectedJob: selectSelectedJob(state),
    authToken: selectAuthToken(state),
    airflowOperator: selectJobAirflowOperator(state),
    computeRuntimes: selectJobsComputeRuntimes(state),
    sparkVersions: selectJobsSparkVersions(state),
    jobsEndpoint: selectJobsEndpoint(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEmrConfig: () => dispatch(fetchEmrConfig()),
    createJob: (payload, selectedGroup, selectedFilter) =>
      dispatch(createJob(payload, selectedGroup, selectedFilter)),
    listJobs: (selectedGroup, selectedFilter, jobId) =>
      dispatch(listJobs(selectedGroup, selectedFilter, jobId)),
    describeJob: (job) => dispatch(describeJob(job)),
    deleteJob: (jobId) => dispatch(deleteJob(jobId)),
    generateAirflowOperator: (jobId) => dispatch(generateAirflowOperator(jobId)),
  }
}

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), ToJS)(JobsDisplayPage)

export default enhanced
