/** @jsx jsx */
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'
import { Modal, Button } from '@nike/epic-react-ui-old'
import { css, jsx } from '@emotion/core'
import { Flex, Box } from 'rebass'

import CreateEmrClusterForm from 'components/emr/create-emr-cluster-form'
import ToJS from 'components/common/to-js'

import { selectSelectedGroup } from 'selectors/selectSelectedGroup'
import { selectEmrVersion } from 'selectors/selectEmrVersion'
import { selectSupportedEmrVersionOptions } from 'selectors/selectSupportedEmrVersionOptions'
import { selectDefaultInstances } from 'selectors/selectDefaultInstances'
import { selectDefaultInstanceFleets } from 'selectors/selectDefaultInstanceFleets'
import { selectClusterSizeOptions } from 'selectors/selectClusterSizeOptions'
import { selectSupportedInstanceTypeOptions } from 'selectors/selectSupportedInstanceTypeOptions'
import { selectSupportedTaskInstanceTypeOptions } from 'selectors/selectSupportedTaskInstanceTypeOptions'
import {
  selectEmrRegularInstanceTypeCosts,
  selectEmrInstanceFleetInstanceTypeCosts,
  selectEmrRegularClusterCostsBySize,
  selectEmrInstanceClusterCostsBySize,
  selectInstanceTypeCostRanking,
} from 'selectors/selectEmrInstanceCosts'
import { selectEmrApplications } from 'selectors/selectEmrApplications'
import { selectIsRangerAllowed } from 'selectors/selectIsRangerAllowed'
import { selectIsBootstrapAllowed } from 'selectors/selectIsBootstrapAllowed'
import { selectIsManagedScalingAllowed } from 'selectors/selectIsManagedScalingAllowed'

import { setEmrVersion } from 'actions/setEmrVersion'

import { getInitialFieldValues, submit, validate } from 'utils/create-emr-cluster-form-helpers'

export function CreateEmrClusterDialog(props) {
  const { isCreateEmrClusterDialogOpen, toggleCreateEmrClusterDialog, submit } = props

  return (
    <Formik
      initialValues={getInitialFieldValues(props)}
      validate={validate}
      /* TODO: refactor enableReinitialize to clear the pre-selected form values when user accidentally closes the Create cluster dialog */
      // enableReinitialize
      onSubmit={(values, bag) => submit(values, bag, props)}
    >
      {(formikProps) => {
        const { handleSubmit, errors } = formikProps

        return (
          <Modal
            title='New EMR Cluster'
            show={isCreateEmrClusterDialogOpen}
            onClose={toggleCreateEmrClusterDialog}
            modalSize='lg'
            swoosh
            css={css`
              z-index: 500;
              height: 75vh;
              overflow: scroll;
            `}
          >
            <Flex flexDirection='column'>
              <Box style={{ height: '51vh', overflow: 'scroll' }}>
                <CreateEmrClusterForm {...props} formikProps={formikProps} />
              </Box>

              <Flex mt='15px' justifyContent='center'>
                <Button
                  data-e2e='create-emr-cluster-cancel-button'
                  data-external-id='create-emr-cluster-cancel'
                  id='create-emr-cluster-cancel-button'
                  onClick={toggleCreateEmrClusterDialog}
                  large
                  inverse
                  css={css`
                    margin-right: 10px;
                  `}
                >
                  Cancel
                </Button>

                <Button
                  data-e2e='create-emr-cluster-submit-button'
                  data-external-id='create-emr-cluster-submit'
                  id='create-emr-cluster-submit-button'
                  onClick={handleSubmit}
                  disabled={!isEmpty(errors)}
                  large
                  css={css`
                    margin-right: 20px;
                  `}
                >
                  Create Cluster
                </Button>
              </Flex>
            </Flex>
          </Modal>
        )
      }}
    </Formik>
  )
}

CreateEmrClusterDialog.propTypes = {
  isCreateEmrClusterDialogOpen: PropTypes.bool,
  toggleCreateEmrClusterDialog: PropTypes.func.isRequired,
  selectedRegion: PropTypes.string,
  selectedGroup: PropTypes.string.isRequired,
  emrVersion: PropTypes.string.isRequired,
  supportedCoreInstanceTypeOptions: PropTypes.array,
  supportedTaskInstanceTypeOptions: PropTypes.array,
  supportedEmrVersionOptions: PropTypes.array,
  defaultInstances: PropTypes.object,
  defaultInstanceFleets: PropTypes.object,
  emrRegularInstanceTypeCosts: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  emrInstanceFleetInstanceTypeCosts: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  emrRegularClusterCostsBySize: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  emrInstanceClusterCostsBySize: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  instanceTypeCostRanking: PropTypes.array,
  emrApplications: PropTypes.array,
  createEmrCluster: PropTypes.func.isRequired,
  setEmrVersion: PropTypes.func.isRequired,
  getInitialFieldValues: PropTypes.func,
  submit: PropTypes.func,
  validate: PropTypes.func,
  isRangerAllowed: PropTypes.bool,
  isBootstrapAllowed: PropTypes.bool,
  selectIsManagedScalingAllowed: PropTypes.bool,
}

CreateEmrClusterDialog.defaultProps = {
  isCreateEmrClusterDialogOpen: false,
  submit,
}

const mapStateToProps = (state) => {
  return {
    selectedGroup: selectSelectedGroup(state),
    emrVersion: selectEmrVersion(state),
    supportedInstanceTypeOptions: selectSupportedInstanceTypeOptions(state),
    supportedTaskInstanceTypeOptions: selectSupportedTaskInstanceTypeOptions(state),
    supportedEmrVersionOptions: selectSupportedEmrVersionOptions(state),
    defaultInstances: selectDefaultInstances(state),
    defaultInstanceFleets: selectDefaultInstanceFleets(state),
    clusterSizeOptions: selectClusterSizeOptions(state),
    emrRegularInstanceTypeCosts: selectEmrRegularInstanceTypeCosts(state),
    emrInstanceFleetInstanceTypeCosts: selectEmrInstanceFleetInstanceTypeCosts(state),
    emrRegularClusterCostsBySize: selectEmrRegularClusterCostsBySize(state),
    emrInstanceClusterCostsBySize: selectEmrInstanceClusterCostsBySize(state),
    instanceTypeCostRanking: selectInstanceTypeCostRanking(state),
    emrApplications: selectEmrApplications(state),
    isRangerAllowed: selectIsRangerAllowed(state),
    isBootstrapAllowed: selectIsBootstrapAllowed(state),
    isManagedScalingAllowed: selectIsManagedScalingAllowed(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setEmrVersion: (emrVersion) => dispatch(setEmrVersion(emrVersion)),
  }
}

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), ToJS)(CreateEmrClusterDialog)

export default enhanced
