import { createSelector } from 'reselect'
import { Map } from 'immutable'

import { selectEmrConfig } from 'selectors/selectEmrConfig'
import { selectEmrVersion } from 'selectors/selectEmrVersion'

export const selectEmrVersionConfig = createSelector(
  [selectEmrConfig, selectEmrVersion],
  (emrConfig, emrVersion) => {
    const emrVersionFormatted = 'emr' + emrVersion.replace(/\./g, '')

    return emrConfig.get(emrVersionFormatted) || new Map()
  }
)
