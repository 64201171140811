import { Map } from 'immutable'

import {
  FETCH_AIRFLOW_ONE_CONFIG_REQUEST,
  FETCH_AIRFLOW_ONE_CONFIG_SUCCESS,
  FETCH_AIRFLOW_ONE_CONFIG_FAILURE,
} from 'utils/constant'

const initialState = new Map({
  isFetching: false,
  data: new Map(),
})

export default function airflowOneConfig(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_AIRFLOW_ONE_CONFIG_REQUEST:
      return state.set('isFetching', true)

    case FETCH_AIRFLOW_ONE_CONFIG_SUCCESS:
      return state.set('data', action.airflowOneConfig).set('isFetching', false)

    case FETCH_AIRFLOW_ONE_CONFIG_FAILURE:
      return state.set('isFetching', false).set('data', new Map())

    default:
      return state
  }
}
