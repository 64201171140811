import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import IconButton from '@nike/nr-vamp-xl-icon-button'
import { faAngleDown } from '@fortawesome/fontawesome-free-solid'
import Menu from '@material-ui/core-new/Menu'
import MenuItem from '@material-ui/core-new/MenuItem'
import { Box, Flex } from 'rebass'
import {
  faStepForward,
  faCopy,
  faTrash,
  faGraduationCap,
} from '@fortawesome/fontawesome-free-solid'
import isEmpty from 'lodash.isempty'
import ReactGA from 'react-ga'

import { setCookie, deleteCookie } from 'utils/cookie'

import { selectSelfServiceIsSecure } from 'selectors/selectSelfServiceIsSecure'

export function JobsTableActionsMenu(props) {
  const { tableJob, setHasResetAutoTable, toggleDialog, selfServiceIsSecure, navigateToAutoTable } =
    props

  const [anchorEl, setAnchorEl] = useState(null)

  if (isEmpty(tableJob)) {
    return null
  }

  function onCheck(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleContinue(jobId) {
    setCookie('tableJobId', jobId)

    setHasResetAutoTable(false)

    navigateToAutoTable(1)

    ReactGA.event({ category: 'SELF-SERVICE', action: 'Continue table job' })
  }

  function handleClone(jobId) {
    deleteCookie('tableJobId')

    setCookie('cloneJobId', jobId)

    ReactGA.event({ category: 'SELF-SERVICE', action: 'Clone table job' })

    window.location.reload()
  }

  return (
    <Box ml={10}>
      <IconButton
        icon={faAngleDown}
        onCheck={onCheck}
        styleExtension={{
          container: { backgroundColor: 'transparent' },
          iconStyle: { '&:hover': { color: '#3498DB' } },
        }}
      />

      <Menu id='menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {tableJob.status.toLowerCase() !== 'failed' && (
          <MenuItem
            onClick={() => {
              handleClone(tableJob.job_id)
              handleClose()
            }}
          >
            <IconButton
              icon={faCopy}
              tooltip='CLONE'
              styleExtension={{
                container: { backgroundColor: 'transparent' },
              }}
            />
            CLONE
          </MenuItem>
        )}
        {tableJob.status.toLowerCase().includes('schema_inferr') && (
          <MenuItem
            onClick={() => {
              handleContinue(tableJob.job_id)
              handleClose()
            }}
          >
            <IconButton
              icon={faStepForward}
              tooltip='CONTINUE'
              styleExtension={{
                container: { backgroundColor: 'transparent' },
              }}
            />
            CONTINUE
          </MenuItem>
        )}

        {!selfServiceIsSecure &&
          (tableJob.status.toLowerCase() === 'created_table' ||
            tableJob.status.toLowerCase() === 'succeeded') && (
            <MenuItem
              onClick={() => {
                toggleDialog('Graduate', tableJob)
                handleClose()
              }}
            >
              <Flex justifyContent='space-between'>
                <IconButton
                  icon={faGraduationCap}
                  styleExtension={{
                    container: { backgroundColor: 'transparent' },
                  }}
                />

                <label style={{ marginLeft: '-5px' }}>GRADUATE</label>
              </Flex>
            </MenuItem>
          )}

        <MenuItem
          onClick={() => {
            toggleDialog('DeleteJobDialog', tableJob)
            handleClose()
          }}
        >
          <IconButton
            icon={faTrash}
            styleExtension={{
              container: { backgroundColor: 'transparent' },
            }}
          />
          DELETE
        </MenuItem>
      </Menu>
    </Box>
  )
}

JobsTableActionsMenu.propTypes = {
  tableJob: PropTypes.object,
  handleClone: PropTypes.func,
  handleContinue: PropTypes.func,
  match: PropTypes.object,
  toggleDeleteJobDialog: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    selfServiceIsSecure: selectSelfServiceIsSecure(state),
  }
}

export default connect(mapStateToProps)(JobsTableActionsMenu)
