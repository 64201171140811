/** @jsx jsx */
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { css, jsx } from '@emotion/core'
import { Modal } from '@nike/epic-react-ui-old'
import { Box } from 'rebass'

import ToJS from 'components/common/to-js'
import ResizableTable from 'components/common/resizable-table'

import { selectSupportedInstanceTypeOptions } from 'selectors/selectSupportedInstanceTypeOptions'

const columns = [
  {
    accessor: 'instance',
    Header: 'Instance Type',
  },
]

export function EmrNodeInfo(props) {
  const { isOpen, toggleEmrNodeInfoDialog, supportedInstanceTypeOptions } = props

  const data = supportedInstanceTypeOptions.map((supportedInstanceTypeOption) => ({
    instance: supportedInstanceTypeOption.value,
  }))

  return (
    <Modal
      title='Available EMR instance types'
      show={isOpen}
      onClose={toggleEmrNodeInfoDialog}
      modalSize='md'
      css={css`
        z-index: 500;
        height: 80vh;
        overflow: scroll;
      `}
    >
      <Box style={{ height: '70vh', overflow: 'scroll' }}>
        <ResizableTable data={data} columns={columns} noRowsMessage='No clusters found.' />
      </Box>
    </Modal>
  )
}

EmrNodeInfo.propTypes = {
  isOpen: PropTypes.bool,
  supportedInstanceTypeOptions: PropTypes.array,
  toggleEmrNodeInfoDialog: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    supportedInstanceTypeOptions: selectSupportedInstanceTypeOptions(state),
  }
}

const enhanced = compose(connect(mapStateToProps), ToJS)(EmrNodeInfo)

export default enhanced
