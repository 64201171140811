import { createSelector } from 'reselect'

import { selectGroups } from './selectGroups'

export const selectFilteredGroups = createSelector([selectGroups], (groups) =>
  groups.filter(
    (group) =>
      group !== 'Leads' &&
      group !== 'FireFighters' &&
      group !== 'PlatformDev' &&
      group !== 'GeneralAccess' &&
      group !== 'Platform'
  )
)
