import { UPDATE_SELECTED_GROUP } from 'utils/constant'

const initialState = ''

export default function selectedGroup(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_SELECTED_GROUP:
      return action.selectedGroup

    default:
      return state
  }
}
