import React from 'react'
import PropTypes from 'prop-types'
import { HomePage as ConsoleHomePage } from '@nike/console-integrated-platform'

function HomePage({ accessToken }) {
  const homePageConfig = {
    techSolutionId: 'adf81adb-3786-4f68-8c75-496d1bba8afc',
    accessToken,
  }
  return <ConsoleHomePage {...homePageConfig} />
}

HomePage.propTypes = {
  accessToken: PropTypes.string.isRequired,
}

export default HomePage
