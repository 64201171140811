import { getApiBaseUrl } from 'utils/getApiBaseUrl'
import { getSelfServiceEndpoint } from 'utils/getSelfServiceEndpoint'

export const ENV_PRODUCTION = 'production'
export const ENV_STAGING = 'staging'
export const ENV_DEVELOP = 'develop'
const environments = { one: ENV_PRODUCTION, 'one-stage': ENV_STAGING }

// TODO: parse with `new URL()`
const hostname = window.location.hostname.split('.')
if (hostname[0] === 'console') hostname[0] = 'one' // console.platforms.nike.com
if (hostname[0] === 'console-nonprod') hostname[0] = 'one-stage' // console-nonprod.platforms.nike.com
if (hostname[0] === 'console-dev') hostname[0] = 'one-dev' // console-dev.platforms.nike.com
export const subDomain = hostname[0] // one, one-stage or one-dev
export const environment = environments[subDomain] || ENV_DEVELOP

/* streams-test or cdt */
//export const account = hostname[1] === 'ngap2' ? 'cdt' : 'streams-test';
export const account = ['one', 'one-stage'].includes(hostname[0]) ? 'cdt' : 'streams-test'

export const domain = account === 'cdt' ? '.ngap2.nike.com' : '.ngap2.nike.com'

// QUESTION: Should be `/v2`?
export const arExitLogger = `${getApiBaseUrl(subDomain, domain)}/auth/assume-role-exit/v1`

export const googleAnalyticsTrackingId = 'UA-141370031-1'

export const SentryDSN = 'https://63a8aa264d61425fa6d610d1104c06ae@sentry.io/1327068'

const oktaHost = 'nike.okta.com'
const oktaAuthServerId = 'aus27z7p76as9Dz0H1t7'

let origin = window.location.origin
if (origin.includes('.platforms.nike.com')) origin += '/ngap'

export const oktaConfig = {
  issuer: `https://${oktaHost}/oauth2/${oktaAuthServerId}`,
  client_id: 'nike.cde.ngap2',
  scope: 'profile openid email',
  redirect_uri: origin + '/implicit/callback',
  pkce: false,
}

/* API endpoints */

export const PAAS_TOKEN_ENDPOINT = `${getApiBaseUrl(subDomain, domain)}/auth/okta-ngaptoken/v1`

export const SELF_SERVICE_ENDPOINT = getSelfServiceEndpoint()

export const HUE_ROLE_CHANGE_ENDPOINT =
  'https://0nh0z04w5l.execute-api.us-east-1.amazonaws.com/prod/change_role'

export const PUBLIC_KEY_ENDPOINT =
  'https://9ps0m0n8mf.execute-api.us-east-1.amazonaws.com/dev/get-key'

export const PROMO_MESSAGE_ENDPOINT = `https://biu0nwjmxl.execute-api.us-east-1.amazonaws.com/prod/promo?environment=${
  environment === ENV_DEVELOP ? 'prod' : 'prod'
}`

// NOTE: the below apply ONLY TO CN NORTHWEST
export const CNNW_EMR_STAGE_ENDPOINT = `https://compute-api.emr.ure.cnnw1.stage.dynamicanalytics.platforms.nike.com/emr`
export const CNNW_EMR_PROD_ENDPOINT = `https://compute-api.emr.ure.cnnw1.prod.dynamicanalytics.platforms.nike.com/emr`
// TODO: CNNW_EMR_STAGE_ENDPOINT **is not working**, so default to PROD for now.
export const CNNW_EMR_ENDPOINT =
  environment === ENV_DEVELOP ? CNNW_EMR_PROD_ENDPOINT : CNNW_EMR_PROD_ENDPOINT

export const META_ENDPOINT = `${getApiBaseUrl(subDomain, domain)}/meta`
export const FF_AUTH_ENDPOINT = `${getApiBaseUrl(subDomain, domain)}/auth`
export const COMMON_ENDPOINT = `${getApiBaseUrl(subDomain, domain)}/common`

export const FETCH_TOKEN_REQUEST = 'FETCH_TOKEN_REQUEST'
export const FETCH_TOKEN_SUCCESS = 'FETCH_TOKEN_SUCCESS'
export const FETCH_TOKEN_FAILURE = 'FETCH_TOKEN_FAILURE'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const CREATE_EMR_CLUSTER_REQUEST = 'CREATE_EMR_CLUSTER_REQUEST'
export const CREATE_EMR_CLUSTER_SUCCESS = 'CREATE_EMR_CLUSTER_SUCCESS'
export const CREATE_EMR_CLUSTER_FAILURE = 'CREATE_EMR_CLUSTER_FAILURE'

export const RESIZE_EMR_CLUSTER_REQUEST = 'RESIZE_EMR_CLUSTER_REQUEST'
export const RESIZE_EMR_CLUSTER_SUCCESS = 'RESIZE_EMR_CLUSTER_SUCCESS'
export const RESIZE_EMR_CLUSTER_FAILURE = 'RESIZE_EMR_CLUSTER_FAILURE'

export const LIST_EMR_CLUSTERS_REQUEST = 'LIST_EMR_CLUSTERS_REQUEST'
export const LIST_EMR_CLUSTERS_SUCCESS = 'LIST_EMR_CLUSTERS_SUCCESS'
export const LIST_EMR_CLUSTERS_FAILURE = 'LIST_EMR_CLUSTERS_FAILURE'

export const TERMINATE_EMR_CLUSTER_REQUEST = 'TERMINATE_EMR_CLUSTER_REQUEST'
export const TERMINATE_EMR_CLUSTER_SUCCESS = 'TERMINATE_EMR_CLUSTER_SUCCESS'
export const TERMINATE_EMR_CLUSTER_FAILURE = 'TERMINATE_EMR_CLUSTER_FAILURE'

export const LIST_AIRFLOW_CLUSTERS_REQUEST = 'LIST_AIRFLOW_CLUSTERS_REQUEST'
export const LIST_AIRFLOW_CLUSTERS_SUCCESS = 'LIST_AIRFLOW_CLUSTERS_SUCCESS'
export const LIST_AIRFLOW_CLUSTERS_FAILURE = 'LIST_AIRFLOW_CLUSTERS_FAILURE'

export const CREATE_TABLE_JOB_REQUEST = 'CREATE_TABLE_JOB_REQUEST'
export const CREATE_TABLE_JOB_SUCCESS = 'CREATE_TABLE_JOB_SUCCESS'
export const CREATE_TABLE_JOB_FAILURE = 'CREATE_TABLE_JOB_FAILURE'

export const LIST_TABLE_JOBS_REQUEST = 'LIST_TABLE_JOBS_REQUEST'
export const LIST_TABLE_JOBS_SUCCESS = 'LIST_TABLE_JOBS_SUCCESS'
export const LIST_TABLE_JOBS_FAILURE = 'LIST_TABLE_JOBS_FAILURE'

export const CREATE_JOB_REQUEST = 'CREATE_JOB_REQUEST'
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS'
export const CREATE_JOB_FAILURE = 'CREATE_JOB_FAILURE'

export const LIST_JOBS_REQUEST = 'LIST_JOBS_REQUEST'
export const LIST_JOBS_SUCCESS = 'LIST_JOBS_SUCCESS'
export const LIST_JOBS_FAILURE = 'LIST_JOBS_FAILURE'

export const DESCRIBE_JOB_REQUEST = 'DESCRIBE_JOB_REQUEST'
export const DESCRIBE_JOB_SUCCESS = 'DESCRIBE_JOB_SUCCESS'
export const DESCRIBE_JOB_FAILURE = 'DESCRIBE_JOB_FAILURE'

export const DELETE_JOB_REQUEST = 'DELETE_JOB_REQUEST'
export const DELETE_JOB_SUCCESS = 'DELETE_JOB_SUCCESS'
export const DELETE_JOB_FAILURE = 'DELETE_JOB_FAILURE'

export const GENERATE_AIRFLOW_OPERATOR_REQUEST = 'GENERATE_AIRFLOW_OPERATOR_REQUEST'
export const GENERATE_AIRFLOW_OPERATOR_SUCCESS = 'GENERATE_AIRFLOW_OPERATOR_SUCCESS'
export const GENERATE_AIRFLOW_OPERATOR_FAILURE = 'GENERATE_AIRFLOW_OPERATOR_FAILURE'

export const FETCH_GROUP_CONFIG_REQUEST = 'FETCH_GROUP_CONFIG_REQUEST'
export const FETCH_GROUP_CONFIG_SUCCESS = 'FETCH_GROUP_CONFIG_SUCCESS'
export const FETCH_GROUP_CONFIG_FAILURE = 'FETCH_GROUP_CONFIG_FAILURE'

export const FETCH_SELF_SERVICE_CONFIG_REQUEST = 'FETCH_SELF_SERVICE_CONFIG_REQUEST'
export const FETCH_SELF_SERVICE_CONFIG_SUCCESS = 'FETCH_SELF_SERVICE_CONFIG_SUCCESS'
export const FETCH_SELF_SERVICE_CONFIG_FAILURE = 'FETCH_SELF_SERVICE_CONFIG_FAILURE'

export const FETCH_EMR_CONFIG_REQUEST = 'FETCH_EMR_CONFIG_REQUEST'
export const FETCH_EMR_CONFIG_SUCCESS = 'FETCH_EMR_CONFIG_SUCCESS'
export const FETCH_EMR_CONFIG_FAILURE = 'FETCH_EMR_CONFIG_FAILURE'

export const FETCH_AIRFLOW_CONFIG_REQUEST = 'FETCH_AIRFLOW_CONFIG_REQUEST'
export const FETCH_AIRFLOW_CONFIG_SUCCESS = 'FETCH_AIRFLOW_CONFIG_SUCCESS'
export const FETCH_AIRFLOW_CONFIG_FAILURE = 'FETCH_AIRFLOW_CONFIG_FAILURE'

export const FETCH_AIRFLOW_ONE_CONFIG_REQUEST = 'FETCH_AIRFLOW_ONE_CONFIG_REQUEST'
export const FETCH_AIRFLOW_ONE_CONFIG_SUCCESS = 'FETCH_AIRFLOW_ONE_CONFIG_SUCCESS'
export const FETCH_AIRFLOW_ONE_CONFIG_FAILURE = 'FETCH_AIRFLOW_ONE_CONFIG_FAILURE'

export const FETCH_METADATA_EXPLORER_CONFIG_REQUEST = 'FETCH_METADATA_EXPLORER_CONFIG_REQUEST'
export const FETCH_METADATA_EXPLORER_CONFIG_SUCCESS = 'FETCH_METADATA_EXPLORER_CONFIG_SUCCESS'
export const FETCH_METADATA_EXPLORER_CONFIG_FAILURE = 'FETCH_METADATA_EXPLORER_CONFIG_FAILURE'

export const UPDATE_SELECTED_GROUP = 'UPDATE_SELECTED_GROUP'

export const UPDATE_SELECTED_REGION = 'UPDATE_SELECTED_REGION'

export const UPDATE_SELECTED_ACCOUNT = 'UPDATE_SELECTED_ACCOUNT'

export const UPDATE_SELECTED_RUNTIME = 'UPDATE_SELECTED_RUNTIME'

export const SET_EMR_VERSION = 'SET_EMR_VERSION'

export const SET_EMR_CLUSTER_STATUS = 'SET_EMR_CLUSTER_STATUS'

export const DELETE_TABLE_JOB_REQUEST = 'DELETE_TABLE_JOB_REQUEST'
export const DELETE_TABLE_JOB_SUCCESS = 'DELETE_TABLE_JOB_SUCCESS'
export const DELETE_TABLE_JOB_FAILURE = 'DELETE_TABLE_JOB_FAILURE'

export const overviewTableStyles = {
  container: {
    ' .ReactVirtualized__Table__headerRow': { paddingLeft: '10px !important' },
    minWidth: '1650px',
  },
  tableCell: { paddingLeft: '20px !important' },
}

export const selfServiceWGPrivacyStatement = `With great data . . . Comes great responsibility.
 
  At Nike, we are all responsible for properly handling the personal data entrusted to us. Responsible data handling involves making sure that data is used, shared, and stored consistent with Nike’s Privacy values and Security principles.
   
  By using this tool, you will be accessing secured walled garden data. Access to this data is restricted within Nike and there are certain responsibilities you assume when you access and use this data.
   
  * You should only access this data when absolutely necessary to enable your work at Nike
  * You should only access and use the minimum amount of data necessary for your work
  * You must not retain, copy, or store any of this data outside of existing locations. If temporary storage is needed to perform your work, please contact Privacy and Security for instructions. 
  * If you need to share any of this data internally, please work with both Privacy and Security to ensure that the data is shared consisted with our Privacy and Security principles. 
  * Before you use any of this data for marketing use cases, or any use case that might directly impact a consumer, please consult with the Privacy Team.
   
  For any Privacy questions, please contact Privacy.Office@nike.com
  You can also learn more about Nike’s Privacy values in our NikeU training “Privacy Matters.”
   
  For answers to your security questions or to learn more about Nike’s Information Security Program please visit nisp.nike.com`

export const appShortName = 'ngap2'
export const appFullName = 'Advanced Compute'
