import React from 'react'
import PropTypes from 'prop-types'

const defaultStyle = {
  display: 'block',
  width: '100%',
  height: '100vh',
  overflow: 'auto',
  padding: '0',
}

function Iframe({ title, src, style, onLoad }) {
  return <iframe title={title} src={src} style={style} onLoad={onLoad} />
}

Iframe.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string,
  style: PropTypes.object,
  onLoad: PropTypes.func,
  sandbox: PropTypes.string,
}

Iframe.defaultProps = {
  title: '',
  src: '',
  style: defaultStyle,
  onLoad: () => {},
  sandbox: '',
}

export default Iframe
