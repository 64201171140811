/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box } from 'rebass'
import { Select, FormSection, TextInput, TextArea } from '@nike/epic-react-ui-old'
import { jsx } from '@emotion/core'
import { Form } from 'formik'

import { jobTypeOptions, createSelectOptionsFromArray } from 'utils/create-job-form-helpers'

export default function CreateJobForm(props) {
  const { formikProps, newJob, computeRuntimes, sparkVersions } = props

  const { values, touched, errors, handleChange, setFieldValue, setFieldTouched } = formikProps

  const handleRuntimeChange = (option) => {
    setFieldValue('databricksToken', '')
    setFieldTouched('databricksToken', false)
    setFieldValue('runtime', option)
  }

  const runtimeOptions = computeRuntimes ? createSelectOptionsFromArray(computeRuntimes) : []
  const sparkVersionOptions = sparkVersions ? createSelectOptionsFromArray(sparkVersions) : []

  return (
    <Form>
      <Box>
        <FormSection>
          <Box style={{ position: 'relative' }}>
            <TextInput
              data-e2e='job-name-textinput'
              name='jobName'
              label='Job Name'
              value={values.jobName}
              onChange={handleChange}
            />
            <Select
              id='job-type-select'
              name='jobType'
              label='Job Type'
              options={jobTypeOptions}
              placeholder='Select'
              value={values.jobType}
              onChange={(option) => setFieldValue('jobType', option)}
            />
            <TextArea
              data-e2e='job-arguments-textarea'
              name='jobArguments'
              label='Job Arguments'
              style={{ minHeight: '120px' }}
              placeholder='Enter your job arguments here. Provide only one per line.&#10;Example:&#10;arg1&#10;arg2'
              value={values.jobArguments}
              onChange={handleChange}
            />
            <TextInput
              data-e2e='job-entry-point-textinput'
              name='jobEntryPoint'
              label='Job Entry Point'
              placeholder='Example: s3://ngap2-cdt-platform/spark-common-pattern-data/spark_demo/test-wc_2.11-0.1.jar'
              value={values.jobEntryPoint}
              onChange={handleChange}
            />
            <TextInput
              data-e2e='class-name-textinput'
              name='className'
              label='Class Name'
              placeholder='Example: src.sample.min_max'
              value={values.className}
              onChange={handleChange}
            />
            <TextArea
              data-e2e='job-conf-textarea'
              name='jobConf'
              label='Spark Configuration'
              style={{ minHeight: '120px' }}
              placeholder='Enter your Spark configuration options here. Provide only one key-value pair per line.&#10;Example:&#10;spark.eventLog.enabled true&#10;spark.reducer.maxSizeInFlight 48m'
              value={values.jobConf}
              onChange={handleChange}
            />
            <TextArea
              data-e2e='files-textarea'
              name='files'
              label='Files'
              style={{ minHeight: '120px' }}
              placeholder='Enter a list of files to be placed in the working directory of each executor. Provide only one filepath per line.&#10;Example:&#10;s3://path/to/file/1&#10;s3://path/to/file/2'
              value={values.files}
              onChange={handleChange}
            />
            <TextArea
              data-e2e='env-textarea'
              name='env'
              label='Environment Variables'
              style={{ minHeight: '120px' }}
              placeholder='Enter your environment variables here. Provide only one key-value pair per line.&#10;Example:&#10;PYSPARK_PYTHON /databricks/python3/bin/python3'
              value={values.env}
              onChange={handleChange}
            />
            <Select
              id='spark-version-select'
              name='version'
              label='Spark Version'
              options={sparkVersionOptions}
              value={values.version}
              onChange={(option) => setFieldValue('version', option)}
            />
            <TextArea
              data-e2e='hints-textarea'
              name='hints'
              label='Hints'
              style={{ minHeight: '120px' }}
              placeholder='This option offers additional handles to be injected to influence platform decisions. Provide only one key-value pair per line.&#10;Example:&#10;eap.compute.runtime.version 6.4.x-scala2.11&#10;eap.spark.cluster.worker.numnodes 5'
              value={values.hints}
              onChange={handleChange}
            />
            <Select
              id='runtime-select'
              name='runtime'
              label='Runtime'
              options={runtimeOptions}
              placeholder='Select'
              value={values.runtime}
              onChange={handleRuntimeChange}
              isOptionDisabled={(option) => option.disabled === 'yes'}
              isDisabled={!newJob}
            />
            {values?.runtime?.value === 'databricks' && (
              <TextInput
                data-e2e='databricks-token-textinput'
                name='databricksToken'
                label='Databricks Token'
                value={values.databricksToken}
                onChange={handleChange}
                hasErrors={errors.databricksToken && touched.databricksToken}
                errorMessage={errors.databricksToken}
                required
              />
            )}
          </Box>
        </FormSection>
      </Box>
    </Form>
  )
}

CreateJobForm.propTypes = {
  formikProps: PropTypes.object.isRequired,
}
