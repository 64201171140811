import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rebass'
import styled from '@emotion/styled'
import { addDays } from 'utils/addDate'
import moment from 'moment'
import ReactGA from 'react-ga'

import DetailsGroup from 'components/common/details-group'
import WalledGardenWarningDialog from 'components/self-service/walled-garden-warning-dialog'

const Message = styled.p`
  color: #f4562c;
  font-weight: bold;
`

export default function ReviewFields({ values, selfServiceIsSecure, setAutoTableActiveStep }) {
  const {
    sourceLocation,
    fileFormat,
    tableName,
    dbName,
    tableDataLocation,
    jobName,
    scheduleType,
    cadence,
    scheduleStart,
    scheduleEnd,
    schemaEvolution,
    sendAlert,
    onSuccess,
    onFailure,
    email,
    inferredColumns,
    inferredPartitionColumns,
    inferredFileStructure,
  } = values

  const [showWGWarningDialog, setShowWGWarningDialog] = useState(selfServiceIsSecure)

  function onWGTermsAccept() {
    setShowWGWarningDialog(false)

    ReactGA.event({ category: 'SELF-SERVICE', action: 'Accept WG terms' })
  }

  function onWGTermsDecline() {
    setShowWGWarningDialog(false)

    setAutoTableActiveStep(0) // navigate to Basic Details

    ReactGA.event({ category: 'SELF-SERVICE', action: 'Decline WG terms' })
  }

  const inputDataFields = [
    { label: 'Input data location', value: sourceLocation },
    {
      label: 'File format',
      value: inferredFileStructure,
    },
  ]

  const tableFields = [
    { label: 'Table name', value: tableName },
    { label: 'DB name', value: dbName },
    {
      label: 'Table data location',
      value:
        scheduleType.value === 'one-time' && fileFormat === 'csv'
          ? sourceLocation
          : tableDataLocation,
    },
  ]

  let mergedColumns = inferredColumns.concat(values.curatedColumns)

  const inferredColumnsFields = mergedColumns.map((inferredColumn) => ({
    label: inferredColumn.column_name,
    value: inferredColumn.datatype,
    function_name: inferredColumn.function_name
      ? ' - Function:(' + inferredColumn.function_name + ')'
      : '',
    function_input: inferredColumn.function_input
      ? ' - Function Parameters:(' + inferredColumn.function_input + ')'
      : '',
  }))

  const inferredPartitionColumnsFields = inferredPartitionColumns.map(
    (inferredPartitionColumn) => ({
      label: inferredPartitionColumn.column_name,
      value: inferredPartitionColumn.datatype,
    })
  )

  const dataRefreshFields = [
    { label: 'Schedule type', value: scheduleType.label },
    { label: 'Schema evolution', value: schemaEvolution.label },
  ]

  if (scheduleType.value === 'scheduled') {
    dataRefreshFields.push(
      { label: 'Cadence', value: cadence.label },
      { label: 'Schedule start', value: scheduleStart },
      { label: 'Schedule end', value: scheduleEnd }
    )
  }

  dataRefreshFields.push({
    label: 'Receive alerts',
    value: sendAlert ? 'Yes' : 'No',
  })

  if (sendAlert) {
    dataRefreshFields.push(
      {
        label: 'On success',
        value: onSuccess ? 'Yes' : 'No',
      },
      {
        label: 'On failure',
        value: onFailure ? 'Yes' : 'No',
      },
      { label: 'Email', value: email }
    )
  }

  dataRefreshFields.push({ label: 'Job name', value: jobName })

  return (
    <Box p='30px 100px'>
      <Message>{`Notice: Your table and data would be retained until  ${moment(
        addDays(new Date(scheduleStart), 90)
      ).format(
        'MM/DD/YYYY'
      )} (90 days). Please choose "Graduate" under actions in JOBS page if the data needs to be persisted beyond 90 days.`}</Message>

      <Box mt='30px'>
        <DetailsGroup title='Input Data' fields={inputDataFields} />

        <DetailsGroup title='Table' fields={tableFields} />

        {inferredColumns.length > 0 && (
          <DetailsGroup title='Columns' fields={inferredColumnsFields} />
        )}

        {inferredPartitionColumns.length > 0 && (
          <DetailsGroup title='Partition Columns' fields={inferredPartitionColumnsFields} />
        )}

        <DetailsGroup title='Data Refresh & Maintenance' fields={dataRefreshFields} />
      </Box>

      <WalledGardenWarningDialog
        show={showWGWarningDialog}
        onAccept={onWGTermsAccept}
        onDecline={onWGTermsDecline}
      />
    </Box>
  )
}

ReviewFields.propTypes = {
  values: PropTypes.object.isRequired,
}
