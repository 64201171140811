import { UPDATE_SELECTED_ACCOUNT } from 'utils/constant'

const initialState = ''

export default function selectedAccount(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_SELECTED_ACCOUNT:
      return action.selectedAccount || ''

    default:
      return state
  }
}
