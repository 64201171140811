/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Box } from 'rebass'

import NgapLogo from 'images/ngap-logo.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import { useNarkOnPageLoad } from 'utils/nark.js'
import PageTitle from '../../common/page-title'

const pageName = 'request-access-page'
export default function RequestAccessPage() {
  useNarkOnPageLoad(pageName)
  const requestAccessLink = 'https://confluence.nike.com/display/BIPS'
  const hrefStyle = { textDecoration: 'underline' }
  const iconStyle = { color: '#757575', fontSize: '12px' }

  const pageTitleConfig = { title: 'Request Access' }

  return (
    <Box
      m='30px'
      css={css`
        text-align: center;
        z-index: 10;
      `}
    >
      <PageTitle {...pageTitleConfig} />
      <img
        css={css`
          width: 350px;
          margin: 0 auto;
        `}
        src={NgapLogo}
        alt='ngap-logo'
      />

      <Box
        mt='20px'
        css={css`
          text-align: center;
        `}
      >
        <h1
          css={css`
            font-size: 30px;
            font-weight: bold;
          `}
        >
          You do not have access to Advanced Compute. Follow the links below for requesting access.
        </h1>

        <Box
          css={css`
            font-size: 20px;
          `}
          mt='30px'
        >
          <p>
            If you think you should be part of an existing Advanced Compute Group,{' '}
            <a
              css={css`
                text-decoration: underline;
              `}
              href={requestAccessLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              request
            </a>{' '}
            access via "Advanced Compute User Access".
          </p>

          <p
            css={css`
              margin-top: 30px;
            `}
          >
            If you want to onboard your team/use case to Advanced Compute,{' '}
            <a
              css={css`
                text-decoration: underline;
              `}
              href={requestAccessLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              request
            </a>{' '}
            via "Platform Intake".
          </p>
        </Box>

        <Box
          css={css`
            font-size: 20px;
          `}
          mt='30px'
        >
          <p>
            If you're seeing this page and you <i>do</i> have access, check out these scenarios:
          </p>
          <ul>
            <li>
              <FontAwesomeIcon icon={faChevronCircleRight} style={iconStyle} />{' '}
              <a
                style={hrefStyle}
                target='_blank'
                rel='noopener noreferrer'
                href='https://confluence.nike.com/display/DAA/NGAP+2+User+Documentation#cache'
              >
                Clear your Advanced Compute cache
              </a>
            </li>
            <li>
              <FontAwesomeIcon icon={faChevronCircleRight} style={iconStyle} />{' '}
              <a
                style={hrefStyle}
                target='_blank'
                rel='noopener noreferrer'
                href='https://niketech.service-now.com/nikenow/?id=nike_kb_article&sys_id=a592363313b19a00c83f7a42f244b06a'
              >
                See if you're connected to WHQ VPN
              </a>
            </li>
            <li>
              <FontAwesomeIcon icon={faChevronCircleRight} style={iconStyle} /> You've changed your
              Nike password recently and it is still syncing
            </li>
          </ul>
        </Box>
      </Box>
    </Box>
  )
}
