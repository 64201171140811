import { createSelector } from 'reselect'
import { List } from 'immutable'

const emrConfig = (state) => state.getIn(['emrConfig', 'data'])

export const selectEmrRegularInstanceTypeCosts = createSelector(
  [emrConfig],
  (emrConfig) => emrConfig.get('emr_on_demand_costs') || new List()
)

export const selectEmrInstanceFleetInstanceTypeCosts = createSelector(
  [emrConfig],
  (emrConfig) => emrConfig.get('emr_instance_fleet_costs') || new List()
)

export const selectEmrRegularClusterCostsBySize = createSelector(
  [emrConfig],
  (emrConfig) => emrConfig.get('emr_cluster_cost_by_size') || new List()
)

export const selectEmrInstanceClusterCostsBySize = createSelector(
  [emrConfig],
  (emrConfig) => emrConfig.get('emr_instance_cluster_cost_by_size') || new List()
)

export const selectInstanceTypeCostRanking = createSelector(
  [emrConfig],
  (emrConfig) => emrConfig.get('instanceTypeCostRanking') || new List()
)
