import { fromJS } from 'immutable'
import { create } from 'axios'
import { toast } from 'react-toastify'

import {
  META_ENDPOINT,
  FETCH_AIRFLOW_ONE_CONFIG_REQUEST,
  FETCH_AIRFLOW_ONE_CONFIG_SUCCESS,
  FETCH_AIRFLOW_ONE_CONFIG_FAILURE,
} from 'utils/constant'

import { selectSelectedGroup } from 'selectors/selectSelectedGroup'
import { selectSelectedRegion } from 'selectors/selectSelectedRegion'
import { selectSelectedAccount } from 'selectors/selectSelectedAccount'
import { selectSelectedRuntime } from 'selectors/selectSelectedRuntime'

function fetchAirflowOneConfigRequest() {
  return {
    type: FETCH_AIRFLOW_ONE_CONFIG_REQUEST,
  }
}

function fetchAirflowOneConfigSuccess(airflowOneConfig) {
  return {
    type: FETCH_AIRFLOW_ONE_CONFIG_SUCCESS,
    airflowOneConfig,
  }
}

function fetchAirflowOneConfigFailure() {
  return {
    type: FETCH_AIRFLOW_ONE_CONFIG_FAILURE,
  }
}

export async function fetchAirflowOneConfig() {
  return function (dispatch, getState) {
    dispatch(fetchAirflowOneConfigRequest())

    const selectedGroup = selectSelectedGroup(getState())
    const selectedRegion = selectSelectedRegion(getState())
    const selectedAccount = selectSelectedAccount(getState())
    const selectedRuntime = selectSelectedRuntime(getState())

    return create()
      .get(
        `${META_ENDPOINT}/airflowone/v1?ngap_group=${selectedGroup}&ngap_region=${selectedRegion}&account=${selectedAccount}&runtime=${selectedRuntime}`,
        {
          transformRequest: [
            (data, headers) => {
              delete headers.common.Authorization
              return data
            },
          ],
        }
      )
      .then((response) => dispatch(fetchAirflowOneConfigSuccess(fromJS(response.data))))
      .catch((error) => {
        dispatch(fetchAirflowOneConfigFailure(error))

        if (error.response) {
          toast.error(`AirflowOne configuration not found in ${selectedRegion.toUpperCase()}`, {
            autoClose: false,
            position: 'top-left',
          })
        }
      })
  }
}
