import React, { useMemo } from 'react'
import moment from 'moment'
import ReactGA from 'react-ga'
import { IconButton } from '@nike/epic-react-ui-old'

import ApplicationsMenu from 'components/common/applications-menu'
import ResizableTable from 'components/common/resizable-table'

import { isLongRunningCluster } from 'utils/isLongRunningCluster'

const getColumns = (isUserLead) => {
  const columns = [
    {
      accessor: 'name',
      Header: 'Name',
    },
    {
      accessor: 'creator',
      Header: 'Creator',
    },
    {
      accessor: 'applications',
      Header: 'Applications',
      Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
    },
    {
      accessor: 'version',
      Header: 'EMR Version',
    },
    {
      accessor: 'dataAccess',
      Header: 'Data Access',
      Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
    },
    {
      accessor: 'created',
      Header: 'Created',
    },
    {
      accessor: 'uptime',
      Header: 'Uptime',
      Cell: ({ value }) => (
        <span style={{ color: isLongRunningCluster(value) ? '#fe0000' : '#000000' }}>{value}</span>
      ),
    },
    {
      accessor: 'status',
      Header: 'Status',
    },
    {
      accessor: 'details',
      Header: 'Details',
    },
    {
      accessor: 'terminate',
      Header: 'Terminate',
    },
  ]

  if (isUserLead) {
    columns.push({
      accessor: 'resize',
      Header: 'Resize',
    })
  }

  return columns
}

const getData = (props) => {
  const {
    emrClusters,
    toggleSshDialog,
    toggleEmrLog,
    toggleGenieLog,
    authToken,
    username,
    selectedRegion,
    selectedGroup,
    toggleTerminateEmrClusterDialog,
    toggleResizeEmrClusterDialog,
    toggleEmrClusterDetailsDialog,
    applicationLinks,
    applicationLinkGAInfo,
  } = props

  const handleResizeClick = (emrCluster) => () => {
    toggleResizeEmrClusterDialog(emrCluster)
    ReactGA.modalview('/emr/resize')
  }

  const handleDetailsClick = (emrCluster) => () => {
    toggleEmrClusterDetailsDialog(emrCluster)
    ReactGA.modalview('/emr/details')
  }

  const handleTerminateClick = (emrCluster) => () => {
    toggleTerminateEmrClusterDialog(emrCluster)
    ReactGA.modalview('/emr/terminate')
  }

  return emrClusters
    ? emrClusters.map((emrCluster) => ({
        name: emrCluster.cluster_name,
        creator: emrCluster.created_by,
        applications:
          emrCluster &&
          (emrCluster.cluster_status === 'READY' ||
            emrCluster.cluster_status === 'TERMINATED' ||
            emrCluster.cluster_status === 'FAILED') ? (
            <ApplicationsMenu
              activeCluster={emrCluster}
              activeTab='emr'
              toggleSshDialog={toggleSshDialog}
              toggleEmrLog={toggleEmrLog}
              toggleGenieLog={toggleGenieLog}
              authToken={authToken}
              username={username}
              selectedRegion={selectedRegion}
              selectedGroup={selectedGroup}
              applicationLinks={applicationLinks}
              applicationLinkGAInfo={applicationLinkGAInfo}
            />
          ) : (
            <span>(pending)</span>
          ),
        version: emrCluster.version && emrCluster.version.replace('emr-', ''),
        dataAccess:
          emrCluster.cerebro === 'true' ? 'Okera' : emrCluster.ranger === true ? 'Ranger' : '',
        created: moment(emrCluster.created_at).format('MMM D hh:mm:ss a'),
        uptime: emrCluster.terminated_at
          ? moment(emrCluster.terminated_at).from(emrCluster.created_at, true)
          : moment().from(emrCluster.created_at, true),
        status: (
          <span
            style={{
              color:
                emrCluster.cluster_status === 'READY'
                  ? 'rgb(72, 189, 31)'
                  : emrCluster.cluster_status === 'TERMINATED' ||
                    emrCluster.cluster_status === 'FAILED'
                  ? '#fe0000'
                  : '#000000',
            }}
          >
            {emrCluster.cluster_status}
          </span>
        ),
        details: (
          <IconButton
            data-e2e='emr-details-button'
            type='information'
            iconFontSize='24px'
            bare
            onClick={handleDetailsClick(emrCluster)}
            style={{ width: '100%' }}
          />
        ),
        resize:
          emrCluster.cluster_status === 'TERMINATED' ||
          emrCluster.cluster_status === 'FAILED' ? null : (
            <IconButton
              data-e2e='emr-edit-button'
              type='edit'
              iconFontSize='24px'
              bare
              onClick={handleResizeClick(emrCluster)}
              style={{ width: '100%' }}
            />
          ),
        terminate:
          emrCluster.cluster_status === 'TERMINATED' ||
          emrCluster.cluster_status === 'FAILED' ? null : (
            <IconButton
              data-e2e='emr-terminate-button'
              type='delete'
              iconFontSize='24px'
              bare
              onClick={handleTerminateClick(emrCluster)}
              style={{ width: '100%' }}
            />
          ),
      }))
    : []
}

function EmrClustersTable(props) {
  const { isLoadingEmrPage, isUserLead } = props

  const data = getData(props)
  const columns = useMemo(() => getColumns(isUserLead), [isUserLead])

  return (
    <div data-e2e='emr-clusters-table'>
      <ResizableTable
        columns={columns}
        data={data}
        isLoading={isLoadingEmrPage}
        noRowsMessage='No clusters found.'
      />
    </div>
  )
}

export default EmrClustersTable
