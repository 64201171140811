/** @jsx jsx */
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@nike/epic-react-ui-old'
import { create } from 'axios'
import isEmpty from 'lodash.isempty'
import { css, jsx } from '@emotion/core'
import { toast } from 'react-toastify'

import ScheduledJobsTable from 'components/self-service/scheduled-jobs-table'

import { SELF_SERVICE_ENDPOINT } from 'utils/constant'

export function ScheduledJobDialog(props) {
  const { activeJob, isScheduledJobDialogOpen, toggleDialog } = props

  const [isFetchingScheduledJobs, setIsFetchingScheduledJobs] = useState(false)

  const [scheduledJobs, setScheduledJobs] = useState([])

  const [isRerunJob, setIsRerunJob] = useState(false)

  useEffect(fetchScheduledJobs, [activeJob])

  function fetchScheduledJobs() {
    if (isEmpty(activeJob)) {
      return
    }

    setIsFetchingScheduledJobs(true)

    create()
      .get(`${SELF_SERVICE_ENDPOINT}/scheduled_jobs/v1/${activeJob.job_id}`)
      .then((response) => {
        setScheduledJobs(response.data)
      })
      .finally(() => {
        setIsFetchingScheduledJobs(false)
      })
  }

  function handleRerun(scheduledJobId) {
    setIsRerunJob(true)

    create()
      .post(`${SELF_SERVICE_ENDPOINT}/rerun_scheduled_job/v1/${scheduledJobId}`)
      .then(() => toast.success('Rerun successful', { autoClose: false, position: 'top-left' }))
      .catch((error) =>
        toast.error(`Unable to rerun job: ${error && error.response ? error.response.data : ''}`, {
          autoClose: false,
          position: 'top-left',
        })
      )
      .finally(() => {
        setIsRerunJob(false)
      })
  }

  function closeDialog() {
    toggleDialog('ScheduledJobDialog')
  }

  return (
    <Modal
      title={`${activeJob.job_name}`}
      show={isScheduledJobDialogOpen}
      onClose={closeDialog}
      modalSize='lg'
      css={css`
        z-index: 500;
      `}
    >
      <ScheduledJobsTable
        isRerunJob={isRerunJob}
        isFetchingScheduledJobs={isFetchingScheduledJobs}
        scheduledJobs={scheduledJobs}
        handleRerun={handleRerun}
      />
    </Modal>
  )
}

ScheduledJobDialog.propTypes = {
  isScheduledJobDialogOpen: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  activeJob: PropTypes.object.isRequired,
}

export default ScheduledJobDialog
