import { createSelector } from 'reselect'

import { selectIsFetchingAirflowConfig } from 'selectors/selectIsFetchingAirflowConfig'

const isFetchingAirflowClusters = (state) => state.getIn(['airflowClusters', 'isFetching'])

export const selectIsFetchingAirflowClusters = createSelector(
  [isFetchingAirflowClusters, selectIsFetchingAirflowConfig],
  (isFetchingAirflowClusters, isFetchingAirflowConfig) =>
    isFetchingAirflowClusters || isFetchingAirflowConfig
)
