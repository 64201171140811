import { createSelector } from 'reselect'
import { selectSelfServiceS3RoAcls } from 'selectors/selectSelfServiceS3RoAcls'
import { selectSelfServiceS3RwAcls } from 'selectors/selectSelfServiceS3RwAcls'

import { getS3AclMap } from 'utils/getS3AclMap'
export const selectS3AclMap = createSelector(
  [selectSelfServiceS3RoAcls, selectSelfServiceS3RwAcls],
  (selfServiceS3RoAcls, selfServiceS3RwAcls) =>
    getS3AclMap(selfServiceS3RoAcls, selfServiceS3RwAcls)
)
