/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, Flex } from 'rebass'
import Divider from '@material-ui/core-new/Divider'
import { css, jsx } from '@emotion/core'

export default function DetailsGroup(props) {
  const { title, children, marginTop, innerWidth, fields } = props

  return (
    <Box mt={marginTop}>
      <h3
        css={css`
          font-size: 16px;
          font-weight: bold;
        `}
      >
        {title}
      </h3>

      <Divider />

      {children ? (
        children
      ) : (
        <Box mt={30} width={innerWidth}>
          {fields
            .filter((field) => !(field.error && !field.value))
            .map((field, index) => (
              <Flex mb={30} key={index}>
                <label
                  css={css`
                    font-weight: bold;
                    margin-right: 30px;
                    margin-left: 100px;
                    text-align: right;
                    width: 50%;
                  `}
                >
                  {field.label}:
                </label>

                <span
                  css={css`
                    width: 100%;
                    color: ${field.error ? '#FE0000' : '#000000'};
                  `}
                >
                  {String(field.value) +
                    ' ' +
                    (field.function_name ? String(field.function_name) : '') +
                    ' ' +
                    (field.function_input ? String(field.function_input) : '')}
                </span>
              </Flex>
            ))}
        </Box>
      )}
    </Box>
  )
}

DetailsGroup.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  marginTop: PropTypes.number,
}

DetailsGroup.defaultProps = {
  marginTop: 0,
  innerWidth: '100%',
}
