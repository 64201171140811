import { fromJS } from 'immutable'
import { create } from 'axios'

import {
  LIST_EMR_CLUSTERS_REQUEST,
  LIST_EMR_CLUSTERS_SUCCESS,
  LIST_EMR_CLUSTERS_FAILURE,
} from 'utils/constant'

import { selectEmrEndpoint } from 'selectors/selectEmrEndpoint'

import { fetchEmrConfigFailure } from './fetchEmrConfig'

function listEmrClustersRequest() {
  return {
    type: LIST_EMR_CLUSTERS_REQUEST,
  }
}

function listEmrClustersSuccess(emrClusters) {
  return {
    type: LIST_EMR_CLUSTERS_SUCCESS,
    emrClusters,
  }
}

function listEmrClustersFailure() {
  return {
    type: LIST_EMR_CLUSTERS_FAILURE,
  }
}

export async function listEmrClusters() {
  return function (dispatch, getState) {
    const endpoint = selectEmrEndpoint(getState())

    if (!endpoint) {
      dispatch(fetchEmrConfigFailure())
      return
    }

    const selectedGroup = getState().get('selectedGroup')

    const emrClusterStatus = getState().get('selectedEmrClusterStatus') || ''

    dispatch(listEmrClustersRequest())

    return create()
      .get(
        `${endpoint}/role?group=${selectedGroup}&cluster_status=${emrClusterStatus.toUpperCase()}`
      )
      .then((response) => dispatch(listEmrClustersSuccess(fromJS(response.data))))
      .catch((error) => {
        dispatch(listEmrClustersFailure(error))
      })
  }
}
