import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rebass'

function TabContainer({ hidden, children }) {
  return <Box style={{ display: hidden ? 'none' : 'block' }}>{children}</Box>
}

TabContainer.propTypes = {
  hidden: PropTypes.bool,
  children: PropTypes.any,
}

export default TabContainer
