import { createSelector } from 'reselect'
import { List } from 'immutable'

import { selectGroupConfig } from './selectGroupConfig'
import { selectSelectedAccount } from './selectSelectedAccount'
import { selectSelectedRegion } from './selectSelectedRegion'

export const selectRuntimes = createSelector(
  [selectGroupConfig, selectSelectedAccount, selectSelectedRegion],
  (groupConfig, selectedAccount, selectedRegion) => {
    const runtimes = groupConfig.getIn(['group_services', selectedAccount, selectedRegion])

    return runtimes ? List(runtimes.keySeq()) : new List()
  }
)
