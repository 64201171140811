import { List, Map } from 'immutable'

import {
  LIST_AIRFLOW_CLUSTERS_REQUEST,
  LIST_AIRFLOW_CLUSTERS_SUCCESS,
  LIST_AIRFLOW_CLUSTERS_FAILURE,
  FETCH_AIRFLOW_CONFIG_FAILURE,
} from 'utils/constant'

const initialState = new Map({
  isFetching: false,
  data: new List(),
})

export default function airflowClusters(state = initialState, action = {}) {
  switch (action.type) {
    case LIST_AIRFLOW_CLUSTERS_REQUEST:
      return state.set('isFetching', true)

    case LIST_AIRFLOW_CLUSTERS_SUCCESS:
      return state.set('data', action.airflowClusters).set('isFetching', false)

    case LIST_AIRFLOW_CLUSTERS_FAILURE:
      return state.set('isFetching', false)

    case FETCH_AIRFLOW_CONFIG_FAILURE:
      return state.set('data', new List())

    default:
      return state
  }
}
