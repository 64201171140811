import { fromJS } from 'immutable'
import { create } from 'axios'
import { toast } from 'react-toastify'

import { selectSelectedGroup } from 'selectors/selectSelectedGroup'
import { selectSelectedRegion } from 'selectors/selectSelectedRegion'
import { selectSelectedAccount } from 'selectors/selectSelectedAccount'
import { selectSelectedRuntime } from 'selectors/selectSelectedRuntime'

import {
  META_ENDPOINT,
  FETCH_METADATA_EXPLORER_CONFIG_REQUEST,
  FETCH_METADATA_EXPLORER_CONFIG_SUCCESS,
  FETCH_METADATA_EXPLORER_CONFIG_FAILURE,
} from 'utils/constant'

function fetchMetadataExplorerConfigRequest() {
  return {
    type: FETCH_METADATA_EXPLORER_CONFIG_REQUEST,
  }
}

function fetchMetadataExplorerConfigSuccess(metadataExplorerConfig) {
  return {
    type: FETCH_METADATA_EXPLORER_CONFIG_SUCCESS,
    metadataExplorerConfig,
  }
}

function fetchEmrConfigFailure() {
  return {
    type: FETCH_METADATA_EXPLORER_CONFIG_FAILURE,
  }
}

export async function fetchMetadataExplorerConfig() {
  return function (dispatch, getState) {
    dispatch(fetchMetadataExplorerConfigRequest())

    const selectedGroup = selectSelectedGroup(getState())
    const selectedRegion = selectSelectedRegion(getState())
    const selectedAccount = selectSelectedAccount(getState())
    const selectedRuntime = selectSelectedRuntime(getState())

    return create()
      .get(
        `${META_ENDPOINT}/metadata-explorer/v1?ngap_group=${selectedGroup}&ngap_region=${selectedRegion}&account=${selectedAccount}&runtime=${selectedRuntime}`,
        {
          transformRequest: [
            (data, headers) => {
              delete headers.common.Authorization
              return data
            },
          ],
        }
      )
      .then((response) => dispatch(fetchMetadataExplorerConfigSuccess(fromJS(response.data))))
      .catch((error) => {
        dispatch(fetchEmrConfigFailure(error))

        if (error.response) {
          toast.error(`Metadata Explorer configuration not found in ${selectedRegion}`, {
            autoClose: false,
            position: 'top-left',
          })
        }
      })
  }
}
