/** @jsx jsx */
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { fromJS } from 'immutable'
import axios from 'axios'
import { connect } from 'react-redux'
import { Modal } from '@nike/epic-react-ui-old'
import { css, jsx } from '@emotion/core'

import EmrClusterDetails from 'components/emr/emr-cluster-details'

import { selectEmrEndpoint } from 'selectors/selectEmrEndpoint'

export function EmrClusterDetailsDialog(props) {
  const { isOpen, toggleDialog, activeCluster, endpoint } = props

  const [isInstanceFleet, setIsInstanceFleet] = useState(false)
  const [isFetchingInstanceFleetDescription, setIsFetchingInstanceFleetDescription] =
    useState(false)
  const [instanceFleetDescription, setInstanceFleetDescription] = useState(null)

  useEffect(() => {
    function fetchInstanceFleetDescription(emrClusterName) {
      return axios.get(`${endpoint}/instancefleets/${emrClusterName}`)
    }

    if (isOpen) {
      if (activeCluster.cluster_status.toLowerCase() !== 'ready') {
        return
      }

      setIsFetchingInstanceFleetDescription(true)

      fetchInstanceFleetDescription(activeCluster.cluster_name)
        .then((response) => {
          setIsInstanceFleet(true)
          setInstanceFleetDescription(fromJS(response.data))
        })
        .catch(() => setIsInstanceFleet(false))
        .finally(() => setIsFetchingInstanceFleetDescription(false))
    } else {
      // reset instanceFleetDescription when modal is closed
      setIsInstanceFleet(false)
      setInstanceFleetDescription(null)
    }
  }, [isOpen, activeCluster, endpoint])

  return (
    <Modal
      title={activeCluster.cluster_name}
      show={isOpen}
      onClose={toggleDialog}
      modalSize='md'
      swoosh
      css={css`
        z-index: 500;
      `}
    >
      <EmrClusterDetails
        activeCluster={activeCluster}
        isInstanceFleet={isInstanceFleet}
        isFetchingInstanceFleetDescription={isFetchingInstanceFleetDescription}
        instanceFleetDescription={instanceFleetDescription}
      />
    </Modal>
  )
}

EmrClusterDetailsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  activeCluster: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    endpoint: selectEmrEndpoint(state),
  }
}

export default connect(mapStateToProps)(EmrClusterDetailsDialog)
