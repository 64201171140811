import { UPDATE_SELECTED_RUNTIME } from 'utils/constant'

const initialState = ''

export default function selectedRuntime(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_SELECTED_RUNTIME:
      return action.selectedRuntime || ''

    default:
      return state
  }
}
