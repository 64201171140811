import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash.isempty'
import { Box } from 'rebass'
import { Map } from 'immutable'

import DetailsGroup from 'components/common/details-group'

const getFleetIndexes = (instanceFleets) => {
  let indexes = new Map()

  if (!isEmpty(instanceFleets)) {
    for (let i = 0; i < instanceFleets.size; i++) {
      //indexes[instanceFleets[i].InstanceFleetType.toLowerCase()] = i;
      indexes = indexes.set(`${instanceFleets.getIn([i, 'InstanceFleetType']).toLowerCase()}`, i)
    }
  }

  return indexes
}

export default function DescribeInstanceFleetFields(props) {
  const { instanceFleetDescription, masterIp } = props

  if (!instanceFleetDescription.get('InstanceFleets')) {
    return null
  }

  const fleetIndexes = getFleetIndexes(instanceFleetDescription.get('InstanceFleets'))

  const masterFields = [
    {
      label: 'Master IP',
      value: masterIp,
    },
    {
      label: 'Master instance type',
      value: instanceFleetDescription
        .getIn(['InstanceFleets', fleetIndexes.get('master'), 'InstanceTypeSpecifications'])
        .map((instanceType) => instanceType.get('InstanceType'))
        .join(', '),
    },
    {
      label: 'On demand capacity',
      value: String(
        instanceFleetDescription.getIn([
          'InstanceFleets',
          fleetIndexes.get('master'),
          'ProvisionedOnDemandCapacity',
        ])
      ),
    },
    {
      label: 'Spot capacity',
      value: String(
        instanceFleetDescription.getIn([
          'InstanceFleets',
          fleetIndexes.get('master'),
          'ProvisionedSpotCapacity',
        ])
      ),
    },
  ]

  const coreFields = [
    {
      label: 'Core instance type',
      value: instanceFleetDescription
        .getIn(['InstanceFleets', fleetIndexes.get('core'), 'InstanceTypeSpecifications'])
        .map((instanceType) => instanceType.get('InstanceType'))
        .join(', '),
    },
    {
      label: 'On demand capacity',
      value: String(
        instanceFleetDescription.getIn([
          'InstanceFleets',
          fleetIndexes.get('core'),
          'ProvisionedOnDemandCapacity',
        ])
      ),
    },
    {
      label: 'Spot capacity',
      value: String(
        instanceFleetDescription.getIn([
          'InstanceFleets',
          fleetIndexes.get('core'),
          'ProvisionedSpotCapacity',
        ])
      ),
    },
  ]

  const hasTaskInstance = instanceFleetDescription.getIn([
    'InstanceFleets',
    fleetIndexes.get('task'),
  ])

  let taskFields = []

  if (hasTaskInstance) {
    taskFields = [
      {
        label: 'Task instance type',
        value: instanceFleetDescription
          .getIn(['InstanceFleets', fleetIndexes.get('task'), 'InstanceTypeSpecifications'])
          .map((instanceType) => instanceType.get('InstanceType'))
          .join(', '),
      },
      {
        label: 'On demand capacity',
        value: String(
          instanceFleetDescription.getIn([
            'InstanceFleets',
            fleetIndexes.get('task'),
            'ProvisionedOnDemandCapacity',
          ])
        ),
      },
      {
        label: 'Spot capacity',
        value: String(
          instanceFleetDescription.getIn([
            'InstanceFleets',
            fleetIndexes.get('task'),
            'ProvisionedSpotCapacity',
          ])
        ),
      },
    ]
  }

  return (
    <Box width='70%' m='0 auto' mt={30}>
      <DetailsGroup title='Master' fields={masterFields} />

      <DetailsGroup title='Core' fields={coreFields} />

      {hasTaskInstance && <DetailsGroup title='Task' fields={taskFields} />}
    </Box>
  )
}

DescribeInstanceFleetFields.propTypes = {
  instanceFleetDescription: PropTypes.instanceOf(Map),
  marginLeft: PropTypes.number,
  masterIp: PropTypes.string,
}

DescribeInstanceFleetFields.defaultProps = {
  marginLeft: '0px',
  masterIp: '',
}
