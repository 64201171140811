/** @jsx jsx */
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Flex } from 'rebass'
import TextField from '@material-ui/core-new/TextField'
import { css, jsx } from '@emotion/core'
import { Select, FormSection, TextInput, Checkbox, Icon, Tooltip } from '@nike/epic-react-ui-old'

import { getCookie } from 'utils/cookie'
import { formatDate } from 'utils/formatDate'

import { usePrevious } from 'hooks/usePrevious'

const scheduleTypeOptions = [
  {
    label: 'One-time',
    value: 'one-time',
  },
  {
    label: 'Recurring',
    value: 'scheduled',
  },
]

const cadenceOptions = [
  {
    label: 'Daily',
    value: 'daily',
  },
  {
    label: 'Weekly',
    value: 'weekly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
]

const schemaEvolutionOptions = [
  {
    label: 'Allow Additives Changes',
    value: 'add_update',
  },
  {
    label: 'Suspend Data Refresh',
    value: 'no_update',
  },
]

const infoIconStyle = css`
  margin-left: 15px;
  color: #3498db;
`

export default function BasicDetailsFields({
  selectedGroup,
  username,
  setSourceLocationWasEdited,
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  setFieldTouched,
  errors,
  touched,
  setSavedJobId,
  hasResetAutoTable,
  selfServiceIsSecure,
}) {
  useEffect(() => {
    if (!touched.tableDataLocation && !getCookie('tableJobId')) {
      let dataLocation = selfServiceIsSecure
        ? `s3://digital-pschemas-ttl-secure-east/${selectedGroup}/${username}/${values.tableName}`.toLowerCase()
        : `s3://ngap2-user-data/${selectedGroup}/${username}/${values.tableName}`.toLowerCase()
      setFieldValue('tableDataLocation', dataLocation)
    }
  }, [
    touched.tableDataLocation,
    setFieldValue,
    selectedGroup,
    username,
    values.tableName,
    selfServiceIsSecure,
  ])

  useEffect(() => {
    if (!touched.jobName) {
      const now = new Date()

      const nowISO = new Date(now.toUTCString()).toISOString()

      const nowFormatted = nowISO.substr(0, nowISO.indexOf('.'))

      setFieldValue('jobName', `${values.tableName}_${username}_${nowFormatted}`)
    }
  }, [touched.jobName, setFieldValue, username, values.tableName, values.jobName])

  const previousSourceLocation = usePrevious(values.sourceLocation)

  useEffect(() => {
    if (previousSourceLocation && previousSourceLocation !== values.sourceLocation) {
      setSourceLocationWasEdited(true)
    }
  }, [previousSourceLocation, values.sourceLocation, setSourceLocationWasEdited])

  useEffect(() => {
    if (!getCookie('cloneJobId')) {
      setSavedJobId('')
    } // reset job id when user returns to basic details from define table, unless a clone job id is present
  }, [setSavedJobId])

  return (
    <Box>
      <FormSection title='Input Data'>
        <Flex>
          <TextInput
            name='sourceLocation'
            label='Input data location'
            placeholder='Example: s3://digital-ingress-east/sample'
            value={values.sourceLocation}
            onChange={handleChange}
            onBlur={handleBlur}
            hasErrors={
              (errors.sourceLocation && touched.sourceLocation) ||
              (errors.sourceLocation && hasResetAutoTable)
            }
            errorMessage={
              (errors.sourceLocation && touched.sourceLocation) || hasResetAutoTable
                ? errors.sourceLocation
                : ''
            }
            required
          />

          <Tooltip
            position='right'
            message="Provide location of your data on S3. Please make sure you've “read” access to the data before proceeding. You could utilize data-lake browser tool to locate it, as well as test your access"
          >
            <div>
              <Icon type='information' fontSize='20px' css={infoIconStyle} />
            </div>
          </Tooltip>
        </Flex>
      </FormSection>

      <FormSection title='Table'>
        <Flex>
          <TextInput
            name='tableName'
            label='Table name'
            placeholder='Example: my_table'
            value={values.tableName}
            onChange={handleChange}
            onBlur={handleBlur}
            hasErrors={
              (errors.tableName && touched.tableName) || (errors.tableName && hasResetAutoTable)
            }
            errorMessage={
              (errors.tableName && touched.tableName) || hasResetAutoTable ? errors.tableName : ''
            }
            required
          />

          <Tooltip
            position='right'
            message='Name of your new table. Hint: Only alphanumeric characters and underscores (_) are allowed in table name and it should start with an Alphabet. Max 64 characters limit'
          >
            <div>
              <Icon type='information' fontSize='20px' css={infoIconStyle} />
            </div>
          </Tooltip>
        </Flex>

        <Flex>
          <TextInput
            name='dbName'
            value={values.dbName}
            label='Database name'
            disabled
            css={css`
              margin-top: 20px;
              width: 95%;
            `}
          />
        </Flex>

        <Flex mt='20px'>
          <TextInput
            name='tableDataLocation'
            label='Table data location'
            placeholder='S3 path'
            value={values.tableDataLocation}
            onChange={handleChange}
            onBlur={handleBlur}
            hasErrors={
              (errors.tableDataLocation && touched.tableDataLocation) ||
              (errors.tableDataLocation && hasResetAutoTable)
            }
            errorMessage={
              (errors.tableDataLocation && touched.tableDataLocation) || hasResetAutoTable
                ? errors.tableDataLocation
                : ''
            }
          />

          <Tooltip
            position='right'
            message="Every table needs a place to store backend data. Provide S3 location where your table data should be stored. This field is prepoluated with your default user folder. Please note, it's not recommended to host big tables in user folders; and data stored in user folder generally has 90 day TTL"
          >
            <div>
              <Icon type='information' fontSize='20px' css={infoIconStyle} />
            </div>
          </Tooltip>
        </Flex>
      </FormSection>

      <FormSection title='Data Refresh & Maintenance'>
        <Flex>
          <Box width='95%'>
            <Select
              name='scheduleType'
              options={scheduleTypeOptions}
              label='Schedule type'
              placeholder='Schedule type'
              value={values.scheduleType}
              onChange={(option) => setFieldValue('scheduleType', option)}
              onBlur={handleBlur}
            />
          </Box>
        </Flex>

        <Flex mt='20px'>
          <Box width='95%'>
            <Select
              name='schemaEvolution'
              options={schemaEvolutionOptions}
              label='Schema evolution options'
              placeholder='Schema evolution options'
              value={values.schemaEvolution}
              onChange={(option) => setFieldValue('schemaEvolution', option)}
              onBlur={handleBlur}
              isDisabled={!(values.scheduleType.value === 'scheduled')}
            />
          </Box>

          <Tooltip
            position='right'
            message={`Schema Evolution: change/addition/discontinuance of fields in input data feed. 
              This option is only available in “Auto Detect” mode.
              *Allow Additives Changes*: Add new columns to the table automatically. Field Name changes are processed as new columns. discontinuance is Ignored.
              *Suspend Data Refresh*: If new columns appear in input data then fail load process and suspend future schedules. Field Name changes would cause suspension. discontinuance is Ignored.
              *Ignore Changes*: No action`}
            type='action'
          >
            <div>
              <Icon type='information' fontSize='20px' css={infoIconStyle} />
            </div>
          </Tooltip>
        </Flex>

        <Flex mt='20px'>
          <Box width='95%'>
            <Select
              name='cadence'
              options={cadenceOptions}
              label='Cadence'
              placeholder='Cadence'
              value={values.cadence}
              onChange={(option) => setFieldValue('cadence', option)}
              onBlur={handleBlur}
              isDisabled={values.scheduleType.value !== 'scheduled'}
            />
          </Box>
        </Flex>

        <Flex mt='30px' ml='10px'>
          <TextField
            label='Start date'
            type='datetime-local'
            value={values.scheduleStart}
            onChange={(e) => {
              setFieldValue('scheduleStart', e.target.value)
            }}
            disabled={values.scheduleType.value !== 'scheduled'}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ inputProps: { min: formatDate(new Date()) } }}
            css={css`
              width: 50%;
            `}
          />

          <TextField
            label='End date'
            type='datetime-local'
            value={values.scheduleEnd}
            onChange={(e) => {
              setFieldValue('scheduleEnd', e.target.value)
            }}
            disabled={values.scheduleType.value !== 'scheduled'}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ inputProps: { min: formatDate(new Date()) } }}
            css={css`
              width: 50%;
            `}
          />
        </Flex>

        <Box mt='30px'>
          <Checkbox
            name='sendAlert'
            label='Send alerts'
            checked={values.sendAlert}
            onChange={handleChange}
          />

          {values.sendAlert && (
            <Box>
              <Checkbox
                name='onSuccess'
                label='On success'
                checked={values.onSuccess}
                onChange={handleChange}
              />

              <Checkbox
                name='onFailure'
                label='On failure'
                checked={values.onFailure}
                onChange={handleChange}
              />

              <TextInput
                name='email'
                label='Email list'
                placeholder='Emails separated by comma'
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                hasErrors={(errors.email && touched.email) || (errors.email && hasResetAutoTable)}
                errorMessage={
                  (errors.email && touched.email) || hasResetAutoTable ? errors.email : ''
                }
                css={css`
                  margin-top: 20px;
                `}
              />
            </Box>
          )}
        </Box>

        <Box mt='20px'>
          <TextInput
            name='jobName'
            label='Job name'
            placeholder='Job name'
            value={values.jobName}
            onChange={(event) => {
              if (!touched.jobName) {
                setFieldTouched('jobName')
              }
              handleChange(event)
            }}
            onBlur={handleBlur}
          />
        </Box>
      </FormSection>
    </Box>
  )
}

BasicDetailsFields.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
}
